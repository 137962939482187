import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "gettext",
})
export class GettextPipe implements PipeTransform {
	// ######################################
	// FUNCTION: transform
	transform(value: any) {
		return value.etichetta;
	}
}
