import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule } from '@ngx-translate/core';
import { NgZorroModule } from 'src/app/ng-zorro.module';
import { QuestionarioComponent } from './questionario.component';
import { QuestionarioRoutingModule } from './questionario-routing.module';
import { HeadComponent } from './head/head.component';
import { DomandeComponent } from '../questionario/domande/domande.component';
import { AggiungidomandaComponent } from './domande/aggiungidomanda/aggiungidomanda.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TrattiComponent } from './tratti/tratti.component';
import { AttendibilitaComponent } from './attendibilita/attendibilita.component';
import { ForzaturaComponent } from './forzatura/forzatura.component';
import { PdfmodalComponent } from './head/pdfmodal/pdfmodal.component';
import { GettextPipe } from 'src/app/pipes/gettext.pipe';
import { QuillModule } from 'ngx-quill';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    QuestionarioComponent,
    HeadComponent,
    DomandeComponent,
    AggiungidomandaComponent,
    TrattiComponent,
    AttendibilitaComponent,
    ForzaturaComponent,
    PdfmodalComponent,
    GettextPipe
],
  imports: [
    QuestionarioRoutingModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgZorroModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateModule,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    }),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'size': ['small', false, 'large', 'huge'] }]
        ],
      },
      placeholder: 'Inserisci testo...',
      theme: 'snow'
    }),
  ],
  exports: [QuestionarioComponent, NgZorroModule, DragDropModule],
  providers: [AuthService, AuthGuard],
})
export class QuestionarioModule { }
