<div class="uiCompilazioneEndContainer">
    <nz-layout class="uiEndInnerLayout">
        <nz-header class="uiCompilazioneEndHeader">
            <img src="../../../../assets/logo-light.png" alt="logo" class="uiHeaderLogo">
        </nz-header>
        <nz-content class="uiCompilazioneEndContent">
            <h1>Accesso vietato</h1>
            <h3>Non hai i permessi per accedere a questo link!</h3>
        </nz-content>
    </nz-layout>
</div>
<app-legal-footer></app-legal-footer>

