import { Component, OnInit } from "@angular/core";
import { CompileService } from "src/app/services/compile.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
  selector: "app-conclusione",
  templateUrl: "./conclusione.component.html",
  styleUrls: ["./conclusione.component.scss"],
})
export class ConclusioneComponent implements OnInit {
  questTitle: string;
  questSubTitle: string;
  questVersion: string;
  user: any;

  // ######################################
  // FUNCTION: constructor
  constructor(
    public compile: CompileService,
    public g: GlobalService
  ) { }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {

  }

  // ######################################
  // FUNCTION: close
  close() {
    var isiPad = navigator.userAgent.match(/iPad/i) != null;
    var isiPhone = navigator.userAgent.match(/iPhone/i) != null;
    if (isiPad || isiPhone) {
      window.location.href = "https://www.amajorsb.com";
    } else {
      window.close();
    }
  }
}
