import { browser } from "protractor";
import { AfterContentInit, Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";
import { User } from "src/app/entities/users";
import { ChartType, GoogleChartComponent } from "angular-google-charts";

@Component({
  selector: "app-edit-valutazioni",
  templateUrl: "./edit-valutazioni.component.html",
  styleUrls: ["./edit-valutazioni.component.scss"],
})
export class EditValutazioniComponent implements OnInit, AfterContentInit {
  @ViewChild("chart") gChart: any;

  search: string = "";
  isCollapsed = false;
  aId: string = "";
  lId: string = "";
  cId: string = "";
  loggedIn: User;
  data: any[] = [];
  displayedData: any[] = [];
  intestazione: any[] = [];
  questionario: any[] = [];
  loading: boolean = true;
  loadingChart: boolean = true;
  valText: string = "";
  scroll = { y: "" };
  bSave: boolean = false;

  // ######################################
  // FUNCTION: constructor
  constructor(
    private location: Location,
    public auth: AuthService,
    private route: ActivatedRoute,
    private fire: FireService,
    private node: NodeService,
    public g: GlobalService
  ) { }

  // ######################################
  // EVENT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();

    this.g.chartData.forEach((c, index) => {
      if (event.target.innerWidth < 1240) {
        c[0] = this.questionario["tratti"][index].codice;
      } else {
        c[0] = this.questionario["tratti"][index].titolo["ITA"];
      }
    });
    this.g.chartData = [...this.g.chartData];
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiGestioneEditVTable").length > 0) {
      let height = document.getElementsByClassName("uiGestioneEditVTable")[0].clientHeight;
      if (document.getElementsByClassName("uiGestioneEditVTable")[0].getElementsByClassName("ant-table-thead").length) {
        let headH = document
          .getElementsByClassName("uiGestioneEditVTable")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit() {
    this.loggedIn = this.auth.getUser();
    this.route.params.subscribe((param) => {
      this.aId = param.aId;
      this.lId = param.lId;
      this.cId = param.cId;
    });

    this.fire.getSubDoc("clienti", this.aId, "licenze", this.lId, "compilazioni", this.cId).subscribe((res) => {
      this.data[0] = res;
      this.displayedData[0] = res;

      if (this.data[0].commento) {
        this.valText = this.data[0].commento;
      }
      this.createData();
      this.loading = false;
      // setTimeout(() => {
      this.setScrollHeight();
      // }, 200);
    });

    // ['R1', 20, 'color: #661615;', 20],
    // ['R2', 40, 'color: #661615', 40],
    // ['R3', 60, 'color: #661615', 60],
    // ['R4', 80, 'color: #661615', 80],
    // ['R5', -20, 'color: #661615', -20],
    // ['R6', -40, 'color: #661615', -40],
    // ['R7', -60, 'color: #661615', -60],
    // ['R8', -80, 'color: #661615', -80]
  }

  ngAfterContentInit(): void {

  }

  // ######################################
  // FUNCTION: back
  back() {
    this.location.back();
  }

  // ######################################
  // FUNCTION: createData
  createData() {
    this.fire
      .getDocument("clienti", this.data[0].licenza.azienda, "licenze", this.data[0].licenza.licenza)
      .subscribe(async (lic) => {
        await this.fire.getDocument("questionari", lic.questionario, "versioni", lic.versione).subscribe((q) => {
          this.questionario = q;
          this.intestazione = [];
          this.bSave = this.questionario["report"] != 2 ? true : false;

          // CREO L'INSTESTAZIONE NEL GIUSTO ORDINE
          for (let key in this.data[0].intestazione) {
            var rec = q.anagrafica.find((a) => a.etichetta == key);

            this.intestazione.push({
              ordine: rec ? rec.ordine : 99,
              etichetta: key,
              valore: this.data[0].intestazione[key],
            });
          }
          this.intestazione.sort((a, b) => {
            if (a.ordine == b.ordine) return a.etichetta > b.etichetta ? 1 : -1;
            else return a.ordine > b.ordine ? 1 : -1;
          });

          // #################
          this.g.chartDisplay(this.data[0], this.questionario);
          window.dispatchEvent(new Event('resize'));
        });
      });
  }

  // ######################################
  // FUNCTION: save
  save() {
    this.data[0].commento = this.valText;
    this.fire.updateSubDoc("clienti", "licenze", "compilazioni", this.data[0], this.aId, this.lId, this.cId);
  }

  // ######################################
  // FUNCTION: saveAndExit
  saveAndExit() {
    this.save();
    this.location.back();
  }
}
