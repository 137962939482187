<nz-content>
    <div class="uiContainer">
        <nz-layout class="uiCompilazioneInnerLayout">
            <nz-content class="uiCompilazioneContent">
                <div class="uiCompilazioneInnerContent">
                    <router-outlet></router-outlet>
                </div>
            </nz-content>
        </nz-layout>
    </div>
</nz-content>