import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit } from "@angular/core";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  CryptoJS = require("crypto-js");

  code: string;
  password: string;
  username: string;
  showError: boolean;
  modalSuccess: boolean;
  modalError: boolean;

  recoverVisible: boolean = false;

  // ######################################
  // FUNCTION: constructor
  constructor(
    private auth: AuthClienteService,
    private translate: TranslateService,
    private g: GlobalService,
    private node: NodeService,
    private router: Router
  ) { }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    this.password = "";
    this.username = "";
    this.showError = false;
  }

  // ######################################
  // FUNCTION: onSubmit
  /**
  * @desc check if typed credentials are corrects.
    IF CORRECT: return all user's fields and save it in localstorage.
      IF ADMIN: navigate to /admin
      IF NOT ADMIN: navigate to /homepage and add Log
    OTHERWISE: show error
  * @param -
  * @return void
  */
  async onSubmit() {
    const crypt: string = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(this.password));
    const formData = {
      pwd: crypt,
      user: this.username,
    };

    this.showError = false;
    this.auth.checkLogin(formData).subscribe(
      (resU) => {
        let result = resU;
        this.auth.saveUser(result);
        this.router.navigateByUrl("/homepage");
      },
      (err) => {
        // console.log("ERR", err);
        this.showError = true;
      }
    );
  }

  // ######################################
  // FUNCTION: goTo
  goTo(location: string) {
    this.router.navigateByUrl(location);
  }

  // ######################################
  // FUNCTION: showModal
  showRecoverModal() {
    this.recoverVisible = true;
    this.isWaiting = this.modalSuccess = this.modalError = false;
  }

  // ######################################
  // FUNCTION: handleCancel
  handleCancel() {
    this.recoverVisible = false;
  }

  // ######################################
  // FUNCTION: recover
  isWaiting = false;
  recover() {
    const formData = {
      user: this.username,
    };

    this.isWaiting = true;
    this.showError = false;
    this.node.recoverUser(formData).subscribe(
      (res) => {
        this.g.createCustomMessage("OPERAZIONE COMPLETATA", this.translate.instant("successRecover"));
        //this.g.createSuccessNotification("OPERAZIONE COMPLETATA", this.translate.instant("successRecover"), 0);
        this.recoverVisible = false;
      },
      (err) => {
        console.error(err);
        this.g.createErrorNotification("OPERAZIONE COMPLETATA", this.translate.instant("errorRecover"), 0);
        this.recoverVisible = false;
      }
    );
  }
}
