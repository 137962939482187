import { Component, OnInit } from "@angular/core";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { FireService } from "src/app/services/fire.service";

@Component({
  selector: "app-aggregati",
  templateUrl: "./aggregati.component.html",
  styleUrls: ["./aggregati.component.scss"],
})
export class AggregatiComponent implements OnInit {
  search: string;
  user: any;
  reps: any[] = [];
  displayedData: any[] = [];
  loading = true;

  // ######################################
  // FUNCTION: constructor
  constructor(private fire: FireService, private auth: AuthClienteService) { }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    this.user = this.auth.getUser();

    this.fire.getCollection("clienti", this.user.azienda, "licenze").subscribe((res3) => {
      let e = res3;
      e.licenze = [];
      if (res3.length > 0) {
        let licenze = res3;
        licenze.forEach((l, rindex) => {
          this.fire
            .getSubCollection("clienti", this.user.azienda, "licenze", l.id, "compilazioni")
            .subscribe((res5) => {
              l.destinatari = res5.length;
              l.compilazioni = [];
              if (l.questionario && l.versione) {
                this.fire.getDocument("questionari", l.questionario, "versioni", l.versione).subscribe((res4) => {
                  if (res4.tipo === 2) {
                    l.quest = res4;
                    e.licenze.push(l);
                    let index = this.displayedData.findIndex((d) => d.id === this.user.azienda);
                    if (index < 0) {
                      this.displayedData.push(e);
                    }
                  }
                });
              }

            });

          l.reparti.forEach((rep) => {
            let repId = rep.split(" ")[0];
            l.repartiAttivi = [];
            this.fire.getDocument("clienti", this.user.azienda, "reparti", repId).subscribe((resR) => {
              this.fire
                .getSubCollection("clienti", this.user.azienda, "reparti", resR.id, "utenti")
                .subscribe((rUser) => {
                  let index = l.repartiAttivi.findIndex((d) => d.id === resR.id);
                  if (index < 0) {
                    l.repartiAttivi.push({
                      id: resR.id,
                      nome: resR.nome,
                      utenti: rUser.length,
                    });
                  }
                });
            });
          });
        });
        this.loading = false;
      }
    });
  }

  // ######################################
  // FUNCTION: filter
  filter() { }
}
