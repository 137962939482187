import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CompileService } from '../services/compile.service';

@Injectable({
  providedIn: 'root'
})
export class CompileGuard implements CanActivate, CanActivateChild {
  constructor(private c: CompileService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.c.checkIfCompiled()) {
      this.router.navigateByUrl('/compilazione/conclusione');
      return false;
    } else {
      return true;
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.c.checkIfCompiled()) {
      this.router.navigateByUrl('/compilazione/conclusione');
      return false;
    } else {
      return true;
    }
  }

}
