import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AdminComponent } from './admin.component';
import { ClientiComponent } from './amministrazione/clienti/clienti.component';
import { PubblicazioneComponent } from './amministrazione/pubblicazione/pubblicazione.component';
import { UtentiComponent } from './amministrazione/utenti/utenti.component';
import { DesignerComponent } from './designer/designer.component';
import { AggregatiComponent } from './gestione/aggregati/aggregati.component';
import { PrivacyComponent } from './gestione/privacy/privacy.component';
import { QuestionariComponent } from './gestione/questionari/questionari.component';
import { ValutazioniComponent } from './gestione/valutazioni/valutazioni.component';
import { HomepageComponent } from './homepage/homepage.component';

const routes: Routes = [
  {
    path: 'admin', component: AdminComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      { path: '', component: HomepageComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'designer', component: DesignerComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'amministrazione/utenti', component: UtentiComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'amministrazione/clienti', component: ClientiComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'amministrazione/pubblicazione', component: PubblicazioneComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'gestione/questionari', component: QuestionariComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'gestione/aggregati', component: AggregatiComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'gestione/valutazioni', component: ValutazioniComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'gestione/privacy', component: PrivacyComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
