import { Component, OnInit, TemplateRef, HostListener } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { FireService } from "src/app/services/fire.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
  selector: "app-destinatari",
  templateUrl: "./destinatari.component.html",
  styleUrls: ["./destinatari.component.scss"],
})
export class DestinatariComponent implements OnInit {
  reps: any[] = [];
  displayedData: any[] = [];
  onAdd: boolean = false;
  openFolder: any[] = [];
  user: any;
  loading = true;
  editCacheR: { [key: string]: { edit: boolean; data: any; }; } = {};
  editCacheU: { [key: string]: { [key2: string]: { edit: boolean; data: any; }; }; } = {};
  search: string;
  utentiImport: any[] = [];
  isImporting: boolean = false;
  repartoCheck: any = [];
  utentiImportCount: number = 0;
  addUser: boolean = false;
  scroll = { y: "" };
  // ######################################
  // FUNCTION: constructor
  constructor(private fire: FireService, private auth: AuthClienteService, private g: GlobalService, private modal: NzModalService,private fb: FormBuilder, private message: NzMessageService, private translate: TranslateService) { }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiAdminClientiTableContainer").length > 0) {
      let height = document.getElementsByClassName("uiAdminClientiTableContainer")[0].clientHeight;
      if (
        document.getElementsByClassName("uiAdminClientiTableContainer")[0].getElementsByClassName("ant-table-thead").length
      ) {
        let headH = document
          .getElementsByClassName("uiAdminClientiTableContainer")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }


  validateForm!: FormGroup;


  submitForm(): void {
    if (this.validateForm.valid) {
      // console.log('Form submitted:', this.validateForm.value);
      this.message.success('Form submitted successfully!');
      this.validateForm.reset();
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  // ######################################
  // FUNCTION: ngAfterViewInit
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant("clienti");
    });
  }

  ngDoCheck(): void {
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {

    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      message: [null, [Validators.required]]
    });
    this.user = this.auth.getUser();
    this.fire.getCollection("clienti", this.user.azienda, "reparti").subscribe((res2) => {
      this.reps = res2.sort(this.g.customSort);
      this.displayedData = this.reps;
      for (let j = 0; j < res2.length; j++) {
        let a = res2[j];
        this.fire.getSubCollection("clienti", this.user.azienda, "reparti", a.id, "utenti").subscribe((res3) => {
          this.loading = false;
          if (!this.onAdd) {
            a.n_utenti = res3.length;
            a.utenti = res3;
            this.updateEditCache();
          } else {
            a.n_utenti = res3.length;
            a.utenti = res3;
          }
        });
      }
    });
  }

  // ######################################
  // FUNCTION: updateEditCache
  updateEditCache(): void {
    this.reps.forEach((item) => {
      this.editCacheR[item.id] = {
        edit: false,
        data: { ...item },
      };
      this.editCacheU[item.id] = {};
      this.updateEditCacheU(item);
    });
  }

  // ######################################
  // FUNCTION: updateEditCacheU
  updateEditCacheU(rep): void {
    if (rep.utenti) {
      rep.utenti.forEach((user) => {
        this.editCacheU[rep.id][user.id] = {
          edit: false,
          data: { ...user },
        };
      });
    }
    this.loading = false;
  }

  // ######################################
  // FUNCTION: openArea
  openArea(i: number) {
    if (this.openFolder[i]) {
      this.openFolder[i] = false;
    } else {
      this.openFolder[i] = true;
      this.loading = false;
    }
  }

  // ######################################
  // FUNCTION: newU
  newU(i) {
    this.addUser = true;
    this.fire
      .getLastSubSubBy("clienti", this.user.azienda, "reparti", this.displayedData[i].id, "utenti", "codice")
      .then((res) => {
        let newUser = {
          admin: false,
          attivo: true,
          codice: res + 1,
          descrizione: "Nuovo utente",
          email: "",
          id: "U" + (res + 1),
          pwd: "",
          ultimo_accesso: {},
          reparto: this.displayedData[i].id,
        };

        this.displayedData[i].utenti.unshift(newUser);
        this.editCacheU[this.displayedData[i].id][newUser.id] = {
          edit: true,
          data: { ...newUser },
        };
        this.onAdd = true;
        if (!this.openFolder[i]) this.openArea(i);
      });
  }

  // ######################################
  // FUNCTION: deleteU
  deleteU(i: number, ui: number) {
    this.fire
      .deleteSubDoc(
        "clienti",
        "reparti",
        "utenti",
        this.user.azienda,
        this.displayedData[i].id,
        this.displayedData[i].utenti[ui].id
      )
      .then()
      .catch((err) => {
        // console.log(err);
      });
  }

  // ######################################
  // FUNCTION: editU
  editU(id, uid) {
    this.editCacheU[id][uid].edit = true;
  }

  // ######################################
  // FUNCTION: cancelEditU
  cancelEditU(i: number, ui: number, id1: string, id2: string): void {
    if (!this.onAdd) {
      this.editCacheU[id1][id2] = {
        data: { ...this.displayedData[i].utenti[ui] },
        edit: false,
      };
    } else {
      this.displayedData[i].utenti.splice(ui, 1);
    }
    this.addUser = false;
  }

  // ######################################
  // FUNCTION: saveEditU
  saveEditU(i: number, ui: number, id1: string, id2: string) {
    const checkEmail: boolean = this.g.checkEmail(this.editCacheU[id1][id2].data.email);
    const checkMobile: boolean = this.g.checkMobile(this.editCacheU[id1][id2].data.mobile);


    if ((checkEmail && checkMobile) || (checkEmail && !checkMobile && !this.editCacheU[id1][id2].data.mobile) || (!checkEmail && checkMobile && !this.editCacheU[id1][id2].data.email)) {
      let newUser = this.editCacheU[id1][id2].data;

      this.fire
        .addSubCollection(
          "clienti",
          "reparti",
          "utenti",
          newUser,
          this.user.azienda,
          this.displayedData[i].id,
          newUser.id
        )
        .then((newU) => {
          this.onAdd = true;
          this.editCacheU[this.displayedData[i].id][newUser.id].edit = false;
          this.onAdd = false;
          this.addUser = false;
        })
        .catch((err) => {
          // console.log(err);
        });
      this.fire.addCollection("clienti", "utenti", newUser, this.user.azienda, newUser.id);
    } else if (!checkMobile) {
      this.g.createErrorNotification("Problema", "È necessario inserire un numero cellulare valido.");
    } else if (!checkEmail) {
      this.g.createErrorNotification("Problema", "È necessario inserire un indirizzo email valido.");
    } else {
      Object.assign(this.displayedData[i].utenti[ui], this.editCacheU[id1][id2].data);
      this.editCacheU[id1][id2].edit = false;
      this.displayedData = [...this.reps];

      let user = this.displayedData[i].utenti[ui];

      this.fire.updateSubDoc(
        "clienti",
        "reparti",
        "utenti",
        user,
        this.user.azienda,
        this.displayedData[i].id,
        user.id
      );
      this.fire.update("clienti", "utenti", user, this.user.azienda, user.id);
      this.addUser = false;

    }

    // console.log(this.editCacheU[id1][id2].data);

    if (!checkEmail && checkMobile && !this.editCacheU[id1][id2].data.email) {
      this.onChangeType(true, i, ui, this.editCacheU[id1][id2].data.descrizione);
    }
  }

  // ######################################
  // FUNCTION: filter
  filter() {
    this.displayedData = this.reps.filter(
      (r) => r.id.toUpperCase().includes(this.search.toUpperCase()) || r.nome.toUpperCase().includes(this.search.toUpperCase()));
  }
  // ######################################
  // FUNCTION: onChangeType
  onChangeType(e: any, i: number, ui: number, description: string = '') {
    let user = this.displayedData[i].utenti[ui];
    user.sendtype = e;
    if (description) user.descrizione = description;
    // console.log({ user });
    this.fire.updateSubDoc("clienti", "reparti", "utenti", user, this.user.azienda, this.displayedData[i].id, user.id);
  }

  // ######################################
  // FUNCTION: utentiXlsImport
  async utentiXlsImport(tplUserImport: TemplateRef<{}>, tplUserFooter: TemplateRef<{}>, department) {
    this.utentiImport = [];

    this.repartoCheck = department;
    if (!this.repartoCheck) return;

    this.modal.create({
      nzContent: tplUserImport,
      nzFooter: tplUserFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: {
        value: "Template Context",
      },
    });
  }

  // ######################################
  // FUNCTION: utentiGetClipboard
  async utentiGetClipboard() {
    const that = this;
    let clip: string = "";
    this.isImporting = true;
    let utentiLocal: any[] = [];
    let utentiCheck: any = this.repartoCheck.utenti;
    try {
      clip = await navigator.clipboard.readText();
    } catch (err) {
      this.isImporting = false;
      this.modal.create({
        nzTitle: "Permesso negato",
        nzContent: "Si prega di concedere all'applicazione il permesso di accedere gli appunti copiati.",
        nzOkText: null,
        nzCancelText: "Annulla",
      });
      return;
    }
    this.isImporting = false;
    let array: any = clip
      .split("\r\n")
      .filter(function (line) {
        if (!line) return false;
        return true;
      })
      .map(function (line) {
        const arr = line.split("\t");
        // console.log("LINE", line);
        // console.log("ARR", arr);
        // if (arr.length == 3) {
        // 	return {
        // 		nome: arr[0],
        // 		email: arr[1],
        // 		mobile: arr[2],
        // 		stato: "OK",
        // 	};
        // } else {
        // 	return {
        // 		nome: line,
        // 		stato: "Formato riga non corretto",
        // 	};
        // }
        if (arr.length > 1 && arr.length <= 3) {
          if (arr.length == 2) {
            const result = {
              nome: arr[0],
              email: that.g.checkEmail(arr[1]) ? arr[1] : null,
              mobile: that.g.checkMobile(arr[1]) ? arr[1] : null,
              stato: "OK",
            };
            if (result.email || result.mobile) {
              return result;
            } else {
              return {
                nome: line,
                stato: "Non è stato trovata un'email o un telefono validi",
              };
            }
          } else if (arr.length == 3) {
            const result = {
              nome: arr[0],
              email: that.g.checkEmail(arr[1]) ? arr[1] : that.g.checkMobile(arr[2]) ? arr[2] : null,
              mobile: that.g.checkMobile(arr[2]) ? arr[2] : that.g.checkMobile(arr[1]) ? arr[1] : null,
              stato: "OK",
            };
            if (result.email || result.mobile) {
              return result;
            } else {
              return {
                nome: line,
                stato: "Non è stato trovata un'email o un telefono validi",
              };
            }
          } else {
            return {
              nome: line,
              stato: "Non è stato trovata un'email o un telefono validi",
            };
          }
        } else {
          return {
            nome: line,
            stato: "Formato riga non corretto",
          };
        }
      });
    if (array.length < 1) {
      this.modal.create({
        nzTitle: "Dati non corretti",
        nzContent: "Prima di premere il pulsante si prega di copiare negli appunti i dati da importare.",
        nzOkText: null,
        nzCancelText: "Annulla",
      });
      return;
    }
    this.utentiImportCount = 0;
    array.forEach((e) => {
      if (e.stato == "OK") {
        if (!e.nome) {
          e.stato = "Nome non corretto";
        } else if (!e.email && !e.mobile) {
          e.stato = "Email e Telefono non corretti";
        }
      }
      if (e.stato == "OK") {
        var found = Object.keys(utentiCheck).filter(function (key) {
          return e.email ? utentiCheck[key].email === e.email : false;
        });
        if (found.length) {
          e.stato = "Email già presente";
        } else {
          if (e.email && utentiLocal.indexOf(e.email) >= 0) {
            e.stato = "Email già presente";
          } else {
            utentiLocal.push(e.email);
          }
        }
      }
      if (e.stato == "OK") {
        var found = Object.keys(utentiCheck).filter(function (key) {
          return e.mobile ? utentiCheck[key].mobile === e.mobile : false;
        });
        if (found.length) {
          e.stato = "Cellulare già presente";
        } else {
          if (e.mobile && utentiLocal.indexOf(e.mobile) >= 0) {
            e.stato = "Cellulare già presente";
          } else {
            utentiLocal.push(e.email);
          }
        }
      }
      if (e.stato == "OK") this.utentiImportCount++;
    });
    //this.utentiImportCount = utentiLocal.length;
    this.utentiImport = array;
  }

  async utentiSaveImport(ref) {
    this.fire
      .getLastSubSubBy("clienti", this.user.azienda, "reparti", this.repartoCheck.id, "utenti", "codice")
      .then(async (res) => {
        let newUsers: any = [];

        this.utentiImport.forEach((utente) => {
          if (utente.stato === "OK") {
            res++;
            newUsers.push({
              admin: false,
              attivo: true,
              codice: res,
              descrizione: utente.nome,
              email: utente.email,
              mobile: utente.mobile,
              sendtype: utente.email ? false : true,
              id: "U" + res,
              pwd: "",
              ultimo_accesso: {},
              reparto: this.repartoCheck.id,
            });
          }
        });
        await this.fire.addMultipleSubDoc(
          "clienti",
          "reparti",
          "utenti",
          this.user.azienda,
          this.repartoCheck.id,
          newUsers
        );
        ref.close();
        this.g.createSuccessNotification(
          "Importazione competata con successo",
          this.utentiImportCount > 1
            ? "Sono stati aggiunti " + this.utentiImportCount + " nuovi utenti."
            : "È stato aggiunto " + this.utentiImportCount + " nuovo utente."
        );
      });
  }
}
