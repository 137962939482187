import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-img-modal',
  templateUrl: './img-modal.component.html',
  styleUrls: ['./img-modal.component.scss']
})
export class ImgModalComponent implements OnInit {
  @Input() file: string;
  constructor(private modal: NzModalRef, private sanitizer: DomSanitizer) { }
  fileUrl: SafeResourceUrl;

  ngOnInit(): void {
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.file);
  }
}
