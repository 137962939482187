import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { EditPrivacyComponent } from './edit-privacy.component';
const routes: Routes = [
  {
    path: 'admin/gestione/privacy/edit/:pid', component: EditPrivacyComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditPrivacyRoutingModule { }
