import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { User } from "src/app/entities/users";
import { AuthService } from "src/app/services/auth.service";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";
import { Location } from "@angular/common";

@Component({
	selector: "app-edit-aggregati",
	templateUrl: "./edit-aggregati.component.html",
	styleUrls: ["./edit-aggregati.component.scss"],
})
export class EditAggregatiComponent implements OnInit {
	isCollapsed = false;
	rId: string = "";
	aId: string = "";
	lId: string = "";
	resData: any[] = [];
	displayedData: any[] = [];
	loading: boolean = true;
	search: string = "";
	repName: any;
	loggedIn: User;
	CryptoJS = require("crypto-js");
	newDests: any[] = [];
	openFolder: any[] = [];
	// quests: { [email: string]: { compilazioni: any, questionario: any } } = {};
	quests: any[] = [];
	scroll = { y: "" };

	// ######################################
	// FUNCTION: constructor
	constructor(
		private location: Location,
		public auth: AuthService,
		private route: ActivatedRoute,
		private fire: FireService,
		private node: NodeService,
		public g: GlobalService,
		private translate: TranslateService,
		private router: Router
	) {}

	// ######################################
	// EVENT: onResize
	@HostListener("window:resize", ["$event"])
	onResize(event) {
		event.target.innerWidth;
		this.setScrollHeight();
	}

	// ######################################
	// FUNCTION: setScrollHeight
	setScrollHeight() {
		if (document.getElementsByClassName("uiGestioneEditTableContainer").length > 0) {
			let height = document.getElementsByClassName("uiGestioneEditTableContainer")[0].clientHeight;
			if (
				document.getElementsByClassName("uiGestioneEditTableContainer")[0].getElementsByClassName("ant-table-thead")
					.length
			) {
				let headH = document
					.getElementsByClassName("uiGestioneEditTableContainer")[0]
					.getElementsByClassName("ant-table-thead")[0].clientHeight;
				this.scroll = { y: height - headH + "px" };
			} else {
				this.scroll = { y: height - 24 + "px" };
			}
		}
	}

	// ######################################
	// FUNCTION: ngAfterViewInit
	ngAfterViewInit() {
		setTimeout(() => {
			this.g.actualPage = this.translate.instant("questManagment");
		});
	}

	// ######################################
	// FUNCTION: ngOnInit
	ngOnInit(): void {
		this.loggedIn = this.auth.getUser();

		this.route.params.subscribe((param) => {
			this.rId = param.rid;
			this.aId = param.aid;
			this.lId = param.lid;
		});

		this.fire.getDocument("clienti", this.aId, "reparti", this.rId).subscribe((res) => {
			this.repName = res;
		});

		this.fire.getDocument("clienti", this.aId, "licenze", this.lId).subscribe((res) => {
			let l = res;
			if (l.questionario && l.versione) {
				this.fire.getDocument("questionari", l.questionario, "versioni", l.versione).subscribe((res4) => {
					res4.collegati.forEach((q) => {
						this.fire.get("questionari", q.questionario).subscribe((resQ) => {
							this.fire.getDocument("questionari", q.questionario, "versioni", q.versione).subscribe((resV) => {
								let Q = resQ;
								let V = resV;
								this.fire
									.getSubByFilter(
										"clienti",
										this.aId,
										"licenze",
										"questionario",
										q.questionario,
										"versione",
										q.versione
									)
									.subscribe((resL) => {
										let l = resL[0];
										let data = { questionario: Q, versione: V, licenza: l };
										this.displayedData.push(data);
										this.loading = false;
										this.setScrollHeight();
									});
							});
						});
					});
				});
			}
		});
	}

	// ######################################
	// FUNCTION: filter
	filter() {
		this.displayedData = this.resData.filter(
			(r) =>
				r.id.toUpperCase().includes(this.search.toUpperCase()) ||
				r.titolo.toUpperCase().includes(this.search.toUpperCase())
		);
	}

	// ######################################
	// FUNCTION: back
	back() {
		this.location.back();
	}

	// ######################################
	// FUNCTION: sendMail
	sendMail(u) {
		let customLink = {
			azienda: this.aId,
			licenza: this.lId,
			reparto: this.rId,
			utente: u.id,
		};
		let encodedUrl = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(JSON.stringify(customLink)));

		const formData = {
			user: u.email,
			link: encodedUrl,
		};

		this.fire.getDocOnce("clienti", this.aId, "licenze", this.lId).subscribe((resL) => {
			let lData = resL.data();
			this.fire
				.getSubDocOnce(
					"clienti",
					customLink.azienda,
					"licenze",
					customLink.licenza,
					"compilazioni",
					customLink.reparto + customLink.utente
				)
				.subscribe((comp) => {
					let compData = comp.data();
					if (compData && compData.sent === true) {
						this.node.callNodeUrl(formData, "/users/sendLink").subscribe(
							(res) => {
								this.g.createSuccessNotification("Email inviata con successo!", "");
							},
							(err) => {
								// console.log("ERR:", err);
							}
						);
					} else if (lData.utilizzate + 1 >= lData.totali) {
						this.g.createErrorNotification("Email non inviata", "Limite licenze raggiunte");
					} else {
						let dataL = {
							utilizzate: lData.utilizzate + 1,
						};
						this.fire.update("clienti", "licenze", dataL, customLink.azienda, customLink.licenza);
						let data = { sent: true, utente: customLink.utente, reparto: customLink.reparto };
						this.fire
							.addSubCollection(
								"clienti",
								"licenze",
								"compilazioni",
								data,
								customLink.azienda,
								customLink.licenza,
								customLink.reparto + customLink.utente
							)
							.then((added) => {})
							.catch((err) => {
								// console.log(err);
							});

						this.node.callNodeUrl(formData, "/users/sendLink").subscribe(
							(res) => {
								this.g.createSuccessNotification("Email inviata con successo!", "");
							},
							(err) => {
								// console.log("ERR:", err);
							}
						);
					}
				});
		});
	}

	// ######################################
	// FUNCTION: openArea
	openArea(i: number) {
		if (this.openFolder[i]) {
			this.openFolder[i] = false;
		} else {
			this.openFolder[i] = true;
			this.loading = false;
		}
	}

	// ######################################
	// FUNCTION: editRep
	editRep(l) {
		this.router.navigateByUrl("admin/gestione/questionari/edit/" + this.aId + "/" + this.rId + "/" + l);
	}
}
