import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Punteggio } from "src/app/entities/punteggi";
import { GlobalService } from "src/app/shared/global.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
	selector: "app-domande",
	templateUrl: "./domande.component.html",
	styleUrls: ["./domande.component.scss"],
})
export class DomandeComponent implements OnInit {
	displayedData: any[] = [];
	search: string = "";
	scroll = { y: "" };

	constructor(public g: GlobalService, private router: Router, private route: ActivatedRoute) {}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		event.target.innerWidth;
		this.setScrollHeight();
	}

	setScrollHeight() {
		if (document.getElementsByClassName("uiQuestQuesionBody").length > 0) {
			let height = document.getElementsByClassName("uiQuestQuesionBody")[0].clientHeight;
			if (document.getElementsByClassName("uiQuestQuesionBody")[0].getElementsByClassName("ant-table-thead").length) {
				let headH = document
					.getElementsByClassName("uiQuestQuesionBody")[0]
					.getElementsByClassName("ant-table-thead")[0].clientHeight;
				this.scroll = { y: height - headH + "px" };
			} else {
				this.scroll = { y: height - 24 + "px" };
			}
		}
	}

	ngOnInit(): void {
		this.waitDomande();
	}
	waitDomande() {
		setTimeout(() => {
			if (!this.g.temp_quest || !this.g.temp_quest.domande) this.waitDomande();
			else this.setDomande();
		}, 500);
	}
	setDomande() {
		this.displayedData = this.g.temp_quest.domande;
		this.setScrollHeight();
	}

	addQuestion() {
		var newCode: Number = 1;
		if (this.g.temp_quest.domande.length > 0) newCode = this.getNewCode();
		this.router.navigateByUrl(this.router.url + "/modifica/" + "D" + newCode, { skipLocationChange: true });

		this.g.temp_quest.tratti.forEach((t) => {
			let p: any = new Punteggio();
			p.codice = t.codice + "D" + newCode;
			p.domanda = {};
			p.domanda.codice = newCode;
			p.domanda.filtri = [];
			p.domanda.risposte = [];
			t.punteggi["D" + newCode] = p;
		});
	}

	changeNav(code: string) {
		this.router.navigateByUrl(this.router.url + "/modifica/" + code, { skipLocationChange: true });
	}

	drop(event: any): void {
		if (event.previousIndex !== event.currentIndex) {
			moveItemInArray(this.g.temp_quest.domande, event.previousIndex, event.currentIndex);
			this.g.temp_quest.domande.forEach((e: any, index: any) => {
				e.ordine = index + 1;
			});

			this.g.temp_quest.domande.sort((n1, n2) => {
				if (n1.ordine > n2.ordine) return 1;
				if (n1.ordine < n2.ordine) return -1;
				return 0;
			});
			this.displayedData = [...this.g.temp_quest.domande];

			// moveItemInArray(this.g.temp_quest.domande, event.previousIndex, event.currentIndex);
			// this.g.temp_quest.domande.forEach((e: any, index: any) => {
			// 	e.ordine = index + 1;
			// });

			// this.g.temp_quest.tratti.forEach((t) => {
			// 	moveItemInArray(t.punteggi, event.previousIndex, event.currentIndex);
			// 	t.punteggi.forEach((e: any, index: any) => {
			// 		e.ordine = index + 1;
			// 	});
			// });
		}
	}

	filter() {
		this.displayedData = this.g.temp_quest.domande.filter(
			(d) =>
				d.codice.toLowerCase().includes(this.search.toLowerCase()) ||
				d.titolo[this.g.selectedLanguage]?.toLowerCase().includes(this.search.toLowerCase())
		);
	}

	remove(index, id) {
		this.g.temp_quest.tratti.forEach((t) => {
			t.punteggi[id] = undefined;
		});
		this.g.temp_quest.domande.splice(index, 1);
		this.g.temp_quest.domande.forEach((e: any, index: any) => {
			e.ordine = index + 1;
		});
		this.displayedData = [...this.g.temp_quest.domande];
	}

	getNewCode(): Number {
		const dcode = this.g.temp_quest.domande.map((d) => {
			return d.codice.match(/\d+/)[0];
		});
		return Math.max(...dcode) + 1;
	}

	copy(index, id) {
		var newCode: Number = 1;
		if (this.g.temp_quest.domande.length > 0) newCode = this.getNewCode();

		let d = JSON.parse(JSON.stringify(this.g.temp_quest.domande[index]));
		d.codice = "D" + newCode;
		d.ordine = this.g.temp_quest.domande.length + 1;
		this.g.temp_quest.domande.push(d);

		this.g.temp_quest.tratti.forEach((t) => {
			let p: any = new Punteggio();
			p.codice = t.codice + "D" + newCode;
			p.domanda = {};
			p.domanda.codice = "D" + newCode;
			p.domanda.filtri = [];
			p.domanda.risposte = [];

			d.risposte.forEach((answ, aindex) => {
				p.domanda.risposte.push({ codice: answ.codice, valore: 0 });
			});
			t.punteggi[p.domanda.codice] = p;
		});
		this.displayedData = [...this.g.temp_quest.domande];
	}
}
