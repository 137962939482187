import { Component, HostListener, OnInit, TemplateRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { GlobalService } from "src/app/shared/global.service";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { ChartType } from "angular-google-charts";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
})
export class EditComponent implements OnInit {
  rId: string = "";
  aId: string = "";
  lId: string = "";
  loading = true;
  logo: SafeResourceUrl;
  resData: any[] = [];
  dataError: any[] = [];
  displayedData: any[] = [];

  licenza: any = [];
  questionario: any = [];
  versione: any = [];

  newDests: any[] = [];
  repName: any;
  CryptoJS = require("crypto-js");
  search: string;
  commentModal = false;
  comment = "";
  scroll = { y: "" };

  // ######################################
  // FUNCTION: constructor
  constructor(
    private sanitizer: DomSanitizer,
    public auth: AuthClienteService,
    public g: GlobalService,
    private location: Location,
    private route: ActivatedRoute,
    private fire: FireService,
    private node: NodeService,
    private router: Router,
    private modal: NzModalService
  ) { }

  // ######################################
  // EVENT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiGestioneEditTable").length > 0) {
      let height = document.getElementsByClassName("uiGestioneEditTable")[0].clientHeight;
      if (document.getElementsByClassName("uiGestioneEditTable")[0].getElementsByClassName("ant-table-thead").length) {
        let headH = document
          .getElementsByClassName("uiGestioneEditTable")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.aId = this.auth.getUser().azienda;
      this.rId = param.rid;
      this.lId = param.lid;

      this.fire.getDocOnce("clienti", this.aId, "licenze", param.lid).subscribe((resL) => {
        this.licenza = resL.data();
        this.fire.get("questionari", this.licenza.questionario).subscribe((resQ) => {
          this.questionario = resQ;
          this.fire
            .getDocument("questionari", this.questionario.id, "versioni", this.licenza.versione)
            .subscribe((resV) => {
              this.versione = resV;
            });
        });
      });
    });

    let fileURL = this.auth.getLogo();
    this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

    this.fire.getDocument("clienti", this.aId, "reparti", this.rId).subscribe((res) => {
      this.repName = res;
    });

    this.fire.getSubCollection("clienti", this.aId, "reparti", this.rId, "utenti").subscribe((res) => {
      this.loading = false;
      this.setScrollHeight();
      // console.log({ res });
      res.forEach((u) => {
        this.fire
          .getSubDoc("clienti", this.aId, "licenze", this.lId, "compilazioni", u.reparto + u.id)
          .subscribe((compilazione) => {
            if (
              compilazione &&
              (compilazione.sent ||
                compilazione.concluso === true ||
                compilazione.concluso === false ||
                compilazione.added === true)
            ) {
              u.compilazione = compilazione;
              const index = this.resData.findIndex((r) => r.id === u.id);
              if (index < 0) {
                this.resData.push(u);
                this.displayedData = [...this.resData];

                if (u.compilazione.attendibilita === false || u.compilazione.forzatura === false) {
                  this.dataError.push(u);
                }
              }
            }
          });
      });
      this.newDests = [...res];
      // console.log(this.newDests, 'ok');
    });
  }

  // ######################################
  // FUNCTION: filter
  filter() {
    this.displayedData = this.resData.filter(
      (r) =>
        r.email.toUpperCase().includes(this.search.toUpperCase()) ||
        r.nome.toUpperCase().includes(this.search.toUpperCase())
    );
  }

  // ######################################
  // FUNCTION: back
  back() {
    this.location.back();
  }

  // ######################################
  // FUNCTION: sendMail
  sendMail(u) {
    let customLink = {
      azienda: this.aId,
      licenza: this.lId,
      reparto: this.rId,
      utente: u.id,
    };
    let encodedUrl = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(JSON.stringify(customLink)));

    const formData = {
      user: u.email,
      link: encodedUrl,
    };

    this.fire.getDocOnce("clienti", this.aId, "licenze", this.lId).subscribe((resL) => {
      let lData = resL.data();
      this.fire
        .getSubDocOnce(
          "clienti",
          customLink.azienda,
          "licenze",
          customLink.licenza,
          "compilazioni",
          customLink.reparto + customLink.utente
        )
        .subscribe((comp) => {
          let compData = comp.data();
          if (compData && compData.sent === true) {
            this.node.callNodeUrl(formData, "/users/sendLink").subscribe(
              (res) => {
                this.g.createSuccessNotification("Email inviata con successo!", "");
              },
              (err) => {
                console.error("ERR:", err);
              }
            );
          } else if (lData.utilizzate + 1 >= lData.totali) {
            this.g.createErrorNotification("Email non inviata", "Limite licenze raggiunte");
          } else {
            let dataL = {
              utilizzate: lData.utilizzate + 1,
            };
            this.fire.update("clienti", "licenze", dataL, customLink.azienda, customLink.licenza);
            let data = { sent: true, utente: customLink.utente, reparto: customLink.reparto };
            this.fire
              .addSubCollection(
                "clienti",
                "licenze",
                "compilazioni",
                data,
                customLink.azienda,
                customLink.licenza,
                customLink.reparto + customLink.utente
              )
              .then((added) => { })
              .catch((err) => {
                // console.log(err);
              });

            this.node.callNodeUrl(formData, "/users/sendLink").subscribe(
              (res) => {
                this.g.createSuccessNotification("Email inviata con successo!", "");
              },
              (err) => {
                // console.log("ERR:", err);
              }
            );
          }
        });
    });
  }

  // ######################################
  // FUNCTION: addDest
  addDest(n) {
    let data = {
      added: true,
    };

    this.fire
      .addSubCollection("clienti", "licenze", "compilazioni", data, this.aId, this.lId, n.id)
      .then((added) => {
        const index = this.newDests.findIndex((u) => u.id === n.id);
        this.newDests.splice(index, 1);
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  // ######################################
  // FUNCTION: showValutazione
  showValutazione(data) {
    const crypthId = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(data.compilazione.id));
    this.router.navigateByUrl("/questionari/show/" + this.aId + "/" + this.lId + "/" + crypthId);
  }

  // ######################################
  // FUNCTION: export
  export(data) {
    this.g.chartExport(data, this.aId, this.lId);
  }

  // ###################################### ELENCO UTENTI PER EMAIL
  utentiToSend: any[] = [];
  utentiAllChecked: boolean = false;
  utentiIntChecked: boolean = false;

  // ######################################
  // FUNCTION: selectDest
  selectDest(tplUserType: TemplateRef<{}>, tplFooter: TemplateRef<{}>, type: boolean) {
    if (this.newDests.length > 0) {
      this.utentiToSend = [];
      this.utentiAllChecked = false;
      this.utentiIntChecked = false;

      // console.log(this.newDests);
      this.newDests.forEach((u) => {
        if (u.sendtype === undefined) u.sendtype = false;
        // console.log({ uType: u.sendtype, type });
        if (u.sendtype === type) {
          this.utentiToSend.push({ ...{ check: false }, ...u });
        }
      });
      // console.log(this.utentiToSend);

      this.modal.create({
        nzContent: tplUserType,
        nzFooter: tplFooter,
        nzMaskClosable: false,
        nzClosable: false,
        nzComponentParams: {
          value: "Template Context",
        },
      });
    } else {
      this.g.createErrorNotification(
        "Nessun destinatario disponibile",
        "Non sono stati trovati utenti abilitati al tipo di invio selezionato."
      );
    }
  }

  // ######################################
  // FUNCTION: onAllChecked
  onAllChecked(checked: boolean): void {
    this.utentiToSend.forEach((u) => (u.check = checked));
    this.refreshCheckedStatus();
  }

  // ######################################
  // FUNCTION: refreshCheckedStatus
  refreshCheckedStatus(): void {
    this.utentiAllChecked = this.utentiToSend.every((item) => item.check === true);
    if (!this.utentiAllChecked) this.utentiIntChecked = this.utentiToSend.some((item) => item.check === true);
    else this.utentiIntChecked = false;
  }

  // ######################################
  // FUNCTION: utentiSend
  isSending: boolean = false;

  utentiSend(ref) {
    var isError: boolean = false;

    this.fire.getOnce("clienti", this.aId).subscribe((azienda) => {
      azienda = azienda.data();
      this.fire.getDocOnce("clienti", this.aId, "licenze", this.lId).subscribe(async (licenza) => {
        licenza = licenza.data();

        this.isSending = true;
        // console.log("EMAIL: INIZIO INVIO");

        for (const user of this.utentiToSend) {
          if (user.check) {
            var res = await this.g.userSendMail(azienda, licenza, user);
            //// console.log("EMAIL: ESITO", res);

            if (res == "STOP") {
              this.isSending = false;
              return;
            } else if (res) {
              user.check = false;
              this.refreshCheckedStatus();
              this.newDests.splice(user, 1);
            } else isError = true;
          }
        }

        // console.log("EMAIL: FINE INVIO");
        this.isSending = false;
        if (!isError) ref.close();
      });
    });
  }

  sendSms(ref) {
    var isError: boolean = false;

    this.fire.getOnce("clienti", this.aId).subscribe((azienda) => {
      azienda = azienda.data();
      this.fire.getDocOnce("clienti", this.aId, "licenze", this.lId).subscribe(async (licenza) => {
        licenza = licenza.data();

        this.isSending = true;
        // console.log("SMS: INIZIO INVIO");

        for (const user of this.utentiToSend) {
          if (user.check) {
            var res = await this.g.userSendSms(azienda, licenza, user);
            //// console.log("EMAIL: ESITO", res);

            if (res == "STOP") {
              this.isSending = false;
              return;
            } else if (res) {
              user.check = false;
              this.refreshCheckedStatus();
              this.newDests.splice(user, 1);
            } else isError = true;
          }
        }

        // console.log("EMAIL: FINE INVIO");
        this.isSending = false;
        if (!isError) ref.close();
      });
    });
  }

  // ######################################
  // FUNCTION: utenteSend
  utenteSend(user) {
    this.fire.getOnce("clienti", this.aId).subscribe((azienda) => {
      azienda = azienda.data();
      this.fire.getDocOnce("clienti", this.aId, "licenze", this.lId).subscribe((licenza) => {
        licenza = licenza.data();
        this.g.userSendMail(azienda, licenza, user, this.questionario.anonimo);
      });
    });
  }
}
