import { ContentObserver } from "@angular/cdk/observers";
import { Component, HostListener, OnInit, TemplateRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";
import { InterfaceService } from "src/app/shared/interface.service";

@Component({
  selector: "app-pubblicazione",
  templateUrl: "./pubblicazione.component.html",
  styleUrls: ["./pubblicazione.component.scss"],
})
export class PubblicazioneComponent implements OnInit {
  CryptoJS = require("crypto-js");
  reps: any[] = [];
  loading = true;
  openFolder: any[] = [];

  search = "";
  displayedData: any[] = [];
  nowSeconds: number = Math.trunc(new Date().getTime() / 1000);

  questDocs: any[] = [];
  questVersions: { [key: string]: { versioni: any; }; } = {};

  editCacheL: { [key: string]: { [key2: string]: { edit: boolean; data: any; }; }; } = {};
  viewAll = false;

  onAdd: boolean = false;
  scroll = { y: "" };

  confirmDelete = false;

  // ######################################
  // FUNCTION: constructor
  constructor(
    private modal: NzModalService,
    public g: GlobalService,
    public face: InterfaceService,
    private translate: TranslateService,
    private fire: FireService,
    private node: NodeService
  ) { }

  // ######################################
  // EVENT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiLicenzeTableContainer").length > 0) {
      let height = document.getElementsByClassName("uiLicenzeTableContainer")[0].clientHeight;
      if (
        document.getElementsByClassName("uiLicenzeTableContainer")[0].getElementsByClassName("ant-table-thead").length
      ) {
        let headH = document
          .getElementsByClassName("uiLicenzeTableContainer")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }

  // ######################################
  // FUNCTION: ngAfterViewInit
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant("pubblicazione");
    });
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    let tempPref = this.face.getPubblicazioneInterface();
    if (tempPref === null) {
      tempPref = {};
    }

    this.fire.getAll("questionari", "codice").subscribe((res) => {
      this.questDocs = res;
      if (res.length === 0) {
        this.loading = false;
      }
      this.questDocs.forEach((q, i) => {
        this.fire.getCollection("questionari", q.id, "versioni").subscribe((res2) => {
          this.questVersions[q.id] = { versioni: [] };
          res2.forEach((v) => {
            if (v.pubblico) {
              this.questVersions[q.id].versioni.push(v);
            }
          });
        });
      });
    });

    this.fire.getAllAsc("clienti", "codice").subscribe((res) => {
      this.reps = res;
      for (let i = 0; i < res.length; i++) {
        // if (typeof tempPref[res[i].id] === "undefined" || tempPref[res[i].id] === null) {
        // 	tempPref[res[i].id] = true;
        // 	this.openFolder[res[i].id] = true;
        // } else {
        // 	this.openFolder[res[i].id] = tempPref[res[i].id];
        // }

        let e = res[i];
        res[i].licenze = [];
        res[i].reparti = [];

        this.fire.getCollection("clienti", e.id, "reparti").subscribe((res2) => {
          res[i].reparti = res2;
        });
        this.fire.getCollection("clienti", e.id, "licenze").subscribe((res3) => {
          res[i].licenze = res3;
          res3.forEach((rL, rindex) => {
            let r = rL.reparti;
            // rL.utenti = [];
            // r.forEach((rep) => {
            // 	let repId = rep.toString().split(" ")[0];
            // 	if (repId !== "") {
            // 		this.fire.getSubCollection("clienti", e.id, "reparti", repId, "utenti").subscribe((resU) => {
            // 			resU.forEach((u) => {
            // 				let index = rL.utenti.findIndex((rl) => rl.id === u.id);
            // 				if (index < 0) {
            // 					rL.utenti.push(u);
            // 				}
            // 			});
            // 		});
            // 	}
            // });
            if (rindex === res3.length - 1 && i === res.length - 1) {
              this.setScrollHeight();
              this.loading = false;
              this.face.savePubblicazioneInterface(tempPref);
            }
          });
          if (res3.length === 0 && i === res.length - 1) {
            this.setScrollHeight();
            this.loading = false;
            this.face.saveDesignerInterface(tempPref);
          }
          if (res3.length > 0) {
            let index = this.displayedData.findIndex((d) => d.id === e.id);
            if (index < 0) {
              this.displayedData.push(e);
            }
          }
          if (!this.onAdd) {
            this.updateEditCacheL();
          }
        });
      }
    });
  }

  // ######################################
  // FUNCTION: utentiGet
  utentiSend: any[] = [];
  utentiAllChecked: boolean = false;
  utentiIntChecked: boolean = false;
  licenza: any;
  azienda: any;

  async utentiGet(tplUserTemplate: TemplateRef<{}>, tplFooter: TemplateRef<{}>, licenza, data, type: boolean) {
    const that = this;
    var azId = data.id;
    var reparti = data.reparti;
    this.licenza = licenza;
    this.azienda = data;
    this.utentiSend = [];
    for (const rep of reparti) {
      if (rep.id !== "" && licenza.reparti.includes(rep.id)) {
        await new Promise(function (resolve, reject) {
          that.fire.getSubCollection("clienti", azId, "reparti", rep.id, "utenti").subscribe((resU) => {
            resU.forEach((u) => {
              if (u.sendtype === type) {
                that.utentiSend.push({ ...{ check: false, lic: false, form: [] }, ...u });
              }
              resolve(true);
            });
          });
        });
      }
    }
    if (that.utentiSend.length <= 0) {
      this.g.createErrorNotification(
        "Lista vuota",
        "Non sono stati trovati utenti abilitati al tipo di invio selezionato."
      );
      return;
    }
    if (!type) {
      // ################# EMAIL
      that.utentiSend = that.utentiSend.sort(function (a, b) {
        return a.email.localeCompare(b.email, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
      //// console.log("IDX", idx, array.length - 1);
      this.modal.create({
        nzContent: tplUserTemplate,
        nzFooter: tplFooter,
        nzMaskClosable: false,
        nzClosable: false,
        nzComponentParams: {
          value: "Template Context",
        },
      });
    } else {
      // ################# SMS
      that.utentiSend = that.utentiSend.sort(function (a, b) {
        return a.descrizione.localeCompare(b.descrizione, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
      this.modal.create({
        nzContent: tplUserTemplate,
        nzFooter: tplFooter,
        nzMaskClosable: false,
        nzClosable: false,
        nzComponentParams: {
          value: "Template Context",
        },
      });
    }
  }

  // ######################################
  // FUNCTION: sendEmail
  isSending: boolean = false;

  async sendEmail(ref) {
    var isError: boolean = false;
    this.isSending = true;
    // console.log("EMAIL: INIZIO INVIO");

    for (const user of this.utentiSend) {
      if (user.check) {
        var res = await this.g.userSendMail(this.azienda, this.licenza, user);

        //// console.log("EMAIL: ESITO", res);
        //// console.log("USATE", this.utentiLicenza.utilizzate);

        if (res == "STOP") {
          this.isSending = false;
          return;
        } else if (res) {
          user.check = false;
          this.refreshCheckedStatus();
        } else isError = true;
      }
    }
    // console.log("EMAIL: FINE INVIO");
    this.isSending = false;
    if (!isError) ref.close();
  }

  // ######################################
  // FUNCTION: sendSms
  async sendSms(ref) {
    var isError: boolean = false;
    this.isSending = true;
    // console.log("SMS: INIZIO INVIO");

    for (const user of this.utentiSend) {
      if (user.check) {
        var res = await this.g.userSendSms(this.azienda, this.licenza, user);

        //// console.log("EMAIL: ESITO", res);
        //// console.log("USATE", this.utentiLicenza.utilizzate);

        if (res == "STOP" || !res) {
          this.isSending = false;
          return;
        } else if (res) {
          user.check = false;
          this.refreshCheckedStatus();
        } else isError = true;
      }
    }
    // console.log("SMS: FINE INVIO");
    this.isSending = false;
    if (!isError) ref.close();
  }

  // ######################################
  // FUNCTION: onAllChecked
  onAllChecked(checked: boolean): void {
    this.utentiSend.forEach((u) => (u.check = checked));
    this.refreshCheckedStatus();
  }

  // ######################################
  // FUNCTION: refreshCheckedStatus
  refreshCheckedStatus(): void {
    this.utentiAllChecked = this.utentiSend.every((item) => item.check === true);
    if (!this.utentiAllChecked) this.utentiIntChecked = this.utentiSend.some((item) => item.check === true);
    else this.utentiIntChecked = false;
  }

  // ######################################
  // FUNCTION: updateEditCacheL
  updateEditCacheL(): void {
    this.reps.forEach((item) => {
      this.editCacheL[item.id] = {};
      item.licenze.forEach((lic) => {
        this.editCacheL[item.id][lic.id] = {
          edit: false,
          data: { ...lic },
        };
      });
    });
  }

  // ######################################
  // FUNCTION: newL
  newL(i, data) {
    this.fire.getLastSubBy("clienti", this.displayedData[i].id, "licenze", "codice").then((res) => {
      let newLic = {
        codice: res + 1,
        id: "L" + (res + 1),
        questionario: "",
        versione: "",
        reparti: [],
        note: "",
        totali: 0,
        utilizzate: 0,
        scadenza: new Date(Date.now()),
        attivo: false,
      };
      this.fire
        .addCollection("clienti", "licenze", newLic, this.displayedData[i].id, newLic.id)
        .then((result: any) => {
          if (!this.openFolder[data.id]) this.openArea(i);
        })
        .catch((err) => {
          // console.log(err);
        });
    });
  }

  // ######################################
  // FUNCTION: editL
  editL(id1, id2) {
    this.editCacheL[id1][id2].data.scadenza = new Date(this.editCacheL[id1][id2].data.scadenza.seconds * 1000);
    this.editCacheL[id1][id2].edit = true;
  }

  // ######################################
  // FUNCTION: saveEditL
  saveEditL(i: number, ai: number, id1: string, id2: string): void {
    Object.assign(this.displayedData[i].licenze[ai], this.editCacheL[id1][id2].data);
    this.editCacheL[id1][id2].edit = false;

    let licenza = {
      attivo: this.displayedData[i].licenze[ai].attivo,
      codice: this.displayedData[i].licenze[ai].codice,
      id: this.displayedData[i].licenze[ai].id,
      note: this.displayedData[i].licenze[ai].note,
      questionario: this.displayedData[i].licenze[ai].questionario,
      versione: this.displayedData[i].licenze[ai].versione,
      totali: this.displayedData[i].licenze[ai].totali,
      reparti: this.displayedData[i].licenze[ai].reparti,
      utilizzate: this.displayedData[i].licenze[ai].utilizzate,
      scadenza: this.displayedData[i].licenze[ai].scadenza,
    };

    this.fire.update("clienti", "licenze", licenza, this.displayedData[i].id, this.displayedData[i].licenze[ai].id);
  }

  // ######################################
  // FUNCTION: cancelEditL
  cancelEditL(i: number, ai: number, id1: string, id2: string): void {
    this.editCacheL[id1][id2] = {
      data: { ...this.reps[i].licenze[ai] },
      edit: false,
    };
  }

  // ######################################
  // FUNCTION: openArea
  openArea(i: number) {
    if (this.openFolder[this.displayedData[i].id]) {
      this.openFolder[this.displayedData[i].id] = false;
      this.face.openChange(false, "PUBBLICAZIONE", this.displayedData[i].id);
    } else {
      this.openFolder[this.displayedData[i].id] = true;
      this.loading = false;
      this.face.openChange(true, "PUBBLICAZIONE", this.displayedData[i].id);
    }
  }

  // ######################################
  // FUNCTION: selectReparti
  selectReparti(event: any[], i: number, ai: number) {
    let licenza = JSON.parse(JSON.stringify(this.displayedData[i].licenze[ai]));
    licenza.reparti = [];

    if (event.includes("TUTTI")) {
      if (this.displayedData[i].licenze[ai].reparti.length - 1 === this.displayedData[i].reparti.length) {
      } else {
        this.displayedData[i].reparti.forEach((r) => {
          licenza.reparti.push(r.id);
        });
      }
    } else {
      licenza.reparti = event;
    }
    this.fire.update("clienti", "licenze", licenza, this.displayedData[i].id, this.displayedData[i].licenze[ai].id);
  }

  // ######################################
  // FUNCTION: selectQuest
  selectQuest(event: any, i: number, ai: number) {
    let index = this.questDocs.findIndex((q) => q.id === event);
    this.displayedData[i].licenze[ai].versione = "V" + this.questDocs[index].versione_attiva;
    let licenza = {
      questionario: this.displayedData[i].licenze[ai].questionario,
      versione: this.displayedData[i].licenze[ai].versione,
    };
    this.fire.update("clienti", "licenze", licenza, this.displayedData[i].id, this.displayedData[i].licenze[ai].id);
  }

  // ######################################
  // FUNCTION: selectQuest
  selectVersion(event: any, i: number, ai: number) {
    this.displayedData[i].licenze[ai].versione = event;
    let licenza = {
      versione: this.displayedData[i].licenze[ai].versione,
    };
    this.fire.update("clienti", "licenze", licenza, this.displayedData[i].id, this.displayedData[i].licenze[ai].id);
  }

  // ######################################
  // FUNCTION: onChangeSwitchA
  onChangeSwitchA(e, i, ai) {
    let licenza: any = {};
    licenza.attivo = e;

    if (this.editCacheL[this.displayedData[i].id][this.displayedData[i].licenze[ai].id].edit) {
      this.onAdd = true;
      this.editCacheL[this.displayedData[i].id][this.displayedData[i].licenze[ai].id].data.attivo = e;
    }
    this.fire.update("clienti", "licenze", licenza, this.displayedData[i].id, this.displayedData[i].licenze[ai].id);
  }

  // ######################################
  // FUNCTION: deleteL
  deleteL(i, ai) {
    this.fire
      .delete("clienti", "licenze", this.displayedData[i].id, this.displayedData[i].licenze[ai].id)
      .then()
      .catch((err) => {
        // console.log(err);
      });
  }

  // ######################################
  // FUNCTION: filter
  filter() {
    this.displayedData = this.reps.filter(
      (r) =>
        r.id.toUpperCase().includes(this.search.toUpperCase()) ||
        r.ragione_sociale.toUpperCase().includes(this.search.toUpperCase())
    );
  }

  // ######################################
  // FUNCTION: changeView
  changeView() {
    if (!this.viewAll) {
      this.viewAll = true;
      this.displayedData = this.reps;
    } else {
      this.viewAll = false;
      this.displayedData = this.reps.filter((r) => r.licenze.length > 0);
    }
  }

  // ######################################
  // FUNCTION: handleCancel
  handleCancel() {
    this.confirmDelete = false;
  }
}
