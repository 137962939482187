<div class="form-container">
  <nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <h1>Richiedi informazioni</h1>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="name" nzRequired>Nome</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input your name!">
        <input nz-input formControlName="name" id="name" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email" nzRequired>Email</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input a valid email!">
        <input nz-input formControlName="email" id="email" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="message" nzRequired>Messaggio</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input your message!">
        <textarea nz-input formControlName="message" id="message"></textarea>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzOffset]="6">
        <button nz-button nzType="primary">Invia</button>
      </nz-form-control>
    </nz-form-item>
  </nz-form>
</div>
