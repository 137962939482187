<div class="uiCompilazioneEndContainer">
    <nz-layout class="uiEndInnerLayout">
        <nz-header class="uiCompilazioneEndHeader">
            <img *ngIf="compile.custLogo" [src]="compile.custLogo" alt="Logo" class="uiHeaderCustLogo">
            <div class="uiQuestTitle" [ngStyle]="{'text-align': (compile.custLogo) ? 'center' : 'left'}">
                <span *ngIf="questTitle" class="uiTitleMain">{{questTitle}}</span><br><span class="uiSubtitleMain" *ngIf="questVersion">{{questVersion}}<span *ngIf="questSubTitle"> - </span></span> <span *ngIf="questSubTitle" class="uiSubtitleMain">{{questSubTitle}}</span>
            </div>
            <img src="../../../../assets/logo-light.png" alt="Logo Amajor" class="uiHeaderLogo">
        </nz-header>
        <nz-content class="uiCompilazioneEndContent">
            <h1>Completato</h1>
            <h3>Grazie per aver compilato il questionario!</h3>
            <p>(il tuo questionario è stato inviato con successo, puoi chiudere la pagina per terminare)</p>
            <!-- <button nz-button class="uiEndButton" (click)="close()">Fine</button> -->
        </nz-content>
    </nz-layout>
</div>

<app-legal-footer></app-legal-footer>
