<nz-layout class="uiPageInnerLayout" (window:scroll)="onScroll()">
    <nz-input-group id="searchInput" nzSearch class="search-input">
        <input
            placeholder="Cerca..."
            nz-input
            (input)="onInput($event)"
            (keyup.enter)="search()"
            [nzAutocomplete]="auto"
            [(ngModel)]="searchText"
            #searchInput />
        <button nz-button nzSize="small" nzType="text" (click)="clearSearch()"
            class="clear-button" *ngIf="searchText">
            <i nz-icon nzType="close-circle"></i>
        </button>
        <nz-autocomplete [nzDataSource]="options" nzBackfill
            #auto></nz-autocomplete>
    </nz-input-group>

    <nz-tabset nzCentered (nzSelectedIndexChange)="tabChanged($event)">
        <nz-tab nzTitle="Tutti">
            <ng-template #tabContent>
                <ng-container *ngTemplateOutlet="cardContent"></ng-container>
            </ng-template>
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
        </nz-tab>
        <nz-tab nzTitle="Clima">
            <ng-template #tabContent>
                <ng-container *ngTemplateOutlet="cardContent"></ng-container>
            </ng-template>
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
        </nz-tab>
        <nz-tab nzTitle="Profili Interni">
            <ng-template #tabContent>
                <ng-container *ngTemplateOutlet="cardContent"></ng-container>
            </ng-template>
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
        </nz-tab>
        <nz-tab nzTitle="Profili Selezioni">
            <ng-template #tabContent>
                <ng-container *ngTemplateOutlet="cardContent"></ng-container>
            </ng-template>
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
        </nz-tab>
    </nz-tabset>

    <ng-template #avatarTemplateAnalisi>
        <nz-avatar
            nzSrc="../../../assets/analisi-logo.png"></nz-avatar>
    </ng-template>

    <ng-template #avatarTemplateClima>
        <nz-avatar
            nzSrc="../../../assets/clima-logo.png"></nz-avatar>
    </ng-template>

    <ng-template #avatarTemplateSelezioni>
        <nz-avatar
            nzSrc="../../../assets/selezioni-logo.png"></nz-avatar>
    </ng-template>


    <ng-template #cardContent>
        <div class="grid-container">
            <div class="grid-data" *ngFor="let data of filteredItems" (click)="showAnalysis(data)">
                <nz-card [nzActions]="getActions(data)">
                    <nz-skeleton [nzActive]="true" [nzLoading]="loading"
                        [nzAvatar]="{ size: 'large' }">
                        <nz-card-meta [nzAvatar]="data.quest.titolo.toLowerCase().includes('interni') ? avatarTemplateAnalisi : (data.quest.titolo.toLowerCase().includes('clima')  ? avatarTemplateClima : avatarTemplateSelezioni)"
                            [nzTitle]="data.quest.titolo"
                            [nzDescription]="'Scadenza: ' + (data.scadenza.seconds * 1000 | date: 'dd/MM/yyyy')">
                        </nz-card-meta>
                        <nz-row class="bottom-row">
                            <nz-col>
                                <nz-statistic
                                    [nzValue]="getSumComplete(data)"
                                    [nzTitle]="'Completati'"></nz-statistic>
                            </nz-col>
                            <nz-col>
                                <nz-statistic
                                    [nzValue]="getSumLicenceUsed(data).toString().concat('/', data.totali)"
                                    [nzTitle]="'Utilizzate'"></nz-statistic>
                            </nz-col>
                        </nz-row>
                    </nz-skeleton>
                </nz-card>
            </div>
            <div class="loading-message" *ngIf="loading"><span nz-icon
                    [nzType]="'sync'" [nzSpin]="true"></span></div>
            <div class="no-datas-message"
                *ngIf=" !loading && filteredItems.length === 0">
                <nz-empty></nz-empty> </div>
        </div>
    </ng-template>

    <ng-template #clearButton>
        <button nz-button nzSize="small" nzType="text" (click)="clearSearch()"
            class="clear-button">
            <span nz-icon nzType="close-circle"></span>
        </button>
    </ng-template>

    <div class="waiter" *ngIf="loading">
        <span class="ant-spin-dot ant-spin-dot-spin ng-star-inserted">
            <i class="ant-spin-dot-data"></i><i class="ant-spin-dot-data"></i><i
                class="ant-spin-dot-data"></i><i
                class="ant-spin-dot-data"></i>
        </span>
    </div>
</nz-layout>
