import { Component, ElementRef, HostListener, Input, OnInit, DoCheck, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { from } from "rxjs";
import { FireService } from "src/app/services/fire.service";
import { GlobalService } from "src/app/shared/global.service";
import { InterfaceService } from "src/app/shared/interface.service";

@Component({
  selector: "app-questionari",
  templateUrl: "./questionari.component.html",
  styleUrls: ["./questionari.component.scss"],
})
export class QuestionariComponent implements OnInit, DoCheck {
  reps: any[] = [];
  loading = true;
  openFolder: any[] = [];
  openAreas: any[] = [];

  search = "";
  displayedData: any[] = [];

  questDocs: any[] = [];
  // questVersions: { [key: string]: { versioni: any; }; } = {};

  editCacheL: { [key: string]: { [key2: string]: { edit: boolean; data: any; }; }; } = {};
  viewAll = false;
  now: number;
  scroll = { y: "" };
  fromExcelButton: { answer: boolean, comment: boolean; };
  dataForExcel: any;
  extraQuestions: number | null = null;

  @ViewChild("chart") chart: ElementRef;

  // ######################################
  // FUNCTION: constructor
  constructor(
    public g: GlobalService,
    public face: InterfaceService,
    private translate: TranslateService,
    private fire: FireService,
    private router: Router,
    private modal: NzModalService
  ) { }

  // ######################################
  // EVENT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiGestioneTableContainer").length > 0) {
      let height = document.getElementsByClassName("uiGestioneTableContainer")[0].clientHeight;
      if (
        document.getElementsByClassName("uiGestioneTableContainer")[0].getElementsByClassName("ant-table-thead").length
      ) {
        let headH = document
          .getElementsByClassName("uiGestioneTableContainer")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }

  // ######################################
  // FUNCTION: ngAfterViewInit
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant("questManagment");
    });
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    this.now = new Date(Date.now()).getTime();
    this.displayedData = [];
    this.fire.getAllAsc("clienti", "codice").subscribe((res) => {
      this.reps = res;
      // console.log(res);
      if (res.length === 0) {
        this.loading = false;
      }
      this.displayedData = res;
      this.loading = false;
    });
  }

  ngDoCheck(): void {
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: openArea
  openArea(i: number) {
    this.openAreas[this.displayedData[i].id] = [];
    let e = this.displayedData[i];
    e.licenze = [];
    this.fire.getCollection("clienti", e.id, "licenze").subscribe((res3) => {
      if (res3.length > 0) {
        let licenze = res3;
        licenze.forEach((l) => {
          this.fire.getSubCollection("clienti", e.id, "licenze", l.id, "compilazioni").subscribe((compilazioni) => {
            l.destinatari = compilazioni.length;

            // #################################### SETUP REPARTI ATTIVI
            var inviateUsers = compilazioni.filter((c) => c.sent);
            l.reparti.forEach((rep) => {
              let repId = rep.split(" ")[0];
              l.repartiAttivi = [];
              this.fire.getDocument("clienti", e.id, "reparti", repId).subscribe((resR) => {
                this.fire.getSubCollection("clienti", e.id, "reparti", resR.id, "utenti").subscribe((rUser) => {
                  let index = l.repartiAttivi.findIndex((d) => d.id === resR.id);
                  if (index < 0) {
                    var inCorso = compilazioni.filter((c) =>
                      c.compilazione ? c.compilazione.licenza.reparto == resR.id : 0
                    );
                    var concluse = compilazioni.filter((c) => (c.licenza ? c.licenza.reparto == resR.id : 0));
                    var inviate = inviateUsers.filter((u) => u.reparto == resR.id);
                    l.repartiAttivi.push({
                      id: resR.id,
                      nome: resR.nome,
                      utenti: rUser.length,
                      inCorso: inCorso.length,
                      concluse: concluse.length,
                      inviate: inviate.length,
                    });
                  }
                  l.repartiAttivi.sort(this.g.dynamicNumberSort("id"));
                });
              });
            });
            // ####################################

            l.compilazioni = [];
            if (l.questionario && l.versione) {
              this.fire.getDocument("questionari", l.questionario, "versioni", l.versione).subscribe((res4) => {
                if (res4.tipo === 1) {
                  l.quest = res4;
                  let lindex = e.licenze.findIndex((lic) => lic.id === l.id);
                  if (lindex < 0) {
                    e.licenze.push(l);
                  }
                  let index = this.displayedData.findIndex((d) => d.id === e.id);
                  if (index < 0) {
                    // console.log(e, 'element');
                    this.displayedData.push(e);
                  }
                  compilazioni.forEach((c) => {
                    if (c.concluso) {
                      let index = l.compilazioni.findIndex((co) => co.id === c.id);
                      if (index < 0) l.compilazioni.push(c);
                    }
                  });

                  this.loading = false;
                  this.setScrollHeight();

                  // ####################### GET TIPO QUESTIONARIO (normale / anonimo) PATCH
                  this.fire.get("questionari", l.questionario).subscribe((quest) => {
                    if (l.quest) l.quest.anonimo = quest.anonimo; // PATCH PERCHE' VERSION RIPORTA VALORE ERRATO
                  });
                }
              });
            }
          });
        });
      }
    });
    if (this.openFolder[this.displayedData[i].id]) {
      this.openFolder[this.displayedData[i].id] = false;
    } else {
      this.openFolder[this.displayedData[i].id] = true;
      this.loading = false;
    }
  }

  // ######################################
  // FUNCTION: openSubarea
  openSubarea(i: number, ai: number) {
    if (this.openAreas[this.displayedData[i].id][this.displayedData[i].licenze[ai].id]) {
      this.openAreas[this.displayedData[i].id][this.displayedData[i].licenze[ai].id] = false;
    } else {
      this.openAreas[this.displayedData[i].id][this.displayedData[i].licenze[ai].id] = true;
      this.loading = false;
    }
  }

  // ######################################
  // FUNCTION: filter
  filter() {
    this.displayedData = this.reps.filter(
      (r) =>
        r.id.toUpperCase().includes(this.search.toUpperCase()) ||
        r.ragione_sociale.toUpperCase().includes(this.search.toUpperCase())
    );
  }

  // ######################################
  // FUNCTION: editRep
  editRep(a, r, l) {
    this.router.navigateByUrl("admin/gestione/questionari/edit/" + a + "/" + r + "/" + l);
  }

  // ###################################### ELENCO REPARTI PER REPORT
  reportReparti: any[] = [];
  reportLicenza: any[] = [];
  reportAllChecked: boolean = false;
  reportIntChecked: boolean = false;

  excelModal(data, tplFooter: TemplateRef<{}>) {

    this.dataForExcel = data;

    this.modal.create({
      nzFooter: tplFooter,
      nzWidth: 650,
      nzCentered: true,
      nzClassName: 'text-center'
    });
  }

  // ######################################
  // FUNCTION: reportSelect
  reportSelect(licenza, tplUserEmail: TemplateRef<{}>, tplFooter: TemplateRef<{}>, excel = { answer: false, comment: false }, ref?) {
    this.reportReparti = [];
    this.reportLicenza = licenza;
    this.reportAllChecked = this.reportIntChecked = false;
    if (ref) ref.close();
    licenza.repartiAttivi.forEach((r) => {
      this.reportReparti.push({ ...{ check: false }, ...r });
    });

    this.fromExcelButton = excel;

    this.modal.create({
      nzContent: tplUserEmail,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzCentered: true,
      nzComponentParams: {
        value: "Template Context",
      },
    });

  }

  // ######################################
  // FUNCTION: onAllChecked
  onAllChecked(checked: boolean): void {
    this.reportReparti.forEach((u) => (u.check = checked));
    this.refreshCheckedStatus();
  }

  // ######################################
  // FUNCTION: refreshCheckedStatus
  refreshCheckedStatus(): void {
    this.reportAllChecked = this.reportReparti.every((item) => item.check === true);
    if (!this.reportAllChecked) this.reportIntChecked = this.reportReparti.some((item) => item.check === true);
    else this.reportIntChecked = false;
  }

  // ######################################
  // FUNCTION: reportCreate
  reportCreate(ref, fromExcelButton) {
    var isError: boolean = false;
    let repartiSelezionati = this.reportReparti.filter((r) => r.check === true);
    if (repartiSelezionati.length > 0) {
      if (fromExcelButton.answer) {
        // console.log('excell', fromExcelButton);
        this.g.chartGroupExportExcell(this.reportLicenza, repartiSelezionati, fromExcelButton, this.extraQuestions,);
      } else {
        // console.log('chart', fromExcelButton);
        this.g.chartGroupExport(this.reportLicenza, repartiSelezionati);
      }
    }
    ref.close();
    this.extraQuestions = null;
    this.loading = false;
  }

  howManyQuestions(modalQuantity: TemplateRef<{}>, modalQuantitiFooter: TemplateRef<{}>, ref) {
    this.modal.create({
      nzContent: modalQuantity,
      nzFooter: modalQuantitiFooter,
      nzWidth: 650,
      nzCentered: true,
      nzClassName: 'text-center'
    });
    ref.close();
  }

  numberOfExtraQuestions(value: number) {
    this.extraQuestions = +value;
  }
}
