<nz-layout class="uiPageInnerLayout">
    <nz-content class="uiGestioneContent">
        <div class="uiGestioneHeader">
            <nz-input-group [nzPrefix]="prefixTemplateUser" class="uiSerchbar">
                <input type="text" nz-input placeholder="{{'search' | translate}}" [(ngModel)]="search" (ngModelChange)="filter()" autocomplete="off" />
            </nz-input-group>
            <ng-template #prefixTemplateUser><i class="customIcon uiFilterIcon"></i></ng-template>
        </div>

        <div class="uiGestioneTableContainer" *ngIf="!loading">
            <nz-table class="uiGestioneTable" #usersTable [nzData]="displayedData" [nzShowPagination]="false" [nzScroll]="scroll" [nzPageSize]="100" [nzWidthConfig]="['3%', '3%', '3%', '20%', '20%', '10%', '10%', '5%', '5%', '15%', '6%' ]">
                <thead>
                    <tr>
                        <th nzAlign="center" colspan="4">NOTE</th>
                        <th nzAlign="center">QUESTIONARIO</th>
                        <th nzAlign="center">REPORT</th>
                        <th nzAlign="center">ANONIMO</th>
                        <th nzAlign="center" colspan="2">LICENZE</th>
                        <th nzAlign="center" colspan="2">SCADENZA</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of usersTable.data; let i = index">
                        <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                            <td nzAlign="center" colSpan="2">
                                <i [ngClass]="openFolder[data.id] ? 'customIcon uiFolderOpenIcon': 'customIcon uiFolderIcon'" (click)="openArea(i)" style="margin: 0;" nz-tooltip [nzTooltipTitle]="openFolder[data.id] ? 'Chiudi il cliente' : 'Apri il cliente'"></i>
                            </td>
                            <td colSpan="9">
                                {{data.id}} - {{data.ragione_sociale}}
                            </td>
                        </tr>
                        <ng-container *ngIf="openFolder[data.id]">
                            <ng-container *ngFor="let a of data.licenze; let ai = index">
                                <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                    <td class="uiBlackCol">
                                    </td>
                                    <td nzAlign="center" colSpan="2">
                                        <i [ngClass]="openAreas[data.id][a.id] ? 'customIcon uiFolderOpenIcon': 'customIcon uiFolderIcon'" (click)="openSubarea(i, ai)" style="margin:0" nz-tooltip [nzTooltipTitle]="openAreas[data.id][a.id] ? 'Chiudi la licenza' : 'Apri la licenza'"></i>
                                    </td>
                                    <td nzAlign="left">
                                        {{a.id}} - {{a.note}}
                                    </td>
                                    <td>
                                        {{a.questionario}}{{a.versione}} - {{a.quest.titolo.ITA}}
                                    </td>
                                    <td nzAlign="center">
                                        <span *ngIf="a.quest.report === '1'">Singolo</span>
                                        <div *ngIf="a.quest.report === '2'">
                                            <span>Gruppo</span>
                                            <span *ngIf="openAreas[data.id][a.id]">
                                              <i style="vertical-align: sub;" class="customIcon uiReportGrayIcon" style="margin-left: 10px;" (click)="reportSelect(a, tplUserEmail, tplFooter)" nz-tooltip nzTooltipTitle="Crea report"></i>
                                              <i style="vertical-align: sub;" class="customIcon uiExcelIcon" style="margin-left: 10px;" (click)="excelModal(a, excelFooter)" nz-tooltip nzTooltipTitle="Esporta"></i>
                                            </span>
                                        </div>
                                    </td>
                                    <td nzAlign="center" nz-tooltip [nzTooltipTitle]="a.quest.anonimo ? 'Tipo questionario: Anonimo' : 'Tipo questionario: Normale'">
                                        <nz-switch [ngModel]="a.quest.anonimo" class="uiNoEvents"></nz-switch>
                                    </td>
                                    <td nzAlign="center" nz-tooltip nzTooltipTitle="Utilizzate">
                                        {{a.utilizzate}}
                                    </td>
                                    <td nzAlign="center" class="uiDarkGreyCol" nz-tooltip nzTooltipTitle="Totali">
                                        {{a.totali}}
                                    </td>

                                    <td nzAlign="center">
                                        {{a.scadenza.seconds * 1000 | date: 'dd/MM/yyyy'}}
                                    </td>
                                    <td nzAlign="center" [ngClass]="(a.scadenza.seconds * 1000 ) > now ? 'uiOnCol':'uiOffCol'" nz-tooltip [nzTooltipTitle]="(a.scadenza.seconds * 1000 ) > now ? 'Licenza attiva' : 'Licenza scaduta'">
                                        <nz-switch [ngModel]="(a.scadenza.seconds * 1000 ) > now" class="uiNoEvents"></nz-switch>
                                    </td>
                                </tr>
                                <ng-container *ngIf="openAreas[data.id][a.id]">
                                    <tr *ngFor="let r of a.repartiAttivi; let ui = index" [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                        <td class="uiBlackCol">
                                        </td>
                                        <td colSpan="5">
                                            {{r.id}} - {{r.nome}}
                                        </td>
                                        <td colSpan="4" nzAlign="center">
                                            <div nz-row>
                                                <div nz-col nzSpan="8">Inviati: {{r.inviate}}</div>
                                                <div nz-col nzSpan="8">In corso: {{r.inCorso}}</div>
                                                <div nz-col nzSpan="8">Conclusi: {{r.concluse}}</div>
                                            </div>
                                        </td>
                                        <td nzAlign="center">
                                            <i class="customIcon uiMoreIcon" (click)="editRep(data.id, r.id, a.id)" nz-tooltip nzTooltipTitle="Visualizza dettagli"></i>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tbody>
            </nz-table>
        </div>
        <google-chart id="chart" class="printChart" [type]="g.chartType" [data]="g.chartData" [columns]="g.chartColumnNames" *ngIf="g.chartVisible" [options]="g.chartDisplayOptions"></google-chart>

        <div class="chartOverlay" *ngIf="g.chartProgress">
            <i class="customIcon uiLoadingIcon"></i>
            <br />
            <h1>Esportazione in corso...</h1>
        </div>
    </nz-content>

        <!-- spinner -->
    <div class="waiter" *ngIf="loading">
      <span class="ant-spin-dot ant-spin-dot-spin ng-star-inserted"><i class="ant-spin-dot-item"></i><i
      class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span>
    </div>
</nz-layout>

<ng-template #tplUserEmail let-ref="modalRef">
    <nz-table #rowSelectionTable nzShowSizeChanger nzBordered [nzData]="reportReparti" [nzShowPagination]="false" [nzScroll]="{ y: '60vh' }">
        <thead>
            <tr>
                <th nzWidth="50px" [(nzChecked)]="reportAllChecked" [nzIndeterminate]="reportIntChecked" (nzCheckedChange)="onAllChecked($event)"></th>
                <th>SELEZIONARE I REPARTI</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let r of reportReparti">
                <td [(nzChecked)]="r.check" (nzCheckedChange)="refreshCheckedStatus()"></td>
                <td>{{r.id}}&nbsp;&nbsp;&nbsp;{{r.nome}}</td>
            </tr>
        </tbody>
    </nz-table>
</ng-template>


<ng-template #tplFooter let-ref="modalRef">
    <button nz-button (click)="ref.destroy()">Annulla</button>
    <button nz-button nzType="primary" [disabled]="!reportIntChecked && !reportAllChecked" (click)="reportCreate(ref, fromExcelButton)">
        Crea Report
    </button>
</ng-template>

<ng-template #excelFooter style="text-align: center;" let-ref="modalRef">
  <div class="text-center">
    <button nz-button nzType="primary" (click)="reportSelect(dataForExcel, tplUserEmail, tplFooter, {answer: true, comment: false}, ref)">
        Estrai risposte
    </button>
    <button nz-button nzType="primary" (click)="reportSelect(dataForExcel, tplUserEmail, tplFooter,{answer: true, comment: true}, ref)">
        Estrai commenti
    </button>
    <button nz-button nzType="primary" (click)="howManyQuestions(howManyQuestion, howManyQuestionFooter, ref)">
        Estrai domande extra
    </button>
  </div>
</ng-template>

<ng-template #howManyQuestion style="text-align: center;" let-ref="modalRef">
  <div class="text-center">
    <label>Quante sono le domande extra alla fine del questionario?</label>
    <div class="text-center">
      <input
        style="width: 180px; margin-top: 10px"
        class="text-center"
        nz-input
        nz-tooltip
        nzTooltipTrigger="focus"
        nzOverlayClassName="numeric-input"
        [ngModel]="extraQuestions"
        placeholder="Inserisci un numero"
        (ngModelChange)="numberOfExtraQuestions($event)"
      />
    </div>
  </div>
</ng-template>
<ng-template #howManyQuestionFooter let-ref="modalRef">
    <button nz-button (click)="ref.destroy()">Annulla</button>
    <button [disabled]="!extraQuestions" nz-button nzType="primary" [disabled]="!reportIntChecked && !reportAllChecked" (click)="reportSelect(dataForExcel, tplUserEmail, tplFooter,{answer: true, comment: true}, ref)">
        Continua
    </button>
</ng-template>
