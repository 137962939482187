import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditValutazioniComponent } from './edit-valutazioni.component';
import { EditValutazioniRoutingModule } from './edit-valutazioni-routing.module';
import { NgZorroModule } from 'src/app/ng-zorro.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { KeysPipe } from '../../../../../pipes/keys.pipe';
import { QuillModule } from 'ngx-quill';
import { GoogleChartsModule } from 'angular-google-charts';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    EditValutazioniComponent,
    KeysPipe
  ],
  imports: [
    EditValutazioniRoutingModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgZorroModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateModule,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    }),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'size': ['small', false, 'large', 'huge'] }]
        ],
      },
      placeholder: 'Inserisci testo...',
      theme: 'snow'
    }),
    GoogleChartsModule
  ],
  exports: [EditValutazioniComponent, NgZorroModule],
  providers: [AuthService, AuthGuard],
})
export class EditValutazioniModule { }
