import { Pipe, PipeTransform } from "@angular/core";
import { GlobalService } from "../shared/global.service";

@Pipe({
	name: "geticon",
})
export class GeticonPipe implements PipeTransform {
	// ######################################
	// FUNCTION: constructor
	constructor(public g: GlobalService) {}

	// ######################################
	// FUNCTION: transform
	transform(value: string): string {
		let opt = this.g.listOfPermissions[this.g.listOfPermissions.findIndex((p) => p.label === value)];
		return opt.icona;
	}
}
