import { Component, OnInit } from "@angular/core";
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { User } from "src/app/entities/users";
import { NodeService } from "src/app/services/node.service";

@Component({
	selector: "app-recover",
	templateUrl: "./recover.component.html",
	styleUrls: ["./recover.component.scss"],
})
export class RecoverComponent implements OnInit {
	CryptoJS = require("crypto-js");
	code: string;
	user: User;
	showError: boolean = false;
	showPwdError: boolean = false;
	loading: boolean = false;
	password: string;
	repeatpassword: string;
	userForm: UntypedFormGroup;

	// ######################################
	// FUNCTION: constructor
	constructor(
		private route: ActivatedRoute,
		private node: NodeService,
		private router: Router,
		private modalService: NzModalService,
		private fb: UntypedFormBuilder
	) {}

	// ######################################
	// FUNCTION: ngOnInit
	ngOnInit(): void {
		this.route.params.subscribe((param) => {
			this.code = param.code;
		});

		const formData = {
			field: "temp_code",
			code: this.code,
		};

		this.node.getUserBy(formData).subscribe(
			(res) => {
				this.user = res as User;
				this.loading = true;
				this.showError = false;
			},
			(err) => {
				// TEMP CODE NOT EXIST OR EXPIRED
				// console.log("ERR", err);
				this.loading = true;
				this.showError = true;
			}
		);

		this.userForm = this.fb.group(
			{
				password: new UntypedFormControl("", [Validators.minLength(8)]),
				confirmPassword: new UntypedFormControl("", [Validators.required]),
			},
			{ validators: this.checkPasswords }
		);
	}

	// ######################################
	// FUNCTION: checkPasswords
	checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
		let pass = group.get("password")!.value;
		let confirmPass = group.get("confirmPassword")!.value;
		return pass === confirmPass ? null : { notSame: true };
	};

	// ######################################
	// FUNCTION: onSubmit
	async onSubmit() {
		this.showPwdError = false;
		if (this.password === this.repeatpassword) {
			this.user.pwd = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(this.password));

			const formData = {
				user: this.user,
			};
			this.node.editUser(formData).subscribe(
				(res) => {
					this.modalService.success({
						nzTitle: "Operazione completata",
						nzContent: "Operazione completata con successo.",
						nzOkText: "Vai alla home",
						nzOnOk: () => this.router.navigateByUrl("/login"),
					});
				},
				(err) => {
					this.modalService.error({
						nzTitle: "Errore",
						nzContent: "Si è verificato un errore durante il ripristino della password.",
					});
					console.error(err);
				}
			);
		} else {
			this.showPwdError = true;
		}
	}
}
