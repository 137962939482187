<nz-layout>
    <nz-sider class="uiEditSidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
        [nzTrigger]="null">
        <div class="uiQuestSidebarLogo">
            <a href="/admin"><img class="uiUncollapsedLogo" src="../../../assets/logo-light.png" alt="logo"
                    *ngIf="!isCollapsed"></a>
            <a href="/admin"><img class="uiCollapsedLogo" src="../../../assets/logo-small.png" alt="logo"
                    *ngIf="isCollapsed"></a>
        </div>
        <ul class="uiQuestLanguage" nz-menu *ngIf="privacy && !isCollapsed" nzMode="inline">
            <li nz-submenu nzTitle="{{pId}} {{language}}"
                class="uiHeadLi">
                <ul class="uiSubMenuList">
                    <li (click)="language = l"
                        [ngClass]="language === l ? 'uiSubMenuLangItem uiSubMenuLangSelectItem' : 'uiSubMenuLangItem'"
                        nz-menu-item *ngFor="let l of privacy.lingue">
                        <span>{{l}}</span>
                    </li>
                </ul>
            </li>
        </ul>
        <ul class="uiMenu" nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed"
            *ngIf="!isCollapsed">
            <li nz-menu-item class="uiMenuItem uiActionItem" [nzMatchRouter]="true">
                <a (click)="back()"><i class="customIcon uiRollbackIcon"></i>
                    Ritorna</a>
            </li>
            <li nz-menu-item class="uiMenuItem uiActionItem" [nzMatchRouter]="true">
                <a (click)="save()"><i class="customIcon uiSaveIcon"></i>
                    Salva</a>
            </li>
            <li nz-menu-item class="uiLastMenuItem uiMenuItem">
                <a (click)="saveAndExit()"><i class="customIcon uiSaveRedIcon" style="margin-right: 15px;"></i>
                    Salva ed esci</a>
            </li>
        </ul>
        <ul class="uiCollapsedMenu" nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed"
            *ngIf="isCollapsed">
            <li nz-menu-item class="uiMenuItem uiMenuCollapsedItem">
                <a (click)="back()"><i class="customIcon uiRollbackIcon"></i></a>
            </li>
            <li nz-menu-item class="uiMenuItem uiMenuCollapsedItem">
                <a (click)="save()"><i class="customIcon uiSaveIcon"></i></a>
            </li>
            <li nz-menu-item class="uiLastMenuItem uiMenuItem uiMenuCollapsedItem">
                <a (click)="saveAndExit()"><i class="customIcon uiSaveRedIcon"></i></a>
            </li>
        </ul>
    </nz-sider>
    <nz-layout>
        <nz-header class="uiHeader uiQuestHeader">
            <div nz-row class="uiHeaderRow">
                <div class="uiHeaderLeftCol" nz-col nzSpan="16">
                    <span class="uiSidebarTrigger" (click)="isCollapsed = !isCollapsed">
                        <i class="trigger"
                            [ngClass]="isCollapsed ? 'customIcon uiMenuFoldIcon' : 'customIcon uiMenuFoldIcon'"></i>
                    </span>
                    <span>{{g.actualPage}}</span>
                </div>
                <div nz-col nzSpan="8" class="uiHeaderRightCol">
                    {{loggedIn.email}}
                    <i *ngIf="auth.isLoggedIn()" class="customIcon uiLogoutIcon" (click)="auth.logout()"></i>
                </div>
            </div>
        </nz-header>
        <nz-content class="uiGestioneEditContent" *ngIf="!loading">
            <div nz-row class="uiEditPrivacyParagraphRow" [nzGutter]="16">
                <span>TESTATA</span>
            </div>
            <div nz-row class="uiEditPrivacyFieldsRow" [nzGutter]="16">
                <div nz-col nzSpan="4" class="uiRowCol uiEditPrivacyFieldsFlex uiLeftFlex">
                    <div class="uiGreyCol">
                        <p>CODICE</p>
                    </div>
                    <div class="uiBlackCol">
                        {{privacy.id}}
                    </div>
                </div>
                <div nz-col nzSpan="4" class="uiRowCol uiEditPrivacyFieldsFlex uiLeftFlex">
                    <div class="uiGreyCol">
                        <p>ATTIVA</p>
                    </div>
                    <div style="justify-content: center;" [ngClass]="privacy.attivo ? 'uiGreenCol' : 'uiLightGreyCol'">
                        <nz-switch [(ngModel)]="privacy.attivo"></nz-switch>
                    </div>
                </div>
                <div nz-col nzSpan="8" class="uiRowCol uiEditPrivacyFieldsFlex uiLeftFlex">
                    <div class="uiGreyCol">
                        <p>DATA CREAZIONE</p>
                    </div>
                    <div class="uiBlackCol">
                        {{privacy.creazione.seconds * 1000 | date: 'dd/MM/yyyy'}}
                    </div>
                </div>
                <div nz-col nzSpan="8" class="uiRowCol uiEditPrivacyFieldsFlex uiLeftFlex">
                    <div class="uiGreyCol">
                        <p>DATA DISATTIVAZIONE</p>
                    </div>
                    <div class="uiLightGreyCol">
                        <nz-date-picker class="uiDateInput" [(ngModel)]="data_disattivazione" nzFormat="dd/MM/yyyy"
                            [nzLocale]="g.dataITtranslation" nzBorderless></nz-date-picker>
                    </div>
                </div>
            </div>
            <div nz-row class="uiEditPrivacyFieldsRow" [nzGutter]="16">
                <div nz-col nzSpan="24" class="uiRowCol uiEditPrivacyFieldsFlex uiLeftFlex">
                    <div class="uiGreyCol">
                        <p>TITOLO</p>
                    </div>
                    <div class="uiLightGreyCol" style="justify-content: center;">
                        <input nz-input class="uiHeadInput" [(ngModel)]="privacy.titolo" />
                    </div>
                </div>
            </div>
            <div nz-row class="uiEditPrivacyFieldsRow" [nzGutter]="16">
                <div nz-col nzSpan="20" class="uiRowCol uiEditPrivacyFieldsFlex uiLeftFlex">
                    <div class="uiGreyCol">
                        <p>LINGUE</p>
                    </div>
                    <div class="uiBlackCol">
                        <nz-select nzMode="multiple" [(ngModel)]="privacy.lingue" (ngModelChange)="onSelect($event)"
                            [ngClass]="privacy.lingue.length > 1 ? 'uiHeadSelect uiShowCancel' : 'uiHeadSelect uiNoCancel'">
                            <nz-option *ngFor="let item of env.languages" [nzLabel]="item" [nzValue]="item"></nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col nzSpan="4" class="uiRowCol uiEditPrivacyFieldsFlex uiLeftFlex">
                    <div class="uiGreyCol">
                        <p>ALLEGATO</p>
                    </div>
                    <div *ngIf="!privacy.allegato[language] || privacy.allegato[language] === '' else pdfViewer;" class="uiLightGreyCol">
                        <nz-upload (nzChange)="handleChange($event)" nzAction="{{uploadURL}}" nzAccept="application/pdf"
                            [nzCustomRequest]="customFileReq" [nzShowUploadList]="g.uploadButtons">
                            <button nz-button class="uiUploadBtn">
                                <i class="customIcon uiPdfIcon"></i>
                            </button>
                        </nz-upload>

                    </div>
                    <ng-template #pdfViewer>
                        <div class="uiYellowCol">
                            <button nz-button class="uiUploadBtn"
                                (click)="downloadFile(privacy.allegato[language])">
                                <i class="customIcon uiPdfIcon"></i>
                                <i class="customIcon uiCloseGreyIcon"
                                    style="width: 15px; height: 15px; margin-bottom: 17px;" (click)="deleteFile()"></i>
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div nz-row class="uiEditPrivacyParagraphRow" [nzGutter]="16">
                <div nz-col nzSpan="12" class="uiRowCol uiParagraphLeft">
                    <div class="uiBlackCol">
                        <span>CHECKBOX | {{privacy.checkbox[language].length}}</span>
                    </div>
                </div>
                <div nz-col nzSpan="12" class="uiRowCol uiParagraphRight">
                    <button nz-button class="uiAddCheckbox" (click)="addCheckbox()">
                        Aggiungi checkbox <i class="customIcon uiPlusCircleIcon"></i>
                    </button>
                </div>
            </div>

            <div class="uiCheckboxTableContainer">
                <div class="uiBkgContainer">
                    <nz-table *ngIf="privacy.checkbox[language] && privacy.checkbox[language].length > 0; else emptyTemplate" class="uiCheckboxTable" #check
                        [nzData]="privacy.checkbox[language]" [nzFrontPagination]="false" [nzScroll]="scroll"
                        [nzPageSize]="100" [nzShowPagination]="false">
                        <thead>
                            <tr>
                                <th nzWidth="10%" nzAlign="center">CODICE</th>
                                <th nzWidth="30%" nzAlign="center">TESTO CAMPO</th>
                                <th nzWidth="20%" nzAlign="center">TESTO ACCETTATO</th>
                                <th nzWidth="20%" nzAlign="center">TESTO NON ACCETTATO</th>
                                <th nzWidth="10%" nzAlign="center">RICHIESTO</th>
                                <th nzWidth="10%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of check.data; let i = index"
                                [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                <td class="uiEditedCol">
                                    {{c.id}}
                                </td>
                                <td class="uiEditedCol">
                                    <input type="text" nz-input class="uiTableInput" [(ngModel)]="c.testo" />
                                </td>
                                <td class="uiEditedCol">
                                    <input type="text" nz-input class="uiTableInput" [(ngModel)]="c.accetto" />
                                </td>
                                <td class="uiEditedCol">
                                    <input type="text" nz-input class="uiTableInput" [(ngModel)]="c.non_accetto" />
                                </td>
                                <td class="uiEditedCol" nzAlign="center">
                                    <nz-switch [(ngModel)]="c.richiesto"></nz-switch>
                                </td>
                                <td class="uiEditedCol" nzAlign="center">
                                    <i class="customIcon uiCloseIcon" (click)="deleteCheckbox(c.id)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                    <ng-template #emptyTemplate>
                        <div class="uiEmptyContainer">
                            <nz-empty nzNotFoundImage="simple" style="color: var(--neutral-color)"></nz-empty>
                        </div>
                    </ng-template>
                </div>
            </div>
        </nz-content>
    </nz-layout>
</nz-layout>