<nz-layout>
    <nz-sider class="uiEditSidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
        <div class="uiQuestSidebarLogo">
            <a href="/admin"><img class="uiUncollapsedLogo" src="../../../assets/logo-light.png" alt="logo" *ngIf="!isCollapsed"></a>
            <a href="/admin"><img class="uiCollapsedLogo" src="../../../assets/logo-small.png" alt="logo" *ngIf="isCollapsed"></a>
        </div>
        <ul class="uiMenu" nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed" *ngIf="!isCollapsed">
            <li nz-menu-item class="uiMenuItem uiActionItem" [nzMatchRouter]="true">
                <a (click)="back()"><i class="customIcon uiRollbackIcon"></i>
                    Ritorna</a>
            </li>
            <li nz-menu-item class="uiMenuItem uiActionItem" [nzMatchRouter]="true" *ngIf="bSave">
                <a (click)="save()"><i class="customIcon uiSaveIcon"></i>
                    Salva</a>
            </li>
            <li nz-menu-item class="uiLastMenuItem uiMenuItem" *ngIf="bSave">
                <a (click)="saveAndExit()"><i class="customIcon uiSaveRedIcon" style="margin-right: 15px;"></i>
                    Salva ed esci</a>
            </li>
        </ul>

        <ul class="uiCollapsedMenu" nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed" *ngIf="isCollapsed">
            <li nz-menu-item class="uiMenuItem uiMenuCollapsedItem">
                <a (click)="back()"><i class="customIcon uiRollbackIcon"></i></a>
            </li>
        </ul>
    </nz-sider>
    <nz-layout>
        <nz-header class="uiHeader uiQuestHeader">
            <div nz-row class="uiHeaderRow">
                <div class="uiHeaderLeftCol" nz-col nzSpan="16">
                    <span class="uiSidebarTrigger" (click)="isCollapsed = !isCollapsed">
                        <i class="trigger" [ngClass]="isCollapsed ? 'customIcon uiMenuFoldIcon' : 'customIcon uiMenuFoldIcon'"></i>
                    </span>
                    <span>{{g.actualPage}}</span>
                </div>
                <div nz-col nzSpan="8" class="uiHeaderRightCol">
                    {{loggedIn.email}}
                    <i *ngIf="auth.isLoggedIn()" class="customIcon uiLogoutIcon" (click)="auth.logout()"></i>
                </div>
            </div>
        </nz-header>
        <nz-content class="uiGestioneEditContent">
            <div class="uiGestioneEditVContainer" *ngIf="!loading">
                <nz-table class="uiGestioneEditVTable" #valTable [nzData]="intestazione" [nzShowPagination]="false" [nzScroll]="scroll" [nzPageSize]="100" [nzWidthConfig]="['30%', '70%']">
                    <thead>
                        <tr>
                            <th colspan="2">INTESTAZIONE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let int of valTable.data; let i = index">
                            <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                <td>
                                    {{int.etichetta}}
                                </td>
                                <td>
                                    {{int.valore}}
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </nz-table>
                <div class="uiValControls">
                    <div class="uiValControlsLeft">
                        <span *ngIf="data[0].attendibilita === true" class="uiValPositive">ATTENDIBILE</span>
                        <span *ngIf="data[0].attendibilita === false" class="uiValNegative">NON ATTENDIBILE</span>
                    </div>
                    <div class="uiValControlsRight">

                        <span *ngIf="data[0].forzatura === true" class="uiValPositive">NON FORZATO</span>
                        <span *ngIf="data[0].forzatura === false" class="uiValNegative">FORZATO</span>
                    </div>
                </div>
                <div class="uiGestioneEditVGraph" [class.uiGestioneEditVBigGraph]="!bSave">
                    <div nz-row class="uiEditGridRow">
                        <div nz-col nzFlex="50px" class="uiEditGridCol uiEditGridFirstCol">
                            <span>Range +/- 100</span>
                        </div>
                        <div nz-col nzFlex="auto" class="uiEditGridCol uiEditGridSecondCol">
                            <google-chart class="chart" #chart [type]="g.chartType" [data]="g.chartData" [columns]="g.chartColumnNames" *ngIf="!g.chartVisible" [options]="g.chartDisplayOptions"></google-chart>
                        </div>
                    </div>
                </div>
                <div class="uiGestioneEditVGrid" *ngIf="bSave">
                    <div nz-row class="uiEditGridRow">
                        <div nz-col nzFlex="50px" class="uiEditGridCol uiEditGridFirstCol">
                            <i class="customIcon uiWhiteCommentIcon"></i>
                        </div>
                        <div nz-col nzFlex="auto" class="uiEditGridCol uiEditGridSecondCol">
                            <quill-editor [(ngModel)]="valText"></quill-editor>
                        </div>
                    </div>
                </div>
            </div>
        </nz-content>
    </nz-layout>
</nz-layout>
