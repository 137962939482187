import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpHeaders, HttpErrorResponse, HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import { GlobalService } from "../shared/global.service";
import { StorageService } from "./storage.service";
import { User } from "../entities/users";
@Injectable()
export class AuthService {
  private baseUrl: string;

  // ######################################
  // FUNCTION: constructor
  constructor(
    private myRoute: Router,
    private http: HttpClient,
    private g: GlobalService,
    private storage: StorageService
  ) {
    this.baseUrl = this.g.nodePath;
  }

  // ######################################
  // FUNCTION: saveUser
  saveUser(user: User) {
    sessionStorage.setItem("LoggedInUser", btoa(JSON.stringify(user)));
  }

  // ######################################
  // FUNCTION: getUser
  getUser() {
    let loggedIn = sessionStorage.getItem("LoggedInUser") as string;
    if (loggedIn !== null) {
      try {
        let decripted = atob(loggedIn);
        if (decripted) {
          return JSON.parse(atob(loggedIn));
        }
      } catch (error) {
        sessionStorage.clear();
      }
    } else {
      return null;
    }
  }

  // ######################################
  // FUNCTION: resetUser
  resetUser() {
    sessionStorage.clear();
  }

  // ######################################
  // FUNCTION: isLoggedIn
  isLoggedIn() {
    return this.getUser() !== null;
  }

  // ######################################
  // FUNCTION: logout
  logout() {
    this.resetUser();
    this.myRoute.navigate(["admin/login"]);
    this.g.actualPage = "login";
  }

  // ######################################
  // FUNCTION: checkLogin
  checkLogin(formData: any): Observable<Error | any> {
    const header = new HttpHeaders();
    header.append("Content-Type", "application/json");
    return this.http.post<any>(this.baseUrl + "/users/login", formData).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
    );
  }
}
