<nz-layout class="uiPageInnerLayout">
  <nz-content
    class="uiQuestHeadContent"
    *ngIf="g.temp_quest && g.temp_quest.tipo === 1"
  >
    <div nz-row class="uiQuestParagraphRow" [nzGutter]="16">
      <span>TESTATA</span>
    </div>
    <div nz-row class="uiQuestFieldsRow" [nzGutter]="16">
      <div nz-col nzSpan="8" class="uiRowCol uiQuestFieldsFlex uiLeftFlex">
        <div class="uiGreyCol">
          <p>CODICE</p>
        </div>
        <div class="uiBlackCol" style="justify-content: center">
          <p>{{ g.temp_quest_id }}</p>
        </div>
        <div class="uiGreyCol">
          <p>VERSIONE</p>
        </div>
        <div class="uiBlackCol" style="justify-content: center">
          <p>V{{ g.temp_quest.codice }}</p>
        </div>
      </div>
      <div nz-col nzSpan="8" class="uiRowCol uiQuestFieldsFlex uiLeftFlex">
        <div class="uiGreyCol">
          <p>TIPO</p>
        </div>
        <div class="uiBlackCol" *ngIf="g.temp_quest.tipo === 1">
          <p>Standard</p>
        </div>
        <div class="uiBlackCol" *ngIf="g.temp_quest.tipo === 2">
          <p>Aggregato</p>
        </div>
      </div>
      <div nz-col nzSpan="4" class="uiRowCol uiQuestFieldsFlex uiRightFlex">
        <div class="uiGreyCol">
          <p>PRIVACY</p>
        </div>
        <div class="uiLightGreyCol">
          <nz-select [(ngModel)]="g.temp_quest.privacy">
            <nz-option
              *ngFor="let p of privacies"
              [nzLabel]="p.id"
              [nzValue]="p.id"
            ></nz-option>
          </nz-select>
        </div>
      </div>
      <div nz-col nzSpan="4" class="uiRowCol uiQuestFieldsFlex uiRightFlex">
        <div class="uiGreyCol">
          <p>PUBBLICO</p>
        </div>
        <div
          [ngClass]="
            g.temp_quest.pubblico
              ? 'uiGreenCol uiLast'
              : 'uiLightGreyCol uiLast'
          "
          style="justify-content: center"
        >
          <nz-switch
            [(ngModel)]="g.temp_quest.pubblico"
            (ngModelChange)="onChangeSwitch($event)"
          ></nz-switch>
        </div>
      </div>
    </div>
    <div nz-row class="uiQuestFieldsRow" [nzGutter]="16">
      <div nz-col nzSpan="16" class="uiRowCol uiQuestFieldsFlex uiLeftFlex">
        <div class="uiGreyCol">
          <p>TITOLO</p>
        </div>
        <div class="uiYellowCol">
          <input
            nz-input
            class="uiHeadInput"
            [(ngModel)]="g.temp_quest.titolo[g.temp_quest_lang]"
          />
        </div>
      </div>
      <div nz-col nzSpan="8" class="uiRowCol uiQuestFieldsFlex uiRightFlex">
        <div class="uiGreyCol">
          <p>NOTE VERSIONE</p>
        </div>
        <div class="uiLightGreyCol">
          <input nz-input class="uiHeadInput" [(ngModel)]="g.temp_quest.note" />
        </div>
      </div>
    </div>
    <div nz-row class="uiQuestFieldsRow" [nzGutter]="16">
      <div nz-col nzSpan="16" class="uiRowCol uiQuestFieldsFlex uiLeftFlex">
        <div class="uiGreyCol">
          <p>LINGUE</p>
        </div>
        <div class="uiBlackCol">
          <nz-select
            nzMode="multiple"
            [(ngModel)]="g.temp_quest.lingue"
            [ngClass]="
              g.temp_quest.lingue.length > 1
                ? 'uiHeadSelect uiShowCancel'
                : 'uiHeadSelect uiNoCancel'
            "
          >
            <nz-option
              *ngFor="let item of env.languages"
              [nzLabel]="item"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
        </div>
      </div>
      <div nz-col nzSpan="4" class="uiRowCol uiQuestFieldsFlex uiRightFlex">
        <div class="uiGreyCol">
          <p>REPORT</p>
        </div>
        <div class="uiLightGreyCol">
          <nz-select [(ngModel)]="g.temp_quest.report">
            <nz-option nzValue="1" nzLabel="Singolo"></nz-option>
            <nz-option nzValue="2" nzLabel="Gruppo"></nz-option>
          </nz-select>
        </div>
      </div>
      <div nz-col nzSpan="4" class="uiRowCol uiQuestFieldsFlex uiRightFlex">
        <div class="uiGreyCol">
          <p>COPERTINA REPORT</p>
        </div>
        <div
          *ngIf="
            g.temp_quest.copertina_report[g.temp_quest_lang] === '';
            else pdfViewer
          "
          class="uiLightGreyCol"
        >
          <nz-upload
            (nzChange)="handleChange($event)"
            nzAction="{{ uploadURL }}"
            nzAccept="application/pdf"
            [nzCustomRequest]="customFileReq"
            [nzShowUploadList]="g.uploadButtons"
          >
            <button nz-button class="uiUploadBtn">
              <i class="customIcon uiPdfIcon"></i>
            </button>
          </nz-upload>
          <!-- <p>{{g.temp_quest.copertina_report[g.temp_quest_lang]}}</p> -->
        </div>
        <ng-template #pdfViewer>
          <div class="uiYellowCol">
            <button
              nz-button
              class="uiUploadBtn"
              (click)="
                downloadFile(g.temp_quest.copertina_report[g.temp_quest_lang])
              "
            >
              <i class="customIcon uiPdfIcon"></i>
              <i
                class="customIcon uiCloseGreyIcon"
                style="width: 15px; height: 15px; margin-bottom: 17px"
                (click)="deleteFile()"
              ></i>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
    <div nz-row class="uiQuestParagraphRow" [nzGutter]="16">
      <span>AUTORIZZAZIONI</span>
    </div>
    <div nz-row class="uiQuestFieldsRow" [nzGutter]="16">
      <div nz-col nzSpan="24" class="uiRowCol uiQuestFieldsFlex">
        <div
          class="uiBlackCol"
          style="
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: left;
          "
        >
          <nz-select
            class="uiHeadSelect"
            nzMode="multiple"
            [(ngModel)]="g.temp_quest.autorizzazioni"
            [nzCustomTemplate]="selectTplAuth"
            [ngClass]="
              g.temp_quest.autorizzazioni.length > 1
                ? 'uiHeadSelect uiShowCancel'
                : 'uiHeadSelect uiNoCancel'
            "
          >
            <nz-option
              *ngFor="let rep of reps"
              [nzLabel]="rep.label"
              [nzValue]="rep.label"
            ></nz-option>
          </nz-select>
        </div>
        <ng-template #selectTplAuth let-selected>
          <span style="margin-right: 20px">{{ selected.nzValue }}</span>
        </ng-template>
        <ng-template #selectIconTpl>
          <i class="customIcon uiSelectIcon"></i>
        </ng-template>
      </div>
    </div>
    <!-- ############################################################# -->
    <div nz-row class="uiQuestParagraphRow" [nzGutter]="16">
      <div nz-col nzSpan="12" class="uiRowCol uiParagraphLeft">
        <div class="uiBlackCol">
          <span>ANAGRAFICA ({{ g.temp_quest.anagrafica.length }})</span>
        </div>
      </div>
      <div nz-col nzSpan="12" class="uiRowCol uiParagraphRight">
        <button nz-button class="uiAddAnagrafica" (click)="addAnagrafica()">
          Aggiungi riga <i class="customIcon uiPlusCircleIcon"></i>
        </button>
      </div>
    </div>
    <!-- ############################################################# -->
    <div class="uiAnagraficaTableContainer">
      <div class="uiBkgContainer">
        <nz-table
          *ngIf="g.temp_quest.anagrafica.length > 0; else emptyTemplate"
          class="uiAnagraficaTable"
          #anag
          [nzData]="g.temp_quest.anagrafica"
          [nzFrontPagination]="false"
          [nzScroll]="scroll"
          [nzPageSize]="100"
          [nzShowPagination]="false"
          [nzWidthConfig]="['5%', '20%', '25%', '25%', '8%', '7%', '10%']"
        >
          <thead id="uiAnagraficaTableHead">
            <tr>
              <th></th>
              <th>ETICHETTA</th>
              <th>TIPO CAMPO</th>
              <th>OPZIONI</th>
              <th colspan="2">RICHIESTO | MULTIPLO</th>
              <th></th>
            </tr>
          </thead>
          <tbody cdkDropList (cdkDropListDropped)="drop($event)">
            <tr
              *ngFor="let data of anag.data; let i = index"
              cdkDrag
              [ngClass]="i % 2 === 0 ? 'uiLightColorRow' : 'uiDarkColorRow'"
            >
              <td class="uiEditedCol" cdkDragHandle nzAlign="center">
                <i class="customIcon uiDragIcon"></i>
              </td>
              <td class="uiEditedCol">
                <input
                  nz-input
                  [(ngModel)]="data.etichetta"
                  class="uiTableInput"
                />
              </td>
              <td class="uiEditedCol">
                <nz-select [(ngModel)]="data.tipo" class="uiTableSelect">
                  <nz-option
                    *ngFor="let type of env.anagraficaTypes"
                    [nzLabel]="type"
                    [nzValue]="type"
                  >
                  </nz-option>
                </nz-select>
              </td>
              <ng-container *ngIf="data.tipo === 'Range'">
                <td class="uiEditedCol uiOptionCol">
                  <div nz-row>
                    <div nz-col nzSpan="12" class="uiBorderCol">
                      <input
                        type="number"
                        nz-input
                        class="uiTableInput"
                        [(ngModel)]="data.minimo"
                        (ngModelChange)="setRangeOption($event, 'minimo', i)"
                      />
                    </div>
                    <div nz-col nzSpan="12">
                      <input
                        type="number"
                        nz-input
                        class="uiTableInput"
                        [(ngModel)]="data.massimo"
                        (ngModelChange)="setRangeOption($event, 'massimo', i)"
                      />
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngIf="data.tipo === 'Elenco'">
                <td class="uiEditedCol">
                  <input
                    type="text"
                    nz-input
                    class="uiTableInput"
                    [(ngModel)]="data.opzioni"
                  />
                </td>
              </ng-container>
              <ng-container *ngIf="data.tipo === 'Nota'">
                <td class="uiEditedCol" nzAlign="center">
                  <i
                    class="customIcon uiCommentIcon"
                    (click)="openEditor(i)"
                  ></i>
                  <!-- <textarea nz-input placeholder="Inserisci nota" class="uiTableInput" [(ngModel)]="data.opzioni" [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea> -->
                  <!-- <quill-editor [(ngModel)]="data.opzioni"></quill-editor> -->
                </td>
              </ng-container>
              <ng-container
                *ngIf="
                  data.tipo !== 'Elenco' &&
                  data.tipo !== 'Range' &&
                  data.tipo !== 'Nota'
                "
              >
                <td class="uiNoEditedCol"></td>
              </ng-container>
              <ng-container *ngIf="data.tipo === 'Elenco'">
                <td
                  nzAlign="center"
                  [ngClass]="data.richiesto ? 'uiGreenCol' : 'uiEditedCol'"
                  nzAlign="center"
                >
                  <nz-switch [(ngModel)]="data.richiesto"></nz-switch>
                </td>
                <td
                  nzAlign="center"
                  [ngClass]="data.multiplo ? 'uiGreenCol' : 'uiEditedCol'"
                  nzAlign="center"
                >
                  <nz-switch [(ngModel)]="data.multiplo"></nz-switch>
                </td>
              </ng-container>
              <ng-container *ngIf="data.tipo === 'Nota'">
                <td colSpan="2" class="uiNoEditedCol"></td>
              </ng-container>
              <td
                [ngClass]="data.richiesto ? 'uiGreenCol' : 'uiEditedCol'"
                nzAlign="center"
                colSpan="2"
                *ngIf="data.tipo !== 'Elenco' && data.tipo !== 'Nota'"
              >
                <nz-switch [(ngModel)]="data.richiesto"></nz-switch>
              </td>
              <td class="uiEditedCol" nzAlign="center">
                <i
                  class="customIcon uiCloseIcon"
                  (click)="deleteAnagrafica(data.ordine)"
                ></i>
              </td>
            </tr>
          </tbody>
        </nz-table>
        <ng-template #emptyTemplate>
          <div class="uiEmptyContainer">
            <nz-empty
              nzNotFoundImage="simple"
              style="color: var(--neutral-color)"
            ></nz-empty>
          </div>
        </ng-template>
      </div>
    </div>
  </nz-content>
  <nz-content
    class="uiQuestHeadContent"
    *ngIf="g.temp_quest && g.temp_quest.tipo === 2"
  >
    <div nz-row class="uiQuestParagraphRow" [nzGutter]="16">
      <span>TESTATA</span>
    </div>
    <div nz-row class="uiQuestFieldsRow" [nzGutter]="16">
      <div nz-col nzSpan="8" class="uiRowCol uiQuestFieldsFlex uiLeftFlex">
        <div class="uiGreyCol">
          <p>CODICE</p>
        </div>
        <div class="uiBlackCol" style="justify-content: center">
          <p>{{ g.temp_quest_id }}</p>
        </div>
        <div class="uiGreyCol">
          <p>VERSIONE</p>
        </div>
        <div class="uiBlackCol" style="justify-content: center">
          <p>V{{ g.temp_quest.codice }}</p>
        </div>
      </div>
      <div nz-col nzSpan="8" class="uiRowCol uiQuestFieldsFlex uiLeftFlex">
        <div class="uiGreyCol">
          <p>TIPO</p>
        </div>
        <div class="uiBlackCol" *ngIf="g.temp_quest.tipo === 1">
          <p>Standard</p>
        </div>
        <div class="uiBlackCol" *ngIf="g.temp_quest.tipo === 2">
          <p>Aggregato</p>
        </div>
      </div>
      <div
        nz-col
        nzSpan="4"
        class="uiRowCol uiQuestFieldsFlex uiRightFlex"
      ></div>
      <div nz-col nzSpan="4" class="uiRowCol uiQuestFieldsFlex uiRightFlex">
        <div class="uiGreyCol">
          <p>PUBBLICO</p>
        </div>
        <div class="uiLightGreyCol uiLast" style="justify-content: center">
          <nz-switch [(ngModel)]="g.temp_quest.pubblico"></nz-switch>
        </div>
      </div>
    </div>
    <div nz-row class="uiQuestFieldsRow" [nzGutter]="16">
      <div nz-col nzSpan="16" class="uiRowCol uiQuestFieldsFlex uiLeftFlex">
        <div class="uiGreyCol">
          <p>TITOLO</p>
        </div>
        <div class="uiYellowCol">
          <input
            nz-input
            class="uiHeadInput"
            [(ngModel)]="g.temp_quest.titolo[g.temp_quest_lang]"
          />
        </div>
      </div>
      <div nz-col nzSpan="8" class="uiRowCol uiQuestFieldsFlex uiRightFlex">
        <div class="uiGreyCol">
          <p>NOTE VERSIONE</p>
        </div>
        <div class="uiLightGreyCol">
          <input nz-input class="uiHeadInput" [(ngModel)]="g.temp_quest.note" />
        </div>
      </div>
    </div>
    <div nz-row class="uiQuestParagraphRow" [nzGutter]="16">
      <span>AUTORIZZAZIONI</span>
    </div>
    <div nz-row class="uiQuestFieldsRow" [nzGutter]="16">
      <div nz-col nzSpan="24" class="uiRowCol uiQuestFieldsFlex">
        <div
          class="uiBlackCol"
          style="
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: left;
          "
        >
          <nz-select
            class="uiHeadSelect"
            nzMode="multiple"
            [(ngModel)]="g.temp_quest.autorizzazioni"
            [nzCustomTemplate]="selectTplAuth"
          >
            <nz-option
              *ngFor="let rep of reps"
              [nzLabel]="rep.label"
              [nzValue]="rep.label"
            ></nz-option>
          </nz-select>
        </div>
        <ng-template #selectTplAuth let-selected>
          <span style="margin-right: 20px">{{ selected.nzValue }}</span>
        </ng-template>
        <ng-template #selectIconTpl>
          <i class="customIcon uiSelectIcon"></i>
        </ng-template>
      </div>
    </div>
    <div nz-row class="uiQuestParagraphRow" [nzGutter]="16">
      <div nz-col nzSpan="12" class="uiRowCol uiParagraphLeft">
        <div class="uiBlackCol">
          <span>CONTROLLO TRATTI ({{ g.temp_quest.anagrafica.length }})</span>
        </div>
      </div>
      <div nz-col nzSpan="12" class="uiRowCol uiParagraphRight">
        <button nz-button class="uiAddAnagrafica" (click)="addSemaforo()">
          Aggiungi semaforo <i class="customIcon uiPlusCircleIcon"></i>
        </button>
      </div>
    </div>
    <div class="uiAnagraficaTableContainer">
      <div class="uiBkgContainer">
        <nz-table
          *ngIf="g.temp_quest.anagrafica.length > 0; else emptyTemplate"
          class="uiAnagraficaTable"
          #anag
          [nzData]="g.temp_quest.anagrafica"
          [nzFrontPagination]="false"
          [nzScroll]="scroll"
          [nzPageSize]="100"
          [nzShowPagination]="false"
          [nzWidthConfig]="[
            '5%',
            '25%',
            '10%',
            '10%',
            '10%',
            '10%',
            '10%',
            '10%',
            '10%'
          ]"
        >
          <thead>
            <tr>
              <th colSpan="9">Questionario</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of anag.data; let i = index">
              <tr
                [ngClass]="i % 2 === 0 ? 'uiLightColorRow' : 'uiDarkColorRow'"
              >
                <td class="uiEditedCol" colSpan="4">
                  <div
                    style="
                      display: flex;
                      width: 100%;
                      align-items: center;
                      justify-content: left;
                    "
                  >
                    <nz-select
                      nzMode="default"
                      [(ngModel)]="g.temp_quest.anagrafica[i].qlabel"
                      style="width: 100%"
                      [nzCustomTemplate]="selectTpl"
                      class="uiQuestSelect"
                      nzBorderless="true"
                      nzPlaceHolder="Seleziona questionario"
                      [nzShowArrow]="true"
                      nzDropdownClassName="uiDropdownSelect"
                      (ngModelChange)="selectQuest($event, i)"
                      [nzMenuItemSelectedIcon]="selectIconTpl"
                      [nzOptionHeightPx]="43"
                    >
                      <nz-option
                        [nzCustomContent]="true"
                        *ngFor="let q of questionaries"
                        [nzValue]="q"
                        [nzLabel]="q"
                      >
                        {{ q }}
                      </nz-option>
                    </nz-select>
                  </div>
                  <ng-template #selectTpl let-selected>
                    <span style="margin-right: 20px">{{
                      selected.nzValue
                    }}</span>
                  </ng-template>
                  <ng-template #selectIconTpl>
                    <i class="customIcon uiSelectIcon"></i>
                  </ng-template>
                </td>
                <td class="uiEditedCol" colSpan="4">
                  <div
                    style="
                      display: flex;
                      width: 100%;
                      align-items: center;
                      justify-content: left;
                    "
                    *ngIf="
                      g.temp_quest.anagrafica[i].qlabel !== '' &&
                      questVersions[g.temp_quest.anagrafica[i].questionario.id]
                    "
                  >
                    <nz-select
                      nzMode="default"
                      [(ngModel)]="g.temp_quest.anagrafica[i].vlabel"
                      style="width: 100%"
                      [nzCustomTemplate]="selectTplV"
                      class="uiQuestSelect"
                      nzBorderless="true"
                      nzPlaceHolder="Seleziona versione"
                      [nzShowArrow]="true"
                      nzDropdownClassName="uiDropdownSelect"
                      [nzMenuItemSelectedIcon]="selectIconTpl"
                      [nzOptionHeightPx]="43"
                      (ngModelChange)="selectVersion($event, i)"
                    >
                      <nz-option
                        [nzCustomContent]="true"
                        *ngFor="
                          let version of questVersions[
                            g.temp_quest.anagrafica[i].questionario.id
                          ].versioni
                        "
                        [nzValue]="version.vlabel"
                        [nzLabel]="version.vlabel"
                      >
                        {{ version.vlabel }}
                      </nz-option>
                    </nz-select>
                  </div>
                  <ng-template #selectTplV let-selected>
                    <span style="margin-right: 20px">{{
                      selected.nzValue
                    }}</span>
                  </ng-template>
                  <ng-template #selectIconTpl>
                    <i class="customIcon uiSelectIcon"></i>
                  </ng-template>
                </td>
                <td class="uiEditedCol" nzAlign="center">
                  <i
                    style="margin-right: 10px"
                    (click)="addTrait(i)"
                    [ngClass]="
                      g.temp_quest.anagrafica[i].vlabel === ''
                        ? 'customIcon uiPlusCircleIcon uiDisabledIcon'
                        : 'customIcon uiPlusCircleIcon'
                    "
                  ></i>
                  <i
                    nz-popconfirm
                    class="customIcon uiTrashIcon"
                    nzPopconfirmTitle="Eliminare questa riga?"
                    (nzOnConfirm)="deleteQuest(i)"
                  ></i>
                </td>
              </tr>
              <ng-container
                *ngFor="
                  let t of g.temp_quest.anagrafica[i].tratti;
                  let ti = index
                "
              >
                <tr
                  [ngClass]="i % 2 === 0 ? 'uiLightColorRow' : 'uiDarkColorRow'"
                >
                  <td class="uiNoEditedCol" nzAlign="center"></td>
                  <td class="uiEditedCol" colSpan="2">
                    <div
                      style="
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: left;
                      "
                      *ngIf="
                        versionTraits[
                          g.temp_quest.anagrafica[i].questionario.id
                        ] &&
                        versionTraits[
                          g.temp_quest.anagrafica[i].questionario.id
                        ][g.temp_quest.anagrafica[i].versione.id]
                      "
                    >
                      <nz-select
                        nzMode="default"
                        [(ngModel)]="t.tlabel"
                        style="width: 100%"
                        [nzCustomTemplate]="selectTpl"
                        class="uiQuestSelect"
                        nzBorderless="true"
                        nzPlaceHolder="Seleziona tratto"
                        [nzShowArrow]="true"
                        nzDropdownClassName="uiDropdownSelect"
                        (ngModelChange)="selectTrait($event, i, ti)"
                        [nzMenuItemSelectedIcon]="selectIconTpl"
                        [nzOptionHeightPx]="43"
                      >
                        <nz-option
                          [nzCustomContent]="true"
                          *ngFor="
                            let t of versionTraits[
                              g.temp_quest.anagrafica[i].questionario.id
                            ][g.temp_quest.anagrafica[i].versione.id].tratti
                          "
                          [nzValue]="t.tlabel"
                          [nzLabel]="t.tlabel"
                        >
                          {{ t.tlabel }}
                        </nz-option>
                      </nz-select>
                    </div>
                  </td>
                  <td class="uiEditedCol" nzAlign="center">
                    <nz-select [(ngModel)]="t.operatore1" class="uiTableSelect">
                      <nz-option
                        *ngFor="let s of env.operators"
                        [nzLabel]="s"
                        [nzValue]="s"
                      >
                      </nz-option>
                    </nz-select>
                  </td>
                  <td class="uiEditedCol" nzAlign="center">
                    <input
                      type="number"
                      nz-input
                      class="uiTableInput"
                      max="100"
                      [(ngModel)]="t.valore1"
                    />
                  </td>
                  <td class="uiEditedCol" nzAlign="center">
                    <nz-select [(ngModel)]="t.operatore2" class="uiTableSelect">
                      <nz-option
                        *ngFor="let s of env.operators"
                        [nzLabel]="s"
                        [nzValue]="s"
                      >
                      </nz-option>
                    </nz-select>
                  </td>
                  <td class="uiEditedCol" nzAlign="center">
                    <input
                      type="number"
                      nz-input
                      class="uiTableInput"
                      max="100"
                      [(ngModel)]="t.valore2"
                    />
                  </td>
                  <td
                    nzAlign="center"
                    [ngClass]="[
                      t.semaforo === '' || !t.semaforo ? 'uiEditedCol' : '',
                      t.semaforo === 3 ? 'uiEditedCol uiSem uiBlueSelect' : '',
                      t.semaforo === 1 ? 'uiEditedCol uiSem uiGreenSelect' : '',
                      t.semaforo === 0
                        ? 'uiEditedCol uiSem uiYellowSelect'
                        : '',
                      t.semaforo === -3 ? 'uiEditedCol uiSem uiRedSelect' : '',
                      t.semaforo === -5 ? 'uiEditedCol uiSem uiBlackSelect' : ''
                    ]"
                  >
                    <nz-select
                      [(ngModel)]="t.semaforo"
                      class="uiTableSelect uiSemSelect"
                      nzDropdownClassName="uiSemOptions"
                    >
                      <nz-option nzLabel="3" [nzValue]="3"> </nz-option>
                      <nz-option nzLabel="1" [nzValue]="1"> </nz-option>
                      <nz-option nzLabel="0" [nzValue]="0"> </nz-option>
                      <nz-option nzLabel="-3" [nzValue]="-3"> </nz-option>
                      <nz-option nzLabel="-5" [nzValue]="-5"> </nz-option>
                    </nz-select>
                  </td>
                  <td class="uiEditedCol" nzAlign="center">
                    <i class="customIcon uiCloseIcon"></i>
                  </td>
                </tr>
                <tr
                  [ngClass]="i % 2 === 0 ? 'uiLightColorRow' : 'uiDarkColorRow'"
                >
                  <td class="uiNoEditedCol" nzAlign="center"></td>
                  <td colSpan="8" class="uiEditedCol">
                    <input
                      nz-input
                      class="uiTableInput"
                      [(ngModel)]="t.descrizione"
                    />
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </nz-table>
        <ng-template #emptyTemplate>
          <div class="uiEmptyContainer">
            <nz-empty
              nzNotFoundImage="simple"
              style="color: var(--neutral-color)"
            ></nz-empty>
          </div>
        </ng-template>
      </div>
    </div>
  </nz-content>
</nz-layout>

<nz-modal
  [(nzVisible)]="showEditor"
  nzTitle=""
  nzFooter="null"
  [nzContent]="modalManualContent"
  nzWrapClassName="innerEditorModal"
  (nzOnCancel)="closeEditor()"
>
  <ng-template #modalManualContent>
    <quill-editor [(ngModel)]="editorData"></quill-editor>
    <button
      nz-button
      nzSize="large"
      nzType="primary"
      class="saveBtn"
      (click)="save()"
    >
      Inserisci
    </button>
  </ng-template>
</nz-modal>
