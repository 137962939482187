import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Impostazioni } from "../shared/interface.service";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class FireService {
  // ######################################
  // FUNCTION: constructor
  constructor(public db: AngularFirestore) { }

  ngUnsubscribe: Subject<void> = new Subject<void>();

  // ######################################
  // FUNCTION: add
  add(collection: string, data: any, id: string): any {
    return this.db.collection(collection).doc(id).set(Object.assign({}, data));
  }

  // ######################################
  // FUNCTION: addCollection
  addCollection(collection1: string, collection2: string, data: any, id: string, id2: string): any {
    return this.db.collection(collection1).doc(id).collection(collection2).doc(id2).set(Object.assign({}, data));
  }

  // ######################################
  // FUNCTION: addSubCollection
  addSubCollection(
    collection1: string,
    collection2: string,
    collection3: string,
    data: any,
    id: string,
    id2: string,
    id3: string
  ): Promise<any> {
    return this.db
      .collection(collection1)
      .doc(id)
      .collection(collection2)
      .doc(id2)
      .collection(collection3)
      .doc(id3)
      .set(Object.assign({}, data));
  }

  // ######################################
  // FUNCTION: get
  get(collection: string, id: string): Observable<any> {
    return this.db.collection(collection).doc(id).valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
  }

  // ######################################
  // FUNCTION: getCollection
  getCollection(collection: string, id: string, collection2: string): Observable<any> {
    return this.db.collection(collection).doc(id).collection(collection2).valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
  }

  // ######################################
  // FUNCTION: getSubCollection
  getSubCollection(
    collection: string,
    id: string,
    collection2: string,
    id2: string,
    collection3: string
  ): Observable<any> {
    return this.db
      .collection(collection)
      .doc(id)
      .collection(collection2)
      .doc(id2)
      .collection(collection3)
      .valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
  }
  // ######################################
  // FUNCTION: getSubCollection
  getSubCollectionOnce(
    collection: string,
    id: string,
    collection2: string,
    id2: string,
    collection3: string
  ): Observable<any> {
    return this.db.collection(collection).doc(id).collection(collection2).doc(id2).collection(collection3).get().pipe(takeUntil(this.ngUnsubscribe));
  }

  // ######################################
  // FUNCTION: getDocument
  getDocument(collection: string, id: string, collection2: string, id2: string): Observable<any> {
    return this.db.collection(collection).doc(id).collection(collection2).doc(id2).valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
  }

  // ######################################
  // FUNCTION: getSubDoc
  getSubDoc(collection: string, id: string, collection2: string, id2: string, collection3: string, id3: string) {
    return this.db
      .collection(collection)
      .doc(id)
      .collection(collection2)
      .doc(id2)
      .collection(collection3)
      .doc(id3)
      .valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
  }

  // ######################################
  // FUNCTION: getOnce
  getOnce(collection: string, id: string): Observable<any> {
    return this.db.collection(collection).doc(id).get().pipe(takeUntil(this.ngUnsubscribe));
  }

  // ######################################
  // FUNCTION: getDocOnce
  getDocOnce(collection: string, id: string, collection2: string, id2: string): Observable<any> {
    return this.db.collection(collection).doc(id).collection(collection2).doc(id2).get().pipe(takeUntil(this.ngUnsubscribe));
  }

  // ######################################
  // FUNCTION: getSubDocOnce
  getSubDocOnce(collection: string, id: string, collection2: string, id2: string, collection3: string, id3: string) {
    return this.db
      .collection(collection)
      .doc(id)
      .collection(collection2)
      .doc(id2)
      .collection(collection3)
      .doc(id3)
      .get().pipe(takeUntil(this.ngUnsubscribe));
  }

  // ######################################
  // FUNCTION: update
  update(collection: string, collection2: string, data: any, id: string, id2: string): Promise<void> {
    return this.db.collection(collection).doc(id).collection(collection2).doc(id2).update(data);
  }

  // ######################################
  // FUNCTION: updateSubDoc
  updateSubDoc(
    collection: string,
    collection2: string,
    collection3: string,
    data: any,
    id: string,
    id2: string,
    id3: string
  ): Promise<void> {
    return this.db
      .collection(collection)
      .doc(id)
      .collection(collection2)
      .doc(id2)
      .collection(collection3)
      .doc(id3)
      .update(data);
  }

  // ######################################
  // FUNCTION: addMultipleSubDoc
  addMultipleSubDoc(
    collection: string,
    collection2: string,
    collection3: string,
    id: string,
    id2: string,
    data: any
  ): Promise<void> {
    return new Promise(async (resolve) => {
      for (let idx = 0; idx < data.length; idx++) {
        await this.db
          .collection(collection)
          .doc(id)
          .collection(collection2)
          .doc(id2)
          .collection(collection3)
          .doc(data[idx].id)
          .set(Object.assign({}, data[idx]));
      }
      resolve();
    });
  }

  async addSingleSubDoc(
    collection: string,
    collection2: string,
    collection3: string,
    id: string,
    id2: string,
    data: any
  ): Promise<void> {
      return await this.db
        .collection(collection)
        .doc(id)
        .collection(collection2)
        .doc(id2)
        .collection(collection3)
        .doc(data.id)
        .set(Object.assign({}, data));
  
  }

  async addSingleSubDocCheck(
    collection: string,
    collection2: string,
    collection3: string,
    id: string,
    id2: string,
    data: any
): Promise<Boolean> {
    try {
        await this.db
            .collection(collection)
            .doc(id)
            .collection(collection2)
            .doc(id2)
            .collection(collection3)
            .doc(data.id)
            .set(Object.assign({}, data));
        
        return true;
    } catch (error) {
        console.error(`Failed to write document: ${error}`);
        return false;
    }
}



  // ######################################
  // FUNCTION: updateCollection
  updateCollection(collection: string, data: any, id: string): Promise<void> {
    return this.db.collection(collection).doc(id).update(data);
  }

  // ######################################
  // FUNCTION: delete
  delete(collection: string, collection2: string, id: string, id2: string): Promise<void> {
    return this.db.collection(collection).doc(id).collection(collection2).doc(id2).delete();
  }


  // ######################################
  // FUNCTION: deleteDoc
  deleteDoc(collection: string, id): Promise<void> {
    return this.db.collection(collection).doc(id).delete();
  }

  // ######################################
  // FUNCTION: deleteSubDoc
  deleteSubDoc(
    collection: string,
    collection2: string,
    collection3: string,
    id: string,
    id2: string,
    id3: string
  ): Promise<void> {
    return this.db
      .collection(collection)
      .doc(id)
      .collection(collection2)
      .doc(id2)
      .collection(collection3)
      .doc(id3)
      .delete();
  }

  // ######################################
  // FUNCTION: getLast
  getLast(collection: string, field: string): Promise<number> {
    return new Promise((promise) => {
      this.db.firestore
        .collection(collection)
        .orderBy(field, "desc")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            return promise(1);
          } else {
            return promise(parseInt(querySnapshot.docs[0].data().codice) + 1);
          }
        });
    });
  }

  // ######################################
  // FUNCTION: getLastBy
  getLastBy(collection: string, field: string): Promise<number> {
    return new Promise((promise) => {
      this.db.firestore
        .collection(collection)
        .orderBy(field, "desc")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            return promise(0);
          } else {
            return promise(parseInt(querySnapshot.docs[0].data().codice));
          }
        });
    });
  }

  // ######################################
  // FUNCTION: getLastSubBy
  getLastSubBy(collection: string, id: string, collection2: string, field: string): Promise<number> {
    return new Promise((promise) => {
      this.db.firestore
        .collection(collection)
        .doc(id)
        .collection(collection2)
        .orderBy(field, "desc")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            return promise(0);
          } else {
            return promise(parseInt(querySnapshot.docs[0].data().codice));
          }
        });
    });
  }

  // ######################################
  // FUNCTION: getLastSubSubBy
  getLastSubSubBy(
    collection: string,
    id: string,
    collection2: string,
    id2: string,
    collection3: string,
    field: string
  ): Promise<number> {
    return new Promise((promise) => {
      this.db.firestore
        .collection(collection)
        .doc(id)
        .collection(collection2)
        .doc(id2)
        .collection(collection3)
        .orderBy(field, "desc")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            return promise(0);
          } else {
            return promise(parseInt(querySnapshot.docs[0].data().codice));
          }
        });
    });
  }

  // ######################################
  // FUNCTION: getAll
  getAll(collection: string, order: string = ""): Observable<any> {
    if (order === "") {
      return this.db.collection(collection).valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
    } else {
      return this.db.collection(collection, (ref) => ref.orderBy(order, "desc")).valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
    }
  }

  // ######################################
  // FUNCTION: getAllAsc
  getAllAsc(collection: string, order: string = ""): Observable<any> {
    if (order === "") {
      return this.db.collection(collection).valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
    } else {
      return this.db.collection(collection, (ref) => ref.orderBy(order, "asc")).valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
    }
  }

  // ######################################
  // FUNCTION: getCollectionByOrder
  getCollectionByOrder(collection: string, id: string, collection2: string, order: string = ""): Observable<any> {
    if (order === "") {
      return this.db.collection(collection).doc(id).collection(collection2).valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
    } else {
      return this.db
        .collection(collection)
        .doc(id)
        .collection(collection2, (ref) => ref.orderBy(order, "desc"))
        .valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
    }
  }

  // ######################################
  // FUNCTION: getByFilter
  getByFilter(collection: string, field: string = "", filterValue: string = "", order: string = ""): Observable<any> {
    if (field === "" || order === "") {
      return this.db.collection(collection).valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
    } else {
      return this.db
        .collection(collection, (ref) => ref.where(field, "==", filterValue).orderBy(order, "desc"))
        .valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
    }
  }

  // ######################################
  // FUNCTION: getByDoubleFilter
  getByDoubleFilter(
    collection: string,
    field: string = "",
    filterValue: string = "",
    order: string = "",
    field2: string = "",
    filterValue2: string = ""
  ): Observable<any> {
    if (field === "" || order === "") {
      return this.db.collection(collection).valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
    } else {
      return this.db
        .collection(collection, (ref) =>
          ref.where(field, "==", filterValue).where(field2, "==", filterValue2).orderBy(order, "desc")
        )
        .valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
    }
  }

  // ######################################
  // FUNCTION: getSubByFilter
  getSubByFilter(
    collection: string,
    doc: string,
    collection2,
    field: string = "",
    filterValue: string = "",
    field2: string = "",
    filterValue2: string = ""
  ): Observable<any> {
    if (field === "") {
      return this.db.collection(collection).valueChanges({ idField: "id" });
    } else {
      if (field2 === "") {
        return this.db
          .collection(collection)
          .doc(doc)
          .collection(collection2, (ref) => ref.where(field, "==", filterValue))
          .valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
      } else {
        return this.db
          .collection(collection)
          .doc(doc)
          .collection(collection2, (ref) => ref.where(field, "==", filterValue).where(field2, "==", filterValue2))
          .valueChanges({ idField: "id" }).pipe(takeUntil(this.ngUnsubscribe));
      }
    }
  }

  // ######################################
  // FUNCTION: getSettingOptions
  getSettingOptions(): Observable<Impostazioni[] | undefined> {
    return this.db.collection<Impostazioni>('impostazioni').valueChanges().pipe(takeUntil(this.ngUnsubscribe));
  }

  getAllUnsubscribed() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
