<nz-header class="uiHeader">
    <div nz-row class="uiHeaderRow">
        <div class="uiHeaderLeftCol" nz-col nzSpan="16">
            <a href="/admin"><img class="uiUncollapsedLogo" src="../../../assets/logo-light.png" alt="logo" *ngIf="!isCollapsed"></a>
            <a href="/admin"><img class="uiCollapsedLogo" src="../../../assets/logo-small.png" alt="logo" *ngIf="isCollapsed"></a>
            <span class="uiSidebarTrigger" (click)="clickCollapse($event)">
                <i class="trigger" [ngClass]="isCollapsed ? 'customIcon uiMenuFoldIcon' : 'customIcon uiMenuFoldIcon'"></i>
            </span>
            <div class="uiTitleContainer"><span>{{g.actualPage}}</span></div>

        </div>
        <div nz-col nzSpan="8" class="uiHeaderRightCol">
            {{loggedIn.email}}
            <i *ngIf="auth.isLoggedIn()" class="customIcon uiLogoutIcon" (click)="auth.logout()" nz-tooltip nzTooltipTitle="Esci"></i>
        </div>
    </div>
</nz-header>
<nz-content>
    <div class="uiContainer">
        <nz-sider class="uiSidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
            <ul class="uiMenu" nz-menu nzTheme="light" nzMode="inline" *ngIf="!isCollapsed">
                <li nz-submenu nzTitle="DESIGN" class="uiSubMenu uiDesignMenu" *ngIf="loggedIn.permessi.includes('Designer')" [nzOpen]="face.adminInterface.DESIGN" (nzOpenChange)="face.openChange(!face.adminInterface.DESIGN, 'ADMIN', 'DESIGN')">
                    <ul class="uiSubMenuList">
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('designer')" *ngIf="loggedIn.permessi.includes('Designer')">
                            <a routerLink="./designer"><i class="customIcon uiDesignerIcon"></i> Designer</a>
                        </li>
                    </ul>
                </li>
                <li nz-submenu nzTitle="AMMINISTRAZIONE" class="uiSubMenu uiAmministrazioneMenu" [nzOpen]="face.adminInterface.AMMINISTRAZIONE" (nzOpenChange)="face.openChange(!face.adminInterface.AMMINISTRAZIONE, 'ADMIN', 'AMMINISTRAZIONE')" *ngIf="loggedIn.permessi.includes('Utenti e gruppi') || loggedIn.permessi.includes('Clienti e aziende') || loggedIn.permessi.includes('Pubblicazione')">
                    <ul class="uiSubMenuList">
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('amministrazione/utenti')" *ngIf="loggedIn.permessi.includes('Utenti e gruppi')">
                            <a routerLink="./amministrazione/utenti"><i class="customIcon uiUtentigruppiIcon"></i> Utenti e gruppi</a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('amministrazione/clienti')" *ngIf="loggedIn.permessi.includes('Clienti e aziende')">
                            <a routerLink="./amministrazione/clienti"><i class="customIcon uiClientiaziendeIcon"></i> Clienti e aziende</a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('amministrazione/pubblicazione')" *ngIf="loggedIn.permessi.includes('Pubblicazione')">
                            <a routerLink="./amministrazione/pubblicazione"><i class="customIcon uiPubblicazioneIcon"></i> Pubblicazione</a>
                        </li>
                    </ul>
                </li>
                <li nz-submenu nzTitle="GESTIONE" class="uiSubMenu uiGestioneMenu" [nzOpen]="face.adminInterface.GESTIONE" (nzOpenChange)="face.openChange(!face.adminInterface.GESTIONE, 'ADMIN', 'GESTIONE')" *ngIf="loggedIn.permessi.includes('Gestione questionari') || loggedIn.permessi.includes('Gestione aggregati') || loggedIn.permessi.includes('Valutazioni') || loggedIn.permessi.includes('Privacy')">
                    <ul class="uiSubMenuList">
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('gestione/questionari')" *ngIf="loggedIn.permessi.includes('Gestione questionari')">
                            <a routerLink="./gestione/questionari"><i class="customIcon uiQuestionariIcon"></i> Questionari</a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" nzDisabled *ngIf="loggedIn.permessi.includes('Gestione destinatari')">
                            <a routerLink=""><i class="customIcon uiDestinatariIcon"></i> Destinatari</a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('gestione/aggregati')" *ngIf="loggedIn.permessi.includes('Gestione aggregati')">
                            <a routerLink="./gestione/aggregati"><i class="customIcon uiAggregatiIcon"></i> Aggregati</a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('gestione/valutazioni')" *ngIf="loggedIn.permessi.includes('Valutazioni')">
                            <a routerLink="./gestione/valutazioni"><i class="customIcon uiWhiteValutazioniIcon"></i> Valutazioni</a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('gestione/privacy')" *ngIf="loggedIn.permessi.includes('Privacy')">
                            <a routerLink="./gestione/privacy"><i class="customIcon uiPrivacyIcon"></i> Privacy</a>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="uiCollapsedMenu" nz-menu nzTheme="light" nzMode="inline" nzBreakpoint="md" *ngIf="isCollapsed" nzOpen>
                <li nz-submenu nzTitle="DESIGN" class="uiSubMenu uiDesignMenu" *ngIf="loggedIn.permessi.includes('Designer')" [nzOpen]="face.adminInterface.DESIGN" (nzOpenChange)="face.openChange(!face.adminInterface.DESIGN, 'ADMIN', 'DESIGN')">
                    <ul class="uiSubMenuList">
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('designer')">
                            <a routerLink="./designer"><i class="customIcon uiDesignerIcon"></i> <span>Designer</span></a>
                        </li>
                    </ul>
                </li>
                <li nz-submenu nzTitle="AMMINISTRAZIONE" class="uiSubMenu uiAmministrazioneMenu" [nzOpen]="face.adminInterface.AMMINISTRAZIONE" (nzOpenChange)="face.openChange(!face.adminInterface.AMMINISTRAZIONE, 'ADMIN', 'AMMINISTRAZIONE')" *ngIf="loggedIn.permessi.includes('Utenti e gruppi') || loggedIn.permessi.includes('Clienti e aziende') || loggedIn.permessi.includes('Pubblicazione')">
                    <ul class="uiSubMenuList">
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('amministrazione/utenti')">
                            <a routerLink="./amministrazione/utenti"><i class="customIcon uiUtentigruppiIcon"></i></a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('amministrazione/clienti')">
                            <a routerLink="./amministrazione/clienti"><i class="customIcon uiClientiaziendeIcon"></i></a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('amministrazione/pubblicazione')">
                            <a routerLink="./amministrazione/pubblicazione"><i class="customIcon uiPubblicazioneIcon"></i></a>
                        </li>
                    </ul>
                </li>
                <li nz-submenu nzTitle="GESTIONE" class="uiSubMenu uiGestioneMenu" [nzOpen]="face.adminInterface.GESTIONE" (nzOpenChange)="face.openChange(!face.adminInterface.GESTIONE, 'ADMIN', 'GESTIONE')" *ngIf="loggedIn.permessi.includes('Gestione questionari') || loggedIn.permessi.includes('Gestione aggregati') || loggedIn.permessi.includes('Valutazioni') || loggedIn.permessi.includes('Privacy')">
                    <ul class="uiSubMenuList">
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('gestione/questionari')">
                            <a routerLink="./gestione/questionari"><i class="customIcon uiQuestionariIcon"></i> <span>Questionari</span></a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" nzDisabled>
                            <a routerLink=""><i class="customIcon uiDestinatariIcon"></i> <span>Destinatari</span></a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('gestione/aggregati')">
                            <a routerLink="./gestione/aggregati"><i class="customIcon uiAggregatiIcon"></i></a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('gestione/valutazioni')">
                            <a routerLink="./gestione/valutazioni"><i class="customIcon uiWhiteValutazioniIcon"></i> <span>Valutazioni</span></a>
                        </li>
                        <li nz-menu-item class="uiSubMenuItem" [nzSelected]="router.url.includes('gestione/privacy')">
                            <a routerLink="./gestione/privacy"><i class="customIcon uiPrivacyIcon"></i> <span>Privacy</span></a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nz-sider>
        <nz-layout class="uiAdminInnerLayout">
            <nz-content class="uiAdminContent">
                <div class="uiAdminInnerContent">
                    <router-outlet></router-outlet>
                </div>
            </nz-content>
        </nz-layout>
    </div>
</nz-content>