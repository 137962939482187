import { GlobalService } from "src/app/shared/global.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-legal-footer",
  templateUrl: "./legal-footer.component.html",
  styleUrls: ["./legal-footer.component.scss"],
})
export class LegalFooterComponent implements OnInit {
  // ######################################
  // FUNCTION: constructor
  constructor(public g: GlobalService) {
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void { }
}
