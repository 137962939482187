import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "getname",
})
export class GetnamePipe implements PipeTransform {
	// ######################################
	// FUNCTION: transform
	transform(value: any): string {
		return value.id + " " + value.nome;
	}
}
