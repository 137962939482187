import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule } from '@ngx-translate/core';
import { NgZorroModule } from 'src/app/ng-zorro.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ClienteHomepageRoutingModule } from './homepage-routing.module';
import { AuthClienteGuard } from 'src/app/guards/authCliente.guard';
import { AuthClienteService } from 'src/app/services/authCliente.service';
import { HomepageComponent } from './homepage.component';
import { LogoComponent } from './logo/logo.component';
import { QuestionariComponent } from './licenze/licenze.component';
import { DestinatariComponent } from './destinatari/destinatari.component';
import { AggregatiComponent } from './aggregati/aggregati.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    HomepageComponent,
    LogoComponent,
    QuestionariComponent,
    DestinatariComponent,
    AggregatiComponent,
],
  imports: [
    ClienteHomepageRoutingModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgZorroModule,
    ScrollingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateModule,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    })
  ],
  exports: [NgZorroModule],
  providers: [AuthClienteGuard, AuthClienteService],
})
export class ClienteHomepageModule { }
