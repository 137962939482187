import { NzTableComponent, NzTableModule } from "ng-zorro-antd/table";
import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth.service";
import { NodeService } from "src/app/services/node.service";
import { StorageService } from "src/app/services/storage.service";
import { GlobalService } from "src/app/shared/global.service";
import * as env from "src/environments/env.json";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { HttpClient, HttpEvent, HttpEventType, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { NzUploadChangeParam } from "ng-zorro-antd/upload";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzModalService } from "ng-zorro-antd/modal";
import { PdfmodalComponent } from "./pdfmodal/pdfmodal.component";
import { FireService } from "src/app/services/fire.service";

@Component({
  selector: "app-head",
  templateUrl: "./head.component.html",
  styleUrls: ["./head.component.scss"],
})
export class HeadComponent implements OnInit {
  env: any = (env as any).default;
  uploadedFile: string = "";
  successUpload = true;
  uploadURL: string = "";
  showEditor: boolean = false;
  editorData: any;
  editorIndex: number;
  privacies: any[] = [];
  reps: any[] = [];
  questionaries: any[] = [];
  questVersions: { [key: string]: { versioni: any; }; } = {};
  versionTraits: { [key: string]: { [key2: string]: { tratti: any; }; }; } = {};
  loading = true;
  linkedQuests: any[] = [];
  scroll = { y: "" };

  constructor(
    private router: Router,
    private auth: AuthService,
    public g: GlobalService,
    private translate: TranslateService,
    private node: NodeService,
    private storage: StorageService,
    private http: HttpClient,
    private msg: NzMessageService,
    private notification: NzNotificationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private fire: FireService
  ) { }

  @ViewChild("anag") tableAnagrafica: ElementRef;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  setScrollHeight() {
    if (document.getElementsByClassName("uiAnagraficaTableContainer").length > 0) {
      let height = document.getElementsByClassName("uiAnagraficaTableContainer")[0].clientHeight;
      if (
        document.getElementsByClassName("uiAnagraficaTableContainer")[0].getElementsByClassName("ant-table-thead")
          .length
      ) {
        let headH = document
          .getElementsByClassName("uiAnagraficaTableContainer")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }

  ngOnInit(): void {
    this.uploadURL = this.g.nodePath + "/quest/upload";
    this.fire.getAllAsc("privacy", "codice").subscribe((res) => {
      this.privacies = res;
    });

    this.fire.getAllAsc("reparti", "codice").subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        let e = res[i];
        this.fire.getCollection("reparti", e.id, "aree").subscribe((res2) => {
          res2.forEach((a) => {
            let rep = {
              label: e.nome + " | " + a.nome,
              repId: e.id,
              areaId: a.id,
            };
            let index = this.reps.findIndex((r) => r.repId === e.id && r.areaId === a.id);
            if (index >= 0) {
            } else {
              this.reps.push(rep);
            }
          });
        });
      }
    });

    this.fire.getAllAsc("questionari", "codice").subscribe((resQ) => {
      this.loading = true;
      resQ.forEach((q, qi) => {
        this.versionTraits[q.id] = {};
        this.questionaries.push(q.id + " " + q.titolo);
        this.fire.getCollection("questionari", q.id, "versioni").subscribe((resV) => {
          this.questVersions[q.id] = { versioni: [] };
          resV.forEach((v, vi) => {
            this.questVersions[q.id].versioni.push({
              vlabel: v.id + " " + v.titolo["ITA"],
              versione: v,
            });
            this.versionTraits[q.id][v.id] = { tratti: [] };
            this.questVersions[q.id].versioni[vi].versione.tratti.forEach((t, ti) => {
              this.versionTraits[q.id][v.id].tratti.push({
                tlabel: t.codice + " " + t.titolo["ITA"],
                tratto: t,
              });
            });
          });
          this.setScrollHeight();
        });
      });
    });
  }

  addAnagrafica() {
    let correct = true;
    this.g.temp_quest.anagrafica.forEach((a) => {
      if (a.tipo === "Range") {
        if (!a.minimo || !a.massimo || a.minimo >= a.massimo) {
          correct = false;
        }
      }
    });
    if (correct) {
      this.g.temp_quest.anagrafica = [
        ...this.g.temp_quest.anagrafica,
        {
          ordine: this.g.temp_quest.anagrafica.length + 1,
          etichetta: "Titolo",
          tipo: "Testo",
          opzioni: "",
          richiesto: false,
        },
      ];
    } else {
      this.notification.create("error", "Inserire un valore corretto", "");
    }
  }

  deleteAnagrafica(index: any) {
    this.g.temp_quest.anagrafica = this.g.temp_quest.anagrafica.filter((e: any) => e.ordine !== index);
  }

  drop(event: any): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.g.temp_quest.anagrafica, event.previousIndex, event.currentIndex);
      this.g.temp_quest.anagrafica.forEach((e: any, index: any) => {
        e.ordine = index + 1;
      });
      this.g.temp_quest.anagrafica = [...this.g.temp_quest.anagrafica];
    }
  }

  customFileReq = (item: any) => {
    // Create a FormData here to store files and other parameters.
    const ext = item.file.name.substr(item.file.name.lastIndexOf("."));
    let filename = "";
    filename = this.g.temp_quest_id + "-copertina" + ext;
    const formData = new FormData();
    formData.append("file", item.file as any);
    formData.append("filename", filename);
    const req = new HttpRequest("POST", item.action!, formData, {
      reportProgress: true,
    });

    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      (err) => {
        item.onError!(err, item.file!);
      }
    );
  };

  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      const ext = info.file.name.substring(info.file.name.lastIndexOf("."));
      this.uploadedFile = this.g.temp_quest_id + "-copertina" + ext;
      this.g.temp_quest.copertina_report[this.g.temp_quest_lang] = this.uploadedFile;
      this.successUpload = true;
    } else if (info.file.status === "error") {
      this.msg.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === "removed") {
      this.msg.warning(`${info.file.name} file removed.`);
      this.successUpload = false;
    }
  }

  deleteFile() {
    this.g.temp_quest.copertina_report[this.g.temp_quest_lang] = "";
  }

  setRangeOption(e: any, label: string, i) {
    this.g.temp_quest.anagrafica[i].opzioni =
      this.g.temp_quest.anagrafica[i].minimo + "," + this.g.temp_quest.anagrafica[i].massimo;
  }

  downloadFile(path: string) {
    let params = { "filepath": path };
    this.node.getData(params, "/download").subscribe((data) => {
      if (data.status !== 200) {
        var fileURL: string = URL.createObjectURL(data);
        const modal = this.modal.create({
          nzTitle: "Visualizza file",
          nzContent: PdfmodalComponent,
          nzViewContainerRef: this.viewContainerRef,
          nzClassName: "uiPdfModal",
          nzFooter: null,
          nzComponentParams: {
            file: fileURL,
          },
        });
      }
    });
  }

  openEditor(i) {
    this.editorIndex = i;
    this.showEditor = true;
    this.editorData = this.g.temp_quest.anagrafica[this.editorIndex].testo;
  }

  closeEditor() {
    this.showEditor = false;
  }

  save() {
    this.showEditor = false;
    this.g.temp_quest.anagrafica[this.editorIndex].testo = this.editorData;
  }

  addSemaforo() {
    this.g.temp_quest.anagrafica = [
      ...this.g.temp_quest.anagrafica,
      {
        qlabel: "",
        vlabel: "",
        questionario: {
          id: "",
          titolo: "",
        },
        versione: {
          id: "",
          titolo: "",
        },
        tratti: [],
      },
    ];
  }

  selectQuest(e, i) {
    this.g.temp_quest.anagrafica[i].qlabel = e;
    const [key, ...rest] = e.split(" ");
    const value = rest.join(" ");
    this.g.temp_quest.anagrafica[i].questionario = {
      id: key,
      titolo: value,
    };
    this.g.temp_quest.anagrafica[i].versione = {
      id: "",
      titolo: "",
    };
    this.g.temp_quest.anagrafica[i].vlabel = "";
  }

  selectVersion(e, i) {
    this.g.temp_quest.anagrafica[i].vlabel = e;
    const [key, ...rest] = e.split(" ");
    const value = rest.join(" ");
    this.g.temp_quest.anagrafica[i].versione = {
      id: key,
      titolo: value,
    };
    let linkedIndex = this.g.temp_quest.collegati.findIndex(
      (l) => l.questionario === this.g.temp_quest.anagrafica[i].questionario.id
    );
    if (linkedIndex < 0) {
      this.g.temp_quest.collegati.push({
        questionario: this.g.temp_quest.anagrafica[i].questionario.id,
        versione: this.g.temp_quest.anagrafica[i].versione.id,
      });
    }
  }

  deleteQuest(i) {
    this.g.temp_quest.anagrafica.splice(i, 1);
  }

  addTrait(i) {
    let t = {
      tlabel: "",
      id: "",
      titolo: "",
      operatore1: "",
      valore1: "",
      operatore2: "",
      valore2: "",
      semaforo: "",
      descrizione: "Descrizione semaforo",
    };
    this.g.temp_quest.anagrafica[i].tratti.push(t);
  }

  selectTrait(e, i, ti) {
    const [key, ...rest] = e.split(" ");
    const value = rest.join(" ");

    this.g.temp_quest.anagrafica[i].tratti[ti].id = key;
    this.g.temp_quest.anagrafica[i].tratti[ti].titolo = value;
  }

  onChangeSwitch(e: any) {
    // check se versione è attiva in qualche licenza attiva
    let licenseUsed = false;
    this.fire.getAll("clienti", "codice").subscribe((clienti) => {
      for (let c = 0; c < clienti.length; c++) {
        if (!licenseUsed) {
          this.fire.getCollection("clienti", clienti[c].id, "licenze").subscribe((licenze) => {
            for (let l = 0; l < licenze.length; l++) {
              if (!licenseUsed) {
                let vId = "V" + this.g.temp_quest.codice;
                let qId = this.g.temp_quest_id;
                if (licenze[l].questionario === qId && licenze[l].versione === vId) {
                  licenseUsed = true;
                  this.g.temp_quest.pubblico = true;
                  this.g.createErrorNotification(
                    "Versione in uso nella licenza <br/>" + clienti[c].id + " - " + licenze[l].id,
                    ""
                  );
                  c = clienti.length;
                  l = licenze.length;
                } else if (!licenseUsed && c === clienti.length - 1 && l === licenze.length - 1) {
                  // console.log(this.g.temp_quest.pubblico);
                  this.g.temp_quest.pubblico = e;
                }
              }
            }
          });
        }
      }
    });
  }
}
