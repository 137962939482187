<nz-layout class="uiPageInnerLayout">
    <nz-content class="uiGestioneContent">
        <div nz-row class="uiGestionePrivacyHeader" [nzGutter]="16">
            <div class="uiGestioneLeftHeader">
                <nz-input-group [nzPrefix]="prefixTemplateUser" class="uiSerchbar">
                    <input type="text" nz-input placeholder="{{'search' | translate}}" [(ngModel)]="search" (ngModelChange)="filter()" autocomplete="off" />
                </nz-input-group>
                <ng-template #prefixTemplateUser><i class="customIcon uiFilterIcon"></i></ng-template>
            </div>
            <div class="uiGestioneRightHeader">
                <button nz-button class="uiNewBtn" (click)="addPrivacy()">
                    Nuova privacy
                </button>
            </div>
        </div>

        <div class="uiGestioneTableContainer" *ngIf="!loading">
            <nz-table class="uiGestioneTable" #privacyTable [nzData]="displayedData" [nzShowPagination]="false" [nzScroll]="scroll" [nzPageSize]="100" [nzWidthConfig]="['10%', '10%', '10%', '10%', '10%', '10%', '5%' ]">
                <thead>
                    <tr>
                        <th nzAlign="center">CODICE</th>
                        <th nzAlign="center">TITOLO</th>
                        <th nzAlign="center">LINGUE</th>
                        <th nzAlign="center">ATTIVO</th>
                        <th nzAlign="center">CREAZIONE</th>
                        <th nzAlign="center">DISATTIVAZIONE</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of privacyTable.data; let i = index">
                        <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                            <td nzAlign="center">
                                {{data.id}}
                            </td>
                            <td>
                                {{data.titolo}}
                            </td>
                            <td nzAlign="center">
                                {{data.lingue.length}}
                            </td>
                            <td nzAlign="center" [ngClass]="data.attivo ? 'uiGreenCol' : ''" nz-tooltip [nzTooltipTitle]="data.attivo ? 'Privacy attiva' : 'Privacy non attiva'">
                                <nz-switch [ngModel]="data.attivo" class="uiNoEvents"></nz-switch>
                            </td>
                            <td nzAlign="center">
                                {{data.creazione.seconds * 1000 | date: 'dd/MM/yyyy'}}
                            </td>
                            <td nzAlign="center">
                                {{data.disattivazione.seconds * 1000 | date: 'dd/MM/yyyy'}}
                            </td>
                            <td nzAlign="center">
                                <i class="customIcon uiEditIcon" (click)="editPrivacy(data.id)" nz-tooltip nzTooltipTitle="Modifica"></i>
                                <i class="customIcon uiTrashGreyIcon" style="margin-right: 0; margin-left: 15px;" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler cancellare la privacy {{data.id}}?" (nzOnConfirm)="delete(data.id)" nz-tooltip nzTooltipTitle="Cancella"></i>
                            </td>
                        </tr>
                    </ng-container>
            </nz-table>
        </div>
    </nz-content>

        <!-- spinner -->
    <div class="waiter" *ngIf="loading">
      <span class="ant-spin-dot ant-spin-dot-spin ng-star-inserted"><i class="ant-spin-dot-item"></i><i
      class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span>
    </div>
</nz-layout>
