import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Domanda } from "src/app/entities/domande";
import { Risposta } from "src/app/entities/risposte";
import { GlobalService } from "src/app/shared/global.service";
import * as env from "src/environments/env.json";
import { Location } from "@angular/common";
import { Punteggio } from "src/app/entities/punteggi";
import { NzTreeHigherOrderServiceToken } from "ng-zorro-antd/core/tree";

@Component({
	selector: "app-aggiungidomanda",
	templateUrl: "./aggiungidomanda.component.html",
	styleUrls: ["./aggiungidomanda.component.scss"],
})
export class AggiungidomandaComponent implements OnInit {
	env: any = (env as any).default;
	gQuestion: Domanda;
	code: string;
	answerCode: number;
	index: number;
	activatedIndex: number;

	constructor(
		private route: ActivatedRoute,
		public g: GlobalService,
		private router: Router,
		private location: Location
	) {}

	ngOnInit(): void {
		this.route.params.subscribe((param) => {
			this.code = param.id;
			this.index = -1;
			if (this.g.temp_quest && this.g.temp_quest.domande) {
				this.index = this.g.temp_quest.domande.findIndex((d) => d.codice === this.code);
			} else {
				this.router.navigateByUrl("/admin/designer/questionario/" + this.g.activeId + "/domande");
			}

			if (this.index < 0) {
				this.gQuestion = new Domanda();
				this.gQuestion.codice = this.code;
				this.gQuestion.ordine = this.g.temp_quest.domande.length + 1;
				this.gQuestion.titolo = {};
				this.gQuestion.risposte = [];
				this.gQuestion.filtri = [];
				this.answerCode = 0;
				this.g.temp_quest.domande.push(this.gQuestion);
				this.activatedIndex = this.g.temp_quest.domande.length;
			} else {
				this.gQuestion = this.g.temp_quest.domande[this.index];
				this.answerCode = this.gQuestion.risposte.length;
				this.activatedIndex = this.index + 1;
			}

			this.g.temp_quest.domande[this.activatedIndex - 1].risposte.forEach((d) => {
				if (d["trend"] == null) d["trend"] = 0;
			});
			// console.log(this.g.temp_quest.domande[this.activatedIndex - 1].risposte);
		});
	}

	add() {
		let answ: Risposta = new Risposta();
		answ.codice = "R" + (this.answerCode + 1);
		answ.titolo = {};
		answ.filtri = [];
		this.g.temp_quest.domande[this.activatedIndex - 1].risposte.push(answ);
		this.answerCode += 1;

		this.g.temp_quest.tratti.forEach((t) => {
			t.punteggi[this.gQuestion.codice].domanda.risposte.push({ codice: answ.codice, valore: 0, trend: 0 });

			this.g.temp_quest.domande.forEach((d) =>
				t.punteggi[d.codice].domanda.filtri.forEach((f) => {
					f.risposte.push({ codice: answ.codice, valore: 0, trend: "0" });
				})
			);
		});
	}

	addQuestionFilter() {
		this.g.temp_quest.domande[this.activatedIndex - 1].filtri.push({
			campo: {
				ordine: 0,
				etichetta: "",
				tipo: "Testo",
				opzioni: "",
				richiesto: false,
			},
			operatore1: "",
			operatore2: "",
			valore1: "",
			valore2: "",
			minimo: 0,
			massimo: 0,
		});
	}

	reInitQFilter(k) {
		this.g.temp_quest.domande[this.activatedIndex - 1].filtri[k] = {
			campo: {
				ordine: 0,
				etichetta: "",
				tipo: "Testo",
				opzioni: "",
				richiesto: false,
			},
			operatore1: "",
			operatore2: "",
			valore1: "",
			valore2: "",
			minimo: 0,
			massimo: 0,
		};
	}

	selectAnagrafica(event: any, i: number) {
		this.g.temp_quest.domande[this.activatedIndex - 1].filtri[i].campo = event;
		if (event.tipo === "Range") {
			let types = this.g.temp_quest.domande[this.activatedIndex - 1].filtri[i].campo.opzioni.replace(";", ",");
			types = this.g.temp_quest.domande[this.activatedIndex - 1].filtri[i].campo.opzioni.replace(" ", "");
			let typesArray = types.split(",");
			this.g.temp_quest.domande[this.activatedIndex - 1].filtri[i].minimo = parseInt(typesArray[0]);
			this.g.temp_quest.domande[this.activatedIndex - 1].filtri[i].massimo = parseInt(typesArray[1]);
		}
		if (event.tipo === "Elenco") {
			let types = this.g.temp_quest.domande[this.activatedIndex - 1].filtri[i].campo.opzioni.replace(";", ",");
			types = this.g.temp_quest.domande[this.activatedIndex - 1].filtri[i].campo.opzioni.replace(" ", "");
			let typesArray = types.split(",");
			this.g.temp_quest.domande[this.activatedIndex - 1].filtri[i].selezioni = typesArray;
		}
	}

	selectAnswerAnagrafica(event: any, a: number, i: number) {
		this.g.temp_quest.domande[this.activatedIndex - 1].risposte[a].filtri[i].campo = event;
		if (event.tipo === "Range") {
			let types = this.g.temp_quest.domande[this.activatedIndex - 1].risposte[a].filtri[i].campo.opzioni.replace(
				";",
				","
			);
			types = this.g.temp_quest.domande[this.activatedIndex - 1].risposte[a].filtri[i].campo.opzioni.replace(" ", "");
			let typesArray = types.split(",");
			this.g.temp_quest.domande[this.activatedIndex - 1].risposte[a].filtri[i].minimo = parseInt(typesArray[0]);
			this.g.temp_quest.domande[this.activatedIndex - 1].risposte[a].filtri[i].massimo = parseInt(typesArray[1]);
		}
		if (event.tipo === "Elenco") {
			let types = this.g.temp_quest.domande[this.activatedIndex - 1].risposte[a].filtri[i].campo.opzioni.replace(
				";",
				","
			);
			types = this.g.temp_quest.domande[this.activatedIndex - 1].risposte[a].filtri[i].campo.opzioni.replace(" ", "");
			let typesArray = types.split(",");
			this.g.temp_quest.domande[this.activatedIndex - 1].risposte[a].filtri[i].selezioni = typesArray;
		}
	}

	buildArray(min: any, max: any) {
		return Array.from({ length: parseInt(max) - parseInt(min) + 1 }, (v, k) => k + parseInt(min));
	}

	removeQuestionFilter(i: number) {
		this.g.temp_quest.domande[this.activatedIndex - 1].filtri.splice(i, 1);
	}

	removeAnswer(i: number) {
		this.g.temp_quest.tratti.forEach((t) => {
			t.punteggi[this.gQuestion.codice].domanda.risposte.splice(i, 1);

			this.g.temp_quest.domande.forEach((d) =>
				t.punteggi[d.codice].domanda.filtri.forEach((p) => {
					p.domanda.filtri.forEach((f) => {
						f.risposte.splice(i, 1);
					});
				})
			);
		});

		//   t.punteggi.forEach(p => {
		//     p.domanda.filtri.forEach( f => {
		//       f.risposte.splice(i, 1);
		//     })
		//   });
		// });
		this.g.temp_quest.domande[this.activatedIndex - 1].risposte.splice(i, 1);
	}

	removeAnswerFilter(a: number, i: number) {
		this.g.temp_quest.domande[this.activatedIndex - 1].risposte[a].filtri.splice(i, 1);
	}

	// copy() {
	// 	// console.log(this.activatedIndex);
	// 	// console.log(this.g.temp_quest.domande[this.activatedIndex - 1]);
	// 	this.gQuestion = JSON.parse(JSON.stringify(this.g.temp_quest.domande[this.activatedIndex - 1]));
	// 	this.gQuestion.codice =
	// 		"D" + (parseInt(this.g.temp_quest.domande[this.g.temp_quest.domande.length - 1].codice.split("D")[1]) + 1);
	// 	this.g.temp_quest.domande.push(this.gQuestion);
	// 	this.answerCode = this.gQuestion.risposte.length;
	// 	this.activatedIndex = this.g.temp_quest.domande.length;

	// 	// this.gQuestion = new Domanda;
	// 	// if (this.g.temp_quest.domande.length > 0) {
	// 	//   this.gQuestion.codice = 'D' + ((parseInt(this.g.temp_quest.domande[this.g.temp_quest.domande.length - 1].codice.split('D')[1])) + 1);
	// 	// } else {
	// 	//   this.gQuestion.codice = 'D' + 1;
	// 	// }
	// 	// this.gQuestion.titolo = this.g.temp_quest.domande[this.g.temp_quest.domande.length - 1].titolo;
	// 	// this.gQuestion.risposte = this.g.temp_quest.domande[this.g.temp_quest.domande.length - 1].risposte;
	// 	// this.gQuestion.filtri = this.g.temp_quest.domande[this.g.temp_quest.domande.length - 1].filtri;
	// 	// this.answerCode = 0;
	// 	// this.g.temp_quest.domande.push(this.gQuestion);

	// 	this.g.temp_quest.tratti.forEach((t) => {
	// 		let p: any = new Punteggio();
	// 		p.codice = t.codice + this.gQuestion.codice;
	// 		p.ordine = this.g.temp_quest.domande.length + 1;
	// 		p.domanda = {};
	// 		p.domanda.codice = this.gQuestion.codice;
	// 		p.domanda.filtri = [];
	// 		p.domanda.risposte = [];
	// 		this.gQuestion.risposte.forEach((answ, aindex) => {
	// 			p.domanda.risposte.push({ codice: answ.codice, valore: 0, trend: "0" });
	// 		});
	// 		t.punteggi[this.gQuestion.codice] = p;
	// 		/*
	//     t.punteggi = [
	//       ...t.punteggi,
	//       p
	//     ];
	//     */
	// 	});
	// }

	remove() {
		const id = this.g.temp_quest.domande[this.activatedIndex - 1].codice;
		this.g.temp_quest.domande.splice(this.activatedIndex - 1, 1);
		this.g.temp_quest.tratti.forEach((t) => {
			t.punteggi[id] = undefined;
		});

		this.activatedIndex = this.g.temp_quest.domande.length;

		if (this.g.temp_quest.domande.length > 0) {
			this.gQuestion = this.g.temp_quest.domande[this.activatedIndex - 1];
		} else {
			this.router.navigateByUrl("/admin/designer/questionario/" + this.g.activeId + "/domande");
		}
	}

	addAnswerFilter(i: number) {
		this.g.temp_quest.domande[this.activatedIndex - 1].risposte[i].filtri.push({
			campo: {
				ordine: 0,
				etichetta: "",
				tipo: "Testo",
				opzioni: "",
				richiesto: false,
			},
			operatore1: "",
			operatore2: "",
			valore1: "",
			valore2: "",
			minimo: 0,
			massimo: 0,
		});
	}

	reInitAFilter(a, i) {
		this.g.temp_quest.domande[this.activatedIndex - 1].risposte[a].filtri[i] = {
			campo: {
				ordine: 0,
				etichetta: "",
				tipo: "Testo",
				opzioni: "",
				richiesto: false,
			},
			operatore1: "",
			operatore2: "",
			valore1: "",
			valore2: "",
			minimo: 0,
			massimo: 0,
		};
	}

	navigateQuest(e: any) {
		this.activatedIndex = e;
		this.gQuestion = this.g.temp_quest.domande[e - 1];
		this.answerCode = this.gQuestion.risposte.length;
	}

	changeNav() {
		this.router.navigateByUrl("/admin/designer/questionario/" + this.g.activeId + "/domande");
	}

	addQuest() {
		this.gQuestion = new Domanda();
		if (this.g.temp_quest.domande.length > 0) {
			this.gQuestion.codice =
				"D" + (parseInt(this.g.temp_quest.domande[this.g.temp_quest.domande.length - 1].codice.split("D")[1]) + 1);
		} else {
			this.gQuestion.codice = "D" + 1;
		}
		this.gQuestion.titolo = { it: "", en: "" };
		this.gQuestion.risposte = [];
		this.gQuestion.filtri = [];
		this.gQuestion.ordine = this.g.temp_quest.domande.length + 1;
		this.answerCode = 0;
		this.g.temp_quest.domande.push(this.gQuestion);
		this.activatedIndex = this.g.temp_quest.domande.length;

		this.g.temp_quest.tratti.forEach((t) => {
			let p: any = new Punteggio();
			p.codice = t.codice + this.gQuestion.codice;
			p.domanda = {};
			p.domanda.codice = this.gQuestion.codice;
			p.domanda.filtri = [];
			p.domanda.risposte = [];
			this.gQuestion.risposte.forEach((answ, aindex) => {
				p.domanda.risposte.push({ codice: answ.codice, valore: 0 });
			});
			t.punteggi[this.gQuestion.codice] = p;
			/*
      t.punteggi = [
        ...t.punteggi,
        p
      ];
      */
		});
	}
}
