import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthClienteGuard } from "src/app/guards/authCliente.guard";
import { EditComponent } from "./edit.component";

const routes: Routes = [
	{
		path: "questionari/edit/:lid/:rid",
		component: EditComponent,
		canActivate: [AuthClienteGuard],
		canActivateChild: [AuthClienteGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class EditRoutingModule {}
