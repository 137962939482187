import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthClienteGuard } from "src/app/guards/authCliente.guard";
import { ShowValutazioniComponent } from "./show-valutazioni.component";
const routes: Routes = [
	{
		path: "questionari/show/:aId/:lId/:cId",
		component: ShowValutazioniComponent,
		canActivate: [AuthClienteGuard],
		canActivateChild: [AuthClienteGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ShowValutazioniRoutingModule {}
