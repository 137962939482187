import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginClienteGuard } from "src/app/guards/loginCliente.guard";
import { LoginComponent } from "./login/login.component";
import { PinComponent } from "./pin/pin.component";

const routes: Routes = [
	{ path: "login", component: LoginComponent, canActivate: [LoginClienteGuard], canActivateChild: [LoginClienteGuard] },
	{ path: "pin", component: PinComponent, canActivate: [LoginClienteGuard], canActivateChild: [LoginClienteGuard] },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ClienteRoutingModule {}
