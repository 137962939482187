import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AttendibilitaComponent } from './attendibilita/attendibilita.component';
import { AggiungidomandaComponent } from './domande/aggiungidomanda/aggiungidomanda.component';
import { DomandeComponent } from './domande/domande.component';
import { ForzaturaComponent } from './forzatura/forzatura.component';
import { HeadComponent } from './head/head.component';
import { QuestionarioComponent } from './questionario.component';
import { TrattiComponent } from './tratti/tratti.component';

const routes: Routes = [
  {
    path: 'admin/designer/questionario/:docID', component: QuestionarioComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      { path: 'intestazione', component: HeadComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'domande', component: DomandeComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'domande/modifica/:id', component: AggiungidomandaComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'tratti', component: TrattiComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'attendibilita', component: AttendibilitaComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: 'forzatura', component: ForzaturaComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
      { path: '**', redirectTo: 'intestazione' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuestionarioRoutingModule { }
