import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
  selector: "app-pin",
  templateUrl: "./pin.component.html",
  styleUrls: ["./pin.component.scss"],
})
export class PinComponent implements OnInit {
  CryptoJS = require("crypto-js");

  modalWrongError = false;
  modalExpiredError = false;
  pin: number[] = [];
  currentWindowWidth: number;
  isWaiting = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthClienteService,
    private translate: TranslateService,
    private g: GlobalService,
    private fire: FireService,
    private node: NodeService
  ) { }

  ngOnInit() {
    this.currentWindowWidth = window.innerWidth;
    this.route.queryParams.subscribe((params) => {
      if (params.code) {
        if (params.code.length === 8 && !isNaN(params.code)) {
          this.pin = String(params.code)
            .split("")
            .map((str) => Number(str));
          this.pinLogin(params.code);
        }
      }
    });
  }

  // ######################################
  // FUNCTION: pinLogin
  pinLogin(pin: number = 0) {
    if (!pin || pin == 0 || isNaN(pin)) return;

    // console.log("PIN", pin);

    this.isWaiting = true;

    this.fire.get("sms", pin.toString()).subscribe((res) => {
      if (res.timestamp) {
        let customLink = {
          azienda: res.azienda,
          licenza: res.licenza,
          reparto: res.reparto,
          utente: res.utente,
        };
        let encodedUrl = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(JSON.stringify(customLink)));
        //// console.log("res", res);
        //// console.log("customLink", customLink);
        //// console.log("encodedUrl", encodedUrl);

        this.router.navigateByUrl("/compilazione/" + encodedUrl);
      } else {
        this.g.createErrorNotification("PIN ERRATO", "Il sistema non ha trovato il codice: <b>" + pin + "</b>", 0);
        this.isWaiting = false;
        this.pin = [];
      }
    });
  }

  @HostListener("window:resize")
  onResize() {
    this.currentWindowWidth = window.innerWidth;
  }

  @HostListener("document:keydown", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    event.preventDefault();

    if (Number.parseInt(event.key, 10) && this.pin.length < 8) {
      this.pin.push(Number.parseInt(event.key, 10));
    } else if (event.key === "Backspace") {
      this.pin.pop();
    }
  }

  checkButton() {
    return this.pin.length !== 8;
  }
}
