<div class="uiPinContainer">
  <h1 class="pinTitle">Compilazione questionario</h1>
  <img class="uiUncollapsedLogo" src="../../../assets/logo-light.png" alt="logo">
  <div class="uiFormContainer">
    <p class="uiDescriptionText" *ngIf="currentWindowWidth >= 600" [innerHTML]="'pinloginText' | translate"></p>
    <p class="uiDescriptionText uiDescriptionTextMobile" *ngIf="currentWindowWidth < 600" [innerHTML]="'pinloginMobileText' | translate"></p>
    <div class="pinWrapper">
      <input nz-input disabled class="pinN" [(ngModel)]="pin[0]">
      <input nz-input disabled class="pinN" [(ngModel)]="pin[1]">
      <input nz-input disabled class="pinN" [(ngModel)]="pin[2]">
      <input nz-input disabled class="pinN" [(ngModel)]="pin[3]">
      <input nz-input disabled class="pinSEP" value="-" nzBorderless>
      <input nz-input disabled class="pinN" [(ngModel)]="pin[4]">
      <input nz-input disabled class="pinN" [(ngModel)]="pin[5]">
      <input nz-input disabled class="pinN" [(ngModel)]="pin[6]">
      <input nz-input disabled class="pinN" [(ngModel)]="pin[7]">
    </div>
    <br>
    <button [nzLoading]="isWaiting" [disabled]="checkButton()" nz-button nzSize="large" (click)="pinLogin(+pin.join(''))" nzType="primary" class="loginBtn pinloginBtn">Compila il questionario</button>
    <p *ngIf="modalWrongError" class="errorMessage">{{'wrongPin' | translate}}</p>
    <p *ngIf="modalExpiredError" class="errorMessage">{{'expiredPin' | translate}}</p>
    <br><br><br>

    <button nz-button nzSize="large" [routerLink]="'/'" nzType="default" class="loginBtn returnBtn">Torna alla home</button>
  </div>
</div>