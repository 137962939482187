import { Punteggio } from "./punteggi";

export class Tratto {
	codice: string;
	titolo: any;
	gruppo: string;
	valore: number;
	min: number;
	max: number;

	punteggi: any;
}
