<nz-layout class="uiPageInnerLayout">
    <nz-content class="uiQuestQuesionContent" *ngIf="g.temp_quest">
        <div nz-row class="uiQuestParagraphRow">
            <div class="uiParagraphLeft">
                LISTA DOMANDE ({{g.temp_quest.domande.length}})
            </div>
            <div class="uiParagraphRight">
                <button nz-button class="uiAddQuestion" (click)="addQuestion()">
                    Aggiungi domanda <i class="customIcon uiPlusCircleIcon"></i>
                </button>
            </div>
        </div>
        <div class="uiQuestionHeader">
            <nz-input-group [nzPrefix]="prefixTemplateUser" class="uiSerchbar">
                <input type="text" nz-input placeholder="{{'search' | translate}}" [(ngModel)]="search" (ngModelChange)="filter()" autocomplete="off" />
            </nz-input-group>
            <ng-template #prefixTemplateUser><i class="customIcon uiFilterIcon"></i></ng-template>
        </div>

        <div class="uiQuestQuesionBody">
            <nz-table class="uiQuestionTable" *ngIf="displayedData.length > 0; else emptyTemplate" #questions [nzData]="displayedData" [nzFrontPagination]="false" [nzScroll]="scroll" [nzPageSize]="100" [nzShowPagination]="false">
                <thead>
                    <tr>
                        <th nzWidth="5%" nzAlign="center"><span nz-icon nzType="ordered-list" nzTheme="outline"></span></th>
                        <th nzWidth="10%" nzAlign="center">Codice</th>
                        <th nzWidth="65%">Domanda</th>
                        <th nzWidth="10%" nzWidth="80px" nzAlign="center">Risposte</th>
                        <th nzWidth="10%" nzAlign="center"></th>
                    </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                    <ng-container *ngFor="let data of questions.data;let i= index">
                        <tr cdkDrag class="uiDarkColorRow" [ngClass]="i%2 === 0 ? 'uiGreyTableRow' : 'uiLightTableRow'">
                            <td nzAlign="center" (click)="changeNav(data.codice)">{{data.ordine}}</td>
                            <td nzAlign="center" (click)="changeNav(data.codice)">{{data.codice}}</td>
                            <td (click)="changeNav(data.codice)">{{data.titolo[g.selectedLanguage]}}</td>
                            <td (click)="changeNav(data.codice)" nzAlign="center">{{data.risposte.length}}</td>
                            <td nzAlign="center">
                                <div nz-row>
                                    <div nz-col nzSpan="12"><i class="customIcon uiCopyIcon" (click)="copy(i, data.codice)" nz-tooltip nzTooltipTitle="Duplica domanda"></i></div>
                                    <div nz-col nzSpan="12"><i class="customIcon uiTrashIcon" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler cancellare la domanda {{data.codice}}?" (nzOnConfirm)="remove(i, data.codice)" nz-tooltip nzTooltipTitle="Elimina domanda"></i></div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </nz-table>

            <ng-template #emptyTemplate>
                <div class="uiEmptyContainer">
                    <nz-empty nzNotFoundImage="simple" style="color: var(--neutral-color)" nzNotFoundContent="Nessuna domanda trovata."></nz-empty>
                </div>
            </ng-template>
        </div>
    </nz-content>
</nz-layout>