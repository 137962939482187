import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditAggregatiComponent } from './edit-aggregati.component';
import { EditAggregatiRoutingModule } from './edit-aggregati-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgZorroModule } from 'src/app/ng-zorro.module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [EditAggregatiComponent],
  imports: [
    EditAggregatiRoutingModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgZorroModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateModule,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    })
  ],
  exports: [EditAggregatiComponent, NgZorroModule],
  providers: [AuthService, AuthGuard],
})
export class EditAggregatiModule { }
