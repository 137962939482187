import { AfterViewChecked, AfterViewInit, Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Questionario } from "src/app/entities/questionari";
import { User } from "src/app/entities/users";
import { AuthService } from "src/app/services/auth.service";
import { FireService } from "src/app/services/fire.service";
import { StorageService } from "src/app/services/storage.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
  selector: "app-questionario",
  templateUrl: "./questionario.component.html",
  styleUrls: ["./questionario.component.scss"],
})
export class QuestionarioComponent implements OnInit, AfterViewInit {
  isCollapsed = false;
  docID: string = "";
  quest: Questionario;
  bkpQuest: Questionario;
  qID: string = "";
  vID: string = "";
  loggedIn: User;
  firstTime: boolean = true;
  questToExport: Questionario;
  downloadJsonHref!: SafeUrl;
  jsonData: any;

  // ######################################
  // FUNCTION: constructor
  constructor(
    public auth: AuthService,
    public g: GlobalService,
    private translate: TranslateService,
    public router: Router,
    private storage: StorageService,
    private fire: FireService,
    private route: ActivatedRoute,
    private notification: NzNotificationService,
    private sanitizer: DomSanitizer
  ) { }

  // ######################################
  // FUNCTION: ngAfterViewInit
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant("questionnaire") + " " + this.g.temp_quest_id + this.vID;
    }, 300);
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit() {
    this.g.questLoaded = false;
    this.loggedIn = this.auth.getUser();
    this.route.params.subscribe((param) => {
      this.docID = param.docID;
      this.g.activeId = this.docID;
      this.qID = this.docID.split("V")[0];
      this.vID = "V" + this.docID.split("V")[1];
    });
    this.fire.getDocument("questionari", this.qID, "versioni", this.vID).subscribe((res) => {
      this.g.questLoaded = false;
      this.quest = JSON.parse(JSON.stringify(res));
      this.questToExport = this.quest;
      // console.log(this.questToExport);
      this.jsonExport();
      if (this.firstTime) {
        this.bkpQuest = JSON.parse(JSON.stringify(res));
        this.firstTime = false;
      }
      this.g.questLoaded = true;

      // FIX MISSING TREND IN RISPOSTE
      res.domande.forEach((d) => {
        d.risposte.forEach((r) => {
          if (r["trend"] == null) r["trend"] = 0;
        });
      });
      // ################

      this.g.temp_quest = res;
      this.g.temp_quest_questions = res.domande;
      this.g.temp_quest_traits = res.tratti;
      this.g.temp_quest_id = this.qID;
    });
  }

  // ######################################
  // FUNCTION: validate
  validate(): number {
    let result: number = 0;
    this.g.temp_quest.anagrafica.forEach((a) => {
      if (a.tipo === "Range") {
        if (!a.minimo || !a.massimo || a.minimo >= a.massimo) {
          result = -1;
        }
      } else if (a.tipo === "Elenco") {
        const cnt = a.opzioni.split(",").length;
        if (cnt <= 1) result = -2;
      }
    });
    if (result == 0) {
      // CHECK ETICHETTE INSTESTAZIONI UNIVOCHE
      var valueArr = this.g.temp_quest.anagrafica.map(function (item) {
        return item.etichetta;
      });
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx;
      });
      if (isDuplicate) result = -3;
    }
    if (result == -1) {
      this.notification.create("error", "Inserire un valore corretto nei campi Range.", "");
      return result;
    } else if (result == -2) {
      this.notification.create("error", "Devi inserire almeno due elementi separati da virgola nei campi Elenco.", "");
      return result;
    } else if (result == -3) {
      this.notification.create("error", "Non ci possono essere etichette uguali nei campi intestazione.", "");
      return result;
    } else {
      return result;
    }
  }

  // ######################################
  // FUNCTION: navigate
  navigate(url, event): void {
    let result: number = this.validate();

    if (result == 0) {
      if (url === "/admin/domande" && this.g.temp_quest.domande.length > 0) {
        const code = this.g.temp_quest.domande[0].codice;
        this.router.navigateByUrl("/admin/designer/questionario/" + this.docID + url);
      } else {
        this.router.navigateByUrl("/admin/designer/questionario/" + this.docID + url);
      }
      if (JSON.stringify(this.quest) === JSON.stringify(this.g.temp_quest)) {
      } else {
        this.quest = JSON.parse(JSON.stringify(this.g.temp_quest));
        this.fire.update("questionari", "versioni", this.quest, this.qID, this.vID);
      }
    } else {
      event.stopPropagation();
    }
  }

  // ######################################
  // FUNCTION: cancel
  cancel() {
    this.fire.update("questionari", "versioni", this.bkpQuest, this.qID, this.vID);
    this.router.navigateByUrl("/admin/designer");
  }

  // ######################################
  // FUNCTION: save
  save() {
    let result: number = this.validate();
    if (result < 0) return;

    this.quest = JSON.parse(JSON.stringify(this.g.temp_quest));

    // ########  SE QUESTIONARIO DI GRUPPO CALCOLO MIN-MAX TRATTI

    if ((this.quest.report == "2")) {
      this.quest.tratti.forEach((t) => {
        var refMax: number = 0;
        var refMin: number = 0;

        Object.keys(t.punteggi).forEach((key) => {
          var d = t.punteggi[key];
          var max: number = Math.max(...d.domanda.risposte.map((r) => r.valore));
          var min: number = Math.min(...d.domanda.risposte.map((r) => r.valore));

          //// console.log("MINMAX;" + t.codice + ";" + d.codice + ";" + min + ";" + max);

          refMax += max; // > 0 ? max : 0;
          refMin += min; // < 0 ? min : 0;
        });
        t.max = refMax;
        t.min = refMin;
      });
    }

    this.quest.ultima_modifica = new Date(Date.now());
    this.notification.create("success", "Operazione completata", "");
    this.bkpQuest = this.quest;

    // console.log(this.quest, this.qID, this.vID);

    this.fire.update("questionari", "versioni", this.quest, this.qID, this.vID);
  }

  // ######################################
  // FUNCTION: saveAndExit
  saveAndExit() {
    let result: number = this.validate();
    if (result < 0) return;

    this.save();
    this.router.navigateByUrl("/admin/designer");
  }


  jsonExport() {
    var theJSON = JSON.stringify(this.questToExport);
    var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
    this.downloadJsonHref = uri;
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.readFile(file);
    }
  }

  readFile(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.jsonData = JSON.parse(reader.result as string);
      const res = {
        ...this.jsonData,
        copertina_report: { ENG: "", ITA: "" },
        privacy: ""
      };
      this.quest = JSON.parse(JSON.stringify(res));
      this.questToExport = this.quest;
      this.jsonExport();
      if (this.firstTime) {
        this.bkpQuest = JSON.parse(JSON.stringify(res));
        this.firstTime = false;
      }
      this.g.questLoaded = true;

      // FIX MISSING TREND IN RISPOSTE
      res.domande.forEach((d) => {
        d.risposte.forEach((r) => {
          if (r["trend"] == null) r["trend"] = 0;
        });
      });
      // ################

      this.g.temp_quest = res;
      this.g.temp_quest_questions = res.domande;
      this.g.temp_quest_traits = res.tratti;
      this.g.temp_quest_id = this.qID;
    };
    reader.readAsText(file);
  }
}
