<nz-layout>
    <nz-sider class="uiEditSidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
        [nzTrigger]="null">
        <div class="uiQuestSidebarLogo">
            <a href="/admin"><img class="uiUncollapsedLogo" src="../../../assets/logo-light.png" alt="logo"
                    *ngIf="!isCollapsed"></a>
            <a href="/admin"><img class="uiCollapsedLogo" src="../../../assets/logo-small.png" alt="logo"
                    *ngIf="isCollapsed"></a>
        </div>
        <ul class="uiMenu" nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed"
            *ngIf="!isCollapsed">
            <li nz-menu-item class="uiMenuItem uiActionItem" [nzMatchRouter]="true">
                <a (click)="back()"><i class="customIcon uiRollbackIcon"></i>
                    Ritorna</a>
            </li>
        </ul>
        <ul class="uiCollapsedMenu" nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed"
            *ngIf="isCollapsed">
            <li nz-menu-item class="uiMenuItem uiMenuCollapsedItem">
                <a (click)="back()"><i class="customIcon uiRollbackIcon"></i></a>
            </li>
        </ul>
    </nz-sider>
    <nz-layout>
        <nz-header class="uiHeader uiQuestHeader">
            <div nz-row class="uiHeaderRow">
                <div class="uiHeaderLeftCol" nz-col nzSpan="16">
                    <span class="uiSidebarTrigger" (click)="isCollapsed = !isCollapsed">
                        <i class="trigger"
                            [ngClass]="isCollapsed ? 'customIcon uiMenuFoldIcon' : 'customIcon uiMenuFoldIcon'"></i>
                    </span>
                    <span>{{g.actualPage}}</span>
                </div>
                <div nz-col nzSpan="8" class="uiHeaderRightCol">
                    {{loggedIn.email}}
                    <i *ngIf="auth.isLoggedIn()" class="customIcon uiLogoutIcon" (click)="auth.logout()"></i>
                </div>
            </div>
        </nz-header>
        <nz-content class="uiGestioneEditContent">
            <div nz-row class="uiGestioneEditParagraphRow" [nzGutter]="16">
                <div class="uiParagraphLeft">
                </div>
            </div>

            <div class="uiGestioneEditHeader">
                <nz-input-group [nzPrefix]="prefixTemplateUser" class="uiSerchbar">
                    <input type="text" nz-input placeholder="{{'search' | translate}}" [(ngModel)]="search"
                        (ngModelChange)="filter()" autocomplete="off" />
                </nz-input-group>
                <ng-template #prefixTemplateUser><i class="customIcon uiFilterIcon"></i></ng-template>
            </div>

            <div class="uiGestioneEditTableContainer" *ngIf="!loading">
                <nz-table class="uiGestioneEditTable" #usersTable [nzData]="displayedData" [nzShowPagination]="false"
                    [nzScroll]="scroll" [nzPageSize]="100"
                    [nzWidthConfig]="['5%', '10%', '35%', '10%', '30%', '10%']">
                    <thead>
                        <tr>
                            <th nzAlign="center" colspan="3">QUESTIONARIO</th>
                            <th nzAlign="center" colspan="3">VERSIONE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let data of usersTable.data; let i = index">
                            <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                <td nzAlign="center">
                                    <i [ngClass]="openFolder[i] ? 'customIcon uiFolderOpenIcon': 'customIcon uiFolderIcon'"
                                    (click)="openArea(i)" style="margin: 0;"></i>
                                </td>
                                <td nzAlign="center">
                                    {{data.questionario.id}}
                                </td>
                                <td nzAlign="center">
                                    {{data.questionario.titolo}}
                                </td>
                                <td nzAlign="center">
                                    {{data.versione.id}}
                                </td>
                                <td nzAlign="center" colSpan="2">
                                    {{data.versione.titolo['ITA']}}
                                </td>
                            </tr>
                            <ng-container *ngIf="openFolder[i]">
                                    <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                        <td nzAlign="center">
                                            
                                        </td>
                                        <td nzAlign="center">
                                            {{data.licenza.id}}
                                        </td>
                                        <td nzAlign="center">
                                            {{repName.nome}}
                                        </td>
                                        <td nzAlign="center" colSpan="2">
                                            {{data.licenza.utilizzate}}/{{data.licenza.totali}} Completato
                                        </td>
                                        <td nzAlign="center">
                                            <i class="customIcon uiEditIcon" (click)="editRep(data.licenza.id)"></i>
                                        </td>
                                    </tr>
                                </ng-container>
                        </ng-container>
                    </tbody>
                </nz-table>
            </div>
        </nz-content>
    </nz-layout>
</nz-layout>