import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthClienteGuard } from "src/app/guards/authCliente.guard";
import { AggregatiComponent } from "./aggregati/aggregati.component";
import { DestinatariComponent } from "./destinatari/destinatari.component";
import { HomepageComponent } from "./homepage.component";
import { LogoComponent } from "./logo/logo.component";
import { QuestionariComponent } from "./licenze/licenze.component";

const routes: Routes = [
	{
		path: "",
		component: HomepageComponent,
		canActivate: [AuthClienteGuard],
		canActivateChild: [AuthClienteGuard],
		children: [
			{ path: "", component: LogoComponent },
			{ path: "questionari", component: QuestionariComponent },
			{ path: "aggregati", component: AggregatiComponent },
			{ path: "destinatari", component: DestinatariComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ClienteHomepageRoutingModule {}
