import { Component, HostListener, OnInit } from '@angular/core';
import { Gruppo_attendibilità } from 'src/app/entities/gruppi_attendibilita';
import { GlobalService } from 'src/app/shared/global.service';

@Component({
  selector: 'app-attendibilita',
  templateUrl: './attendibilita.component.html',
  styleUrls: ['./attendibilita.component.scss']
})
export class AttendibilitaComponent implements OnInit {
  availableAnswers: any = [];
  scroll = { y: '' };

  constructor(public g: GlobalService) { }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight()
  }

  setScrollHeight() {
    if (document.getElementsByClassName('uiQustAttendBody').length > 0) {
      let height = document.getElementsByClassName('uiQustAttendBody')[0].clientHeight;
      if (document.getElementsByClassName('uiQustAttendBody')[0].getElementsByClassName('ant-table-thead').length) {
        let headH = document.getElementsByClassName('uiQustAttendBody')[0].getElementsByClassName('ant-table-thead')[0].clientHeight;
        this.scroll = { y: height - headH + 'px' };
      } else {
        this.scroll = { y: height - 24 + 'px' };
      }
    }
  }


  ngOnInit(): void {
    setTimeout(() => {
      this.setScrollHeight();
    }, 500)
  }

  addGroup() {
    let g: Gruppo_attendibilità = new Gruppo_attendibilità;
    if (this.g.temp_quest.gruppi_attendibilita.length > 0) {
      g.codice = 'G' + ((parseInt(this.g.temp_quest.gruppi_attendibilita[this.g.temp_quest.gruppi_attendibilita.length - 1].codice.split('G')[1])) + 1);
    } else {
      g.codice = 'G' + 1;
    }
    this.g.languages.forEach(lang => {
      g.titolo[lang] = '';
    });
    g.operazioni = [];

    this.g.temp_quest.gruppi_attendibilita = [
      ...this.g.temp_quest.gruppi_attendibilita,
      g
    ];
  }

  remove(i: string) {
    this.g.temp_quest.gruppi_attendibilita = this.g.temp_quest.gruppi_attendibilita.filter(e => e.codice !== i)
  }

  addOperation(i: number) {
    this.g.temp_quest.gruppi_attendibilita[i].operazioni.push({})
  }

  selectQuestion(o: number, i: number) {
    this.g.temp_quest.gruppi_attendibilita[i].operazioni[o].risposta = '';
    let index = this.g.temp_quest.domande.findIndex(q => q.codice === this.g.temp_quest.gruppi_attendibilita[i].operazioni[o].domanda);
    this.g.temp_quest.gruppi_attendibilita[i].operazioni[o].availableAnswers = this.g.temp_quest.domande[index].risposte;
  }

  selectAnswer(e: boolean, o: number, i: number) {
    if (e === true) {
      let index = this.g.temp_quest.domande.findIndex(q => q.codice === this.g.temp_quest.gruppi_attendibilita[i].operazioni[o].domanda);
      this.availableAnswers[o] = this.g.temp_quest.domande[index].risposte;
    }
  }
  
  removeOp(o: number, i: number) {
    this.g.temp_quest.gruppi_attendibilita[i].operazioni.splice(o, 1);
  }

}
