import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { User } from "src/app/entities/users";
import { AuthService } from "src/app/services/auth.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  CryptoJS = require("crypto-js");

  appVersion: string;
  password: string;
  username: string;
  showError: boolean;
  recoverSuccess: boolean;
  recoverError: boolean;
  recoverModal: boolean = false;

  // ######################################
  // FUNCTION: constructor
  constructor(
    private router: Router,
    private auth: AuthService,
    private g: GlobalService,
    private translate: TranslateService,
    private node: NodeService,
    private modal: NzModalService
  ) { }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit() {
    this.password = "";
    this.username = "";
    this.showError = false;
    this.recoverSuccess = false;
  }

  // ######################################
  // FUNCTION: ngAfterViewInit
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = "";
    });
  }

  // ######################################
  // FUNCTION: onSubmit
  async onSubmit() {
    const crypt: string = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(this.password));
    const formData = {
      pwd: crypt,
      user: this.username,
    };
    sessionStorage.setItem('email', this.username);
    sessionStorage.setItem('key', crypt);

    this.showError = false;
    this.auth.checkLogin(formData).subscribe(
      (res) => {
        this.g.loggedInUser = res as User;
        // console.log(res);
        sessionStorage.setItem('id', res.id);
        this.auth.saveUser(res as User);
        this.router.navigateByUrl("/admin");
      },
      (err) => {
        // console.log("ERR", err);
        this.showError = true;
      }
    );
  }

  // ######################################
  // FUNCTION: goTo
  goTo(location: string) {
    this.router.navigateByUrl(location);
  }

  // ######################################
  // FUNCTION: showModal
  showModal() {
    this.isWaiting = false;
    this.recoverModal = true;
    this.recoverSuccess = false;
    this.recoverError = false;
  }

  // ######################################
  // FUNCTION: handleCancel
  handleCancel() {
    this.recoverModal = false;
    this.recoverSuccess = false;
  }

  // ######################################
  // FUNCTION: recover
  isWaiting = false;
  recover() {
    const formData = {
      user: this.username,
    };

    this.isWaiting = true;
    this.showError = false;
    this.node.recoverUser(formData).subscribe(
      (res) => {
        this.g.createCustomMessage("OPERAZIONE COMPLETATA", this.translate.instant("successRecover"));
        //this.g.createSuccessNotification("OPERAZIONE COMPLETATA", this.translate.instant("successRecover"), 0);
        this.recoverModal = false;
      },
      (err) => {
        console.error(err);
        this.g.createErrorNotification("OPERAZIONE COMPLETATA", this.translate.instant("errorRecover"), 0);
        this.recoverModal = false;
      }
    );
  }
}
