import { Component, HostListener, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/entities/users';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import { GlobalService } from 'src/app/shared/global.service';
import { FireService } from 'src/app/services/fire.service';
import * as env from "src/environments/env.json";
import { HttpClient, HttpEvent, HttpEventType, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PdfmodalComponent } from '../../../designer/questionario/head/pdfmodal/pdfmodal.component';
import { NodeService } from 'src/app/services/node.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-edit-privacy',
  templateUrl: './edit-privacy.component.html',
  styleUrls: ['./edit-privacy.component.scss']
})
export class EditPrivacyComponent implements OnInit {

  constructor(private route: ActivatedRoute, private location: Location, public auth: AuthService, public g: GlobalService, private fire: FireService,
    private http: HttpClient, private msg: NzMessageService, private node: NodeService, private viewContainerRef: ViewContainerRef, private modal: NzModalService) { }

  env: any = (env as any).default;
  uploadedFile: string = '';
  successUpload = true;
  uploadURL: string = '';
  isCollapsed = false;
  pId: string = '';
  resData: any[] = [];
  displayedData: any[] = [];
  loading: boolean = true;
  search: string = '';
  privacy: any;
  data_disattivazione;
  loggedIn: User;
  CryptoJS = require('crypto-js');
  newDests: any[] = [];
  language = "ITA";
  scroll = { y: '' };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  setScrollHeight() {
    if (document.getElementsByClassName('uiBkgContainer').length > 0) {
      let height = document.getElementsByClassName('uiBkgContainer')[0].clientHeight;
      if (document.getElementsByClassName('uiBkgContainer')[0].getElementsByClassName('ant-table-thead').length) {
        let headH = document.getElementsByClassName('uiBkgContainer')[0].getElementsByClassName('ant-table-thead')[0].clientHeight;
        this.scroll = { y: height - headH + 'px' };
      } else {
        this.scroll = { y: height - 24 + 'px' };
      }
    }
  }

  ngOnInit(): void {
    this.loggedIn = this.auth.getUser();
    this.uploadURL = this.g.nodePath + '/quest/upload';
    this.route.params.subscribe((param) => {
      this.pId = param.pid;
    });

    this.fire.get('privacy', this.pId).subscribe(
      res => {
        this.privacy = res;
        this.data_disattivazione = new Date(this.privacy.disattivazione.seconds * 1000);
        this.loading = false;
        this.setScrollHeight();
      }
    );
  }

  back() {
    this.location.back();
  }

  addCheckbox() {
    if (this.privacy.checkbox[this.language].length > 0) {
      let last = this.privacy.checkbox[this.language][this.privacy.checkbox[this.language].length - 1].codice;
      let c = {
        id: 'C' + (last + 1),
        codice: last + 1,
        testo: 'Testo checkbox',
        accetto: 'Accetto',
        non_accetto: 'Non accetto',
        richiesto: false
      };
      this.privacy.checkbox[this.language].push(c);
    } else {
      let c = {
        id: 'C1',
        codice: 1,
        testo: 'Testo checkbox',
        accetto: 'Accetto',
        non_accetto: 'Non accetto',
        richiesto: false
      };
      this.privacy.checkbox[this.language].push(c);
    }
  }

  deleteCheckbox(id: any) {
    this.privacy.checkbox[this.language] = this.privacy.checkbox[this.language].filter((e: any) => e.id !== id);
  }

  save() {
    this.privacy.disattivazione = this.data_disattivazione;
    this.fire.updateCollection('privacy', this.privacy, this.pId);
  }

  saveAndExit() {
    this.save();
    this.location.back();
  }

  customFileReq = (item: any) => {
    // Create a FormData here to store files and other parameters.
    const ext = item.file.name.substr(item.file.name.lastIndexOf('.'));
    let filename = '';
    filename = this.privacy.id + '-attach-' + this.language + ext;
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('filename', filename);
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });

    return this.http.request(req).subscribe((event: HttpEvent<any>) => {
      if (event.type === HttpEventType.UploadProgress) {
        if (event.total! > 0) {
          (event as any).percent = (event.loaded / event.total!) * 100;
        }
        item.onProgress!(event, item.file!);
      } else if (event instanceof HttpResponse) {
        item.onSuccess!(event.body, item.file!, event);
      }
    },
      err => {
        item.onError!(err, item.file!);
      }
    );
  };

  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
      const ext = info.file.name.substring(info.file.name.lastIndexOf('.'));
      this.uploadedFile = this.privacy.id + '-attach-' + this.language + ext;
      this.privacy.allegato[this.language] = this.uploadedFile;
      this.successUpload = true;
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === 'removed') {
      this.msg.warning(`${info.file.name} file removed.`);
      this.successUpload = false;
    }
  }

  deleteFile() {
    this.privacy.allegato[this.language] = '';
  }

  downloadFile(path: string) {
    let params = { 'filepath': path };
    this.node.getData(params, '/download').subscribe(
      (data) => {
        if (data.status !== 200) {
          var fileURL: string = URL.createObjectURL(data);
          const modal = this.modal.create({
            nzTitle: 'Visualizza file',
            nzContent: PdfmodalComponent,
            nzViewContainerRef: this.viewContainerRef,
            nzClassName: 'uiPdfModal',
            nzFooter: null,
            nzComponentParams: {
              file: fileURL
            },
          });
        }
      },
    );
  }

  onSelect(e) {
    let checkboxes = {};
    e.forEach(l => {
      if (this.privacy.checkbox[l]) {
        checkboxes[l] = this.privacy.checkbox[l];
      } else {
        checkboxes[l] = [];
      }
    });
    this.privacy.checkbox = checkboxes;
  }
}
