import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { User } from "src/app/entities/users";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { StorageService } from "src/app/services/storage.service";
import { GlobalService } from "src/app/shared/global.service";
import { InterfaceService } from "src/app/shared/interface.service";

@Component({
  selector: "app-utenti",
  templateUrl: "./utenti.component.html",
  styleUrls: ["./utenti.component.scss"],
})
export class UtentiComponent implements OnInit, AfterViewInit {
  reps: any[] = [];
  loading = true;
  openFolder: any[] = [];
  openAreas: any[] = [];
  tableH: string = "";
  displayedData: any[] = [];
  search = "";
  editCacheR: { [key: string]: { edit: boolean; data: any; }; } = {};
  editCacheA: { [key: string]: { [key2: string]: { edit: boolean; data: any; }; }; } = {};
  editCacheU: { [key: string]: { [key2: string]: { [key3: string]: { edit: boolean; data: any; }; }; }; } = {};
  recoverModal = false;
  manualChange = false;
  username: any;
  showError: boolean = false;
  showPwdError: boolean = false;
  pwdForm: UntypedFormGroup;
  onAdd = false;
  scroll = { y: "" };
  addUser = false;

  // ######################################
  // FUNCTION: constructor
  constructor(
    public g: GlobalService,
    private translate: TranslateService,
    private router: Router,
    private node: NodeService,
    private storage: StorageService,
    private fire: FireService,
    private fb: UntypedFormBuilder,
    public face: InterfaceService
  ) { }
  CryptoJS = require("crypto-js");

  // ######################################
  // EVENMT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiUsersTableContainer").length > 0) {
      let height = document.getElementsByClassName("uiUsersTableContainer")[0].clientHeight;
      if (
        document.getElementsByClassName("uiUsersTableContainer")[0].getElementsByClassName("ant-table-thead").length
      ) {
        let headH = document
          .getElementsByClassName("uiUsersTableContainer")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 32 + "px" };
      }
    }
  }

  // ######################################
  // FUNCTION: ngAfterViewInit
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant("utenti");
    });
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    let tempPref = this.face.getUtentiInterface();
    if (tempPref === null) {
      tempPref = {};
    }
    this.fire.getAllAsc("reparti", "codice").subscribe((res) => {
      this.reps = res;
      this.displayedData = [...this.reps];
      for (let i = 0; i < res.length; i++) {
        if (typeof tempPref[res[i].id] === "undefined" || tempPref[res[i].id] === null) {
          tempPref[res[i].id] = { open: true, sub: {} };
          this.openFolder[i] = true;
        } else {
          this.openFolder[i] = tempPref[res[i].id].open;
        }

        this.openAreas[i] = [];
        let e = res[i];
        this.fire.getCollection("reparti", e.id, "aree").subscribe((res2) => {
          e.aree = res2;
          if (res2.length > 0) {
            for (let j = 0; j < res2.length; j++) {
              if (
                typeof tempPref[res[i].id].sub[res2[j].id] === "undefined" ||
                tempPref[res[i].id].sub[res2[j].id] === null
              ) {
                tempPref[res[i].id].sub[res2[j].id] = true;
                this.openAreas[i][j] = true;
              } else {
                this.openAreas[i][j] = tempPref[res[i].id].sub[res2[j].id];
              }

              let a = res2[j];
              this.fire.getByDoubleFilter("utenti", "reparto", e.id, "id", "area", a.id).subscribe((res3) => {
                if (!this.onAdd) {
                  e.n_utenti = res3.length;
                  a.utenti = res3;
                  if (i === res.length - 1) {
                    this.updateEditCacheR();
                    this.face.saveUtentiInterface(tempPref);
                  }
                } else {
                  e.n_utenti = res3.length;
                  a.utenti = res3;
                }
              });
            }
          } else {
            if (i === res.length - 1) {
              if (!this.onAdd) {
                e.aree = [];
                e.n_utenti = 0;
                setTimeout(() => {
                  this.updateEditCacheR();
                  this.face.saveUtentiInterface(tempPref);
                }, 500);
              }
            }
          }
        });
      }
    });
  }

  // ######################################
  // FUNCTION: updateEditCacheR
  updateEditCacheR(): void {
    this.reps.forEach((item) => {
      this.editCacheR[item.id] = {
        edit: false,
        data: { ...item },
      };
      this.editCacheA[item.id] = {};
      this.editCacheU[item.id] = {};

      this.updateEditCacheA(item);
    });
  }

  // ######################################
  // FUNCTION: updateEditCacheA
  updateEditCacheA(item): void {
    if (item.aree) {
      item.aree.forEach((area) => {
        this.editCacheA[item.id][area.id] = {
          edit: false,
          data: { ...area },
        };
        this.editCacheU[item.id][area.id] = {};
        this.updateEditCacheU(item, area);
      });
    }
  }

  // ######################################
  // FUNCTION: updateEditCacheU
  updateEditCacheU(item, area): void {
    if (area.utenti) {
      area.utenti.forEach((user, uindex) => {
        this.editCacheU[item.id][area.id][user.id] = {
          edit: false,
          data: { ...user },
        };
        if (uindex === area.utenti.length - 1) {
          this.loading = false;
          this.setScrollHeight();
        }
      });
    }
  }

  // ######################################
  // FUNCTION: onChangeSwitchR
  onChangeSwitchR(e: any, i: number) {
    this.reps[i].attivo = e;
    let r: any = {};
    r.codice = this.reps[i].codice;
    r.nome = this.reps[i].nome;
    r.attivo = this.reps[i].attivo;
    this.fire.updateCollection("reparti", r, this.reps[i].id);
  }

  // ######################################
  // FUNCTION: onChangeSwitchA
  onChangeSwitchA(e: any, i: number, ai: number) {
    let area: any = {};
    area.attivo = e;
    this.fire.update("reparti", "aree", area, this.reps[i].id, this.reps[i].aree[ai].id);
  }

  // ######################################
  // FUNCTION: onChangeSwitchU
  onChangeSwitchU(e: any, i: number, ai: number, ui: number) {
    let area: any = this.displayedData[i].aree[ai];
    let user = this.displayedData[i].aree[ai].utenti[ui];
    if (user.pwd !== "" && user.email !== "") {
      this.fire.update("reparti", "aree", area, this.displayedData[i].id, this.displayedData[i].aree[ai].id);
      this.fire.updateCollection("utenti", user, user.id);
    } else {
      this.g.createErrorNotification("Impossibile attivare l'utente", "Fornire un' email e una password");
      setTimeout(() => {
        this.displayedData[i].aree[ai].utenti[ui].attivo = !e;
      }, 200);
    }
  }

  // ######################################
  // FUNCTION: openArea
  openArea(i: number) {
    if (this.openFolder[i]) {
      this.openFolder[i] = false;
      this.face.openChange(false, "UTENTI", this.displayedData[i].id);
    } else {
      this.openFolder[i] = true;
      this.loading = false;
      this.face.openChange(true, "UTENTI", this.displayedData[i].id);
    }
  }

  // ######################################
  // FUNCTION: openusers
  openusers(i: number, j: number) {
    if (this.openAreas[i][j]) {
      this.openAreas[i][j] = false;
      this.face.openChange(false, "UTENTI", this.displayedData[i].id, this.displayedData[i].aree[j].id);
    } else {
      this.openAreas[i][j] = true;
      this.face.openChange(true, "UTENTI", this.displayedData[i].id, this.displayedData[i].aree[j].id);
      this.loading = false;
    }
  }

  // ######################################
  // FUNCTION: editR
  editR(id) {
    this.editCacheR[id].edit = true;
  }

  // ######################################
  // FUNCTION: cancelEditR
  cancelEditR(id: string): void {
    const index = this.reps.findIndex((item) => item.id === id);
    this.editCacheR[id] = {
      data: { ...this.reps[index] },
      edit: false,
    };
  }

  // ######################################
  // FUNCTION: saveEditR
  saveEditR(id: string): void {
    const index = this.reps.findIndex((item) => item.id === id);
    Object.assign(this.reps[index], this.editCacheR[id].data);
    this.editCacheR[id].edit = false;
    this.displayedData = [...this.reps];
    let r: any = {};
    r.codice = this.reps[index].codice;
    r.nome = this.reps[index].nome;
    this.fire.updateCollection("reparti", r, this.reps[index].id);
  }

  // ######################################
  // FUNCTION: editA
  editA(id1, id2) {
    this.editCacheA[id1][id2].edit = true;
  }

  // ######################################
  // FUNCTION: saveEditA
  saveEditA(i: number, ai: number, id1: string, id2: string): void {
    Object.assign(this.reps[i].aree[ai], this.editCacheA[id1][id2].data);
    this.editCacheA[id1][id2].edit = false;
    this.displayedData = [...this.reps];

    let area = this.reps[i].aree[ai];
    this.fire.update("reparti", "aree", area, this.reps[i].id, this.reps[i].aree[ai].id);
  }

  // ######################################
  // FUNCTION: cancelEditA
  cancelEditA(i: number, ai: number, id1: string, id2: string): void {
    this.editCacheA[id1][id2] = {
      data: { ...this.reps[i].aree[ai] },
      edit: false,
    };
  }

  // ######################################
  // FUNCTION: editU
  editU(id1, id2, id3) {
    this.editCacheU[id1][id2][id3].edit = true;
  }

  // ######################################
  // FUNCTION: saveEditU
  saveEditU(i: number, ai: number, ui: number, id1: string, id2: string, id3: string): void {
    this.addUser = false;
    if (this.onAdd && this.editCacheU[id1][id2][id3].data.email !== "") {
      let newUser = this.editCacheU[id1][id2][id3].data;
      this.fire
        .add("utenti", newUser, newUser.id)
        .then((add) => {
          this.onAdd = true;
          this.editCacheU[this.displayedData[i].id][this.displayedData[i].aree[ai].id][newUser.id].edit = false;
          this.showManualChange();
          this.onAdd = false;
        })
        .catch((err) => {
          // console.log(err);
        });
      this.username = this.displayedData[i].aree[ai].utenti[ui];
    } else if (this.editCacheU[id1][id2][id3].data.email === "") {
      this.g.createErrorNotification("Impossibile aggiungere l'utente", "Fornire un' email");
    } else {
      Object.assign(this.reps[i].aree[ai].utenti[ui], this.editCacheU[id1][id2][id3].data);
      this.editCacheU[id1][id2][id3].edit = false;
      this.displayedData = [...this.reps];

      let area = this.reps[i].aree[ai];
      this.fire.update("reparti", "aree", area, this.reps[i].id, this.reps[i].aree[ai].id);

      let user = this.reps[i].aree[ai].utenti[ui];
      this.fire.updateCollection("utenti", user, id3);
    }
  }

  // ######################################
  // FUNCTION: cancelEditU
  cancelEditU(i: number, ai: number, ui: number, id1: string, id2: string, id3: string): void {
    this.addUser = false;
    if (!this.onAdd) {
      this.editCacheU[id1][id2][id3] = {
        data: { ...this.displayedData[i].aree[ai].utenti[ui] },
        edit: false,
      };
    } else {
      this.displayedData[i].aree[ai].utenti.splice(ui, 1);
    }
  }

  // ######################################
  // FUNCTION: newR
  newR() {
    this.fire.getLastBy("reparti", "codice").then((res) => {
      let newRep = {
        attivo: false,
        codice: res + 1,
        id: "R" + (res + 1),
        n_utenti: 0,
        nome: "Nuovo reparto",
        aree: [],
      };
      this.openAreas[newRep.id] = [];
      this.editCacheR[newRep.id] = {
        edit: false,
        data: { ...newRep },
      };
      this.editCacheA[newRep.id] = {};
      this.editCacheU[newRep.id] = {};

      let addRep = {
        attivo: false,
        codice: res + 1,
        id: "R" + (res + 1),
        n_utenti: 0,
        nome: "Nuovo reparto",
      };
      this.fire
        .add("reparti", addRep, addRep.id)
        .then((res) => { })
        .catch((err) => {
          // console.log(err);
        });
    });
  }

  // ######################################
  // FUNCTION: newA
  newA(i: number) {
    this.fire.getLastSubBy("reparti", this.displayedData[i].id, "aree", "codice").then((res) => {
      let newArea = {
        attivo: false,
        codice: res + 1,
        id: "A" + (res + 1),
        nome: "Nuova area",
        permessi: [],
        utenti: [],
      };
      this.editCacheA[this.displayedData[i].id][newArea.id] = {
        edit: false,
        data: { ...newArea },
      };
      let addArea = {
        attivo: false,
        codice: res + 1,
        id: "A" + (res + 1),
        nome: "Nuova area",
        permessi: [],
      };
      this.fire
        .addCollection("reparti", "aree", addArea, this.displayedData[i].id, addArea.id)
        .then((result: any) => {
          if (!this.openFolder[i]) this.openArea(i);
        })
        .catch((err) => {
          // console.log(err);
        });
    });
  }

  // ######################################
  // FUNCTION: newU
  newU(i, ai) {
    this.addUser = true;
    this.fire.getLastBy("utenti", "codice").then((res) => {
      let newUser = {
        reparto: this.displayedData[i].id,
        area: this.displayedData[i].aree[ai].id,
        attivo: false,
        descrizione: "Nuovo utente",
        email: "",
        id: "U" + (res + 1),
        codice: res + 1,
        pwd: "",
        ultimo_accesso: {},
      };
      this.editCacheU[this.displayedData[i].id][this.displayedData[i].aree[ai].id][newUser.id] = {
        edit: true,
        data: { ...newUser },
      };
      this.displayedData[i].aree[ai].utenti.unshift(newUser);
      this.editU(this.displayedData[i].id, this.displayedData[i].aree[ai].id, newUser.id);
      this.onAdd = true;
      if (!this.openAreas[i][ai]) this.openusers(i, ai);
    });
  }

  // ######################################
  // FUNCTION: selectPermessi
  selectPermessi(event: any[], i: number, ai: number) {
    let area = this.reps[i].aree[ai];
    area.permessi = event;
    this.fire.update("reparti", "aree", area, this.reps[i].id, this.reps[i].aree[ai].id);
  }

  // ######################################
  // FUNCTION: deleteR
  deleteR(i: number) {
    let deleted = this.displayedData[i];
    this.fire
      .deleteDoc("reparti", this.displayedData[i].id)
      .then((rD) => {
        deleted.aree.forEach((a, aindex) => {
          //this.deleteA(i, aindex);
          let delArea = deleted.aree[aindex];
          this.fire
            .delete("reparti", "aree", deleted.id, deleted.aree[aindex].id)
            .then((rA) => {
              delArea.utenti.forEach((u, uindex) => {
                this.fire
                  .deleteDoc("utenti", delArea.utenti[uindex].id)
                  .then()
                  .catch((err) => {
                    // console.log(err);
                  });
              });
            })
            .catch((err) => {
              // console.log(err);
            });
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  // ######################################
  // FUNCTION: deleteA
  deleteA(i: number, ai: number) {
    this.fire
      .delete("reparti", "aree", this.displayedData[i].id, this.displayedData[i].aree[ai].id)
      .then((rA) => {
        this.displayedData[i].aree[ai].utenti.forEach((u, uindex) => {
          this.deleteU(i, ai, uindex);
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  // ######################################
  // FUNCTION: deleteU
  deleteU(i: number, ai: number, ui: number) {
    let id = this.displayedData[i].aree[ai].utenti[ui].id;
    this.fire
      .deleteDoc("utenti", id)
      .then()
      .catch((err) => {
        // console.log(err);
      });
  }

  // ######################################
  // FUNCTION: filter
  filter() {
    this.displayedData = this.reps.filter(
      (r) =>
        r.id.toUpperCase().includes(this.search.toUpperCase()) ||
        r.nome.toUpperCase().includes(this.search.toUpperCase())
    );
  }

  // ######################################
  // FUNCTION: resetPwd
  resetPwd(i: number, ai: number, ui: number) {
    this.recoverModal = true;
    this.username = this.displayedData[i].aree[ai].utenti[ui];
  }

  // ######################################
  // FUNCTION: showManualChange
  showManualChange() {
    this.manualChange = true;
    this.recoverModal = false;

    this.pwdForm = this.fb.group(
      {
        password: new UntypedFormControl("", [Validators.minLength(8)]),
        confirmPassword: new UntypedFormControl("", [Validators.required]),
      },
      { validators: this.checkPasswords }
    );
  }

  // ######################################
  // FUNCTION: checkPasswords
  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get("password")!.value;
    let confirmPass = group.get("confirmPassword")!.value;
    return pass === confirmPass ? null : { notSame: true };
  };

  // ######################################
  // FUNCTION: handleCancel
  handleCancel() {
    this.recoverModal = false;
    this.manualChange = false;
    this.username = "";
  }

  // ######################################
  // FUNCTION: recover
  recover() {
    const formData = {
      user: this.username.email,
    };

    this.node.recoverUser(formData).subscribe(
      (res) => {
        this.recoverModal = false;
        this.manualChange = false;
        this.username = "";
      },
      (err) => {
        this.recoverModal = false;
        this.manualChange = false;
        // console.log("ERR:", err);
      }
    );
  }

  // ######################################
  // FUNCTION: onSubmitManualChange
  async onSubmitManualChange() {
    this.showPwdError = false;
    if (!this.pwdForm.invalid) {
      this.username.pwd = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(this.pwdForm.value.password));
      const formData = {
        user: this.username,
      };
      this.node.editUser(formData).subscribe(
        () => {
          this.manualChange = false;
          this.recoverModal = false;
          this.g.createSuccessNotification("Password modificata con successo!", "");
        },
        (err) => {
          this.manualChange = false;
          this.recoverModal = false;
          this.g.createErrorNotification("Si è verificato un problema durante la modifica!", "");
          // console.log("ERR", err);
        }
      );
    } else {
      this.showPwdError = true;
    }
  }
}
