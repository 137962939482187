import { Location } from "@angular/common";
import { HttpClient, HttpEvent, HttpEventType, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { User } from "src/app/entities/users";
import { AuthService } from "src/app/services/auth.service";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "app-edit-questionari",
  templateUrl: "./edit-questionari.component.html",
  styleUrls: ["./edit-questionari.component.scss"],
})
export class EditQuestionariComponent implements OnInit {
  isCollapsed = false;
  rId: string = "";
  aId: string = "";
  lId: string = "";
  resData: any[] = [];
  displayedData: any[] = [];
  licenza: any = [];
  questionario: any = [];
  loading: boolean = true;
  search: string = "";
  repName: any;
  loggedIn: User;
  CryptoJS = require("crypto-js");
  newDests: any[] = [];
  scroll = { y: "" };
  dataExcel: any;

  @ViewChild("chart") chart: ElementRef;

  // ######################################
  // FUNCTION: constructor
  constructor(
    private location: Location,
    public auth: AuthService,
    private route: ActivatedRoute,
    private fire: FireService,
    private node: NodeService,
    public g: GlobalService,
    private translate: TranslateService,
    private router: Router,
    private http: HttpClient,
    private modal: NzModalService
  ) { }

  // ######################################
  // EVENT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiGestioneEditTableContainer").length > 0) {
      let height = document.getElementsByClassName("uiGestioneEditTableContainer")[0].clientHeight;
      if (
        document.getElementsByClassName("uiGestioneEditTableContainer")[0].getElementsByClassName("ant-table-thead")
          .length
      ) {
        let headH = document
          .getElementsByClassName("uiGestioneEditTableContainer")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }

  // ######################################
  // FUNCTION: ngAfterViewInit
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant("questManagment");
    });
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    this.loggedIn = this.auth.getUser();

    this.route.params.subscribe((param) => {
      this.rId = param.rid;
      this.aId = param.aid;
      this.lId = param.lid;
    });

    this.fire.getDocOnce("clienti", this.aId, "licenze", this.lId).subscribe((licenza) => {
      this.licenza = licenza.data();
      this.fire.get("questionari", this.licenza.questionario).subscribe((questionario) => {
        this.questionario = questionario;

        this.fire.getDocument("clienti", this.aId, "reparti", this.rId).subscribe((res) => {
          this.repName = res;
          this.fire.getSubCollection("clienti", this.aId, "reparti", this.rId, "utenti").subscribe((utenti) => {
            this.loading = false;
            utenti.forEach((u) => {
              this.fire
                .getSubDoc("clienti", this.aId, "licenze", this.lId, "compilazioni", u.reparto + u.id)
                .subscribe((res2) => {
                  if (res2 && (res2.sent || res2.concluso === true || res2.concluso === false || res2.added === true)) {
                    u.compilazione = res2;
                    const index = this.resData.findIndex((r) => r.id === u.id);
                    if (index < 0) {
                      this.resData.push(u);
                      this.displayedData = [...this.resData];
                    }
                  } else {
                    this.newDests.push(u);
                  }
                });
            });
          });
        });
      });
    });
  }

  // ######################################
  // FUNCTION: filter
  filter() {
    this.displayedData = this.resData.filter((r) => r.email.toUpperCase().includes(this.search.toUpperCase()));
  }

  // ######################################
  // FUNCTION: back
  back() {
    this.location.back();
  }

  // ######################################
  // FUNCTION: addValutazione
  addValutazione(id, compilazioneId) {
    this.router.navigateByUrl("admin/gestione/valutazioni/edit/" + this.aId + "/" + this.lId + "/" + compilazioneId);
  }

  // ######################################
  // FUNCTION: export
  export(dataV) {
    this.g.chartExport(dataV, this.aId, this.lId);
  }

  excelExport(dataV, comment = false) {
    this.g.excelExport(dataV, this.aId, this.lId, comment);
  }

  excelModal(data, tplFooter: TemplateRef<{}>) {

    this.dataExcel = data;

    this.modal.create({
      nzFooter: tplFooter,
      nzCentered: true,
      nzClassName: 'text-center'
    });
  }

  // ######################################
  // FUNCTION: selectDest
  utentiEmail: any[] = [];
  utentiAllChecked: boolean = false;
  utentiIntChecked: boolean = false;

  selectDest(tplUserEmail: TemplateRef<{}>, tplFooter: TemplateRef<{}>) {
    if (this.newDests.length > 0) {
      this.utentiEmail = [];
      this.utentiAllChecked = false;
      this.utentiIntChecked = false;

      this.newDests.forEach((u) => {
        this.utentiEmail.push({ ...{ check: false }, ...u });
      });

      this.modal.create({
        nzContent: tplUserEmail,
        nzFooter: tplFooter,
        nzMaskClosable: false,
        nzClosable: false,
        nzCentered: true,
        nzComponentParams: {
          value: "Template Context",
        },
      });
    } else {
      this.g.createErrorNotification(
        "Nessun destinatario disponibile",
        "Non ci sono nuovi destinatari a cui inviare l'email."
      );
    }
  }

  // ######################################
  // FUNCTION: onAllChecked
  onAllChecked(checked: boolean): void {
    this.utentiEmail.forEach((u) => (u.check = checked));
    this.refreshCheckedStatus();
  }

  // ######################################
  // FUNCTION: refreshCheckedStatus
  refreshCheckedStatus(): void {
    this.utentiAllChecked = this.utentiEmail.every((item) => item.check === true);
    if (!this.utentiAllChecked) this.utentiIntChecked = this.utentiEmail.some((item) => item.check === true);
    else this.utentiIntChecked = false;
  }

  // ######################################
  // FUNCTION: utentiSend
  isSending: boolean = false;

  utentiSend(ref) {
    var isError: boolean = false;

    this.fire.getOnce("clienti", this.aId).subscribe((azienda) => {
      azienda = azienda.data();
      this.fire.getDocOnce("clienti", this.aId, "licenze", this.lId).subscribe(async (licenza) => {
        licenza = licenza.data();

        this.isSending = true;
        // console.log("EMAIL: INIZIO INVIO");

        for (const user of this.utentiEmail) {
          if (user.check) {
            var res = await this.g.userSendMail(azienda, licenza, user);
            //// console.log("EMAIL: ESITO", res);
            if (res == "STOP") {
              this.isSending = false;
              return;
            } else if (res) {
              user.check = false;
              this.refreshCheckedStatus();
              this.newDests.splice(user, 1);
            } else isError = true;
          }
        }

        // console.log("EMAIL: FINE INVIO");
        this.isSending = false;
        if (!isError) ref.close();
      });
    });
  }

  // ######################################
  utenteSend(user) {
    this.fire.getOnce("clienti", this.aId).subscribe((azienda) => {
      azienda = azienda.data();
      this.fire.getDocOnce("clienti", this.aId, "licenze", this.lId).subscribe((licenza) => {
        licenza = licenza.data();
        this.g.userSendMail(azienda, licenza, user, this.questionario.anonimo);
      });
    });
  }
}
