import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginGuard } from "./guards/login.guard";
import { AdminRoutingModule } from "./pages/admin/admin-routing.module";
import { QuestionarioRoutingModule } from "./pages/admin/designer/questionario/questionario-routing.module";
import { EditAggregatiRoutingModule } from "./pages/admin/gestione/aggregati/edit-aggregati/edit-aggregati-routing.module";
import { EditPrivacyRoutingModule } from "./pages/admin/gestione/privacy/edit-privacy/edit-privacy-routing.module";
import { EditQuestionariRoutingModule } from "./pages/admin/gestione/questionari/edit-questionari/edit-questionari-routing.module";
import { EditValutazioniRoutingModule } from "./pages/admin/gestione/valutazioni/edit-valutazioni/edit-valutazioni-routing.module";
import { ClienteRoutingModule } from "./pages/cliente/cliente-routing.module";
import { ClienteHomepageRoutingModule } from "./pages/cliente/homepage/homepage-routing.module";
import { EditRoutingModule } from "./pages/cliente/homepage/licenze/edit/edit-routing.module";
import { ShowValutazioniRoutingModule } from "./pages/cliente/homepage/licenze/analysis/show-valutazioni-routing.module";
import { CompilazioneRoutingModule } from "./pages/compilazione/compilazione-routing.module";
import { LoginComponent } from "./pages/login/login.component";
import { RecoverComponent } from "./pages/recover/recover.component";
import { ShowValutazioniComponent } from "./pages/cliente/homepage/licenze/analysis/show-valutazioni.component";

const routes: Routes = [
  { path: "admin/login", component: LoginComponent, canActivate: [LoginGuard], canActivateChild: [LoginGuard] },
  { path: "recupera/:code", component: RecoverComponent, canActivate: [LoginGuard], canActivateChild: [LoginGuard] },
  { path: 'valutazioni', component: ShowValutazioniComponent },
  { path: "**", pathMatch: "full", redirectTo: "/" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CompilazioneRoutingModule,
    AdminRoutingModule,
    ClienteRoutingModule,
    ClienteHomepageRoutingModule,
    EditRoutingModule,
    ShowValutazioniRoutingModule,
    QuestionarioRoutingModule,
    EditValutazioniRoutingModule,
    EditQuestionariRoutingModule,
    EditPrivacyRoutingModule,
    EditAggregatiRoutingModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
