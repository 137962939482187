import { Injectable } from "@angular/core";

export interface Impostazioni {
  domandeSalvataggioDifferito: number,
  timeoutSpinner: number;
}

@Injectable({
  providedIn: "root",
})
export class InterfaceService {
  adminInterface: any;
  designerInterface: any;

  utentiInterface: any;
  clientiInterface: any;
  pubblicazioneInterface: any;

  questionariInterface: any;
  aggregatiInterface: any;
  valutazioniInterface: any;

  // ######################################
  // FUNCTION: constructor
  constructor() { }

  // ######################################
  // FUNCTION: openChange
  openChange(event: any, face: string, element: string, element2: string = "") {
    if (face === "ADMIN") {
      this.adminInterface[element] = event;
      this.saveAdminInterface(this.adminInterface);
    } else if (face === "DESIGNER") {
      this.designerInterface[element] = event;
      this.saveDesignerInterface(this.designerInterface);
    } else if (face === "UTENTI") {
      if (element2 === "") {
        this.utentiInterface[element].open = event;
        this.saveUtentiInterface(this.utentiInterface);
      } else {
        this.utentiInterface[element].sub[element2] = event;
        this.saveUtentiInterface(this.utentiInterface);
      }
    } else if (face === "CLIENTI") {
      if (element2 === "") {
        this.clientiInterface[element].open = event;
        this.saveClientiInterface(this.clientiInterface);
      } else {
        this.clientiInterface[element].sub[element2] = event;
        this.saveClientiInterface(this.clientiInterface);
      }
    } else if (face === "PUBBLICAZIONE") {
      this.pubblicazioneInterface[element] = event;
      this.savePubblicazioneInterface(this.pubblicazioneInterface);
    } else if (face === "QUESTIONARI") {
      if (element2 === "") {
        this.questionariInterface[element].open = event;
        this.saveQuestionariInterface(this.questionariInterface);
      } else {
        this.questionariInterface[element].sub[element2] = event;
        this.saveQuestionariInterface(this.questionariInterface);
      }
    } else if (face === "AGGREGATI") {
      if (element2 === "") {
        this.aggregatiInterface[element].open = event;
        this.saveAggregatiInterface(this.aggregatiInterface);
      } else {
        this.aggregatiInterface[element].sub[element2] = event;
        this.saveAggregatiInterface(this.aggregatiInterface);
      }
    } else if (face === "VALUTAZIONI") {
      if (element2 === "") {
        this.valutazioniInterface[element].open = event;
        this.saveValutazioniInterface(this.valutazioniInterface);
      } else {
        this.valutazioniInterface[element].sub[element2] = event;
        this.saveValutazioniInterface(this.valutazioniInterface);
      }
    }
  }

  // ADMIN MENU

  // ######################################
  // FUNCTION: saveAdminInterface
  saveAdminInterface(face: any) {
    this.adminInterface = face;
    localStorage.setItem("AmajorAdminInterface", JSON.stringify(face));
  }

  // ######################################
  // FUNCTION: getAdminInterface
  getAdminInterface() {
    let temp: string | null = localStorage.getItem("AmajorAdminInterface");
    if (temp) {
      this.adminInterface = JSON.parse(temp);
      return JSON.parse(temp);
    } else {
      this.adminInterface = JSON.parse("{}");
      return null;
    }
  }

  //DESIGNER TABLE

  // ######################################
  // FUNCTION: saveDesignerInterface
  saveDesignerInterface(face: any) {
    this.designerInterface = face;
    localStorage.setItem("AmajorDesignerInterface", JSON.stringify(face));
  }

  // ######################################
  // FUNCTION: getDesignerInterface
  getDesignerInterface() {
    let temp: string | null = localStorage.getItem("AmajorDesignerInterface");
    if (temp) {
      this.designerInterface = JSON.parse(temp);
      return JSON.parse(temp);
    } else {
      this.designerInterface = JSON.parse("{}");
      return null;
    }
  }

  //UTENTI TABLE

  // ######################################
  // FUNCTION: saveUtentiInterface
  saveUtentiInterface(face: any) {
    this.utentiInterface = face;
    localStorage.setItem("AmajorUtentiInterface", JSON.stringify(face));
  }

  // ######################################
  // FUNCTION: getUtentiInterface
  getUtentiInterface() {
    let temp: string | null = localStorage.getItem("AmajorUtentiInterface");
    if (temp) {
      this.utentiInterface = JSON.parse(temp);
      return JSON.parse(temp);
    } else {
      this.utentiInterface = JSON.parse("{}");
      return null;
    }
  }

  //CLIENTI TABLE

  // ######################################
  // FUNCTION: saveClientiInterface
  saveClientiInterface(face: any) {
    this.clientiInterface = face;
    localStorage.setItem("AmajorClientiInterface", JSON.stringify(face));
  }

  // ######################################
  // FUNCTION: getClientiInterface
  getClientiInterface() {
    let temp: string | null = localStorage.getItem("AmajorClientiInterface");
    if (temp) {
      this.clientiInterface = JSON.parse(temp);
      return JSON.parse(temp);
    } else {
      this.clientiInterface = JSON.parse("{}");
      return null;
    }
  }

  //PUBBLICAZIONE TABLE

  // ######################################
  // FUNCTION: savePubblicazioneInterface
  savePubblicazioneInterface(face: any) {
    this.pubblicazioneInterface = face;
    localStorage.setItem("AmajorPubblicazioneInterface", JSON.stringify(face));
  }

  // ######################################
  // FUNCTION: getPubblicazioneInterface
  getPubblicazioneInterface() {
    let temp: string | null = localStorage.getItem("AmajorPubblicazioneInterface");
    if (temp) {
      this.pubblicazioneInterface = JSON.parse(temp);
      return JSON.parse(temp);
    } else {
      this.pubblicazioneInterface = JSON.parse("{}");
      return null;
    }
  }

  //QUESTIONARI TABLE

  // ######################################
  // FUNCTION: saveQuestionariInterface
  saveQuestionariInterface(face: any) {
    this.questionariInterface = face;
    localStorage.setItem("AmajorQuestionariInterface", JSON.stringify(face));
  }

  // ######################################
  // FUNCTION: getQuestionariInterface
  getQuestionariInterface() {
    let temp: string | null = localStorage.getItem("AmajorQuestionariInterface");
    if (temp) {
      this.questionariInterface = JSON.parse(temp);
      return JSON.parse(temp);
    } else {
      this.questionariInterface = JSON.parse("{}");
      return null;
    }
  }

  //AGGREGATI TABLE

  // ######################################
  // FUNCTION: saveAggregatiInterface
  saveAggregatiInterface(face: any) {
    this.aggregatiInterface = face;
    localStorage.setItem("AmajorAggregatiInterface", JSON.stringify(face));
  }

  // ######################################
  // FUNCTION: getAggregatiInterface
  getAggregatiInterface() {
    let temp: string | null = localStorage.getItem("AmajorAggregatiInterface");
    if (temp) {
      this.aggregatiInterface = JSON.parse(temp);
      return JSON.parse(temp);
    } else {
      this.aggregatiInterface = JSON.parse("{}");
      return null;
    }
  }

  //VALUTAZIONI TABLE

  // ######################################
  // FUNCTION: saveValutazioniInterface
  saveValutazioniInterface(face: any) {
    this.valutazioniInterface = face;
    localStorage.setItem("AmajorValutazioniInterface", JSON.stringify(face));
  }

  // ######################################
  // FUNCTION: getValutazioniInterface
  getValutazioniInterface() {
    let temp: string | null = localStorage.getItem("AmajorValutazioniInterface");
    if (temp) {
      this.valutazioniInterface = JSON.parse(temp);
      return JSON.parse(temp);
    } else {
      this.valutazioniInterface = JSON.parse("{}");
      return null;
    }
  }
}
