import { browser, element } from "protractor";
import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation, ElementRef } from "@angular/core";
import { Location } from "@angular/common";
import { Data, Router } from "@angular/router";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";
import { User } from "src/app/entities/users";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { NzButtonSize } from "ng-zorro-antd/button";
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from "ng-zorro-antd/table";
import { ExcelService } from "./excel.service";
import * as Highcharts from 'highcharts';
import HC_dilldown from 'highcharts/modules/drilldown';
import { firstValueFrom } from "rxjs";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { SeriesOptionsType } from "highcharts";
import ExportingModule from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';

ExportData(Highcharts);
HC_dilldown(Highcharts);
ExportingModule(Highcharts);


/*
TODO: 
npm run build --prod --output-hashing=all

- Fix:
	disabilitare invia se superato la data di scadenza
	db -> rimuovi prefisso 
	

- Implementazione:
	dati tabella: 
		- filtro per nome
		- ordine alfabetico
	numero estero -> modificare re numeri telefonici
	check excel ->  dei dati
	inserimento tutorial
	
*/



interface DataItem {
	name: string;
	nameId: string;
	role: string;
	roleCode: string;
	address: string;
	isComplete: boolean,
	tratti: any[],
	isSended: boolean,
	isInProgress: boolean,
	stateCompilation: number,
	comments: string,
	status: string
}

interface ColumnItem {
	name: string;
	sortOrder: NzTableSortOrder | null;
	sortFn: NzTableSortFn<DataItem> | null;
	listOfFilter: NzTableFilterList;
	filterFn: NzTableFilterFn<DataItem> | null;
	filterMultiple: boolean;
	sortDirections: NzTableSortOrder[];
}




@Component({
	selector: "app-show-valutazioni",
	templateUrl: "./show-valutazioni.component.html",
	encapsulation: ViewEncapsulation.None,
	styleUrls: ["./show-valutazioni.component.scss"],
})
export class ShowValutazioniComponent implements OnInit {
	@ViewChild("chart") gChart: any;
	receivedObject: any;
	search: string = '';
	isCollapsed = false;
	aId: string = '';
	lId: string = '';
	cId: string = '';
	size: NzButtonSize = 'large';
	CryptoJS = require("crypto-js");

	loggedIn: User;
	data: any[] = [];
	displayedData: any[] = [];
	intestazione: any[] = [];
	questionario: any[] = [];
	loading: boolean = false;
	logo: SafeResourceUrl;
	loadingChart: boolean = true;
	valText: string = '';
	scroll: object;

	bSave: boolean = false;

	uniqueRoles: string[] = [];
	showedUniqueRoles: string[] = [];

	licenzeDisponibili: number = 0;
	listOfColumns: ColumnItem[];
	listOfColumnName: string[] = ['Nome', 'Email/Cellulare', 'Reparto', 'Stato Questionario', 'Azioni'];
	listStateOfQuestionaire: string[] = ['Link da Inviare', 'Da Iniziare', 'In Compilazione', 'Completato'];

	emailOrPhonePattern = '^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}|\\+\\d{12})$';

	// FILTERS
	listStateOfQuestionaireFilterToSend = this.listStateOfQuestionaire.map(item => (item === this.listStateOfQuestionaire[0] ? { text: item, value: item, byDefault: true } : { text: item, value: item }));
	listStateOfQuestionaireFilter = this.listStateOfQuestionaire.map(item => ({ text: item, value: item }));
	listRepartOfQuestionaireFilter: any[] = [];

	functionListStateOfQuestionaireFilter = (list: string[], item: DataItem) => list.some(name => item.status.indexOf(name) !== -1);
	functionListRepartsOfQuestionaireFilter = (list: string[], item: DataItem) => list.some(name => item.role.indexOf(name) !== -1);

	editingRow: number | null = null;
	isVisibleSendQuestionnaires = false;
	isVisibleElaboratorQuestionnaires = false;
	checked = false;
	indeterminate = false;
	listOfCurrentPageData: readonly Data[] = [];
	setOfCheckedId = new Set<string>();
	userAzienda: string;
	nameAnonimo = 'Anonimo'

	// progress send para.
	percentSend: number = 0;
	pecentSendString: string = '';
	showProgressSend = false;

	allElements: DataItem[];
	listOfData: DataItem[];
	listOfDataToSend: DataItem[];
	completeQuestionnaires: DataItem[];

	navigation;

	// Dashboard statistic
	numberOfComplete: number;
	numberOfSend: number;
	numberOfInProgress: number;
	nuberOfLicenceUsed: number;

	// manage user
	user: any;
	reps: any[] = [];


	// 
	isClima: boolean = false;
	trattiResultElaboration: any[] = [];

	//Chart
	Highcharts: typeof Highcharts = Highcharts;
	chartOptions: Highcharts.Options;
	commentChart: string = '';
	backUpListOfData: DataItem[];

	isClickedNewUser: boolean = false;
	fileterClima: string[];
	listOfSelectedFilterClima: string[] = [];
	countClimaCompilation: any;
	hasListOfDataToSend: boolean;
	isInEditing: boolean = false;


	countLinkToSend: number = 0;
	textSend: string;

	// ######################################
	// FUNCTION: constructor
	constructor(
		private sanitizer: DomSanitizer,
		private location: Location,
		public auth: AuthClienteService,
		private fire: FireService,
		private node: NodeService,
		public g: GlobalService,
		private excelService: ExcelService,
		private router: Router,
		private notification: NzNotificationService,
	) {
		this.navigation = this.router.getCurrentNavigation();
		this.updateScrollValue();


	}

	updateScrollValue() {
		// Apply the custom formula to calculate the scroll value
		this.scroll = { y: `${290 + 290 * (1 - (window.outerWidth / window.innerWidth))}px` };
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.updateScrollValue();
	}

	setDashBoardStatistic() {
		this.uniqueRoles = this.receivedObject.reps.map(department => department.nome).filter(item => item !== undefined);
		this.showedUniqueRoles = this.uniqueRoles.map(item => item.split('@')[0].trim())
		this.licenzeDisponibili = (this.receivedObject.totali - this.receivedObject.utilizzate);
		this.nuberOfLicenceUsed = this.receivedObject.totali - this.licenzeDisponibili;
		this.numberOfComplete = this.receivedObject.reps.reduce((sum, department) => sum + department.concluse, 0);
		this.numberOfSend = this.receivedObject.reps.reduce((sum, department) => sum + department.inviate, 0);
		this.numberOfInProgress = this.receivedObject.reps.reduce((sum, department) => sum + department.inCorso, 0);
		this.isClima = this.receivedObject?.quest?.titolo.toLowerCase().includes('clima');
		if (this.isClima)
			this.computeClima();
	}

	// AMAJOR FUNCTION
	computeClima(): void {
		let trattiClima = this.receivedObject.vers.tratti;
		let completeQuestionnaires = this.receivedObject.compilazioni;
		this.trattiResultElaboration = [];

		if (this.listOfSelectedFilterClima.length > 0) {
			let roleCodeList: string[] = [];
			this.listOfSelectedFilterClima.forEach(item => {
				let matchedElement = this.allElements.find(elem => elem.role === item);
				if (matchedElement)
					roleCodeList.push(matchedElement.roleCode);
			});
			completeQuestionnaires = completeQuestionnaires.filter(item => roleCodeList.includes(item.licenza.reparto));
		}
		this.countClimaCompilation = completeQuestionnaires.length;

		trattiClima.forEach((t, _: number) => {
			let pointsTratto = 0;

			completeQuestionnaires.forEach(complete => {
				complete.domande.forEach(({ domanda, risposta }) => {
					const match = t.punteggi[domanda].domanda.risposte.find(r => r.codice === risposta);
					if (match) {
						pointsTratto += Number(match.valore);
					}
				});
			});

			const totalQuestionnaires = completeQuestionnaires.length;
			const scaledPoints = ((pointsTratto - t.min * totalQuestionnaires) / (t.max * totalQuestionnaires - t.min * totalQuestionnaires)) * 200 - 100
			this.trattiResultElaboration.push({ [t.codice]: Math.round(scaledPoints) });

		});
	}

	rigenerateClima() {
		let isValidCompilation = (this.allElements.filter(element => {
			const selectedRole = this.listOfSelectedFilterClima[0];
			const validStatuses = this.listStateOfQuestionaire.slice(1)
			return element.role === selectedRole && validStatuses.includes(element.status);
		})).length >= 3;

		if ((this.listOfSelectedFilterClima.length === 1 && !isValidCompilation)) {

			this.notification.create(
				'error',
				'Elaborazione Errata',
				`Non è possibile elaborare il clima del reparto "${this.listOfSelectedFilterClima[0]}" perché è necessario inviare il link ad almeno 3 destinatari.`)

			this.listOfSelectedFilterClima = this.listOfSelectedFilterClima.slice(0, -1);
		}
		else {
			this.computeClima();
			this.generateChart();
		}
	}

	generateChart(dataUser?: DataItem) {
		this.commentChart = dataUser?.comments.replace(/<\/?p>/g, '') ?? '';
		let categoriesList: string[] = [];
		let titleQuestionnaires: string;
		const logoTitle: string = `<img class="chartLogo" src="../../../assets/logo.svg" alt="Amajor">`;;
		let inputData: Array<SeriesOptionsType>;


		if (this.isClima) {
			let repartClimaActive = this.listOfSelectedFilterClima.length > 0 ? this.listOfSelectedFilterClima : this.uniqueRoles.filter(item => item !== 'Tutti');
			titleQuestionnaires = '<div class="chart-header"> <p>Reparti: ' + repartClimaActive.join(' | ') + ' </p>' +
				'<p>' + new Date().toLocaleDateString() + ' - Numero Completati: ' + this.countClimaCompilation + '</p> </div>';

			categoriesList = [
				'Gestione del Personale', 'Etica', 'Organizzazione', 'Comunicazione Verticale', 'Comunicazione Orizzontale', 'Ambiente', 'Clima', 'Parità di Genere'
			]
			let trattiElaborated: any[] = this.trattiResultElaboration.map(item => Object.values(item)[0]);
			let markerColors = ['red', 'red', 'blue', 'blue', 'blue', 'green', 'yellow', 'yellow'];
			inputData = [{
				type: 'line',
				name: '',
				marker: {
					symbol: 'triangle',
					radius: 6,
					lineColor: '#666666',
					lineWidth: 1,

				},
				dataLabels: {
					enabled: true
				},
				lineWidth: 1,
				dashStyle: 'Dash',
				color: 'black',
				data: trattiElaborated.map((value, index) => ({
					y: value,
					marker: {
						fillColor: markerColors[index % markerColors.length] // Assign marker color based on index
					}
				})),
				showInLegend: false,
			}]
		}

		else {
			categoriesList = [
				'Alinemaneto Valoriale', 'Gestione Scambio', 'Coerenza', 'Sabilità e Controllo', 'Sociolevolezza', 'Comprensione', 'Propositività', 'Visiona Futura', 'Carisma',
				'Costruzione e Gestione Team', 'Gestione Difficoltà Commando', 'Vendite', 'Finanze Prodottività', 'Automotivazione', ' Problem Solving', 'Focus Ordine', 'Affidabilità', 'Affermazione'
			];

			titleQuestionnaires = `<p> ${new Date().toLocaleDateString()} - Nominativo: ${dataUser?.name}</p>`;

			inputData = [{
				type: 'column',
				name: '',
				borderRadius: 2,
				colorByPoint: true,
				dataLabels: {
					enabled: true
				},
				colors: [
					'red', 'red', 'red', 'red',
					'orange', 'orange', 'orange',
					'blue', 'blue', 'blue', 'blue', 'blue', 'blue',
					'green', 'green', 'green', 'green',
					'yellow'
				],
				data: dataUser?.tratti.map(item => item.punteggio).slice(0, categoriesList.length),
				showInLegend: false
			}];
		}

		this.chartOptions = {
			lang: {
				exitFullscreen: "Esci",
				viewFullscreen: "Mostra tutto schermo",
				downloadPDF: "Scarica PDF"
			},
			title: {
				text: logoTitle,
				style: {
					width: 200,
					height: 100
				},

				useHTML: true,
				align: 'left'
			},
			subtitle: {
				text: titleQuestionnaires,
				useHTML: true,
				align: 'right'
			},
			responsive: {
				rules: [{
					condition: {
						maxWidth: 600,
						maxHeight: 400
					}
				}]
			},
			xAxis: {
				categories: categoriesList,
				labels: {
					rotation: -90,
					align: 'right',
					useHTML: true
				}
			},
			yAxis: {
				min: -100,
				max: 100,
				title: {
					text: ''
				}
			},
			series: inputData,
			credits: {
				enabled: false
			},
			exporting: {
				enabled: true,
				allowHTML: true,
				fallbackToExportServer: false,
				filename: `Amajor - ${dataUser?.name}`,
				buttons: {
					contextButton: {
						menuItems: ["viewFullscreen", "downloadPDF"]
					}
				},
				chartOptions: {
					title: {
						text: 'Amajor SB ',

						align: 'left'
					},
				}
			}
		};

	}


	getStatusMessage(data: any): string {
		if (data.isSended) {
			if (data.isComplete) {
				return this.listStateOfQuestionaire[3];
			} else {
				return data.isInProgress ? this.listStateOfQuestionaire[2] : this.listStateOfQuestionaire[1];
			}
		} else {
			return this.listStateOfQuestionaire[0];
		}
	}


	async fetchData(uniqueRoles): Promise<DataItem[]> {
		try {
			let users: any[] = [];
			let userFinal: any[] = [];
			const repartiRes = await firstValueFrom(this.fire.getCollection('clienti', this.userAzienda, 'reparti'));

			for (const item of repartiRes) {
				const subUsers = await firstValueFrom(this.fire.getSubCollection('clienti', this.userAzienda, 'reparti', item.id, 'utenti'));
				subUsers.idOriginal = subUsers.id;
				users = users.concat(subUsers);
			}

			for (let user of users) {
				let licenzaResult = [];
				await firstValueFrom(this.fire.getSubDoc('clienti', this.user.azienda, 'licenze', this.receivedObject.id, 'compilazioni', user.reparto + user.id)).then(res => {
					licenzaResult = res as any;
				})

				if (licenzaResult) {
					delete (licenzaResult as any).id;
				}

				userFinal.push({ ...user, ...licenzaResult })
			}

			const manageCompleteEmail = (text: string, isComplete: boolean) => {
				return isComplete ? text + '  ' : text;
			}

			let dataMerged: DataItem[] = userFinal.map(user => ({
				name: user.descrizione,
				nameId: user.id,
				role: repartiRes.find(item => item.id === user.reparto)?.nome ?? '',
				roleCode: user.reparto,
				address: user.email ? manageCompleteEmail(user.email, user.concluso) : manageCompleteEmail(user.mobile, user.concluso),
				isComplete: user.concluso ?? false,
				tratti: user.tratti ?? [],
				isSended: user.concluso || (user.sent ?? false) || (user.questionario !== undefined),
				isInProgress: user.questionario !== undefined,
				stateCompilation: user.concluso ? 1.0 :
					(user.compilazione !== undefined && user.compilazione.last !== undefined && user.compilazione?.last > 0) ?
						(user.compilazione.last / user.questionario.domande.length) :
						0.0,
				comments: user.commento ?? '',
				status: ''
			}));

			dataMerged = dataMerged.filter(dataItem =>
				uniqueRoles.some(role => dataItem.role === role));

			dataMerged.forEach(user => {
				user.status = this.getStatusMessage(user)
			})

			return Promise.resolve(dataMerged); // Ensure we return a Promise that resolves with dataMerged
		} catch (error) {
			console.error('Error fetching data:', error);
			return Promise.resolve([]); // Return a resolved Promise with an empty array in case of error
		}
	}



	// ######################################
	// FUNCTION: ngOnInit
	async ngOnInit(): Promise<void> {
		this.loading = true;

		if (this.navigation?.extras.state) {
			this.receivedObject = this.navigation.extras.state['object'];

			this.setDashBoardStatistic();

			this.user = this.auth.getUser();
			this.userAzienda = this.user.azienda;
			this.allElements = await this.fetchData(this.uniqueRoles);
			this.listOfCurrentPageData = this.allElements;
			this.filterByRepart('Tutti');

		}
		else {
			this.back();
		}

		// Extract all roles from listOfData and filter out empty strings
		const allRoles = this.uniqueRoles

		this.listRepartOfQuestionaireFilter = allRoles.map(item => ({ text: item, value: item }));

		// Create a Set to extract unique roles (including 'Tutti')
		const uniqueRolesSet = new Set(allRoles);


		uniqueRolesSet.add('Tutti'); // Add 'Tutti' to the Set of unique roles
		// Convert the Set back to an array and assign it to this.uniqueRoles
		this.uniqueRoles = [...uniqueRolesSet].reverse()

		this.fileterClima = this.uniqueRoles.filter(item => item !== 'Tutti');

		this.loading = false;

	}

	// ######################################
	// FUNCTION: back
	back() {
		this.location.back();
	}

	isNotSelected(value: string): boolean {
		return this.listOfSelectedFilterClima.indexOf(value) === -1;
	}

	filterByRepart(reparto) {
		if (reparto === 'Tutti') {
			this.listOfData = this.allElements;
			this.numberOfComplete = this.receivedObject.reps.reduce((sum, department) => sum + department.concluse, 0);
			this.numberOfSend = this.receivedObject.reps.reduce((sum, department) => sum + department.inviate, 0);
			this.numberOfInProgress = this.receivedObject.reps.reduce((sum, department) => sum + department.inCorso, 0);
			this.nuberOfLicenceUsed = this.receivedObject.utilizzate;
		}
		else {
			this.listOfData = this.allElements.filter(d => d.role === reparto);
			let result = this.receivedObject.reps.find(row => row.nome === reparto);
			this.numberOfComplete = result.concluse;
			this.numberOfSend = result.inviate;
			this.numberOfInProgress = result.inCorso;
			this.nuberOfLicenceUsed = this.numberOfComplete + this.numberOfSend + this.numberOfInProgress;

		}



	}

	async deleteRow(rowIndex: number): Promise<void> {
		try {
			this.loading = true;
			const userToDelete = this.listOfData[rowIndex];
			if (userToDelete.roleCode && userToDelete.nameId)
				await this.fire.deleteSubDoc('clienti', 'reparti', 'utenti', this.userAzienda, userToDelete.roleCode, userToDelete.nameId);

			// Update the arrays
			this.allElements = this.allElements.filter(d => !(d.address === userToDelete.address && d.name === userToDelete.name));
			this.listOfData = this.listOfData.filter(d => !(d.address === userToDelete.address && d.name === userToDelete.name));
		} catch (error) {
			const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
			this.g.createErrorNotification("Errore durante la eliminazione dell'utente", errorMessage);
			console.error("Error deleting user:", error);
		}
		finally {
			this.loading = false;
		}
	}


	async confirmRow(rowIndex) {
		let isCorrectPatternEmailOrPhone = new RegExp(this.emailOrPhonePattern).test(this.listOfData[rowIndex].address);

		/* Implementation 24.6.20
			Consente di creare un Destinatario con lo stesso indirizzo di invio già esistente solo se il destinatario ha completato il test precedente.
		*/
		let isPresentAddressWithNotCompleteState: boolean = this.allElements.some((item, i) => i !== rowIndex && item.address.toLowerCase() === this.listOfData[rowIndex].address.toLowerCase() && !item.isComplete);

		if (this.listOfData[rowIndex].name && !isPresentAddressWithNotCompleteState && isCorrectPatternEmailOrPhone && this.listOfData[rowIndex].role) {
			this.loading = true;
			try {

				if (this.isClickedNewUser) {
					await this.createUser(rowIndex);
					this.isClickedNewUser = false;
				}
				else {
					await this.fire.getSubDocOnce('clienti', this.user.azienda, 'reparti', this.listOfData[rowIndex].roleCode, 'utenti', this.listOfData[rowIndex].nameId).subscribe(
						async response => {
							const dataUpdata = response.data();
							if (dataUpdata) {
								dataUpdata['descrizione'] = this.listOfData[rowIndex].name;
								if (this.listOfData[rowIndex].address.includes('@')) {
									dataUpdata['email'] = this.listOfData[rowIndex].address;
									dataUpdata['mobile'] = '';
								}
								else {
									dataUpdata['email'] = '';
									dataUpdata['mobile'] = this.listOfData[rowIndex].address;
								}

								dataUpdata['sendtype'] = !dataUpdata['email'];
								await this.fire.updateSubDoc('clienti', 'reparti', 'utenti', dataUpdata, this.user.azienda, this.listOfData[rowIndex].roleCode, this.listOfData[rowIndex].nameId)
							}
						}
					);
					this.editingRow = this.editingRow === rowIndex ? null : rowIndex;

				}
			} catch (error) {
				console.error('Error in confirmRow:', error);
			} finally {

				this.loading = false;
				this.isInEditing = true;
			}
		}
		else {
			this.notification.create(
				'error',
				'Inserimento dati Destinatario',
				isPresentAddressWithNotCompleteState
					? `L'indirizzo "${this.listOfData[rowIndex].address}" non può essere aggiunto perché non ha completato il precedente questionario.`
					: 'I dati inseriti sono mancanti o non corretti.'
			);
		}
		this.isInEditing = false;
	}

	async createUser(rowIndex: any): Promise<any> {
		let rowData;

		if (typeof rowIndex === 'number') {
			if (rowIndex < 0 || rowIndex >= this.listOfData.length) {
				throw new Error(`Invalid rowIndex: ${rowIndex}`);
			}
			rowData = this.listOfData[rowIndex];
		} else {
			rowData = rowIndex;
		}

		try {

			let nextUserId = 0;
			const roleCode = this.receivedObject.reps.find(item => item.nome === rowData.role)?.id ?? '';
			await this.fire.getLastSubSubBy('clienti', this.user.azienda, 'reparti', roleCode, 'utenti', 'codice').then(async (res) => {
				nextUserId = res + 1;
				const address = rowData.address;
				const isEmail = address.toString().includes('@');
				const emailUser = isEmail ? address : '';
				const phoneUser = isEmail ? '' : address;

				// important for send when create new user
				rowData.nameId = `U${nextUserId}`;
				rowData.roleCode = roleCode

				const newUser = {
					admin: false,
					attivo: true,
					codice: nextUserId,
					descrizione: rowData.name,
					email: emailUser,
					mobile: phoneUser,
					sendtype: Boolean(phoneUser),
					id: `U${nextUserId}`,
					pwd: "",
					ultimo_accesso: {},
					reparto: roleCode,
				};

				await this.fire.addSingleSubDoc('clienti', 'reparti', 'utenti', this.user.azienda, roleCode, newUser);

				if (typeof rowIndex === 'number') {
					this.editingRow = this.editingRow === rowIndex ? null : rowIndex;
				}

			})
		} catch (error) {
			const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
			if (typeof rowIndex === 'number') {
				this.g.createErrorNotification("Errore durante la creazione dell'utente", errorMessage);
			}
			console.error("Error creating user:", error);
			// Ensure the error is propagated to the caller
		}
	}

	toggleEditMode(rowIndex: number) {
		this.isInEditing = true;
		this.backUpListOfData = JSON.parse(JSON.stringify(this.listOfData));
		this.editingRow = this.editingRow === rowIndex ? null : rowIndex;
	}

	resetRow(rowIndex: number) {
		if ((!this.listOfData[rowIndex].address && !this.listOfData[rowIndex].name && !this.listOfData[rowIndex].role) || this.isClickedNewUser) {
			this.deleteRow(rowIndex);
		} else {
			this.listOfData = this.backUpListOfData;
		}


		this.editingRow = this.editingRow = this.editingRow === rowIndex ? null : rowIndex;
		this.isClickedNewUser = false;
		this.isInEditing = false;
	}

	downloadExcel() {
		const filteredData = this.allElements.filter(item => item.name && item.address);
		this.excelService.downloadExcel(filteredData, this.receivedObject?.quest?.titolo, this.listOfColumnName.slice(0, 2), this.uniqueRoles.filter(item => item !== 'Tutti'));
	}

	handleImport() {
		const fileInput = document.querySelector('input[type="file"]');

		if (fileInput) {
			(fileInput as HTMLInputElement).click();
		}
	}

	async onFileSelected(event: any, data) {
		const file: File = event.target.files[0];
		if (file) {
			await this.uploadExcel(file, data);
		}
	}


	async uploadExcel(file: File, data: DataItem[]) {
		try {
			this.loading = true;
			let uploadedData = await this.excelService.uploadExcel(file, this.listOfColumnName, this.listStateOfQuestionaire[0]) as DataItem[];
			uploadedData = uploadedData.filter(item => !new Set(data.map(item => item.address)).has(item.address));

			for (let user of uploadedData)
				await this.createUser(user);

			this.allElements = [...this.listOfData, ...uploadedData];
			// Update allElements with the uploaded data
			this.listOfData = this.allElements;
		} catch (error) {
			console.error('Error uploading Excel file:', error);
		} finally {
			this.loading = false;
		}
	}

	addClient() {
		if (!this.isClickedNewUser) {
			const newClient: DataItem = {
				name: '',
				nameId: '',
				role: '',
				roleCode: '',
				address: '',
				isComplete: false,
				tratti: [],
				isSended: false,
				isInProgress: false,
				stateCompilation: 0,
				comments: '',
				status: this.listStateOfQuestionaire[0]
			};


			this.allElements.unshift({ ...newClient });
			this.listOfData = [...this.allElements];
			this.editingRow = 0;
			this.isClickedNewUser = true;
		}
	}

	showModal(typeModal: string) {
		if (typeModal === 'sendQuestionnaires') {
			this.isVisibleSendQuestionnaires = true;
			this.listOfDataToSend = this.allElements;
			this.hasListOfDataToSend = this.listOfDataToSend.filter(item => item.status === this.listStateOfQuestionaire[0]).length > 0
		}
		else if (typeModal === 'elaboratorQuestionnaires') {
			this.completeQuestionnaires = this.allElements.filter(item => item.isComplete == true);
			if (this.completeQuestionnaires.length === 0) {
				this.notification.create(
					'warning',
					'Elaboratore Schede',
					'Non ci sono questionari completati'
				);
			}
			else {
				this.generateChart(this.completeQuestionnaires[0]);
				this.isVisibleElaboratorQuestionnaires = true;
				this.listOfDataToSend = this.listOfData;
			}
		}
		else {


		}
	}

	handleOk(typeModal: string): void {
		if (typeModal === 'sendQuestionnaires')
			this.isVisibleSendQuestionnaires = false;
		else
			this.isVisibleElaboratorQuestionnaires = false;
	}

	handleCancel(typeModal: string): void {
		this.setOfCheckedId = new Set<string>();

		this.showProgressSend = false;

		if (typeModal === 'sendQuestionnaires') {
			this.isVisibleSendQuestionnaires = false;
			this.listOfDataToSend = [];
		}
		else if (typeModal === 'elaboratorQuestionnaires') {
			this.isVisibleElaboratorQuestionnaires = false;
			this.listOfDataToSend = [];
		}
		else {


		}
	}

	updateCheckedSet(id: string, checked: boolean): void {
		if (checked) {
			this.setOfCheckedId.add(id);
		} else {
			this.setOfCheckedId.delete(id);
		}
	}

	onCurrentPageDataChange(listOfCurrentPageData: readonly Data[]): void {
		this.listOfCurrentPageData = listOfCurrentPageData;
		this.refreshCheckedStatus();
	}

	refreshCheckedStatus(): void {
		this.isActiveSend();
		const listOfEnabledData = this.listOfCurrentPageData.filter(item => item.isComplete !== true);
		this.checked = listOfEnabledData.every(({ address }) => this.setOfCheckedId.has(address));
		this.indeterminate = listOfEnabledData.some(({ address }) => this.setOfCheckedId.has(address)) && !this.checked;
	}

	onItemChecked(id: string, checked: boolean): void {
		this.updateCheckedSet(id, checked);
		this.refreshCheckedStatus();
	}

	onAllChecked(checked: boolean): void {
		this.listOfCurrentPageData
			.filter(item => this.isClima || item.isComplete !== true)
			.forEach(({ address }) => this.updateCheckedSet(address, checked));
		this.refreshCheckedStatus();
	}

	isActiveSend() {
		const filteredElements = this.allElements.filter(item => this.setOfCheckedId.has(item.address));
		this.countLinkToSend = filteredElements.filter(item => item.status === this.listStateOfQuestionaire[0]).length;
		const nameLicence = (this.countLinkToSend > 1) ? "Licenze" : "Licenza";

		if (this.setOfCheckedId.size < 1) {
			this.textSend = 'Seleziona i Destinatari';
		} else {
			let nameQuestionario = this.setOfCheckedId.size > 1 ? 'Questionari' : 'Questionario';
			this.textSend = `Stai per reinviare ${this.setOfCheckedId.size} ${nameQuestionario}.`;
		}

		if (this.countLinkToSend > 0) {
			this.textSend = (this.licenzeDisponibili - this.countLinkToSend >= 0) ?
				`Stai per utilizzare ${this.countLinkToSend} ${nameLicence} su ${this.licenzeDisponibili} Disponibili` :
				'Licenze Terminate!';
		}

		return this.setOfCheckedId.size === 0 || this.countLinkToSend > this.licenzeDisponibili;
	}

	async sendRequest(setAddress) {
		let copySetOfCheckedId = new Set<string>(this.setOfCheckedId);
		this.setOfCheckedId = new Set<string>();
		try {
			this.showProgressSend = true;
			let listAddress = Array.from(copySetOfCheckedId)
			let i = 0;
			let numeroLicenze = this.receivedObject.utilizzate;

			for (const [i, element] of listAddress.entries()) {
				let index = this.allElements.findIndex(item => item.address === element && !item.isComplete);
				if (index !== -1) {
					this.allElements[index].status = this.listStateOfQuestionaire[1];

					this.percentSend = Math.round(((i + 1) / listAddress.length) * 100) ?? 0;
					this.pecentSendString = `${i + 1}/${listAddress.length}` ?? '';

					let userIndex = this.listOfDataToSend.findIndex(item => item.address === element && !item.isComplete)

					try {
						numeroLicenze = await this.g.userSendLinkAmajor(this.userAzienda, this.receivedObject, this.listOfDataToSend[userIndex], numeroLicenze);
						this.listOfDataToSend[index].status = this.listStateOfQuestionaire[1];
					} catch (error) {
						console.error("Failed to send link and update license usage:", error);
					}

					this.listOfDataToSend[userIndex].status = this.listStateOfQuestionaire[1];

				}
			}

			let questionarioName = listAddress.length > 1 ? 'Questionari' : 'Questionario'
			this.notification.create('success', 'Invio Link', `Hai Inviato ${listAddress.length} ${questionarioName}!`);

			// UPDATE STATISTIC
			this.numberOfInProgress = this.allElements.filter(item => item.status === this.listStateOfQuestionaire[2]).length;
			this.numberOfSend = this.allElements.filter(item => item.status === this.listStateOfQuestionaire[1]).length;
			this.numberOfComplete = this.allElements.filter(item => item.status === this.listStateOfQuestionaire[3]).length;
			this.nuberOfLicenceUsed = numeroLicenze;
			this.licenzeDisponibili = (this.receivedObject.totali - this.receivedObject.utilizzate);


		}
		catch (error) {
			this.notification.create(
				'error',
				'Invio Questionari',
				'NON è stato possibile inviare il Questionario'
			);

		}
		finally {
			this.showProgressSend = false;
		}
	}

	filterByRepartOfSend(repart: string, isForComplete: boolean = false) {
		if (repart === 'Tutti')
			this.completeQuestionnaires = this.listOfDataToSend = isForComplete ? this.allElements.filter(item => item.isComplete === true) : this.allElements;
		else
			this.completeQuestionnaires = this.listOfDataToSend = this.allElements.filter(d => isForComplete ? (d.role === repart && d.isComplete) : d.role === repart);
	}

}
