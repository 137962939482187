<nz-layout class="uiPageInnerLayout">
    <nz-content class="uiUtentiContent">
        <div class="uiUtentiHeader">
            <div class="uiUtentiLeftHeader">
                <nz-input-group [nzPrefix]="prefixTemplateUser" class="uiSerchbar">
                    <input type="text" nz-input placeholder="{{'search' | translate}}" [(ngModel)]="search" (ngModelChange)="filter()" autocomplete="off" />
                </nz-input-group>
                <ng-template #prefixTemplateUser><i class="customIcon uiFilterIcon"></i></ng-template>
            </div>
            <div class="uiUtentiRightHeader">
                <button nz-button class="uiNewUserButton" (click)="newR()">
                    Nuovo reparto
                </button>
            </div>
        </div>

        <div class="uiUsersTableContainer">
            <nz-table *ngIf="!loading" class="uiUsersTable" #usersTable [nzData]="displayedData" [nzShowPagination]="false" [nzScroll]="scroll" [nzPageSize]="100" [nzWidthConfig]="['5%', '5%', '10%', '9%', '10%', '5%', '10%', '10%', '8%', '13%', '5%', '10%']">
                <thead>
                    <tr>
                        <th nzAlign="center" colspan="3">CODICE</th>
                        <th nzAlign="center" colspan="2">REPARTO - AREA</th>
                        <th nzAlign="center" colspan="3">PERMESSI</th>
                        <th nzAlign="center">UTENTI</th>
                        <th nzAlign="center">ULTIMO ACCESSO</th>
                        <th nzAlign="center" colspan="2">AZIONI</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of usersTable.data; let i = index">
                        <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                            <td nzAlign="center">
                                <i [ngClass]="openFolder[i] ? 'customIcon uiFolderOpenIcon': 'customIcon uiFolderIcon'" (click)="openArea(i)" style="margin: 0;" nz-tooltip [nzTooltipTitle]="openFolder[i] ? 'Chiudi il Reparto' : 'Apri il Reparto'"></i>
                            </td>
                            <td colSpan="2">
                                {{data.id}}
                            </td>
                            <td colSpan="5">
                                <div *ngIf="!editCacheR[data.id].edit">{{data.nome}}</div>
                                <div *ngIf="editCacheR[data.id].edit"><input type="text" nz-input [(ngModel)]="editCacheR[data.id].data.nome" /></div>
                            </td>
                            <td nzAlign="center">{{data.n_utenti}}</td>
                            <td></td>
                            <td nzAlign="center" [ngClass]="data.attivo ? 'uiOnCol':'uiOffCol'">
                                <nz-switch [ngModel]="data.attivo" (ngModelChange)="onChangeSwitchR($event, i)" nz-tooltip [nzTooltipTitle]="data.attivo ? 'Reparto attivo' : 'Reparto non attivo'">
                                </nz-switch>
                            </td>
                            <td nzAlign="center" *ngIf="!editCacheR[data.id].edit">
                                <i style="margin-right: 10px;" class="customIcon uiTrashGreyIcon" nz-popconfirm nzPopconfirmTitle="Eliminare questa riga?" (nzOnConfirm)="deleteR(i)" nz-tooltip nzTooltipTitle="Elimina reparto"></i>
                                <i style="margin-right: 10px;" class="customIcon uiAddIcon" (click)="newA(i)" nz-tooltip nzTooltipTitle="Aggiunti area"></i>
                                <i class="customIcon uiEditIcon" (click)="editR(data.id)" nz-tooltip nzTooltipTitle="Modifica titolo"></i>
                            </td>
                            <td nzAlign="center" *ngIf="editCacheR[data.id].edit">
                                <i style="margin-right: 10px;" class="customIcon uiSaveRedIcon" (click)="saveEditR(data.id)"></i>
                                <i style="margin-right: 10px;" class="customIcon uiCloseGreyIcon" (click)="cancelEditR(data.id)"></i>
                            </td>
                        </tr>
                        <ng-container *ngIf="openFolder[i]">
                            <ng-container *ngFor="let a of data.aree; let ai = index">
                                <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                    <td class="uiBlackCol">

                                    </td>
                                    <td nzAlign="center">
                                        <i [ngClass]="openAreas[i][ai] ? 'customIcon uiFolderOpenIcon': 'customIcon uiFolderIcon'" (click)="openusers(i, ai)" style="margin:0" nz-tooltip [nzTooltipTitle]="openFolder[i] ? 'Chiudi l\'Area' : 'Apri l\'Area'"></i>
                                    </td>
                                    <td>
                                        {{a.id}}
                                    </td>
                                    <td colSpan="2">
                                        <div *ngIf="!editCacheA[data.id][a.id].edit">{{a.nome}}</div>
                                        <div *ngIf="editCacheA[data.id][a.id].edit"><input type="text" nz-input [(ngModel)]="editCacheA[data.id][a.id].data.nome" /></div>
                                    </td>
                                    <td colSpan="4">
                                        <nz-select nzMode="tags" nzPlaceHolder="Tag Mode" [(ngModel)]="a.permessi" [nzCustomTemplate]="selectTpl" class="uiFilterSelect" nzBorderless="true" nzPlaceHolder="Seleziona permessi" [nzShowArrow]="true" nzDropdownClassName="uiDropdownSelect" [nzOptionHeightPx]="46" [nzMenuItemSelectedIcon]="selectIconTpl" (ngModelChange)="selectPermessi($event, i, ai)" nz-tooltip nzTooltipTitle="Saleziona i permessi">
                                            <nz-option [nzCustomContent]="true" *ngFor="let p of g.listOfPermissions" [nzValue]="p.label" [nzLabel]="p.label">
                                                <i [ngClass]="p.icona" style="margin-right: 10px;"></i> {{p.label}}
                                            </nz-option>
                                        </nz-select>
                                        <ng-template #selectTpl let-selected>
                                            <i [ngClass]="selected.nzValue | geticon"></i>
                                        </ng-template>
                                        <ng-template #selectIconTpl>
                                            <i class="customIcon uiSelectIcon"></i>
                                        </ng-template>
                                    </td>
                                    <td></td>
                                    <td nzAlign="center" [ngClass]="a.attivo ? 'uiOnCol':'uiOffCol'">
                                        <nz-switch [(ngModel)]="a.attivo" [nzDisabled]="!data.attivo" (ngModelChange)="onChangeSwitchA($event, i, ai)" nz-tooltip [nzTooltipTitle]="a.attivo ? 'Area attiva' : 'Area disattiva'"></nz-switch>
                                    </td>
                                    <td nzAlign="center" *ngIf="!editCacheA[data.id][a.id].edit">
                                        <button nz-button nzType="text"><i style="margin-right: 10px;" class="customIcon uiTrashGreyIcon" nz-popconfirm nzPopconfirmTitle="Eliminare questa riga?" (nzOnConfirm)="deleteA(i, ai)" nz-tooltip nzTooltipTitle="Elimina area"></i></button>
                                        <button nz-button nzType="text" [disabled]="addUser" (click)="newU(i, ai)"><i style="margin-right: 10px;" class="customIcon uiAddIcon" nz-tooltip nzTooltipTitle="Nuovo utente"></i></button>
                                        <button nz-button nzType="text"><i class="customIcon uiEditIcon" (click)="editA(data.id, a.id)" nz-tooltip nzTooltipTitle="Modifica titolo"></i></button>
                                    </td>
                                    <td nzAlign="center" *ngIf="editCacheA[data.id][a.id].edit">
                                        <i style="margin-right: 10px;" class="customIcon uiSaveRedIcon" (click)="saveEditA(i, ai, data.id, a.id)"></i>
                                        <i style="margin-right: 10px;" class="customIcon uiCloseGreyIcon" (click)="cancelEditA(i, ai, data.id, a.id)"></i>
                                    </td>
                                </tr>
                                <ng-container *ngIf="openAreas[i][ai]">
                                    <tr *ngFor="let u of a.utenti; let ui = index" [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                        <td class="uiBlackCol">

                                        </td>
                                        <td colSpan="1" nzAlign="center">
                                            <i class="customIcon uiUserIcon" style="margin:0"></i>
                                        </td>
                                        <td colSpan="2">
                                            <div *ngIf="!editCacheU[data.id][a.id][u.id].edit">{{u.email}}</div>
                                            <div *ngIf="editCacheU[data.id][a.id][u.id].edit"><input type="text" nz-input [(ngModel)]="editCacheU[data.id][a.id][u.id].data.email" placeholder="Inserisci email" />
                                            </div>
                                        </td>
                                        <td colSpan="2">
                                            <input nz-input [ngModel]="u.pwd" disabled type="password" nzBorderless style="color: #202020; cursor:default;" />
                                        </td>
                                        <td nzAlign="center">
                                            <i class="customIcon uiResetIcon" (click)="resetPwd(i, ai, ui)" nz-tooltip nzTooltipTitle="Reimposta password"></i>
                                        </td>
                                        <td nzEllipsis colSpan="2">
                                            <div *ngIf="!editCacheU[data.id][a.id][u.id].edit">{{u.descrizione}}</div>
                                            <div *ngIf="editCacheU[data.id][a.id][u.id].edit"><input type="text" nz-input [(ngModel)]="editCacheU[data.id][a.id][u.id].data.descrizione" placeholder="Inserisci descrizione" />
                                            </div>
                                        </td>
                                        <td nzEllipsis nzAlign="center">
                                            {{u.ultimo_accesso.seconds * 1000 | date: 'dd/MM/yyyy HH:mm:ss'}}
                                        </td>
                                        <td nzAlign="center" [ngClass]="u.attivo ? 'uiOnCol':'uiOffCol'">
                                            <nz-switch [(ngModel)]="u.attivo" [nzDisabled]="editCacheU[data.id][a.id][u.id].edit || !data.attivo || !a.attivo" (ngModelChange)="onChangeSwitchU($event, i, ai, ui);" nz-tooltip [nzTooltipTitle]="u.attivo ? 'Utente attivo' : 'Utente non attivo'"></nz-switch>
                                        </td>
                                        <td nzAlign="center" *ngIf="!editCacheU[data.id][a.id][u.id].edit">
                                            <i class="customIcon uiTrashGreyIcon" nz-popconfirm nzPopconfirmTitle="Eliminare questa riga?" (nzOnConfirm)="deleteU(i, ai, ui)" nz-tooltip nzTooltipTitle="Elimina utente"></i>
                                            <i class="customIcon uiEditIcon" (click)="editU(data.id, a.id, u.id)" nz-tooltip nzTooltipTitle="Modifica utente"></i>
                                        </td>
                                        <td nzAlign="center" *ngIf="editCacheU[data.id][a.id][u.id].edit">
                                            <i style="margin-right: 10px;" class="customIcon uiSaveRedIcon" (click)="saveEditU(i, ai, ui, data.id, a.id, u.id)"></i>
                                            <i style="margin-right: 10px;" class="customIcon uiCloseGreyIcon" (click)="cancelEditU(i, ai, ui, data.id, a.id, u.id)"></i>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tbody>
            </nz-table>
        </div>
    </nz-content>

    <div class="waiter" *ngIf="loading">
          <span class="ant-spin-dot ant-spin-dot-spin ng-star-inserted"><i class="ant-spin-dot-item"></i><i
          class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span>
    </div>
</nz-layout>


<nz-modal [(nzVisible)]="recoverModal" nzTitle="" nzFooter="null" [nzContent]="modalContent" nzWrapClassName="innerRecoverModal" (nzOnCancel)="handleCancel()">
    <ng-template #modalContent>
        <h1>{{'resetPwd' | translate}}</h1>
        <p class="modalDesc" [innerHTML]="'resetPwdText' | translate"></p>
        <button nz-button nzSize="large" (click)="recover()" nzType="primary" class="loginBtn">Invia</button>
        <br />
        <br />
        <a (click)="showManualChange()">Imposta manualmente</a>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="manualChange" nzTitle="" nzFooter="null" [nzContent]="modalManualContent" nzWrapClassName="innerRecoverModal" (nzOnCancel)="handleCancel()">
    <ng-template #modalManualContent>
        <form [formGroup]="pwdForm">
            <div nz-row class="uiFormRow">
                <h1>{{'resetPwd' | translate}}</h1>
                <input type="password" nz-input formControlName="password" placeholder="Inserisci password" style="margin-bottom: 0;" />
                <div *ngIf="pwdForm.get('password')?.invalid && (pwdForm.get('password')?.dirty && pwdForm.get('password')?.touched)">
                    <small class="uiAlertMessage" *ngIf="pwdForm.get('password')?.hasError('minlength')" style="color: var(--secondary-color);">
                        {{'pwdLengthAlert' | translate}}
                    </small>
                </div>
            </div>
            <div nz-row class="uiFormRow" style="margin-top: 1rem; margin-bottom: 0;">
                <input type="password" nz-input formControlName="confirmPassword" placeholder="Conferma password" style="margin-bottom: 0;" />
                <div *ngIf="(pwdForm.get('confirmPassword')?.dirty && pwdForm.get('confirmPassword')?.touched)">
                    <small class="uiAlertMessage" *ngIf="pwdForm.hasError('notSame')" style="color: var(--secondary-color);">
                        {{'pwdMatchAlert' | translate}}
                    </small>
                </div>
            </div>
            <div nz-row class="uiFormRow">
                <div nz-col nzSpan="12" class="uiFormSubmit">
                    <button nz-button nzSize="large" (click)="onSubmitManualChange()" nzType="primary" [disabled]="pwdForm.invalid" class="loginBtn">{{'saveCredential' |
                        translate}}</button>
                </div>
            </div>
        </form>
        <div class="uiLoginContainer" *ngIf="showError">
            <div class="uiFormContainer">
                <p class="errorMessage">{{'recoverError' | translate}}</p>
            </div>
        </div>
    </ng-template>
</nz-modal>
