import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { EditAggregatiComponent } from './edit-aggregati.component';
const routes: Routes = [
  {
    path: 'admin/gestione/aggregati/edit/:aid/:rid/:lid', component: EditAggregatiComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditAggregatiRoutingModule { }
