<div class="uiCompilazioneEndContainer">
    <nz-layout class="uiEndInnerLayout">
        <nz-header class="uiCompilazioneEndHeader">
            <img src="../../../../assets/logo-light.png" alt="logo" class="uiHeaderLogo">
        </nz-header>
        <nz-content class="uiCompilazioneEndContent">
            <h1>Questionario completato</h1>
            <h3>Il questionario di questo link risulta già compilato!</h3>
        </nz-content>
    </nz-layout>
</div>

<app-legal-footer></app-legal-footer>

