import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NodeService } from "./node.service";

@Injectable({
  providedIn: "root",
})
export class CompileService {
  isAlreadyCompiled: boolean = false;
  custLogo: SafeResourceUrl;

  // ######################################
  // FUNCTION: constructor
  constructor(private myRoute: Router, private node: NodeService, private sanitizer: DomSanitizer) { }

  // ######################################
  // FUNCTION: checkIfCompiled
  checkIfCompiled() {
    return this.isAlreadyCompiled;
  }

  // ######################################
  // FUNCTION: setCompiled
  setCompiled() {
    this.isAlreadyCompiled = true;
  }

  // ######################################
  // FUNCTION: saveLogo
  saveLogo(logo) {
    this.custLogo = false;
    if (logo) {
      let params = { "filepath": logo };
      this.node.getData(params, "/download").subscribe((res) => {
        this.custLogo = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(res));
      });
    }
  }
}
