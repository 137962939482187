import { ReturnStatement } from "@angular/compiler";
import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { clearLine } from "readline";
import { Questionario } from "src/app/entities/questionari";
import { AuthService } from "src/app/services/auth.service";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { StorageService } from "src/app/services/storage.service";
import { GlobalService } from "src/app/shared/global.service";
import { InterfaceService } from "src/app/shared/interface.service";

@Component({
  selector: "app-designer",
  templateUrl: "./designer.component.html",
  styleUrls: ["./designer.component.scss"],
})
export class DesignerComponent implements OnInit, AfterViewInit {
  quests: any[] = [];
  loading = true;
  openFolder: any[] = [];
  tableH: string = "";
  displayedData: any[] = [];
  search = "";
  editCache: { [key: string]: { edit: boolean; data: any; }; } = {};
  userArea = "";
  scroll = { y: "" };

  // ######################################
  // FUNCTION: constructor
  constructor(
    public g: GlobalService,
    private translate: TranslateService,
    private router: Router,
    private node: NodeService,
    private storage: StorageService,
    private fire: FireService,
    private auth: AuthService,
    public face: InterfaceService,
    private db: AngularFirestore
  ) { }

  // ######################################
  // EVENT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: ngAfterViewInit
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant("designer");
    }, 100);
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiQuestsTableContainer").length > 0) {
      let height = document.getElementsByClassName("uiQuestsTableContainer")[0].clientHeight;
      if (
        document.getElementsByClassName("uiQuestsTableContainer")[0].getElementsByClassName("ant-table-thead").length
      ) {
        let headH = document
          .getElementsByClassName("uiQuestsTableContainer")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    let tempPref = this.face.getDesignerInterface();
    if (!tempPref) tempPref = {};
    this.g.temp_quest = null;

    let user = this.auth.getUser();
    this.fire.getOnce("reparti", user.reparto).subscribe((rep) => {
      this.fire.getDocOnce("reparti", user.reparto, "aree", user.area).subscribe((resArea) => {
        this.userArea = rep.data().nome + " | " + resArea.data().nome;
        this.fire.getAllAsc("questionari", "codice").subscribe((res) => {
          this.quests = res;
          this.displayedData = this.quests;
          if (res.length === 0) {
            this.loading = false;
          }
          for (let i = 0; i < res.length; i++) {
            if (typeof tempPref[res[i].id] === "undefined" || tempPref[res[i].id] === null) {
              tempPref[res[i].id] = true;
              this.openFolder[i] = true;
            } else {
              this.openFolder[i] = tempPref[res[i].id];
            }
            this.editCache[res[i].id] = {
              edit: false,
              data: { ...res[i] },
            };
            let e = res[i];
            e.versioni = [];
            e.lingue = [];
            let autorize = false;
            this.fire.getCollectionByOrder("questionari", e.id, "versioni", "codice").subscribe((res2) => {
              e.versioni = [];
              e.lingue = [];
              if (res2.length <= 0) {
                autorize = true;
                e.autorize = true;
              } else {
                res2.forEach((v, vIndex) => {
                  if (v.autorizzazioni.includes(this.userArea)) {
                    autorize = true;
                    if (typeof res2 === "undefined" || res2.length === 0) {
                    } else {
                      if (e.versione_attiva > 0 && res2[e.versione_attiva - 1]) {
                        e.lingue = res2[e.versione_attiva - 1].lingue;
                      } else {
                        e.lingue = [];
                      }
                      e.versioni.push(v);
                    }
                  }
                  if (vIndex === res2.length - 1) {
                    if (autorize) {
                      e.autorize = true;
                    } else {
                      e.autorize = false;
                    }
                  }
                });
              }
            });
            if (i === res.length - 1) {
              this.loading = false;
              this.face.saveDesignerInterface(tempPref);
              this.setScrollHeight();
            }
          }
        });
      });
    });
  }

  // ######################################
  // FUNCTION: new
  new(type: number) {
    let quest: any = {};
    this.fire.getLast("questionari", "codice").then((id) => {
      quest.codice = id;
      quest.tipo = type;
      quest.versione_attiva = 0;
      quest.anonimo = false;
      quest.titolo = "Questionario " + id;
      this.fire
        .add("questionari", quest, "Q" + quest.codice.toString())
        .then()
        .catch((err) => {
          // console.log(err);
        });
    });
  }

  // ######################################
  // FUNCTION: navigateQ
  navigateQ(i: number) {
    let quest = this.quests[i];
    let docID = "Q" + quest.codice + "V" + quest.versione;
    this.router.navigateByUrl("admin/designer/questionario/" + docID);
  }

  // ######################################
  // FUNCTION: addVersion
  addVersion(i: number) {
    let quest = this.quests[i];
    this.fire
      .getLastSubBy("questionari", this.quests[i].id, "versioni", "codice")
      .then((versionID) => {
        let version: Questionario = new Questionario();
        version.codice = versionID + 1;
        version.tipo = this.quests[i].tipo;
        this.g.languages.forEach((lang) => {
          version.titolo[lang] = "";
          version.copertina_report[lang] = "";
        });

        if (version.tipo === 2) {
          version.collegati = [];
        }

        let dateNow = new Date(Date.now());
        version.ultima_modifica = dateNow;
        version.autorizzazioni.push(this.userArea);

        this.fire
          .addCollection("questionari", "versioni", version, "Q" + quest.codice, "V" + version.codice)
          .then()
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  // ######################################
  // FUNCTION: duplicate
  duplicate(i, vi) {
    let newVersion = this.quests[i].versioni[vi];
    let quest = this.quests[i];
    this.fire
      .getLastSubBy("questionari", this.quests[i].id, "versioni", "codice")
      .then((versionID) => {
        newVersion.codice = versionID + 1;
        newVersion.pubblico = false;
        let dateNow = new Date(Date.now());
        newVersion.ultima_modifica = dateNow;

        this.fire
          .addCollection("questionari", "versioni", newVersion, "Q" + quest.codice, "V" + newVersion.codice)
          .then()
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  // ######################################
  // FUNCTION: openVersions
  openVersions(i: number) {
    if (this.openFolder[i]) {
      this.openFolder[i] = false;
      this.face.openChange(false, "DESIGNER", this.displayedData[i].id);
    } else {
      this.openFolder[i] = true;
      this.face.openChange(true, "DESIGNER", this.displayedData[i].id);
    }
  }

  // ######################################
  // FUNCTION: editQ
  editQ(i: number, v: number) {
    let q = this.quests[i];
    let docID = q.id + q.versioni[v].id;
    this.router.navigateByUrl("admin/designer/questionario/" + docID);
  }

  // ######################################
  // FUNCTION: deleteQ
  deleteQ(i: number, v: number) {
    // check if licenze attive
    let licenseUsed = false;
    this.fire.getAll("clienti", "codice").subscribe((clienti) => {
      for (let c = 0; c < clienti.length; c++) {
        if (!licenseUsed) {
          this.fire.getCollection("clienti", clienti[c].id, "licenze").subscribe((licenze) => {
            for (let l = 0; l < licenze.length; l++) {
              if (!licenseUsed) {
                let qId = this.quests[i].id;
                let vId = this.quests[i].versioni[v].id;
                if (licenze[l].questionario === qId && licenze[l].versione === vId) {
                  licenseUsed = true;
                  this.g.createErrorNotification(
                    "Versione in uso nella licenza <br/>" + clienti[c].id + " - " + licenze[l].id,
                    ""
                  );
                  c = clienti.length;
                  l = licenze.length;
                } else if (!licenseUsed && c === clienti.length - 1 && l === licenze.length - 1) {
                  if (this.quests[i].versioni[v].pubblico) {
                    this.quests[i].versione_attiva = 0;
                    let q: any = {};
                    q.codice = this.quests[i].codice;
                    q.tipo = this.quests[i].tipo;
                    q.versione_attiva = 0;
                    q.anonimo = this.quests[i].anonimo;
                    q.lingue = this.quests[i].lingue;
                    this.fire.updateCollection("questionari", q, this.quests[i].id);
                  }
                  let q = JSON.parse(JSON.stringify(this.quests[i])).id;
                  let ve = JSON.parse(JSON.stringify(this.quests[i].versioni[v])).id;
                  this.quests[i].versioni.splice(v, 1);
                  this.fire.delete("questionari", "versioni", q, ve);
                }
              }
            }
          });
        } else if ((!licenseUsed && c === clienti.length - 1) || clienti.lenght === 0) {
          if (this.quests[i].versioni[v].pubblico) {
            this.quests[i].versione_attiva = 0;
            let q: any = {};
            q.codice = this.quests[i].codice;
            q.tipo = this.quests[i].tipo;
            q.versione_attiva = 0;
            q.anonimo = this.quests[i].anonimo;
            q.lingue = this.quests[i].lingue;
            this.fire.updateCollection("questionari", q, this.quests[i].id);
          }
          let q = JSON.parse(JSON.stringify(this.quests[i])).id;
          let ve = JSON.parse(JSON.stringify(this.quests[i].versioni[v])).id;
          this.quests[i].versioni.splice(v, 1);
          this.fire.delete("questionari", "versioni", q, ve);
        }
      }
    });
  }

  // ######################################
  // FUNCTION: onChangeQSwitch
  onChangeQSwitch(e: any, i: number) {
    this.quests[i].anonimo = e;
    let q: any = {};
    q.codice = this.quests[i].codice;
    q.tipo = this.quests[i].tipo;
    q.versione_attiva = this.quests[i].versione_attiva;
    q.anonimo = this.quests[i].anonimo;
    q.lingue = this.quests[i].lingue;
    this.fire.updateCollection("questionari", q, this.quests[i].id);
  }

  // ######################################
  // FUNCTION: onChangeSwitch
  onChangeSwitch(e: any, i: number, vi: number) {
    // check se versione è attiva in qualche licenza attiva
    let licenseUsed = false;
    this.fire.getAll("clienti", "codice").subscribe((clienti) => {
      for (let c = 0; c < clienti.length; c++) {
        if (!licenseUsed) {
          this.fire.getCollection("clienti", clienti[c].id, "licenze").subscribe((licenze) => {
            for (let l = 0; l < licenze.length; l++) {
              if (!licenseUsed) {
                let qId = this.displayedData[i].id;
                let vId = this.displayedData[i].versioni[vi].id;

                if (licenze[l].questionario === qId && licenze[l].versione === vId) {
                  licenseUsed = true;
                  this.displayedData[i].versioni[vi].pubblico = true;
                  // console.log(this.displayedData[i].versioni[vi].pubblico);
                  this.g.createErrorNotification(
                    "Versione in uso nella licenza <br/>" + clienti[c].id + " - " + licenze[l].id,
                    ""
                  );
                  c = clienti.length;
                  l = licenze.length;
                } else if (!licenseUsed && c === clienti.length - 1 && l === licenze.length - 1) {
                  // console.log(this.displayedData[i].versioni[vi].pubblico);
                  this.fire.update(
                    "questionari",
                    "versioni",
                    this.displayedData[i].versioni[vi],
                    this.displayedData[i].id,
                    this.displayedData[i].versioni[vi].id
                  );
                  let vCode = this.displayedData[i].versioni[vi].id.split("V")[1];
                  if (e === true) {
                    // console.log(vCode, this.displayedData[i].versione_attiva);
                    if (vCode >= this.displayedData[i].versione_attiva) {
                      this.displayedData[i].versione_attiva = vCode;
                      this.displayedData[i].lingue = this.displayedData[i].versioni[vi].lingue;
                      let q: any = {};
                      q.codice = this.displayedData[i].codice;
                      q.tipo = this.displayedData[i].tipo;
                      q.versione_attiva = this.displayedData[i].versione_attiva;
                      q.anonimo = this.displayedData[i].anonimo;
                      q.lingue = this.displayedData[i].lingue;
                      this.fire.updateCollection("questionari", q, this.displayedData[i].id);
                    } else {
                    }
                  } else {
                    if (vCode.toString() === this.displayedData[i].versione_attiva.toString()) {
                      this.displayedData[i].versione_attiva = 0;
                      this.displayedData[i].versioni.forEach((v, vIndex) => {
                        if (vIndex !== vi) {
                          if (v.pubblico === true) {
                            this.displayedData[i].versione_attiva = v.id.split("V")[1];
                          }
                        }
                        if (vIndex === this.displayedData[i].versioni.length - 1) {
                          let q: any = {};
                          q.codice = this.displayedData[i].codice;
                          q.tipo = this.displayedData[i].tipo;
                          q.versione_attiva = this.displayedData[i].versione_attiva;
                          q.anonimo = this.displayedData[i].anonimo;
                          q.lingue = this.displayedData[i].lingue;
                          this.fire.updateCollection("questionari", q, this.displayedData[i].id);
                        }
                      });
                    }
                  }
                }
              }
            }
          });
        }
      }
    });
  }

  // ######################################
  // FUNCTION: filter
  filter() {
    if (this.search.length > 0) {
      let count = 0;
      this.displayedData = this.quests.filter(
        (c) =>
          ("Q" + c.codice).toUpperCase().includes(this.search.toUpperCase()) ||
          c.versioni.some((v) => {
            let i =
              ("V" + v.codice).toUpperCase().includes(this.search.toUpperCase()) ||
              v.titolo[this.g.selectedLanguage].includes(this.search.toUpperCase());
            if (i) {
              this.openFolder[count] = true;
              count += 1;
            } else this.openFolder[count] = false;
            return i;
          })
      );
    } else {
      this.displayedData = this.quests;
      this.openFolder.fill(false);
    }
  }

  // ######################################
  // FUNCTION: edit
  edit(id) {
    this.editCache[id].edit = true;
  }

  // ######################################
  // FUNCTION: cancelEdit
  cancelEdit(id: string): void {
    const index = this.quests.findIndex((item) => item.id === id);
    this.editCache[id] = {
      data: { ...this.quests[index] },
      edit: false,
    };
  }

  // ######################################
  // FUNCTION: saveEdit
  saveEdit(id: string): void {
    const index = this.quests.findIndex((item) => item.id === id);
    Object.assign(this.quests[index], this.editCache[id].data);
    this.editCache[id].edit = false;
    this.displayedData = [...this.quests];
    let r: any = {};
    r.titolo = this.quests[index].titolo;
    this.fire.updateCollection("questionari", r, this.quests[index].id);
  }

  // ######################################
  // FUNCTION: delete
  delete(id) {
    this.fire.deleteDoc("questionari", id);
  }
}
