export class Risposta {
	codice: string;
	titolo: any;
	filtri: {
		campo: {
			ordine: number;
			etichetta: string;
			tipo: "Testo" | "Booleano" | "Range" | "Data" | "Codice fiscale" | "Partita iva" | "Elenco";
			opzioni: string;
			richiesto: boolean;
		};
		operatore1: string;
		operatore2: string;
		valore1: string;
		valore2: string;
		minimo: number;
		massimo: number;
	}[];
	commentabile: boolean;
	commento: string;
	trend: number;
}
