import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CompileService } from "src/app/services/compile.service";
import { FireService } from "src/app/services/fire.service";
import { StorageService } from "src/app/services/storage.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
})
export class HomepageComponent implements OnInit {
  CryptoJS = require("crypto-js");
  encoded: string;
  decoded: any;
  viewModal = false;
  startDisabled = true;

  // ######################################
  // FUNCTION: constructor
  constructor(
    private route: ActivatedRoute,
    private fire: FireService,
    private storage: StorageService,
    public router: Router,
    private compile: CompileService,
    public g: GlobalService
  ) { }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.encoded = param.code;
      this.decoded = JSON.parse(this.CryptoJS.enc.Utf8.stringify(this.CryptoJS.enc.Base64.parse(param.code)));
    });

    this.fire
      .getSubDocOnce(
        "clienti",
        this.decoded.azienda,
        "licenze",
        this.decoded.licenza,
        "compilazioni",
        this.decoded.reparto + this.decoded.utente
      )
      .subscribe((comp) => {
        let q = comp.data();


        if (q && q.compilazione && typeof q.compilazione.last !== "undefined") {
          if (q.compilazione.licenza.licenza === this.decoded.licenza) {
            if (q.compilazione.concluso) {
              this.storage.setActiveQuest(this.decoded);
              this.router.navigateByUrl("/compilazione/conclusione");
            } else {
              this.compile.saveLogo(q.logo);
              this.storage.setActiveQuest(this.decoded);
              this.router.navigateByUrl("/compilazione/domande");
            }
          } else {
            // questionario già iniziato e non concluso
            this.viewModal = true;
          }
        } else {
          // controllo se compilazione già conclusa
          if (q && q.concluso === true) {
            // console.log("np1", "completato");
            this.router.navigateByUrl("/compilazione/accesso-completato");
          } else {
            this.fire.get("clienti", this.decoded.azienda).subscribe((az) => {
              // controllo se azienda attiva
              if (az && az.attivo) {
                this.fire
                  .getSubDoc(
                    "clienti",
                    this.decoded.azienda,
                    "reparti",
                    this.decoded.reparto,
                    "utenti",
                    this.decoded.utente
                  )
                  .subscribe((user) => {
                    // controllo se utente attivo
                    if (user && user.attivo) {
                      this.fire
                        .getDocument("clienti", this.decoded.azienda, "licenze", this.decoded.licenza)
                        .subscribe((lic) => {
                          let now = new Date(Date.now()).setHours(0, 0, 0, 0); // prendo la data senza l'ora

                          // controllo se licenza attiva, se scaduta
                          if (lic && lic.attivo && lic.scadenza.seconds * 1000 > now) {
                            this.fire
                              .getDocument("clienti", this.decoded.azienda, "reparti", this.decoded.reparto)
                              .subscribe((rep) => {
                                //controllo se reparto attivo e se licenza comprende reparto
                                if (rep && rep.attivo && lic.reparti.includes(rep.id)) {
                                  this.fire.get("questionari", lic.questionario).subscribe((q) => {
                                    this.fire
                                      .getDocument("questionari", lic.questionario, "versioni", lic.versione)
                                      .subscribe((quest) => {
                                        let qu = {
                                          titoloQ: q.titolo,
                                          logo: az.logo,
                                          concluso: false,
                                          compilazione: {
                                            licenza: this.decoded,
                                          },
                                          questionario: quest,
                                        };

                                        this.compile.saveLogo(quest.logo);
                                        this.fire
                                          .addSubCollection(
                                            "clienti",
                                            "licenze",
                                            "compilazioni",
                                            qu,
                                            qu.compilazione.licenza.azienda,
                                            qu.compilazione.licenza.licenza,
                                            qu.compilazione.licenza.reparto + qu.compilazione.licenza.utente
                                          )
                                          .then((result) => {
                                            this.storage.setActiveQuest(this.decoded);
                                            this.startDisabled = false;
                                          });
                                      });
                                  });
                                } else {
                                  console.error("np2 (reparto non attivo)");
                                  this.router.navigateByUrl("/compilazione/accesso-vietato");
                                }
                              });
                          } else {
                            console.error("np3 (licenza non attiva)");
                            this.router.navigateByUrl("/compilazione/accesso-vietato");
                          }
                        });
                    } else {
                      console.error("np4 (utente non attivo)");
                      this.router.navigateByUrl("/compilazione/accesso-vietato");
                    }
                  });
              } else {
                console.error("np5 (azienda non attiva)");
                this.router.navigateByUrl("/compilazione/accesso-vietato");
              }
            });
          }
        }
      });
  }

  // ######################################
  // FUNCTION: navigate
  navigate(): void {
    this.router.navigateByUrl("/compilazione/intestazione");
  }

  // ######################################
  // FUNCTION: exit
  exit() {
    this.viewModal = false;
    console.error("exit (function exit)");
    this.router.navigateByUrl("/compilazione/accesso-vietato");
  }
}
