import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthService } from "src/app/services/auth.service";
import { AuthGuard } from "src/app/guards/auth.guard";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule } from "@ngx-translate/core";
import { NgZorroModule } from "src/app/ng-zorro.module";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CompilazioneComponent } from "./compilazione.component";
import { CompilazioneRoutingModule } from "./compilazione-routing.module";
import { IntestazioneComponent } from "./intestazione/intestazione.component";
import { HomepageComponent } from "./homepage/homepage.component";
import { DomandeComponent } from "./domande/domande.component";
import { ConclusioneComponent } from "./conclusione/conclusione.component";
import { CompileGuard } from "src/app/guards/compile.guard";
import { NopermissionsComponent } from "./nopermissions/nopermissions.component";
import { NocompletedComponent } from "./nocompleted/nocompleted.component";
import { LegalFooterComponent } from "./legal-footer/legal.footer.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    CompilazioneComponent,
    IntestazioneComponent,
    HomepageComponent,
    DomandeComponent,
    ConclusioneComponent,
    NopermissionsComponent,
    NocompletedComponent,
    LegalFooterComponent,
  ],
  imports: [
    CompilazioneRoutingModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgZorroModule,
    ScrollingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateModule,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient],
      },
    }),
  ],
  exports: [CompilazioneComponent, NgZorroModule],
  providers: [CompileGuard],
})
export class CompilazioneModule { }
