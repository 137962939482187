import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Console } from "console";
import { async } from "rxjs";
import { FireService } from "src/app/services/fire.service";
import { GlobalService } from "src/app/shared/global.service";
import { InterfaceService } from "src/app/shared/interface.service";

@Component({
	selector: "app-valutazioni",
	templateUrl: "./valutazioni.component.html",
	styleUrls: ["./valutazioni.component.scss"],
})
export class ValutazioniComponent implements OnInit {
	reps: any[] = [];
	loading = true;
	openFolder: any[] = [];
	openAreas: any[] = [];

	search = "";
	displayedData: any[] = [];
	allData: any[] = [];
	toDoData: any[] = [];

	questDocs: any[] = [];
	questVersions: { [key: string]: { versioni: any } } = {};

	editCacheL: { [key: string]: { [key2: string]: { edit: boolean; data: any } } } = {};
	viewAll = false;
	now: number;
	scroll = { y: "" };
	filterActive = false;

	// ######################################
	// FUNCTION: constructor
	constructor(
		public g: GlobalService,
		public face: InterfaceService,
		private translate: TranslateService,
		private fire: FireService,
		private router: Router
	) {}

	// ######################################
	// EVENT: onResize
	@HostListener("window:resize", ["$event"])
	onResize(event) {
		event.target.innerWidth;
		this.setScrollHeight();
	}

	// ######################################
	// FUNCTION: setScrollHeight
	setScrollHeight() {
		if (document.getElementsByClassName("uiGestioneTableContainer").length > 0) {
			let height = document.getElementsByClassName("uiGestioneTableContainer")[0].clientHeight;
			if (
				document.getElementsByClassName("uiGestioneTableContainer")[0].getElementsByClassName("ant-table-thead").length
			) {
				let headH = document
					.getElementsByClassName("uiGestioneTableContainer")[0]
					.getElementsByClassName("ant-table-thead")[0].clientHeight;
				this.scroll = { y: height - headH + "px" };
			} else {
				this.scroll = { y: height - 24 + "px" };
			}
		}
	}

	// ######################################
	// FUNCTION: ngOnInit
	ngOnInit(): void {
		let tempPref = this.face.getValutazioniInterface();
		if (tempPref === null) {
			tempPref = {};
		}

		this.now = new Date(Date.now()).getTime();
		this.displayedData = [];
		this.fire.getAllAsc("clienti", "codice").subscribe(async (res) => {
			this.reps = res;
			if (res.length === 0) {
				this.loading = false;
			}
			for (let i = 0; i < res.length; i++) {
				// ############### intefaccia
				if (typeof tempPref[res[i].id] === "undefined" || tempPref[res[i].id] === null) {
					tempPref[res[i].id] = { open: true, sub: {} };
					this.openFolder[res[i].id] = true;
				} else {
					this.openFolder[res[i].id] = tempPref[res[i].id].open;
				}
				// ###############
				this.openAreas[res[i].id] = [];
				let e = res[i];
				e.licenze = [];
				await this.fire.getCollection("clienti", e.id, "licenze").subscribe((res3) => {
					if (res3.length > 0) {
						let licenze = res3;
						e.licenze = licenze;

						licenze.forEach((l, rindex) => {
							// ############### intefaccia
							if (typeof tempPref[res[i].id].sub[l.id] === "undefined" || tempPref[res[i].id].sub[l.id] === null) {
								tempPref[res[i].id].sub[l.id] = true;
								this.openAreas[res[i].id][l.id] = true;
							} else {
								this.openAreas[res[i].id][l.id] = tempPref[res[i].id].sub[l.id];
							}
							// ###############
							if (l.questionario && l.versione) {
								this.fire.getDocument("questionari", l.questionario, "versioni", l.versione).subscribe((res4) => {
									e.licenze[rindex].quest = res4;
									this.fire
										.getSubCollection("clienti", e.id, "licenze", l.id, "compilazioni")
										.subscribe((compilazioni) => {
											l.compilazioni = [];
											let toDo: any = [];
											compilazioni.forEach((c, cindex) => {
												c.utente = {};
												if (c.concluso) {
													//let index = l.compilazioni.findIndex((co) => co.id === c.id);
													//if (index < 0) {
													l.compilazioni.push(c);
													if (!c.commento || c.commento == "") {
														if (l.quest.report != "2") toDo.push(c);
													}
													if (!res4.anonimo) {
														this.fire
															.getSubDoc("clienti", e.id, "reparti", c.licenza.reparto, "utenti", c.licenza.utente)
															.subscribe((res) => {
																c.utente = res;
															});
													} else {
													}
													this.face.saveValutazioniInterface(tempPref);
													this.loading = false;
													this.setScrollHeight();
													//}
												}
												if (cindex === compilazioni.length - 1) {
													let index = this.allData.findIndex((d) => d.id === e.id);
													if (index < 0) {
														// ################################ FILTER INVALID
														e.licenze = e.licenze.filter((obj) => {
															return obj.quest !== undefined && obj.quest.titolo !== undefined;
														});
														if (e.licenze.length == 0) return;
														// ################################
														this.allData.push(e);

														let tempE = JSON.parse(JSON.stringify(e));
														let tempIndex = tempE.licenze.findIndex((tL) => tL.id === l.id);

														if (toDo.length > 0) {
															tempE.licenze[tempIndex].compilazioni = toDo;

															this.displayedData.push(tempE);
															this.toDoData.push(tempE);
														}
													}
												}
											});
										});
								});
							}
						});
					}
				});
			}
		});
	}

	// ######################################
	// FUNCTION: getNested
	getNested(obj, ...args) {
		return args.reduce((obj, level) => obj && obj[level], obj);
	}

	// ######################################
	// FUNCTION: openArea
	openArea(i: number) {
		if (this.openFolder[this.displayedData[i].id]) {
			this.openFolder[this.displayedData[i].id] = false;
			this.face.openChange(false, "VALUTAZIONI", this.displayedData[i].id);
		} else {
			this.openFolder[this.displayedData[i].id] = true;
			this.face.openChange(true, "VALUTAZIONI", this.displayedData[i].id);
			this.loading = false;
		}
	}

	// ######################################
	// FUNCTION: openSubarea
	openSubarea(i: number, ai: number) {
		if (this.openAreas[this.displayedData[i].id][this.displayedData[i].licenze[ai].id]) {
			this.openAreas[this.displayedData[i].id][this.displayedData[i].licenze[ai].id] = false;
			this.face.openChange(false, "VALUTAZIONI", this.displayedData[i].id, this.displayedData[i].licenze[ai].id);
		} else {
			this.openAreas[this.displayedData[i].id][this.displayedData[i].licenze[ai].id] = true;
			this.loading = false;
			this.face.openChange(true, "VALUTAZIONI", this.displayedData[i].id, this.displayedData[i].licenze[ai].id);
		}
	}

	// ######################################
	// FUNCTION: filter
	filter() {
		if (this.filterActive) {
			this.displayedData = this.allData.filter(
				(r) =>
					r.id.toUpperCase().includes(this.search.toUpperCase()) ||
					r.ragione_sociale.toUpperCase().includes(this.search.toUpperCase())
			);
		} else {
			this.displayedData = this.toDoData.filter(
				(r) =>
					r.id.toUpperCase().includes(this.search.toUpperCase()) ||
					r.ragione_sociale.toUpperCase().includes(this.search.toUpperCase())
			);
		}
	}

	// ######################################
	// FUNCTION: edit
	edit(aId, lId, cId) {
		this.router.navigateByUrl("admin/gestione/valutazioni/edit/" + aId + "/" + lId + "/" + cId);
	}

	// ######################################
	// FUNCTION: active
	active() {
		this.filterActive = !this.filterActive;
		if (this.filterActive) {
			this.displayedData = this.allData;
		} else {
			this.displayedData = this.toDoData;
		}
	}
}
