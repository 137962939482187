<nz-layout class="uiPageInnerLayout">
    <nz-content class="uiQuestAttendContent" *ngIf="g.temp_quest">
        <div nz-row class="uiQuestParagraphRow">
            <div class="uiParagraphLeft">
                INDICE DI FORZATURA <nz-input-number [nzStep]="1" [nzMin]="0" class="uiAttendIndex"
                    [(ngModel)]="g.temp_quest.indice_forzatura">
                </nz-input-number>
            </div>

            <div class="uiParagraphRight">
                <button nz-button class="uiAddAttend" (click)="addGroup()">
                    Aggiungi gruppo <i class="customIcon uiPlusCircleIcon"></i>
                </button>
            </div>
        </div>

        <div class="uiQustAttendBody">
            <nz-table class="uiAttendTable" *ngIf="g.temp_quest.gruppi_forzatura.length > 0; else emptyTemplate" #groups
                [nzData]="g.temp_quest.gruppi_forzatura" [nzShowPagination]="false" [nzPageSize]="100" [nzScroll]="scroll">
                <thead>
                    <tr>
                        <th nzWidth="5%" nzAlign="center">COD</th>
                        <th nzWidth="10%">VALORE</th>
                        <th nzWidth="75%" nzAlign="center">NOME GRUPPO</th>
                        <th nzWidth="10%" nzAlign="center"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of groups.data; let i = index">
                        <tr [ngClass]="i%2 === 0 ? 'uiGreyTableRow' : 'uiLightTableRow'">
                            <td nzAlign="center">
                                {{data.codice}}
                            </td>
                            <td>
                                <nz-input-number [nzStep]="1" [nzMin]="0" class="uiPuntInput" [(ngModel)]="data.valore">
                                </nz-input-number>
                            </td>
                            <td>
                                <input nz-input [(ngModel)]="data.titolo[g.temp_quest_lang]" class="uiAttendInput">
                            </td>
                            <td nzAlign="center">
                                <i class="customIcon uiTrashIcon" (click)="remove(data.codice)"></i>
                                <i class="customIcon uiPlusCircleIcon" (click)="addOperation(i)"
                                    style="margin-left: 15px;"></i>
                            </td>
                        </tr>
                        <ng-container *ngFor="let op of g.temp_quest.gruppi_forzatura[i].operazioni; let o = index">
                            <tr [ngClass]="i%2 === 0 ? 'uiGreyRow' : 'uiLightRow'">
                                <td colSpan="4" class="uiAttendOps">
                                    <div nz-row>
                                        <div nz-col nzSpan="1" class="uiEmptyCol uiOpsCol">
                                        </div>
                                        <div nz-col nzSpan="1" class="uiFilterCol">
                                            <i class="customIcon uiConnectGreyIcon"></i>
                                        </div>
                                        <div nz-col nzSpan="9" class="uiFilterCol uiOpsCol">
                                            <nz-select [(ngModel)]="op.tratto" class="uiQuestSelect" nzShowSearch
                                                (ngModelChange)="selectTreat(o, i)">
                                                <nz-option *ngFor="let q of g.temp_quest.tratti"
                                                    [nzLabel]="q.titolo[g.temp_quest_lang]" [nzValue]="q.codice">
                                                </nz-option>
                                            </nz-select>
                                        </div>
                                        <div nz-col nzSpan="3" class="uiFilterCol uiOpsCol">
                                            <nz-select [(ngModel)]="op.operatore" *ngIf="op.tratto"
                                                class="uiQuestSelectOperator uiQuestSelect">
                                                <nz-option *ngFor="let s of env.operators" [nzLabel]="s" [nzValue]="s">
                                                </nz-option>
                                            </nz-select>
                                        </div>
                                        <div nz-col nzSpan="9" class="uiFilterCol uiOpsCol">
                                            <nz-input-number [nzStep]="1" [nzMin]="0" class="uiPuntTraitInput"
                                                *ngIf="op.tratto" [(ngModel)]="op.valore">
                                            </nz-input-number>
                                        </div>
                                        <div nz-col nzSpan="1" class="uiFilterLastCol">
                                            <i class="customIcon uiCloseIcon" (click)="removeOp(o, i)"></i>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </nz-table>
            <ng-template #emptyTemplate>
                <div class="uiEmptyContainer">
                    <nz-empty nzNotFoundImage="simple" style="color: var(--neutral-color)"
                        nzNotFoundContent="Nessun gruppo trovato."></nz-empty>
                </div>
            </ng-template>
        </div>
    </nz-content>
</nz-layout>