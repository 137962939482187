<nz-layout class="uiPageInnerLayout">
    <nz-content class="uiGestioneContent">
        <div class="uiGestioneHeader">
            <nz-input-group [nzPrefix]="prefixTemplateUser" class="uiSerchbar">
                <input type="text" nz-input placeholder="{{'search' | translate}}" [(ngModel)]="search" (ngModelChange)="filter()" autocomplete="off" />
            </nz-input-group>
            <ng-template #prefixTemplateUser><i class="customIcon uiFilterIcon"></i></ng-template>
            <button [ngClass]="filterActive ? 'uiFilterBtn' : 'uiFilterActiveBtn'" (click)="active()">
                <i class="customIcon uiFilterRoundIcon"></i>
            </button>
        </div>

        <div class="uiGestioneTableContainer" *ngIf="!loading">
            <nz-table class="uiGestioneTable" #usersTable [nzData]="displayedData" [nzShowPagination]="false" [nzScroll]="scroll" [nzPageSize]="100" [nzWidthConfig]="['3%', '3%', '3%', '20%', '16%', '15%', '8%', '5%', '5%', '14%', '8%' ]">
                <thead>
                    <tr>
                        <th nzAlign="center" colspan="4">NOTE</th>
                        <th nzAlign="center">QUESTIONARIO</th>
                        <th nzAlign="center">REPORT</th>
                        <th nzAlign="center">ANONIMO</th>
                        <th nzAlign="center" colspan="2">LICENZE</th>
                        <th nzAlign="center" colspan="2">SCADENZA</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of usersTable.data; let i = index">
                        <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                            <td nzAlign="center" colSpan="2">
                                <i [ngClass]="openFolder[data.id] ? 'customIcon uiFolderOpenIcon': 'customIcon uiFolderIcon'" (click)="openArea(i)" style="margin: 0;"></i>
                            </td>
                            <td colSpan="9">
                                {{data.id}}&nbsp;&nbsp;&nbsp;{{data.ragione_sociale}}
                            </td>
                        </tr>
                        <ng-container *ngIf="openFolder[data.id]">
                            <ng-container *ngFor="let a of data.licenze; let ai = index">
                                <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                    <td class="uiBlackCol">
                                    </td>
                                    <td nzAlign="center" colSpan="2">
                                        <i [ngClass]="openAreas[data.id][a.id] ? 'customIcon uiFolderOpenIcon': 'customIcon uiFolderIcon'" (click)="openSubarea(i, ai)" style="margin:0"></i>
                                    </td>
                                    <td nzAlign="left">
                                        {{a.id}}&nbsp;&nbsp;&nbsp;{{a.note}}
                                    </td>
                                    <td>
                                        {{a.questionario}}{{a.versione}}&nbsp;{{a.quest.titolo.ITA}}
                                    </td>
                                    <td nzAlign="center">
                                        <span *ngIf="a.quest.report === '1'">Singolo</span>
                                        <span *ngIf="a.quest.report === '2'">Gruppo</span>
                                    </td>
                                    <td nzAlign="center" nz-tooltip [nzTooltipTitle]="a.quest.anonimo ? 'Tipo questionario: Anonimo' : 'Tipo questionario: Normale'">
                                        <nz-switch [ngModel]="a.quest.anonimo" class="uiNoEvents"></nz-switch>
                                    </td>
                                    <td nzAlign="center" nz-tooltip nzTooltipTitle="Utilizzate">
                                        {{a.utilizzate}}
                                    </td>
                                    <td nzAlign="center" class="uiDarkGreyCol" nz-tooltip nzTooltipTitle="Totali">
                                        {{a.totali}}
                                    </td>
                                    <td nzAlign="center">
                                        {{a.scadenza.seconds * 1000 | date: 'dd/MM/yyyy'}}
                                    </td>
                                    <td nzAlign="center" [ngClass]="(a.scadenza.seconds * 1000 ) > now ? 'uiOnCol':'uiOffCol'" nz-tooltip [nzTooltipTitle]="(a.scadenza.seconds * 1000 ) > now ? 'Licenza attiva' : 'Licenza scaduta'">
                                        <nz-switch [ngModel]="(a.scadenza.seconds * 1000 ) > now" nzDisabled>
                                        </nz-switch>
                                    </td>
                                </tr>
                                <ng-container *ngIf="openAreas[data.id][a.id]">
                                    <tr *ngFor="let c of a.compilazioni; let ui = index" [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                        <td class="uiBlackCol">
                                        </td>
                                        <td colSpan="2" nzAlign="center">
                                            <i class="customIcon uiUserIcon" style="margin:0"></i>
                                        </td>
                                        <td colSpan="2" *ngIf="a.quest.anonimo">
                                            U{{ui + 1}}
                                        </td>
                                        <td colSpan="2" *ngIf="!a.quest.anonimo">
                                            {{c.utente.email}}
                                        </td>
                                        <td *ngIf="a.quest.anonimo">

                                        </td>
                                        <td *ngIf="!a.quest.anonimo">
                                            {{c.utente.reparto}}
                                        </td>
                                        <td colSpan="4" *ngIf="a.quest.anonimo">

                                        </td>
                                        <td colSpan="4" *ngIf="!a.quest.anonimo">
                                            {{c.utente.descrizione}}
                                        </td>
                                        <td nzAlign="center">
                                            <i *ngIf="!a.quest.anonimo && a.quest.report !== '2'" [ngClass]="c.commento || a.quest.report === '2' ? 'customIcon uiValutazioniIcon' : 'customIcon uiRedCommentIcon'" (click)="edit(data.id, a.id, c.id)"></i>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tbody>
            </nz-table>
        </div>
    </nz-content>

        <!-- spinner -->
    <div class="waiter" *ngIf="loading">
      <span class="ant-spin-dot ant-spin-dot-spin ng-star-inserted"><i class="ant-spin-dot-item"></i><i
      class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span>
    </div>
</nz-layout>
