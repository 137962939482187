import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Punteggio } from "src/app/entities/punteggi";
import { Tratto } from "src/app/entities/tratti";
import { GlobalService } from "src/app/shared/global.service";
import * as env from "src/environments/env.json";

@Component({
	selector: "app-tratti",
	templateUrl: "./tratti.component.html",
	styleUrls: ["./tratti.component.scss"],
})
export class TrattiComponent implements OnInit {
	env: any = (env as any).default;
	gTrait: Tratto;
	activeTrait: Tratto;
	activatedIndex: number = 1;
	displayedQuestions: any[] = [];
	search = "";
	filterActive: boolean = false;
	loading: boolean = true;
	showTransformModal: boolean = false;
	scroll = { y: "" };

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		event.target.innerWidth;
		this.setScrollHeight();
	}

	constructor(public g: GlobalService, private router: Router) {}

	ngOnInit(): void {
		setTimeout(() => {
			this.setScrollHeight();
		}, 500);
	}

	setScrollHeight() {
		if (document.getElementsByClassName("uiQuestTraitBody").length > 0) {
			let height = document.getElementsByClassName("uiQuestTraitBody")[0].clientHeight;
			if (document.getElementsByClassName("uiQuestTraitBody")[0].getElementsByClassName("ant-table-thead").length) {
				let headH = document
					.getElementsByClassName("uiQuestTraitBody")[0]
					.getElementsByClassName("ant-table-thead")[0].clientHeight;
				this.scroll = { y: height - headH + "px" };
			} else {
				this.scroll = { y: height - 24 + "px" };
			}
		}
	}

	addTrait() {
		let nTrait = new Tratto();
		if (this.g.temp_quest.tratti.length > 0) {
			nTrait.codice =
				"T" + (parseInt(this.g.temp_quest.tratti[this.g.temp_quest.tratti.length - 1].codice.split("T")[1]) + 1);
		} else {
			nTrait.codice = "T" + 1;
		}

		nTrait.titolo = {};
		this.g.languages.forEach((l) => {
			nTrait.titolo[l] = "";
		});
		nTrait.gruppo = "";
		nTrait.valore = 0;
		nTrait.punteggi = {};

		this.g.temp_quest.domande.forEach((quest) => {
			let p: any = new Punteggio();
			p.codice = nTrait.codice + quest.codice;
			p.domanda = {};
			p.domanda.codice = quest.codice;
			p.domanda.filtri = [];
			p.domanda.risposte = [];
			quest.risposte.forEach((answ) => {
				p.domanda.risposte.push({ codice: answ.codice, valore: 0 });
			});
			nTrait.punteggi[quest.codice] = p;
		});

		this.g.temp_quest.tratti = [...this.g.temp_quest.tratti, nTrait];
		if (this.activatedIndex > 0) {
			this.activeTrait = this.g.temp_quest.tratti[this.activatedIndex - 1];
		} else {
			this.activeTrait = this.g.temp_quest.tratti[0];
			this.activatedIndex += 1;
		}
	}

	navigateTrait(e: any) {
		this.activatedIndex = e;
		this.activeTrait = this.g.temp_quest.tratti[e - 1];
	}

	deleteTrait() {
		this.g.temp_quest.tratti.splice(this.activatedIndex - 1, 1);
	}

	addFilter(index: any) {
		let filter: any = {};
		let quest = this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[index].domanda;
		filter.campo = {
			ordine: 0,
			etichetta: "",
			tipo: "",
			opzioni: "",
		};
		filter.operatore1 = "";
		filter.operatore2 = "";
		filter.valore1 = "";
		filter.valore2 = "";
		filter.minimo = 0;
		filter.massimo = 0;

		filter.risposte = [];
		quest.risposte.forEach((answ) => {
			filter.risposte.push({
				codice: answ.codice,
				valore: 0,
			});
		});
		this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[index].domanda.filtri.push(filter);
	}

	selectAnagrafica(event: any, i: number, code: string) {
		this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[code].domanda.filtri[i].campo = event;
		this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[code].domanda.filtri[i].etichetta = event.etichetta;
		if (event.tipo === "Range") {
			let types = this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[code].domanda.filtri[
				i
			].campo.opzioni.replace(";", ",");
			types = this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[code].domanda.filtri[i].campo.opzioni.replace(
				" ",
				""
			);
			let typesArray = types.split(",");
			this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[code].domanda.filtri[i].minimo = parseInt(
				typesArray[0]
			);
			this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[code].domanda.filtri[i].massimo = parseInt(
				typesArray[1]
			);
		}
		if (event.tipo === "Elenco") {
			let types = this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[code].domanda.filtri[
				i
			].campo.opzioni.replace(";", ",");
			types = this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[code].domanda.filtri[i].campo.opzioni.replace(
				" ",
				""
			);
			let typesArray = types.split(",");
			this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[code].domanda.filtri[i].selezioni = typesArray;
		}
	}

	reInitFilter(index, k) {
		this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[index].domanda.filtri[k].campo = {
			ordine: 0,
			etichetta: "",
			tipo: "",
			opzioni: "",
		};
		this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[index].domanda.filtri[k].operatore1 = "";
		this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[index].domanda.filtri[k].operatore2 = "";
		this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[index].domanda.filtri[k].valore1 = "";
		this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[index].domanda.filtri[k].valore2 = "";
		this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[index].domanda.filtri[k].minimo = 0;
		this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[index].domanda.filtri[k].massimo = 0;
	}

	buildArray(min: any, max: any) {
		return Array.from({ length: parseInt(max) - parseInt(min) + 1 }, (v, k) => k + parseInt(min));
	}

	removeFilter(i: number, code: string) {
		this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[code].domanda.filtri.splice(i, 1);
	}

	filter() {
		this.g.temp_quest_questions = this.g.temp_quest.domande.filter(
			(q) =>
				q.titolo[this.g.selectedLanguage].toUpperCase().includes(this.search.toUpperCase()) ||
				q.codice.toUpperCase().includes(this.search.toUpperCase())
		);
	}

	active() {
		this.filterActive = !this.filterActive;
		if (this.filterActive) {
			this.g.temp_quest_questions = this.g.temp_quest_questions.filter((q) => {
				return this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[q.codice].domanda.risposte.some(
					(a) => a.valore != 0
				);
			});
		} else {
			this.g.temp_quest_questions = this.g.temp_quest.domande;
		}
		// if (this.filterActive) {
		//   let actives: any[] = [];
		//   this.g.temp_quest_questions = this.g.temp_quest_questions.filter((q, qindex) => {
		//     // console.log('qindex', qindex);
		//     // console.log('length', this.g.temp_quest_traits[this.activatedIndex - 1].punteggi.length);
		//     return this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[qindex].domanda.risposte.some(
		//       (a, aindex) => {
		//         // console.log(aindex, ' - ', this.g.temp_quest_traits[this.activatedIndex - 1].punteggi[qindex].domanda.risposte.length - 1, a.valore)

		//         if () {
		//           actives.push(this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[qindex]);
		//           return true;
		//         } else {
		//           if (aindex === this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi[qindex].domanda.risposte.length -1) {
		//             // console.log('here', qindex);
		//             return false;
		//           } else {
		//             return false;
		//           }
		//         }
		//       }
		//     );
		//   });
		//   this.g.temp_quest_traits[this.activatedIndex - 1].punteggi = actives;
		// } else {
		//   // console.log(this.g.temp_quest.tratti[this.activatedIndex - 1].punteggi);
		//   //this.g.temp_quest_questions = this.g.temp_quest.domande;
		//   //this.g.temp_quest_traits = this.g.temp_quest.tratti;
		// }
	}

	showTransform() {
		if (this.g.temp_quest.tratti[this.activatedIndex - 1].transform) {
		} else {
			this.g.temp_quest.tratti[this.activatedIndex - 1].transform = [];
			this.g.temp_quest.tratti[this.activatedIndex - 1].transform.push({ punti: "", trasformato: "" });
		}

		this.showTransformModal = true;
	}

	closeTransform() {
		this.showTransformModal = false;
	}

	addTransformRow(ti) {
		if (this.g.temp_quest.tratti[this.activatedIndex - 1].transform[ti + 1]) {
		} else {
			this.g.temp_quest.tratti[this.activatedIndex - 1].transform.push({ punti: "", trasformato: "" });
		}
	}

	saveTransform() {
		this.g.temp_quest.tratti[this.activatedIndex - 1].transform.sort((a, b) =>
			a.punti > b.punti ? 1 : b.punti > a.punti ? -1 : 0
		);
		for (var i = 0; i < this.g.temp_quest.tratti[this.activatedIndex - 1].transform.length - 1; i++) {
			let t = this.g.temp_quest.tratti[this.activatedIndex - 1].transform[i];
			if (t.punti === "" || t.trasformato === "") {
				this.g.temp_quest.tratti[this.activatedIndex - 1].transform.splice(i, 1);
				i--;
			}
		}
		this.showTransformModal = false;
	}
}
