<div class="uiCompilazioneHomeContainer">
    <img class="uiUncollapsedLogo" src="../../../assets/logo-light.png" alt="logo">
    <p class="uiDescriptionText">Benvenuto nel nostro portale per la compilazione questionari.</p>
    <button nz-button class="uiStartBtn" (click)="navigate()" [nzLoading]="startDisabled" [disabled]="startDisabled">
        Iniziamo
    </button>
</div>

<nz-modal [(nzVisible)]="viewModal" nzTitle="" nzFooter="null" [nzContent]="modalManualContent" nzWrapClassName="innerRecoverModal" (nzOnCancel)="exit()">
    <ng-template #modalManualContent>
        <h1>Impossibile iniziare il questionario</h1>
        <p class="modalDesc">
            Un questionario è già in corso. Concludilo prima di iniziarne uno nuovo.
            <button nz-button nzSize="large" nzType="primary" class="loginBtn" (click)="exit()">Esci</button>
        </p>
    </ng-template>
</nz-modal>

<app-legal-footer></app-legal-footer>
