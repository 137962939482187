import { AfterViewInit, Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
	selector: "app-homepage",
	templateUrl: "./homepage.component.html",
	styleUrls: ["./homepage.component.scss"],
})
export class HomepageComponent implements OnInit, AfterViewInit {
	isCollapsed = false;

	// ######################################
	// FUNCTION: constructor
	constructor(public auth: AuthService, public g: GlobalService) {}

	// ######################################
	// FUNCTION: ngAfterViewInit
	ngAfterViewInit(): void {
		setTimeout(() => {
			this.g.actualPage = "Benvenuto " + this.auth.getUser().descrizione;
		});
	}

	// ######################################
	// FUNCTION: ngOnInit
	ngOnInit(): void {}
}
