import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { User } from "src/app/entities/users";
import { AuthService } from "src/app/services/auth.service";
import { GlobalService } from "src/app/shared/global.service";
import { InterfaceService } from "src/app/shared/interface.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
  isCollapsed: boolean;
  loggedIn: User;

  // ######################################
  // FUNCTION: constructor
  constructor(
    public auth: AuthService,
    public g: GlobalService,
    private translate: TranslateService,
    public router: Router,
    public face: InterfaceService
  ) { }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    this.loggedIn = this.auth.getUser();
    let tempPref = this.face.getAdminInterface();
    if (tempPref === null) {
      tempPref = { DESIGN: true, AMMINISTRAZIONE: true, GESTIONE: true, COLLAPSED: true };
      this.face.saveAdminInterface(tempPref);
    }

    setTimeout(() => {
      this.isCollapsed = tempPref.COLLAPSED;
    }, 500);
  }

  // ######################################
  // FUNCTION: clickCollapse
  clickCollapse(event: any) {
    this.isCollapsed = !this.isCollapsed;
    this.face.openChange(this.isCollapsed, "ADMIN", "COLLAPSED");
  }
}
