import { PinComponent } from "./pin/pin.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule } from "@ngx-translate/core";
import { NgZorroModule } from "src/app/ng-zorro.module";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ClienteComponent } from "./cliente.component";
import { ClienteRoutingModule } from "./cliente-routing.module";
import { AuthClienteGuard } from "src/app/guards/authCliente.guard";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { LoginComponent } from "./login/login.component";

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [ClienteComponent, LoginComponent, PinComponent],
	imports: [
		ClienteRoutingModule,
		CommonModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		NgZorroModule,
		ScrollingModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateModule,
				useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
				deps: [HttpClient],
			},
		}),
	],
	exports: [ClienteComponent, NgZorroModule],
	providers: [AuthClienteGuard, AuthClienteService],
})
export class ClienteModule {}
