import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "./services/auth.service";
import { GlobalService } from "./shared/global.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent {
	title = "amaojor-survey";

	// ######################################
	// FUNCTION: constructor
	constructor(
		public g: GlobalService,
		public auth: AuthService,
		public translate: TranslateService,
		private router: Router
	) {
		translate.addLangs(["en", "it"]);
		translate.setDefaultLang("it");
		translate.use("it");
	}

	// ######################################
	// FUNCTION: useLanguage
	useLanguage(language: string) {
		this.translate.use(language);
		this.g.selectedLanguage = language;
	}

	// ######################################
	// FUNCTION: goTo
	goTo(location: string) {
		this.router.navigateByUrl(location);
	}
}
