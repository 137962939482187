import { Component, OnInit } from "@angular/core";
import { GlobalService } from "src/app/shared/global.service";

@Component({
  selector: "app-nopermissions",
  templateUrl: "./nopermissions.component.html",
  styleUrls: ["./nopermissions.component.scss"],
})
export class NopermissionsComponent implements OnInit {
  // ######################################
  // FUNCTION: constructor
  constructor(public g: GlobalService) { }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void { }
}
