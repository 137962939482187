export var firebaseConfig;

if (
	location.hostname === "localhost" ||
	location.hostname === "127.0.0.1" ||
	location.hostname === "collapp.amajorsb.com"
) {
	firebaseConfig = {
		apiKey: "AIzaSyDIJzKMYoDQciHK6gRPdqT3jEB2UV3YKcQ",
		authDomain: "amajor-oca-collsurvey.firebaseapp.com",
		projectId: "amajor-oca-collsurvey",
		storageBucket: "amajor-oca-collsurvey.appspot.com",
		messagingSenderId: "749694313367",
		appId: "1:749694313367:web:e25e4b42ce2f697cfa5ad2",
	};
} else {
	firebaseConfig = {
		apiKey: "AIzaSyDh-Oaj3jwdhx7Q6NKNtTjJN1JbNAD3Du0",
		authDomain: "amajor-oca-survey.firebaseapp.com",
		projectId: "amajor-oca-survey",
		storageBucket: "amajor-oca-survey.appspot.com",
		messagingSenderId: "987755082064",
		appId: "1:987755082064:web:c12595e07bb4e5b0287c55",
	};
}
