import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "keys",
})
export class KeysPipe implements PipeTransform {
	// ######################################
	// FUNCTION: transform
	transform(value): any {
		let keys: any[] = [];
		for (let key in value) {
			let obj = { key: key, value: value[key] };
			keys.push(obj);
		}
		return keys;
	}
}
