import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminRoutingModule } from "./admin-routing.module";
import { AdminComponent } from "./admin.component";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthService } from "src/app/services/auth.service";
import { AuthGuard } from "src/app/guards/auth.guard";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule } from "@ngx-translate/core";
import { NgZorroModule } from "src/app/ng-zorro.module";
import { HomepageComponent } from "./homepage/homepage.component";
import { DesignerComponent } from "./designer/designer.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ClientiComponent } from "./amministrazione/clienti/clienti.component";
import { UtentiComponent } from "./amministrazione/utenti/utenti.component";
import { PubblicazioneComponent } from "./amministrazione/pubblicazione/pubblicazione.component";
import { GeticonPipe } from "src/app/pipes/geticon.pipe";
import { GetnamePipe } from "../../pipes/getname.pipe";
import { TypeofPipe } from "../../pipes/typeof.pipe";
import { ImgModalComponent } from "./amministrazione/clienti/img-modal/img-modal.component";
import { ValutazioniComponent } from "./gestione/valutazioni/valutazioni.component";
import { PrivacyComponent } from "./gestione/privacy/privacy.component";
import { AggregatiComponent } from "./gestione/aggregati/aggregati.component";
import { EditAggregatiComponent } from "./gestione/aggregati/edit-aggregati/edit-aggregati.component";

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AdminComponent,
		HomepageComponent,
		DesignerComponent,
		ClientiComponent,
		UtentiComponent,
		PubblicazioneComponent,
		GeticonPipe,
		GetnamePipe,
		TypeofPipe,
		ImgModalComponent,
		ValutazioniComponent,
		PrivacyComponent,
		AggregatiComponent,
	],
	imports: [
		AdminRoutingModule,
		CommonModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		NgZorroModule,
		ScrollingModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateModule,
				useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
				deps: [HttpClient],
			},
		}),
	],
	exports: [AdminComponent, NgZorroModule],
	providers: [AuthService, AuthGuard],
})
export class AdminModule {}
