import { Domanda } from "./domande"
import { Gruppo_attendibilità } from "./gruppi_attendibilita"
import { Gruppo_forzatura } from "./gruppi_forzatura"
import { Tratto } from "./tratti"

export class Questionario {
    codice: number
    titolo: any = {}
    versioni: Questionario[] = []
    //versione: string = '1'
    sottoversione: string = '1'
    tipo: number
    pubblico: boolean = false
    note: string = ''
    report: string = '1'
    copertina_report: any = {}
    privacy: any = '';
    autorizzazioni: string[] = [];
    anagrafica: {ordine: number, etichetta: string, tipo: "Testo" | "Booleano" | "Range" | "Data" | "Codice fiscale" | "Partita iva" | "Elenco", opzioni: string, richiesto: boolean}[] = [];
    indice_attendibilita: number = 0;
    indice_forzatura: number = 0;
    ultima_modifica: Date
    anonimo: boolean = false;
    semafori: {questionario: Questionario, tratto: Tratto, descrizione: string, valore: number, filtro: {operatore: string, valori: string} }[] = [];
    lingue: string[] = ['ITA']
    
    domande: Domanda[] = [];
    tratti: Tratto[] = [];
    gruppi_attendibilita: Gruppo_attendibilità[] = [];
    gruppi_forzatura: Gruppo_forzatura[] = [];
    collegati: any[] = []
}