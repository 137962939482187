import { GlobalService } from "src/app/shared/global.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "app-compilazione",
	templateUrl: "./compilazione.component.html",
	styleUrls: ["./compilazione.component.scss"],
})
export class CompilazioneComponent implements OnInit {
	// ######################################
	// FUNCTION: constructor
	constructor(public router: Router, private g: GlobalService) {
		this.g.globalFooter = false;
	}

	// ######################################
	// FUNCTION: ngOnInit
	ngOnInit(): void {}
}
