import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FireService } from "src/app/services/fire.service";

@Component({
	selector: "app-cliente",
	templateUrl: "./cliente.component.html",
	styleUrls: ["./cliente.component.scss"],
})
export class ClienteComponent implements OnInit {
	// ######################################
	// FUNCTION: constructor
	constructor() {}

	// ######################################
	// FUNCTION: ngOnInit
	ngOnInit(): void {}
}
