<nz-layout class="uiPageInnerLayout">
    <nz-content class="uiDesignerContent">
        <div class="uiDesignerHeader">
            <div class="uiDesignerLeftHeader">
                <nz-input-group [nzPrefix]="prefixTemplateUser" class="uiSerchbar">
                    <input type="text" nz-input placeholder="{{'search' | translate}}" [(ngModel)]="search" (ngModelChange)="filter()" autocomplete="off" />
                </nz-input-group>
                <ng-template #prefixTemplateUser><i class="customIcon uiFilterIcon"></i></ng-template>
            </div>
            <div class="uiDesignerRightHeader">
                <button nz-button nz-dropdown [nzDropdownMenu]="questTypes" class="uiNewQuestButton">
                    Nuovo questionario
                    <span class="uiArrowDropdownIcon">&#9660;</span>
                </button>
            </div>
            <nz-dropdown-menu #questTypes="nzDropdownMenu">
                <ul nz-menu class="uiButtonSubMenu">
                    <li nz-menu-item (click)="new(1)">Standard</li>
                    <li nz-menu-item (click)="new(2)">Aggregato</li>
                </ul>
            </nz-dropdown-menu>
        </div>
        <div class="uiQuestsTableContainer">
            <nz-table class="uiQuestsTable" #questsTable [nzData]="displayedData" [nzShowPagination]="false" [nzScroll]="scroll" [nzPageSize]="100" *ngIf="!loading">
                <thead>
                    <tr>
                        <th nzWidth="10%" nzAlign="center">CODICE</th>
                        <th nzWidth="10%" nzAlign="center">PUBBLICO</th>
                        <th nzWidth="27%" nzAlign="center">TITOLO</th>
                        <th nzWidth="10%" nzAlign="center">LINGUE</th>
                        <th nzWidth="10%" nzAlign="center">TIPO</th>
                        <th nzWidth="8%" nzAlign="center">ANONIMO</th>
                        <th nzWidth="15%" nzAlign="center">DATA ULTIMA MOD.</th>
                        <th nzWidth="10%" nzAlign="center">AZIONI</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of questsTable.data; let i = index">
                        <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                            <td colSpan="2">
                                <i *ngIf="data.autorize" [ngClass]="openFolder[i] ? 'customIcon uiFolderOpenIcon': 'customIcon uiFolderIcon'" (click)="openVersions(i)" nz-tooltip [nzTooltipTitle]="openFolder[i] ? 'Chiudi la cartella' : 'Apri la cartella'"></i> Q{{data.codice}}
                            </td>
                            <td>
                                <div *ngIf="!editCache[data.id].edit">
                                    {{data.titolo}}
                                </div>
                                <div *ngIf="editCache[data.id].edit">
                                    <input nz-input [(ngModel)]="editCache[data.id].data.titolo" />
                                </div>
                            </td>
                            <td nzAlign="center" *ngIf="data.lingue">{{data.lingue.length}}</td>
                            <td nzAlign="center">
                                <span *ngIf="data.tipo === 1">
                                    Standard
                                </span>
                                <span *ngIf="data.tipo === 2">
                                    Aggregato
                                </span>
                            </td>
                            <td nzAlign="center" [ngClass]="data.anonimo ? 'uiGreenCol' : ''">
                                <nz-switch class="uiQuestSwitch" [ngModel]="data.anonimo" (ngModelChange)="onChangeQSwitch($event, i)" nz-tooltip [nzTooltipTitle]="data.anonimo ? 'Tipo questionario: Anonimo' : 'Tipo questionario: Normale'"></nz-switch>
                            </td>
                            <td nzAlign="center" class="uiBlackCol" nzAlign="center" *ngIf="data.versione_attiva <= 0">

                            </td>
                            <td nzAlign="center" class="uiGreenCol" nzAlign="center" *ngIf="data.versione_attiva > 0">
                                Pubblico
                            </td>
                            <td nzAlign="center">
                                <div *ngIf="!editCache[data.id].edit">
                                    <i class="customIcon uiAddIcon" (click)="addVersion(i)" *ngIf="data.autorize" nz-tooltip nzTooltipTitle="Aggiungi versione"></i>
                                    <i class="customIcon uiEditIcon" (click)="edit(data.id)" style="margin-left: 15px;" *ngIf="data.autorize" nz-tooltip nzTooltipTitle="Modifica titolo"></i>
                                    <i class="customIcon uiTrashGreyIcon" (nzOnConfirm)="delete(data.id)" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler cancellare il questionario Q{{data.codice}}?" style="margin-left: 15px; margin-right: 0;" *ngIf="data.autorize && data.versioni <= 0" nz-tooltip nzTooltipTitle="Elimina questionario"></i>
                                </div>
                                <div *ngIf="editCache[data.id].edit">
                                    <i style="margin-right: 10px;" class="customIcon uiSaveRedIcon" (click)="saveEdit(data.id)" nz-tooltip nzTooltipTitle="Salva modifiche"></i>
                                    <i style="margin-right: 10px;" class="customIcon uiCloseGreyIcon" (click)="cancelEdit(data.id)" nz-tooltip nzTooltipTitle="Annulla modifiche"></i>
                                </div>

                            </td>
                        </tr>
                        <ng-container *ngIf="openFolder[i]">
                            <tr *ngFor="let v of data.versioni; let vi = index" [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                <td class="uiVersionsCol">
                                    <div class="innerRow">
                                        <div nz-col nzSpan="10" class="uiBlackCol">
                                        </div>

                                        <div nz-col nzSpan="14" class="uiVersionCode">
                                            V{{v.codice}}
                                        </div>
                                    </div>
                                </td>
                                <td nzAlign="center" [ngClass]="v.pubblico ? 'uiGreenCol' : ''">
                                    <nz-switch [(ngModel)]="v.pubblico" (ngModelChange)="onChangeSwitch($event, i, vi)">
                                    </nz-switch>
                                </td>
                                <td colSpan="4">
                                    {{v.titolo[g.selectedLanguage]}}
                                </td>
                                <td nzAlign="center">
                                    {{v.ultima_modifica.seconds * 1000 | date: 'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                                <td nzAlign="center">
                                    <i style="margin-right: 10px;" class="customIcon uiTrashGreyIcon" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler cancellare la versione V{{v.codice}} del questionario Q{{data.codice}}?" (nzOnConfirm)="deleteQ(i, vi)" nz-tooltip nzTooltipTitle="Elimina versione"></i>
                                    <i style="margin-right: 10px;" class="customIcon uiCopyGreyIcon" (click)="duplicate(i, vi)" nz-tooltip nzTooltipTitle="Duplica versione"></i>
                                    <i class="customIcon uiEditIcon" (click)="editQ(i, vi)" nz-tooltip nzTooltipTitle="Modifica versione"></i>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </nz-table>
        </div>
    </nz-content>

    <!-- spinner -->
    <div class="waiter" *ngIf="loading">
      <span class="ant-spin-dot ant-spin-dot-spin ng-star-inserted"><i class="ant-spin-dot-item"></i><i
      class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span>
    </div>
</nz-layout>
