import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthClienteService } from '../services/authCliente.service';

@Injectable({
  providedIn: 'root'
})
export class AuthClienteGuard implements CanActivate, CanActivateChild {
  constructor(private auth: AuthClienteService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.auth.isLoggedIn()) {
        return true;
      } else {
        this.router.navigateByUrl('/login');
        return false;
      }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let user = this.auth.getUser();
      if (this.auth.isLoggedIn()) {
        return true;
      } else {
        this.router.navigateByUrl('/login');
        return false;
      }
  }
}
