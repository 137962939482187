import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CompileGuard } from "src/app/guards/compile.guard";
import { CompilazioneComponent } from "./compilazione.component";
import { ConclusioneComponent } from "./conclusione/conclusione.component";
import { DomandeComponent } from "./domande/domande.component";
import { HomepageComponent } from "./homepage/homepage.component";
import { IntestazioneComponent } from "./intestazione/intestazione.component";
import { NopermissionsComponent } from "./nopermissions/nopermissions.component";
import { NocompletedComponent } from "./nocompleted/nocompleted.component";

const routes: Routes = [
  {
    path: "compilazione",
    component: CompilazioneComponent,
    children: [
      {
        path: "intestazione",
        component: IntestazioneComponent,
        canActivate: [CompileGuard],
        canActivateChild: [CompileGuard],
      },
      { path: "domande", component: DomandeComponent, canActivate: [CompileGuard], canActivateChild: [CompileGuard] },
      { path: "conclusione", component: ConclusioneComponent },
      { path: "accesso-vietato", component: NopermissionsComponent },
      { path: "accesso-completato", component: NocompletedComponent },
      { path: ":code", component: HomepageComponent, canActivate: [CompileGuard], canActivateChild: [CompileGuard] },
      { path: "**", redirectTo: "" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompilazioneRoutingModule { }
