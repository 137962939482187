<nz-layout>
  <nz-sider
    class="uiClienteSidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [nzTrigger]="null"
  >
    <div class="uiClienteSidebarLogo">
      <a href="/"><img class="uiClienteLogo" [src]="logo" alt="logo" /></a>
    </div>
    <ul class="uiClienteMenu" nz-menu nzTheme="light" nzMode="inline">
      <li
        nz-menu-item
        class="uiClienteMenuItem uiActionItem"
        [nzMatchRouter]="true"
      >
        <a (click)="back()"
          ><i class="customIcon uiRollbackIcon"></i> Ritorna</a
        >
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header class="uiHeader uiQuestHeader">
      <div nz-row class="uiHeaderRow">
        <div class="uiHeaderLeftCol" nz-col nzSpan="16">
          <span>{{ g.actualPage }}</span>
        </div>
        <div nz-col nzSpan="8" class="uiHeaderRightCol">
          <i
            *ngIf="auth.isLoggedIn()"
            class="customIcon uiLogoutIcon"
            (click)="auth.logout()"
          ></i>
        </div>
      </div>
    </nz-header>
    <nz-content class="uiGestioneEditContent" *ngIf="!loading">
      <div nz-row class="uiGestioneEditParagraphRow" [nzGutter]="16">
        <div class="uiParagraphLeft">
          <span *ngIf="repName"
            >{{ repName.nome }} |
            {{ newDests.length + displayedData.length }} utenti |
            {{ displayedData.length }} destinatari | {{ dataError.length }} non
            validi</span
          >
        </div>
        <div class="uiParagraphRight">
          <button
            nz-button
            class="uiAddDest"
            (click)="selectDest(tplUserEmail, tplFooter, false)"
          >
            Invia Email
            <i
              style="filter: contrast()"
              class="customIcon uiEmailSendWhite"
            ></i>
          </button>
          <button
            nz-button
            class="uiAddDest"
            (click)="selectDest(tplUserSms, tplSmsFooter, true)"
          >
            Invio SMS
            <i style="filter: contrast()" class="customIcon uiSmsSendWhite"></i>
          </button>
        </div>
      </div>

      <div class="uiGestioneEditHeader">
        <nz-input-group [nzPrefix]="prefixTemplateUser" class="uiSerchbar">
          <input
            type="text"
            nz-input
            placeholder="{{ 'search' | translate }}"
            [(ngModel)]="search"
            (ngModelChange)="filter()"
            autocomplete="off"
          />
        </nz-input-group>
        <ng-template #prefixTemplateUser
          ><i class="customIcon uiFilterIcon"></i
        ></ng-template>
      </div>

      <div class="uiGestioneEditTableContainer" *ngIf="!loading">
        <nz-table
          class="uiGestioneEditTable"
          #usersTable
          [nzData]="displayedData"
          [nzShowPagination]="false"
          [nzScroll]="scroll"
          [nzPageSize]="100"
          [nzWidthConfig]="['80px', '', '', '', '', '150px']"
        >
          <thead>
            <tr>
              <th nzAlign="center" colspan="2">DESTINATARI</th>
              <th nzAlign="center">REPARTO</th>
              <th nzAlign="center">DESCRIZIONE</th>
              <th nzAlign="center">LINK QUESTIONARIO</th>
              <th nzAlign="center">AZIONI</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of usersTable.data; let i = index">
              <tr
                [ngClass]="i % 2 === 0 ? 'uiLightColorRow' : 'uiDarkColorRow'"
              >
                <td nzAlign="center">
                  <i class="customIcon uiUserWhiteIcon"></i>
                </td>
                <td nzAlign="center">
                  {{ questionario.anonimo ? "Anomimo" : data.email }}
                </td>
                <td nzAlign="center">
                  {{ repName.nome }}
                </td>
                <td nzAlign="center">
                  {{ questionario.anonimo ? "Utente" : data.descrizione }}
                </td>
                <td nzAlign="center" class="uiLinkCol">
                  <p
                    *ngIf="
                      data.compilazione &&
                      !data.compilazione.sent &&
                      data.compilazione.concluso === undefined
                    "
                  >
                    Da inviare
                    <i
                      class="customIcon uiLinkNotSent"
                      nz-popconfirm
                      nzPopconfirmTitle="Vuoi inviare nuovamente l'email a questo utente?"
                      (nzOnConfirm)="utenteSend(data)"
                      nz-tooltip
                      nzTooltipTitle="Invia email"
                    ></i>
                  </p>
                  <p
                    *ngIf="
                      data.compilazione &&
                      data.compilazione.sent &&
                      data.compilazione.sent === true
                    "
                  >
                    Inviato
                    <i
                      class="customIcon uiLinkSent"
                      nz-popconfirm
                      nzPopconfirmTitle="Vuoi inviare nuovamente l'email a questo utente?"
                      (nzOnConfirm)="utenteSend(data)"
                      nz-tooltip
                      nzTooltipTitle="Invia email"
                    ></i>
                  </p>
                  <p
                    *ngIf="
                      data.compilazione &&
                      data.compilazione.concluso !== undefined &&
                      data.compilazione.concluso === false
                    "
                  >
                    Iniziato
                    <i
                      class="customIcon uiLinkBegin"
                      nz-popconfirm
                      nzPopconfirmTitle="Vuoi inviare nuovamente l'email a questo utente?"
                      (nzOnConfirm)="utenteSend(data)"
                      nz-tooltip
                      nzTooltipTitle="Invia email"
                    ></i>
                  </p>
                  <p
                    *ngIf="
                      data.compilazione &&
                      data.compilazione.concluso !== undefined &&
                      data.compilazione.concluso === true
                    "
                  >
                    <span
                      *ngIf="
                        data.compilazione.attendibilita === true &&
                        data.compilazione.forzatura === true
                      "
                      >Completato
                      <i
                        class="customIcon uiLinkEnd"
                        nz-popconfirm
                        nzPopconfirmTitle="Vuoi inviare nuovamente l'email a questo utente?"
                        (nzOnConfirm)="utenteSend(data)"
                        nz-tooltip
                        nzTooltipTitle="Invia email"
                      ></i
                    ></span>
                    <span
                      *ngIf="
                        data.compilazione.attendibilita === false &&
                        data.compilazione.forzatura === true
                      "
                      >Non attendibile
                      <i
                        class="customIcon uiLinkError"
                        nz-popconfirm
                        nzPopconfirmTitle="Vuoi inviare nuovamente l'email a questo utente?"
                        (nzOnConfirm)="utenteSend(data)"
                        nz-tooltip
                        nzTooltipTitle="Invia email"
                      ></i
                    ></span>
                    <span
                      *ngIf="
                        data.compilazione.attendibilita === true &&
                        data.compilazione.forzatura === false
                      "
                      >Forzato
                      <i
                        class="customIcon uiLinkError"
                        nz-popconfirm
                        nzPopconfirmTitle="Vuoi inviare nuovamente l'email a questo utente?"
                        (nzOnConfirm)="utenteSend(data)"
                        nz-tooltip
                        nzTooltipTitle="Invia email"
                      ></i
                    ></span>
                    <span
                      *ngIf="
                        data.compilazione.attendibilita === false &&
                        data.compilazione.forzatura === false
                      "
                      >Forzato e non attendibile<i
                        class="customIcon uiLinkError"
                        nz-popconfirm
                        nzPopconfirmTitle="Vuoi inviare nuovamente l'email a questo utente?"
                        (nzOnConfirm)="utenteSend(data)"
                        nz-tooltip
                        nzTooltipTitle="Invia email"
                      ></i
                    ></span>
                  </p>
                </td>
                <td nzAlign="center">
                  <div *ngIf="versione.report !== '2'">
                    <i
                      [ngClass]="
                        data.compilazione &&
                        data.compilazione.concluso !== undefined &&
                        data.compilazione.concluso === true &&
                        data.compilazione.commento !== ''
                          ? 'customIcon uiWhiteValutazioniIcon'
                          : 'customIcon uiWhiteValutazioniIcon uiDisabledIcon'
                      "
                      style="margin-left: 10px"
                      (click)="showValutazione(data)"
                    ></i>
                    <i
                      [ngClass]="
                        data.compilazione &&
                        data.compilazione.concluso !== undefined &&
                        data.compilazione.concluso === true
                          ? 'customIcon uiReportIcon'
                          : 'customIcon uiReportIcon uiDisabledIcon'
                      "
                      style="margin-left: 10px"
                      (click)="export(data)"
                    ></i>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
      <google-chart
        #chart
        id="chart"
        [type]="g.chartType"
        [data]="g.chartData"
        [columns]="g.chartColumnNames"
        *ngIf="g.chartVisible"
        [options]="g.chartDisplayOptions"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 99%;
          height: 99%;
          z-index: -1;
        "
      ></google-chart>
      <div class="chartOverlay" *ngIf="g.chartProgress">
        <i class="customIcon uiLoadingIcon"></i>
        <br />
        <h1>Esportazione in corso...</h1>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>

<!-- <nz-modal [(nzVisible)]="commentModal" nzTitle="" nzFooter="null" [nzContent]="modalCommentContent" nzWrapClassName="innerCommentModal" (nzOnCancel)="hideComment()">
    <ng-template #modalCommentContent>
        <b>Commento e valutazione di Amajor</b>
        <br /><br />
        <p [innerHTML]="comment"></p>
    </ng-template>
</nz-modal> -->

<ng-template #tplUserEmail let-ref="modalRef">
  <nz-table
    #rowSelectionTable
    nzShowSizeChanger
    nzBordered
    [nzData]="utentiToSend"
    [nzShowPagination]="false"
    [nzScroll]="{ y: '60vh' }"
  >
    <thead>
      <tr>
        <th
          nzWidth="50px"
          [(nzChecked)]="utentiAllChecked"
          [nzIndeterminate]="utentiIntChecked"
          (nzCheckedChange)="onAllChecked($event)"
        ></th>
        <th>SELEZIONARE GLI UTENTI</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let u of utentiToSend">
        <td
          [(nzChecked)]="u.check"
          (nzCheckedChange)="refreshCheckedStatus()"
        ></td>
        <td>{{ u.email == "" ? u.mobile : u.email }}</td>
      </tr>
    </tbody>
  </nz-table>
</ng-template>
<ng-template #tplFooter let-ref="modalRef">
  <button nz-button (click)="ref.destroy()">Annulla</button>
  <button
    nz-button
    nzType="primary"
    [disabled]="!utentiIntChecked && !utentiAllChecked"
    (click)="utentiSend(ref)"
  >
    Invia Email
  </button>
</ng-template>

<!-- TEMPLATE SEND SMS LIST -->

<ng-template #tplUserSms let-ref="modalRef">
  <nz-table
    #rowSelectionTable
    nzShowSizeChanger
    nzBordered
    [nzData]="utentiToSend"
    [nzShowPagination]="false"
    [nzScroll]="{ y: '60vh' }"
  >
    <thead>
      <tr>
        <th
          nzWidth="50px"
          [(nzChecked)]="utentiAllChecked"
          [nzIndeterminate]="utentiIntChecked"
          (nzCheckedChange)="onAllChecked($event)"
        ></th>
        <th>UTENTE</th>
        <th>NUMERO</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let u of utentiToSend">
        <td
          [(nzChecked)]="u.check"
          (nzCheckedChange)="refreshCheckedStatus()"
        ></td>
        <td>{{ u.descrizione }}</td>
        <td>{{ u.mobile }}</td>
      </tr>
    </tbody>
  </nz-table>
</ng-template>

<!-- TEMPLATE SMS FOOTER -->
<ng-template #tplSmsFooter let-ref="modalRef">
  <button nz-button (click)="ref.destroy()">Annulla</button>
  <button
    nz-button
    nzType="primary"
    [disabled]="!utentiIntChecked && !utentiAllChecked"
    [nzLoading]="isSending"
    (click)="sendSms(ref)"
  >
    Invia SMS
  </button>
</ng-template>
