<div class="uiLoginContainer" *ngIf="loading && !showError">

    <img class="uiUncollapsedLogo" src="../../../assets/logo.svg" alt="logo">

    <div class="uiFormContainer">
        <h1 class="uiLoginTitle" [innerHTML]="'recoverTitle' | translate"></h1>
        <form [formGroup]="userForm">
            <div nz-row class="uiFormRow">
                <input type="password" nz-input formControlName="password" [(ngModel)]="password" placeholder="Inserisci password" />
                <div *ngIf="userForm.get('password')?.invalid && (userForm.get('password')?.dirty && userForm.get('password')?.touched)">
                    <small class="uiAlertMessage" *ngIf="userForm.get('password')?.hasError('minlength')">
                        {{'pwdLengthAlert' | translate}}
                    </small>
                </div>
            </div>
            <div nz-row class="uiFormRow" style="margin-top: 1rem;">
                <input type="password" nz-input formControlName="confirmPassword" [(ngModel)]="repeatpassword" placeholder="Conferma password" />
                <div *ngIf="(userForm.get('confirmPassword')?.dirty && userForm.get('confirmPassword')?.touched)">
                    <small class="uiAlertMessage" *ngIf="userForm.hasError('notSame')">
                        {{'pwdMatchAlert' | translate}}
                    </small>
                </div>
            </div>
            <div nz-row class="uiFormRow">
                <div nz-col nzSpan="12" class="uiFormSubmit">
                    <button nz-button nzType="primary" [disabled]="userForm.invalid" class="loginBtn" (click)="onSubmit()">{{'saveCredential' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="uiLoginContainer" *ngIf="loading && showError">
    <div class="uiFormContainer">
        <p class="errorMessage">{{'recoverError' | translate}}</p>
    </div>
</div>