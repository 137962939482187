import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from "@angular/forms";
import { Directive } from "@angular/core";

@Directive({
	selector: "[cf]",
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: CfValidatorDirective,
			multi: true,
		},
	],
})
export class CfValidatorDirective implements Validator {
	validate(control: AbstractControl): { [key: string]: any } | null {
		// console.log("VALIDAZIONE");
		return (control: AbstractControl): { [key: string]: any } | null =>
			control.value?.toLowerCase() === "cf" ? null : { wrongCf: control.value };
	}
}
