import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthClienteService } from "src/app/services/authCliente.service";

@Component({
	selector: "app-logo",
	templateUrl: "./logo.component.html",
	styleUrls: ["./logo.component.scss"],
})
export class LogoComponent implements OnInit {
	logo: SafeResourceUrl;

	// ######################################
	// FUNCTION: constructor
	constructor(private sanitizer: DomSanitizer, private auth: AuthClienteService) {}

	// ######################################
	// FUNCTION: ngOnInit
	ngOnInit(): void {
		let fileURL = this.auth.getLogo();
		this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
	}
}
