import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ShowValutazioniRoutingModule } from "./show-valutazioni-routing.module";
import { ShowValutazioniComponent } from "./show-valutazioni.component";
import { NgZorroModule } from "src/app/ng-zorro.module";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule } from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AuthService } from "src/app/services/auth.service";
import { AuthGuard } from "src/app/guards/auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { QuillModule } from "ngx-quill";
import { GoogleChartsModule } from "angular-google-charts";
import { HighchartsChartModule } from "highcharts-angular";

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [ShowValutazioniComponent],
	imports: [
		ShowValutazioniRoutingModule,
		CommonModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		NgZorroModule,
		HighchartsChartModule,
		ReactiveFormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateModule,
				useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
				deps: [HttpClient],
			},
		}),
		QuillModule.forRoot({
			modules: {
				toolbar: [["bold", "italic", "underline", "strike"], [{ size: ["small", false, "large", "huge"] }]],
			},
			placeholder: "Inserisci testo...",
			theme: "snow",
		}),
		GoogleChartsModule,
	],
	exports: [ShowValutazioniComponent, NgZorroModule],
	providers: [AuthService, AuthGuard],
})
export class ShowValutazioniModule {}
