import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { EditRoutingModule } from "./edit-routing.module";
import { EditComponent } from "./edit.component";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgZorroModule } from "src/app/ng-zorro.module";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { TranslateModule } from "@ngx-translate/core";
import { AuthClienteGuard } from "src/app/guards/authCliente.guard";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { GoogleChartsModule } from "angular-google-charts";

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [EditComponent],
	imports: [
		EditRoutingModule,
		CommonModule,
		CommonModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		NgZorroModule,
		ScrollingModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateModule,
				useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
				deps: [HttpClient],
			},
		}),
		GoogleChartsModule,
	],
	exports: [NgZorroModule],
	providers: [AuthClienteGuard, AuthClienteService],
})
export class EditModule {}
