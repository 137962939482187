import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditPrivacyComponent } from './edit-privacy.component';
import { EditPrivacyRoutingModule } from './edit-privacy-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgZorroModule } from 'src/app/ng-zorro.module';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from 'src/app/guards/auth.guard';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [EditPrivacyComponent],
  imports: [
    EditPrivacyRoutingModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgZorroModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateModule,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
      }
    })
  ],
  exports: [EditPrivacyComponent, NgZorroModule],
  providers: [AuthService, AuthGuard],
})
export class EditPrivacyModule { }
