// export class Punteggio {
//     codice: string
//     codice_domanda: string
//     codice_risposta: string
//      filtri: { 
//         campo: { 
//             ordine: number, 
//             etichetta: string, 
//             tipo: "Testo" | "Booleano" | "Range" | "Data" | "Codice fiscale" | "Partita iva" | "Elenco", 
//             opzioni: string, 
//             richiesto: boolean 
//         }, 
//         operatore1: string, operatore2: string, valore1: string, valore2: string, minimo: number, massimo: number 
//     }[]
//     valore: number
// }
export class Punteggio {
    codice: string
    domanda: {
        codice: string,
        risposte: {
            codice: string
            valore: number
        }[]
        filtri: {
            risposte: {
                codice: string
                valore: number
                
            }[]
            campo: { 
                ordine: number, 
                etichetta: string, 
                tipo: "Testo" | "Booleano" | "Range" | "Data" | "Codice fiscale" | "Partita iva" | "Elenco", 
                opzioni: string
            }, 
            operatore1: string, operatore2: string, valore1: string, valore2: string, minimo: number, massimo: number
        }[]
    }
}