<nz-layout class="uiPageInnerLayout">
    <nz-content class="uiClientiContent">
        <div class="uiClientiHeader">
            <div class="uiClientiLeftHeader">
                <nz-input-group [nzPrefix]="prefixTemplateUser" class="uiSerchbar">
                    <input type="text" nz-input placeholder="{{'search' | translate}}" [(ngModel)]="search" (ngModelChange)="filter()" autocomplete="off" />
                </nz-input-group>
                <ng-template #prefixTemplateUser><i class="customIcon uiFilterIcon"></i></ng-template>
            </div>
            <div class="uiClientiRightHeader">
                <button nz-button class="uiNewUserButton" (click)="newR()">
                    Nuovo cliente
                </button>
            </div>
        </div>

        <div class="uiClientiTableContainer" *ngIf="!loading">
            <nz-table class="uiClientiTable" #usersTable [nzData]="displayedData" [nzShowPagination]="false" [nzScroll]="scroll" [nzPageSize]="100" [nzWidthConfig]="['5%', '5%', '5%', '15%', '10%', '8%', '8%', '8%', '8%', '10%', '8%', '10%']">
                <thead>
                    <tr>
                        <th nzAlign="center" colspan="3">CODICE</th>
                        <th nzAlign="center" colspan="4">RAGIONE SOCIALE</th>
                        <th nzAlign="center" nzWidth="80px">LOGO</th>
                        <th nzAlign="center">UTENTI</th>
                        <th nzAlign="center">ULTIMO LOG</th>
                        <th nzAlign="center" nzWidth="80px">STATO</th>
                        <th nzAlign="center" nzWidth="150px">AZIONI</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of usersTable.data; let i = index">
                        <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                            <td nzAlign="center">
                                <i [ngClass]="openFolder[i] ? 'customIcon uiFolderOpenIcon': 'customIcon uiFolderIcon'" (click)="openArea(i)" style="margin: 0;" nz-tooltip [nzTooltipTitle]="openFolder[i] ? 'Chiudi il cliente' : 'Apri il cliente'"></i>
                            </td>
                            <td colSpan="2" nzAlign="center">
                                {{data.id}}
                            </td>
                            <td colSpan="4">
                                <div *ngIf="!editCacheR[data.id].edit">{{data.ragione_sociale}}</div>
                                <div *ngIf="editCacheR[data.id].edit"><input type="text" nz-input [(ngModel)]="editCacheR[data.id].data.ragione_sociale" /></div>
                            </td>
                            <td nzAlign="center" *ngIf="data.logo === ''">
                                <nz-upload (nzChange)="handleChange($event, i)" nzAction="{{uploadURL}}" nzAccept="image/png, image/jpeg" [nzCustomRequest]="customFileReq" [nzShowUploadList]="g.uploadButtons">
                                    <button nz-button class="uiUploadBtn" (click)="beforeUpload(data.id)" nz-tooltip nzTooltipTitle="Carica il logo del cliente">
                                        <i class="customIcon uiAddImageIcon"></i>
                                    </button>
                                </nz-upload>
                            </td>
                            <td class="uiYellowCol" *ngIf="data.logo !== ''">
                                <button nz-button class="uiUploadBtn" (click)="downloadFile(data.logo)" nz-tooltip nzTooltipTitle="Visualizza il logo">
                                    <i class="customIcon uiShowImageIcon"></i>
                                    <i class="customIcon uiCloseGreyIcon" style="width: 15px; height: 15px; margin-bottom: 17px;" (click)="deleteFile(i)" nz-tooltip nzTooltipTitle="Rimuovi il logo"></i>
                                </button>
                            </td>
                            <td nzAlign="center">{{data.n_utenti}}</td>
                            <td></td>
                            <td nzAlign="center" [ngClass]="data.attivo ? 'uiOnCol':'uiOffCol'">
                                <nz-switch [ngModel]="data.attivo" (ngModelChange)="onChangeSwitchR($event, i)" nz-tooltip [nzTooltipTitle]="data.attivo ? 'Cliente attivo' : 'Cliente non attivo'">
                                </nz-switch>
                            </td>
                            <td nzAlign="center" *ngIf="!editCacheR[data.id].edit">
                                <i style="margin-right: 10px;" class="customIcon uiAddIcon" (click)="newA(i)" nz-tooltip nzTooltipTitle="Aggiungi un reparto"></i>
                                <i style="margin-right: 10px;" class="customIcon uiTrashGreyIcon" nz-popconfirm nzPopconfirmTitle="Eliminare questa riga? deleteR" (nzOnConfirm)="deleteR(i)" nz-tooltip nzTooltipTitle="Elimina il cliente"></i>
                                <i class="customIcon uiEditIcon" (click)="editR(data.id)" nz-tooltip nzTooltipTitle="Modifica il nome"></i>
                            </td>
                            <td nzAlign="center" *ngIf="editCacheR[data.id].edit">
                                <i style="margin-right: 10px;" class="customIcon uiSaveRedIcon" (click)="saveEditR(data.id)"></i>
                                <i style="margin-right: 10px;" class="customIcon uiCloseGreyIcon" (click)="cancelEditR(data.id)"></i>
                            </td>
                        </tr>
                        <ng-container *ngIf="openFolder[i]">
                            <ng-container *ngFor="let a of data.reparti; let ai = index">
                                <tr [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                    <td class="uiBlackCol">

                                    </td>
                                    <td nzAlign="center">
                                        <i [ngClass]="openAreas[i][ai] ? 'customIcon uiFolderOpenIcon': 'customIcon uiFolderIcon'" (click)="openusers(i, ai)" style="margin:0" nz-tooltip [nzTooltipTitle]="openAreas[i][ai] ? 'Chiudi il reparto' : 'Apri il reparto'"></i>
                                    </td>
                                    <td nzAlign="center">
                                        {{a.id}}
                                    </td>
                                    <td colSpan="7">
                                        <div *ngIf="!editCacheA[data.id][a.id]?.edit">{{a.nome}}</div>
                                        <div *ngIf="editCacheA[data.id][a.id]?.edit">
                                            <input type="text" nz-input [(ngModel)]="editCacheA[data.id][a.id].data.nome" />
                                        </div>
                                    </td>
                                    <td nzAlign="center" [ngClass]="a.attivo ? 'uiOnCol':'uiOffCol'">
                                        <nz-switch [(ngModel)]="a.attivo" (ngModelChange)="onChangeSwitchA($event, i, ai)" nz-tooltip [nzTooltipTitle]="a.attivo ? 'Reparto attivo' : 'Reparto non attivo'"></nz-switch>
                                    </td>
                                    <td nzAlign="center" *ngIf="!editCacheA[data.id][a.id]?.edit">
                                        <button  nz-button nzType="text"><i style="margin-right: 10px;" class="customIcon uiExcelIcon" (click)="utentiXlsImport(tplUserImport, tplUserFooter, displayedData[i],  ai)" nz-tooltip nzTooltipTitle="Importa da Excel"></i></button>
                                        <button [disabled]="addUser" nz-button nzType="text" (click)="newU(i, ai)"><i style="margin-right: 10px;" class="customIcon uiAddIcon" nz-tooltip nzTooltipTitle="Aggiungi un utente"></i></button>
                                        <button  nz-button nzType="text"><i style="margin-right: 10px;" class="customIcon uiTrashGreyIcon" nz-popconfirm nzPopconfirmTitle="Eliminare questa riga? deleteA" (nzOnConfirm)="deleteA(i, ai)" nz-tooltip nzTooltipTitle="Elimina il reparto"></i></button>
                                        <button  nz-button nzType="text"><i class="customIcon uiEditIcon" (click)="editA(data.id, a.id)" nz-tooltip nzTooltipTitle="Modifica il nome"></i></button>
                                    </td>
                                    <td nzAlign="center" *ngIf="editCacheA[data.id][a.id]?.edit">
                                        <i style="margin-right: 10px;" class="customIcon uiSaveRedIcon" (click)="saveEditA(i, ai, data.id, a.id)"></i>
                                        <i style="margin-right: 10px;" class="customIcon uiCloseGreyIcon" (click)="cancelEditA(i, ai, data.id, a.id)"></i>
                                    </td>
                                </tr>
                                <!-- ############### USERS -->
                                <ng-container *ngIf="openAreas[i][ai]">
                                    <tr *ngFor="let u of a.utenti; let ui = index" [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                        <!-- ############### SPACE -->
                                        <td class="uiBlackCol"></td>
                                        <td class="uiBlackCol"></td>
                                        <!-- ############### ICON -->
                                        <td colSpan="1" nzAlign="center" [ngClass]="u.admin ? 'uiYellowCol' : ''" *ngIf="!editCacheU[data.id][a.id][u.id].edit">
                                            <i class="customIcon uiSuperUserIcon" style="margin:0" *ngIf="u.admin" (click)="changeUserAdmin(false, i, ai, ui)" nz-tooltip nzTooltipTitle="Utente amministratore"></i>
                                            <i class="customIcon uiUserIcon" style="margin:0" *ngIf="!u.admin" (click)="changeUserAdmin(true, i, ai, ui)" nz-tooltip nzTooltipTitle="Utente normale"></i>
                                        </td>
                                        <td nzAlign="center" *ngIf="editCacheU[data.id][a.id][u.id].edit" [ngClass]="editCacheU[data.id][a.id][u.id].data.admin ? 'uiYellowCol' : ''">
                                            <!-- <nz-switch [(ngModel)]="editCacheU[data.id][a.id][u.id].data.admin">
                                            </nz-switch> -->
                                            <i class="customIcon uiSuperUserIcon" style="margin:0; cursor: default;" *ngIf="u.admin"></i>
                                            <i class="customIcon uiUserIcon" style="margin:0; cursor: default;" *ngIf="!u.admin"></i>
                                        </td>
                                        <!-- ############### NAME -->
                                        <td nzEllipsis colSpan="1">
                                            <div *ngIf="!editCacheU[data.id][a.id][u.id].edit">{{u.descrizione}}</div>
                                            <div *ngIf="editCacheU[data.id][a.id][u.id].edit">
                                                <input type="text" nz-input [(ngModel)]="editCacheU[data.id][a.id][u.id].data.descrizione" placeholder="Inserisci descrizione" />
                                            </div>
                                        </td>
                                        <!-- ############### MOBILE-EMAIL -->
                                        <td colSpan="3" style="padding: 0">
                                            <div nz-row>
                                                <div nz-col nzSpan="18">
                                                    <div nz-row style="border-bottom: 2px solid #a1a1a0;">
                                                        <div nz-row class="flex-row">
                                                            <!-- ############### EMAIL -->
                                                            <span nz-icon nzType="mail" nzTheme="outline" class="icon-prefix"></span>
                                                            <div *ngIf="!editCacheU[data.id][a.id][u.id].edit">{{u.email}}</div>
                                                            <div *ngIf="editCacheU[data.id][a.id][u.id].edit"><input type="text" nz-input [(ngModel)]="editCacheU[data.id][a.id][u.id].data.email" placeholder="Email" /></div>
                                                        </div>
                                                    </div>
                                                    <div nz-row>
                                                        <div nz-col class="flex-row">
                                                            <!-- ############### MOBILE -->
                                                            <span nz-icon nzType="mobile" nzTheme="outline" class="icon-prefix"></span>
                                                            <div *ngIf="!editCacheU[data.id][a.id][u.id].edit">{{u.mobile}}</div>
                                                            <div *ngIf="editCacheU[data.id][a.id][u.id].edit"><input type="text" nz-input [(ngModel)]="editCacheU[data.id][a.id][u.id].data.mobile" placeholder="Cellulare" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- ############### EMAIL -->
                                                <div nz-col nzSpan="6" class="send-type">
                                                    <nz-switch [nzDisabled]="!u.mobile || !u.email || editCacheU[data.id][a.id][u.id].edit" [(ngModel)]="u.sendtype" [nzUnCheckedChildren]="unCheckedTemplate" [nzCheckedChildren]="checkedTemplate" (ngModelChange)="onChangeType($event, i, ai, ui);"></nz-switch>
                                                    <ng-template #checkedTemplate><span nz-icon nzType="mobile"></span></ng-template>
                                                    <ng-template #unCheckedTemplate><span nz-icon nzType="mail"></span></ng-template>
                                                </div>
                                            </div>
                                        </td>

                                        <!-- ############### RESET -->
                                        <td nzAlign="center">
                                            <i *ngIf="u.admin" class="customIcon uiResetIcon" (click)="showManualChange(i, ai, ui)"></i>
                                        </td>

                                        <!-- ############### PASSWORD -->
                                        <td colSpan="1">
                                            <input nz-input [ngModel]="u.pwd" disabled type="password" nzBorderless style="color: #202020; cursor:default;" />
                                        </td>

                                        <!-- ############### LAST ACCESS -->
                                        <td nzEllipsis nzAlign="center">
                                            {{u.ultimo_accesso.seconds * 1000 | date: 'dd/MM/yyyy HH:mm:ss'}}
                                        </td>
                                        <td nzAlign="center" [ngClass]="u.attivo ? 'uiOnCol':'uiOffCol'">
                                            <nz-switch [ngModel]="u.attivo" [nzDisabled]="editCacheU[data.id][a.id][u.id].edit" (ngModelChange)="onChangeSwitchU($event, i, ai, ui);"></nz-switch>
                                        </td>
                                        <td nzAlign="center" *ngIf="!editCacheU[data.id][a.id][u.id].edit">
                                            <i class="customIcon uiTrashGreyIcon" nz-popconfirm nzPopconfirmTitle="Eliminare questa riga?" (nzOnConfirm)="deleteU(i, ai, ui)"></i>
                                            <i class="customIcon uiEditIcon" (click)="editU(data.id, a.id, u.id)"></i>
                                        </td>
                                        <td nzAlign="center" *ngIf="editCacheU[data.id][a.id][u.id].edit">
                                            <i style="margin-right: 10px;" class="customIcon uiSaveRedIcon" (click)="saveEditU(i, ai, ui, data.id, a.id, u.id)"></i>
                                            <i style="margin-right: 10px;" class="customIcon uiCloseGreyIcon" (click)="cancelEditU(i, ai, ui, data.id, a.id, u.id)"></i>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tbody>
            </nz-table>
        </div>
    </nz-content>

    <div class="waiter" *ngIf="loading">
          <span class="ant-spin-dot ant-spin-dot-spin ng-star-inserted"><i class="ant-spin-dot-item"></i><i
          class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span>
        </div>
</nz-layout>

<nz-modal [(nzVisible)]="manualChange" nzTitle="" nzFooter="null" [nzContent]="modalManualContent" nzWrapClassName="innerRecoverModal" (nzOnCancel)="handleCancel()">
    <ng-template #modalManualContent>
        <form [formGroup]="pwdForm">
            <div nz-row class="uiFormRow">
                <h1>{{'resetPwd' | translate}}</h1>
                <input type="password" nz-input formControlName="password" placeholder="Inserisci password" style="margin-bottom: 0;" />
                <div *ngIf="pwdForm.get('password')?.invalid && (pwdForm.get('password')?.dirty && pwdForm.get('password')?.touched)">
                    <small class="uiAlertMessage" *ngIf="pwdForm.get('password')?.hasError('minlength')" style="color: var(--secondary-color);">
                        {{'pwdLengthAlert' | translate}}
                    </small>
                </div>
            </div>
            <div nz-row class="uiFormRow" style="margin-top: 1rem; margin-bottom: 0;">
                <input type="password" nz-input formControlName="confirmPassword" placeholder="Conferma password" style="margin-bottom: 0;" />
                <div *ngIf="(pwdForm.get('confirmPassword')?.dirty && pwdForm.get('confirmPassword')?.touched)">
                    <small class="uiAlertMessage" *ngIf="pwdForm.hasError('notSame')" style="color: var(--secondary-color);">
                        {{'pwdMatchAlert' | translate}}
                    </small>
                </div>
            </div>
            <div nz-row class="uiFormRow">
                <div nz-col nzSpan="12" class="uiFormSubmit">
                    <button nz-button nzSize="large" (click)="onSubmitManualChange()" nzType="primary" [disabled]="pwdForm.invalid" class="loginBtn">{{'saveCredential' |
                        translate}}</button>
                </div>
            </div>
        </form>
        <div class="uiLoginContainer" *ngIf="showError">
            <div class="uiFormContainer">
                <p class="errorMessage">{{'recoverError' | translate}}</p>
            </div>
        </div>
    </ng-template>
</nz-modal>

<ng-template #tplUserImport let-ref="modalRef">
    <nz-table class="importTable" #rowSelectionTable nzShowSizeChanger nzBordered [nzData]="utentiImport" [nzScroll]="{ y: '60vh' }" nzSize="small" nzShowPagination="false">
        <thead>
            <tr>
                <th colspan="3" nzAlign="left">IMPORTA UTENTI DA EXCEL<p style="font-size: 10px; ">Prima di premere iniziare copia i valori da importare dal foglio excel</p>
                </th>

            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let u of utentiImport">
                <tr nz-tooltip [nzTooltipTitle]="u.stato" [ngClass]="u.stato !== 'OK' ? 'uiOffCol': 'uiOnCol'">
                    <td>{{ u.nome }}</td>
                    <td>{{ u.email}}</td>
                    <td>{{ u.mobile}}</td>
                </tr>
            </ng-container>
        </tbody>
    </nz-table>
</ng-template>
<ng-template #tplUserFooter let-ref="modalRef">
    <div nz-row>
        <div nz-col nzSpan="12" style="text-align: left;">
            <button *ngIf="utentiImport.length === 0" [nzLoading]="isImporting" nzType="primary" nz-button (click)="utentiGetClipboard()"><span nz-icon nzType="snippets"></span>Importa dati</button>
        </div>
        <div nz-col nzSpan="12">
            <button nz-button (click)="ref.destroy()">Annulla</button>
            <button nz-button nzType="primary" [disabled]="utentiImportCount == 0" (click)="utentiSaveImport(ref)">
                Importa utenti
            </button>
        </div>
    </div>
</ng-template>
