<div class="uiCompilazioneHeadContainer" *ngIf="!loading">
    <nz-layout class="uiHeadInnerLayout">
        <nz-header class="uiCompilazioneHeadHeader">
            <img *ngIf="compile.custLogo" [src]="compile.custLogo" alt="logo" class="uiHeaderCustLogo">
            <div class="uiQuestTitle" [ngStyle]="{'text-align': (compile.custLogo) ? 'center' : 'left'}">
                <span *ngIf="questTitle" class="uiTitleMain">{{questTitle}}</span><br><span class="uiSubtitleMain" *ngIf="questVersion">{{questVersion}}<span *ngIf="questSubTitle"> - </span></span> <span *ngIf="questSubTitle" class="uiSubtitleMain">{{questSubTitle}}</span>
            </div>
            <img src="../../../../assets/logo-light.png" alt="logo" class="uiHeaderLogo">
        </nz-header>
        <nz-content class="uiCompilazioneHeadContent">
            <h1>Benvenuti</h1>
            <span class="uiQuestMobileTitle" style="display: none;">{{questTitle}}</span>
            <form [formGroup]="userForm" class="uiCompilazioneHeadForm">
                <div nz-row class="uiFormRow" *ngFor="let h of head">
                    <input class="uiFormInput" nz-input *ngIf="h.tipo !== 'Range' && h.tipo !== 'Elenco' && h.tipo !== 'Booleano' && h.tipo !== 'Nota' && h.tipo !== 'Data'" [required]="h.required" [formControlName]="h.etichetta" [placeholder]="h.etichetta">

                    <nz-input-number class="uiFormInputNumber" *ngIf="h.tipo === 'Range'" [required]="h.required" [formControlName]="h.etichetta" [nzPlaceHolder]="h.etichetta" [nzMax]="h.massimo" [nzMin]="h.minimo">
                    </nz-input-number>
                    <div *ngIf="h.tipo === 'Elenco'" class="uiFormRadioContainer">
                        <p>{{h.etichetta}}:</p>
                        <nz-radio-group *ngIf="h.multiplo === false" nzSize="large" class="uiFormRadio" [formControlName]="h.etichetta" [required]="h.required">
                            <label nz-radio [formArrayName]="h.etichetta" *ngFor="let v of h.values" [nzValue]="v">{{v}}</label>
                        </nz-radio-group>
                        <!-- <div *ngIf="h.multiplo === false">
                            <label *ngFor="let v of h.values" class="uiFormRadio">
                                <input type="radio" [formArrayName]="h.etichetta" [value]="v" (change)="onRadioboxChange($event, h.etichetta)" />
                                {{v}}
                            </label>
                        </div> -->
                        <div *ngIf="h.multiplo === true">
                            <label *ngFor="let v of h.values" class="uiFormRadio">
                                <input type="checkbox" [formArrayName]="h.etichetta" [value]="v" (change)="onCheckboxChange($event, h.etichetta)" />
                                {{v}}
                            </label>
                        </div>
                    </div>
                    <div *ngIf="h.tipo === 'Booleano'" class="uiFormSwitchContainer">
                        <span class="uiFormLabel">{{h.etichetta}}:</span>
                        No <nz-switch class="uiFormSwitch" [formControlName]="h.etichetta" [required]="h.required">
                        </nz-switch> Si
                    </div>
                    <div *ngIf="h.tipo === 'Nota'" class="uiFormNote" [innerHTML]="h.testo">
                    </div>

                    <nz-date-picker *ngIf="h.tipo === 'Data'" class="uiFormInput" [formControlName]="h.etichetta" nzFormat="dd/MM/yyyy" [nzLocale]="g.dataITtranslation"></nz-date-picker>
                    <div *ngIf="userForm.get(h.etichetta)?.errors?.required && invalidForm === true" class="uiErrorContainer">
                        <p class="uiAlertMessage" style="color: var(--neutral-color);">
                           * Campo obbligatorio
                        </p>
                    </div>
                    <div *ngIf="userForm.get([h.etichetta])?.errors?.invalidCf && invalidForm === true" class="uiErrorContainer">
                        <p class="uiAlertMessage" style="color: var(--neutral-color);">
                            * Codice fiscale non valido
                        </p>
                    </div>
                    <div *ngIf="userForm.get([h.etichetta])?.errors?.invalidPiva && invalidForm === true" class="uiErrorContainer">
                        <p class="uiAlertMessage" style="color: var(--neutral-colo);">
                           *  Partiita iva non corretta
                        </p>
                    </div>
                    <ng-container *ngIf="h.etichetta == 'E-mail'  && !userForm.get(h.etichetta)?.errors?.required">
                      <div *ngIf=" !userForm.get('E-mail')?.valid" class="uiErrorContainer">
                        <p class="uiAlertMessage" style="color: var(--neutral-color);">
                          * Email non valida
                        </p>
                      </div>

                    </ng-container>
                </div>
                <div class="uiPrivacyHeadRow">
                    <p><span class="uiPrivacyTitle">{{privacy.titolo}}</span>
                        <span *ngIf="privacy.allegato['ITA']">
                            <br />
                            <a (click)="downloadFile(privacy.allegato['ITA'])" class="uiAttachLink">Approfondisci</a>
                        </span>
                    </p>
                </div>
                <div nz-row class="uiFormRow uiPrivacyFormRow" *ngFor="let p of privacyCheck">
                    <div class="uiFormPrivacyContainer">
                        <p>{{p.testo}} <small *ngIf="p.richiesto === true">(Obbligatorio)</small></p>
                        <nz-radio-group class="uiFormRadio" [formControlName]="'_privacy' + p.codice" *ngIf="p.richiesto === false">
                            <label nz-radio [nzValue]="p.accetto">{{p.accetto}}</label>
                            <label nz-radio [nzValue]="p.non_accetto">{{p.non_accetto}}</label>
                        </nz-radio-group>
                        <nz-radio-group class="uiFormRadio" [formControlName]="'_privacy' + p.codice" *ngIf="p.richiesto === true">
                            <label nz-radio [nzValue]="p.accetto">{{p.accetto}}</label>
                            <label nz-radio nzValue="">{{p.non_accetto}}</label>
                        </nz-radio-group>
                        <div *ngIf="userForm.get('_privacy' + p.codice)?.invalid && invalidForm === true" class="uiErrorContainer">
                            <p class="uiAlertMessage" style="color: var(--neutral-color);">
                               * Campo obbligatorio
                            </p>
                        </div>
                    </div>
                </div>
                <div nz-row class="uiFormRow" style="justify-content: center;">
                    <div class="uiFormSubmit">
                        <button nz-button nzType="primary" class="uiHeadFormSubmit" (click)="onSubmit()">Salva e prosegui</button>
                    </div>
                </div>
            </form>
        </nz-content>
      </nz-layout>
    </div>
    <div class="waiter" *ngIf="isSpinning">
        <span class="ant-spin-dot ant-spin-dot-spin ng-star-inserted"><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span>
    </div>

<app-legal-footer></app-legal-footer>

