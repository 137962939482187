import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { StorageService } from "./storage.service";
import { GlobalService } from "../shared/global.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class NodeService {
  public baseUrl: string;
  key: string;
  email: string;

  CryptoJS = require("crypto-js");

  // ######################################
  // FUNCTION: constructor
  constructor(private http: HttpClient,) {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      // console.log("NODE API: local development");
      this.baseUrl = "http://localhost:3010";
      //// console.log("NODE API: development");
      //this.baseUrl = "https://collnode.amajorsb.com";
    } else if (location.hostname === "collapp.amajorsb.com") {
      // console.log("NODE API: development");
      this.baseUrl = "https://collnode.amajorsb.com";
    } else {
      // console.log("NODE API: production");
      this.baseUrl = "https://node.amajorsb.com";
    }
  }

  // ######################################
  // FUNCTION: recoverUser
  recoverUser(formData: any): Observable<Error | any> {
    const header = new HttpHeaders();
    header.append("Content-Type", "application/json");

    return this.http.post<any>(this.baseUrl + "/users/recover", formData).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
    );
  }

  // ######################################
  // FUNCTION: getUserBy
  getUserBy(formData: any): Observable<Error | any> {
    const header = new HttpHeaders();
    header.append("Content-Type", "application/json");
    return this.http.post<any>(this.baseUrl + "/users/getby", formData).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
    );
  }

  // ######################################
  // FUNCTION: editUser
  editUser(formData: any): Observable<Error | any> {
    const header = new HttpHeaders();
    header.append("Content-Type", "application/json");
    return this.http.post<any>(this.baseUrl + "/users/edit", formData).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
    );
  }

  // ######################################
  // FUNCTION: editCustomerUser
  editCustomerUser(formData: any): Observable<Error | any> {
    const header = new HttpHeaders();
    header.append("Content-Type", "application/json");
    return this.http.post<any>(this.baseUrl + "/clienti/edit", formData).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
    );
  }

  // ######################################
  // FUNCTION: callNodeUrl
  callNodeUrl(formData: any, url: string): Observable<Error | any> {
    const header: HttpHeaders = new HttpHeaders();
    header.append("Content-Type", "application/json");
    //header.append("Content-Type", "application/x-www-form-urlencoded");
    return this.http.post<any>(this.baseUrl + url, formData).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        } else {
          // A backend error occured (err.status = 500)
          const details = { detail: err.error, status: err.status };
          return throwError(details);
        }
      })
    );
  }

  // ######################################
  // FUNCTION: getData
  getData(getData: any | null, url: string) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    //// console.log("BASE URL", this.baseUrl + url, "URL", url);
    const data = this.crypto(getData);
    return this.http
      .get<any>(this.baseUrl + url, { headers: headers, responseType: "blob" as "json", params: { data } })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          } else {
            // A backend error occured (err.status = 500)
            const details = { detail: err.error, status: err.status };
            return throwError(details);
          }
        })
      );
  }

  // ######################################
  // FUNCTION: exportData
  // !!!!!!!!!!!!!!!! DEPRECATED !!!!!!!!!!!!!!!!!!!!!!!!!!!
  // exportData(data: any | null, url: string) {
  //   let headers = new HttpHeaders();
  //   headers = headers.set("Accept", "application/json");
  //   return this.http.post<any>(this.baseUrl + url, { headers: headers, params: data }).pipe(
  //     catchError((err: HttpErrorResponse) => {
  //       if (err.error instanceof Error) {
  //         // A client-side or network error occurred
  //         const details = { detail: err.error, status: err.status };
  //         return throwError(details);
  //       } else {
  //         // A backend error occured (err.status = 500)
  //         const details = { detail: err.error, status: err.status };
  //         return throwError(details);
  //       }
  //     })
  //   );
  // }

  // funzione che cripta la chiamata
  crypto(value: any) {
    const key = sessionStorage.getItem('key') || '';
    const email = sessionStorage.getItem('email') || '';
    const id = sessionStorage.getItem('id') || '';
    const data = JSON.stringify(
      {
        'data': this.CryptoJS.TripleDES.encrypt(JSON.stringify(value), key).toString(),
        email,
        id
      });
    return data;
  }
}
