import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-pdfmodal',
  templateUrl: './pdfmodal.component.html',
  styleUrls: ['./pdfmodal.component.scss']
})
export class PdfmodalComponent implements OnInit {
  @Input() file: string;
  constructor(private modal: NzModalRef, private sanitizer: DomSanitizer) { }
  fileUrl: SafeResourceUrl;

  ngOnInit(): void {
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.file);
  }
}
