import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FireService } from "src/app/services/fire.service";
import { GlobalService } from "src/app/shared/global.service";
import { InterfaceService } from "src/app/shared/interface.service";

@Component({
  selector: "app-aggregati",
  templateUrl: "./aggregati.component.html",
  styleUrls: ["./aggregati.component.scss"],
})
export class AggregatiComponent implements OnInit {
  reps: any[] = [];
  loading = true;
  openFolder: any[] = [];
  openAreas: any[] = [];

  search = "";
  displayedData: any[] = [];

  questDocs: any[] = [];
  questVersions: { [key: string]: { versioni: any; }; } = {};

  editCacheL: { [key: string]: { [key2: string]: { edit: boolean; data: any; }; }; } = {};
  viewAll = false;
  now: number;
  scroll = { y: "" };

  // ######################################
  // FUNCTION: constructor
  constructor(
    public g: GlobalService,
    public face: InterfaceService,
    private translate: TranslateService,
    private fire: FireService,
    private router: Router
  ) { }

  // ######################################
  // EVENT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiGestioneTableContainer").length > 0) {
      let height = document.getElementsByClassName("uiGestioneTableContainer")[0].clientHeight;
      if (
        document.getElementsByClassName("uiGestioneTableContainer")[0].getElementsByClassName("ant-table-thead").length
      ) {
        let headH = document
          .getElementsByClassName("uiGestioneTableContainer")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }

  // ######################################
  // FUNCTION: ngAfterViewInit
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant("questManagment");
    });
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    let tempPref = this.face.getAggregatiInterface();
    if (tempPref === null) {
      tempPref = {};
    }

    this.now = new Date(Date.now()).getTime();
    this.displayedData = [];
    this.fire.getAllAsc("clienti", "codice").subscribe((res) => {
      if (res.length === 0) {
        this.loading = false;
      }
      this.reps = res;
      for (let i = 0; i < res.length; i++) {
        if (typeof tempPref[res[i].id] === "undefined" || tempPref[res[i].id] === null) {
          tempPref[res[i].id] = { open: true, sub: {} };
          this.openFolder[res[i].id] = true;
        } else {
          this.openFolder[res[i].id] = tempPref[res[i].id].open;
        }

        this.openAreas[res[i].id] = [];
        let e = res[i];
        e.licenze = [];
        this.fire.getCollection("clienti", e.id, "licenze").subscribe((res3) => {
          if (res3.length > 0) {
            let licenze = res3;
            licenze.forEach((l, rindex) => {
              if (typeof tempPref[res[i].id].sub[l.id] === "undefined" || tempPref[res[i].id].sub[l.id] === null) {
                tempPref[res[i].id].sub[l.id] = true;
                this.openAreas[res[i].id][l.id] = true;
              } else {
                this.openAreas[res[i].id][l.id] = tempPref[res[i].id].sub[l.id];
              }
              this.fire.getSubCollection("clienti", e.id, "licenze", l.id, "compilazioni").subscribe((res5) => {
                l.destinatari = res5.length;
                l.compilazioni = [];
                if (l.questionario && l.versione) {
                  this.fire.getDocument("questionari", l.questionario, "versioni", l.versione).subscribe((res4) => {
                    if (res4.tipo === 2) {
                      l.quest = res4;
                      e.licenze.push(l);
                      let index = this.displayedData.findIndex((d) => d.id === e.id);
                      if (index < 0) {
                        this.displayedData.push(e);
                      }
                      this.loading = false;

                      this.face.saveAggregatiInterface(tempPref);
                      this.setScrollHeight();
                    }
                  });
                }

              });

              l.reparti.forEach((rep) => {
                let repId = rep.split(" ")[0];
                l.repartiAttivi = [];
                this.fire.getDocument("clienti", e.id, "reparti", repId).subscribe((resR) => {
                  this.fire.getSubCollection("clienti", e.id, "reparti", resR.id, "utenti").subscribe((rUser) => {
                    let index = l.repartiAttivi.findIndex((d) => d.id === resR.id);
                    if (index < 0) {
                      l.repartiAttivi.push({
                        id: resR.id,
                        nome: resR.nome,
                        utenti: rUser.length,
                      });
                    }
                  });
                });
              });
            });
            this.loading = false;
          }
        });
      }
    });


  }

  // ######################################
  // FUNCTION: openArea
  openArea(i: number) {
    if (this.openFolder[this.displayedData[i].id]) {
      this.openFolder[this.displayedData[i].id] = false;
      this.face.openChange(false, "AGGREGATI", this.displayedData[i].id);
    } else {
      this.openFolder[this.displayedData[i].id] = true;
      this.face.openChange(true, "AGGREGATI", this.displayedData[i].id);
      this.loading = false;
    }
  }

  // ######################################
  // FUNCTION: openSubarea
  openSubarea(i: number, ai: number) {
    if (this.openAreas[this.displayedData[i].id][this.displayedData[i].licenze[ai].id]) {
      this.openAreas[this.displayedData[i].id][this.displayedData[i].licenze[ai].id] = false;
      this.face.openChange(false, "AGGREGATI", this.displayedData[i].id, this.displayedData[i].licenze[ai].id);
    } else {
      this.openAreas[this.displayedData[i].id][this.displayedData[i].licenze[ai].id] = true;
      this.loading = false;
      this.face.openChange(true, "AGGREGATI", this.displayedData[i].id, this.displayedData[i].licenze[ai].id);
    }
  }

  // ######################################
  // FUNCTION: filter
  filter() {
    this.displayedData = this.reps.filter(
      (r) =>
        r.id.toUpperCase().includes(this.search.toUpperCase()) ||
        r.ragione_sociale.toUpperCase().includes(this.search.toUpperCase())
    );
  }

  // ######################################
  // FUNCTION: editRep
  editRep(a, r, l) {
    this.router.navigateByUrl("admin/gestione/aggregati/edit/" + a + "/" + r + "/" + l);
  }
}
