import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FireService } from "src/app/services/fire.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"],
})
export class PrivacyComponent implements OnInit {
  resData: any[] = [];
  search = "";
  displayedData: any[] = [];
  loading: boolean = true;
  scroll = { y: "" };

  // ######################################
  // FUNCTION: constructor
  constructor(private fire: FireService, private router: Router, public g: GlobalService) { }

  // ######################################
  // EVENT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiGestioneTableContainer").length > 0) {
      let height = document.getElementsByClassName("uiGestioneTableContainer")[0].clientHeight;
      if (
        document.getElementsByClassName("uiGestioneTableContainer")[0].getElementsByClassName("ant-table-thead").length
      ) {
        let headH = document
          .getElementsByClassName("uiGestioneTableContainer")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    this.fire.getAllAsc("privacy", "codice").subscribe((ps) => {
      this.resData = ps;
      this.displayedData = this.resData;
      this.loading = false;
      this.setScrollHeight();
    });
  }

  // ######################################
  // FUNCTION: filter
  filter() {
    this.displayedData = this.resData.filter(
      (r) =>
        r.id.toUpperCase().includes(this.search.toUpperCase()) ||
        r.titolo.toUpperCase().includes(this.search.toUpperCase())
    );
  }

  // ######################################
  // FUNCTION: addPrivacy
  addPrivacy() {
    let priv: any = {};
    this.fire
      .getLast("privacy", "codice")
      .then((id) => {
        priv.codice = id;
        priv.attivo = false;
        priv.creazione = new Date(Date.now());
        priv.disattivazione = new Date(Date.now());
        priv.checkbox = {
          ITA: [],
        };
        priv.lingue = ["ITA"];
        priv.allegato = {
          ITA: "",
        };

        priv.titolo = "Privacy " + id;
        this.fire
          .add("privacy", priv, "P" + priv.codice.toString())
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            // console.log(err);
          });
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  // ######################################
  // FUNCTION: editPrivacy
  editPrivacy(id) {
    this.router.navigateByUrl("admin/gestione/privacy/edit/" + id);
  }

  // ######################################
  // FUNCTION: delete
  delete(id) {
    // check if licenze attive
    let privacyUsed = false;
    this.fire.getAll("questionari", "codice").subscribe((quests) => {
      if (quests.length === 0) {
        this.loading = false;
      }
      for (let q = 0; q < quests.length; q++) {
        if (!privacyUsed) {
          this.fire.getCollection("questionari", quests[q].id, "versioni").subscribe((vers) => {
            for (let v = 0; v < vers.length; v++) {
              if (!privacyUsed) {
                if (vers[v].privacy === id) {
                  privacyUsed = true;
                  this.g.createErrorNotification(
                    "Privacy in uso nel questionario <br/>" + quests[q].id + " - " + vers[v].id,
                    ""
                  );
                  v = vers.length;
                  q = quests.length;
                } else if (!privacyUsed && q === quests.length - 1 && v === vers.length - 1) {
                  this.fire.deleteDoc("privacy", id);
                }
              }
            }
          });
        }
      }
    });
  }
}
